import { Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import _ from 'lodash';
import TableHeading from 'components/common/table-header';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { Row, SubRow, SubSubRow } from '../../../../../common/table/table-data-row/rows';
import useLocalStore from 'hooks/user-local-store';
import { roles, routes } from 'constants/strings';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

const FormVI = () => {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formV'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );
  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form5, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form5[idx].actuals) {
      return data?.form5[idx].actuals;
    }
    return data?.form5[idx]?.projection;
  });

  return (
    <MainCard>
      <Table size="small">
        <TableHeading title="Fund Flow Analysis" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Assessment of working capital requirement</b>
              </Typography>
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>Sources</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Net Profit"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.totalCurrentAsset)}
              </NumberDenseCell>
            ))}
          />

          <SubRow
            title="Depreciation"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.otherCurrentLiabilities)}
              </NumberDenseCell>
            ))}
          />

          <SubRow
            title="Increase in Capital"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.workingCapitalGap)}
              </NumberDenseCell>
            ))}
          />

          <SubRow
            title="Increase in Term Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.minStipulatedNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Decrease in"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.actualNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <SubSubRow
            title="Fixed Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.item3MinusItem4)}
              </NumberDenseCell>
            ))}
          />

          <SubSubRow
            title="Other non-current Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.item3MinusItem5)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase in networth"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.maxPermissibleBankFinance)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Total Sources"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell heading key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>USES</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Net loss"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.totalCurrentAsset)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Decrease in Term Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.otherCurrentLiabilities)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase in"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.workingCapitalGap)}
              </NumberDenseCell>
            ))}
          />
          <SubSubRow
            title="Fixed Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.minStipulatedNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <SubSubRow
            title="Other non-current Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.actualNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Dividend Payments"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.item3MinusItem4)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Decrease in networth"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.item3MinusItem5)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Total Uses"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell heading key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.maxPermissibleBankFinance)}
              </NumberDenseCell>
            ))}
          />

          <Row
            title="Long Term Surplus (+) / Deficit (-) [1-2]"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase/decrease in current assets (as per details given below)*"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase/decrease in current liabilities other than bank borrowings"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase/decrease in working capital gap"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Net Surplus / Deficit (-) [3-6]"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase/decrease in bank borrowings"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Increase/decrease in NET SALES"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>* Break up of item-4</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Increase/decrease in Raw Materials"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase/decrease in Stocks-in-Process"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase/decrease in Finished Goods"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase/decrease in Receivables"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubSubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubSubRow
            title="Export"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase/decrease in Stores & Spares"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <SubRow
            title="Increase/decrease in other current assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
        </TableBody>
      </Table>
      <ReviewCamButton
        onClick={() => {
          navigate(routes.CAM_ROUTE, {
            state: { ...state, loanRequestId: state.loanRequestId },
          });
          const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
          camId && camId?.id && dispatch(changeMenuTab(camId?.id));
        }}
      />
    </MainCard>
  );
};

export default FormVI;
