import axios from 'axios';

export const formatNumber = (num: any) =>
  (num && Number(num)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) || num;

export const formatGridNumber = (params: any) => {
  if (typeof params.value === 'string' && (params.value.includes('(') || params.value.includes(')'))) {
    var commaSeparatedVal = formatNumber(params.value.trim().replace(/[()]/g, ''));
    return '(' + commaSeparatedVal + ')';
  }

  return Number(params.value) ? formatNumber(params.value) : params.value;
};

export const getUser = async () => {
  const userValue = localStorage.getItem('user');

  if (typeof userValue === 'string') {
    const userDetails = JSON.parse(userValue);
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/authenticate`, userDetails, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (res.data.jwtToken) {
      return res?.data;
    }
  }
  return null;
};

export const filterGridEmptyData = (data: any, keys: any) => {
  keys.forEach((key: any) => {
    if (data[key].length > 0) {
      const filteredData = data[key].filter((val: any) => {
        if (Object.keys(val).length > 0 && Object.values(val).some((x) => x !== '')) {
          return val;
        } else {
          return null;
        }
      });
      data[key] = filteredData;
    }
  });
  return data;
};

export const createDropdownOptions = (list: any) =>
  list.map((item: any) => ({
    displayText: item,
    id: item,
  }));
