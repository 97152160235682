import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { getCompanyDataById, registerCompany } from 'services/company-services';
import { screenActions } from 'constants/strings';
import { toggleLoader } from 'state/reducers/common-actions';
import { updateCompanyViewType } from './register-company-reducer';
import ActionBar from 'components/styled-components/ActionBar';
import SimpleAccordion from 'components/common/simple-accordian';
import CompanyBasicDetails from './basic-details';
import CompanyMenu from './company-menu';
import ShareholdingAndDirectors from './shareholding-and-directors';
import OtherInformations from './other-information';
import classes from './credit-analysis.module.css';
import { filterGridEmptyData } from 'utils/common-utils';

const AddCompany = () => {
  const [companyData, setCompanyData] = useState<any>(null);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const registerCompanyMutation = useMutation(registerCompany, {
    onSettled: () => {
      navigate('/dashboard/company-master', {
        replace: true,
      });
    },
  });
  const { companyId, screenAction } = state;
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { isLoading, error, data, refetch } = useQuery('orgById', () => getCompanyDataById(companyId || 39), {
    enabled: false,
  });

  if (isLoading) {
    dispatch(toggleLoader(true));
  }
  if (!isLoading || error) dispatch(toggleLoader(false));

  useEffect(() => {
    if (screenAction === screenActions.UPDATE_DATA) {
      refetch();
      setCompanyData(data);
    }

    if (pathname.includes('view-company')) {
      dispatch(updateCompanyViewType({ viewOnly: true }));
    }
  }, [state]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      foreignOperations: 'no',
      orgName: '',
      companyIncorporationDate: '',
      bankDetails: [{}],
      orgAddress: [{}],
      orgContact: [{}],
      directors: [{}],
      shareholding: [{}],
    },
  });

  const watchAllFields = watch();

  useEffect(() => {
    if (screenAction === screenActions.UPDATE_DATA) {
      reset({
        ...data,
        bankDetails: data?.bankDetails.length > 0 ? data?.bankDetails : [{}],
        orgAddress: data?.orgAddress.length > 0 ? data?.orgAddress : [{}],
        orgContact: data?.orgContact.length > 0 ? data?.orgContact : [{}],
        directors: data?.directors.length > 0 ? data?.directors : [{}],
        shareholding: data?.shareholding.length > 0 ? data?.shareholding : [{}],
      });
    }
  }, [companyData, screenAction, data]);

  const onSubmit = (data: any) => {
    if (
      !data.companyIncorporationDate ||
      data.companyIncorporationDate === '' ||
      data.companyIncorporationDate === null
    ) {
      return enqueueSnackbar('Incorporation Date is Required', {
        variant: 'error',
      });
    }

    const filteredData = filterGridEmptyData(data, [
      'bankDetails',
      'orgAddress',
      'orgContact',
      'directors',
      'shareholding',
    ]);

    registerCompanyMutation.mutate(filteredData);

    if (state.screenAction === screenActions.CREATE_NEW) {
      enqueueSnackbar('Company Registered', {
        variant: 'success',
      });
    }

    enqueueSnackbar('Company Updated', {
      variant: 'success',
    });
  };

  const showErrors = () => {
    enqueueSnackbar('Please fill all required fields', {
      variant: 'error',
      autoHideDuration: 5000,
    });
  };

  return (
    <Box sx={{ height: 'calc(100vh - 100px)' }}>
      {/* <CompanyMenu /> */}
      <Box sx={{ p: 1 }} id="containerElement" className={classes.formContainer}>
        <form onSubmit={handleSubmit(onSubmit, showErrors)}>
          {!pathname.includes('view-company') ? (
            <ActionBar>
              <Button type="submit" variant="contained" sx={{ p: 1, right: 20 }}>
                Submit
              </Button>
            </ActionBar>
          ) : (
            <ActionBar></ActionBar>
          )}
          <Grid container>
            <Grid item lg={12}>
              <Element name="company">
                <SimpleAccordion
                  title={'Company Profile'}
                  expand={true}
                  children={
                    <CompanyBasicDetails
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      watchAllFields={watchAllFields}
                    />
                  }
                />
              </Element>
            </Grid>
            <Grid item lg={12}>
              <Element name="shareholding">
                <SimpleAccordion
                  title={t('companyMaster.shareholdings.heading')}
                  children={
                    <ShareholdingAndDirectors
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      watchAllFields={watchAllFields}
                    />
                  }
                />
              </Element>
            </Grid>
            <Grid item lg={12}>
              <Element
                name="other-information"
                style={{
                  marginBottom: '2%',
                }}>
                <SimpleAccordion
                  title={t('companyMaster.otherInfo.heading')}
                  children={
                    <OtherInformations
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      watchAllFields={watchAllFields}
                    />
                  }
                />
              </Element>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddCompany;
