const FONT_PRIMARY = 'Roboto';
const FONT_SIZE = 16;

const typography = {
  fontFamily: FONT_PRIMARY,
  fontSize: FONT_SIZE,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: '30px',
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: '26px',
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '23px',
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '20px',
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '17px',
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: '13px',
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: '13px',
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: '12px',
  },
  body1: {
    lineHeight: 1.5,
    fontSize: '12px',
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: '11px',
  },
  caption: {
    lineHeight: 1.5,
    fontSize: '12px',
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '10px',
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: '18px',
    fontSize: '13px',
    textTransform: 'capitalize',
  },
};

export default typography;
