import { Box, styled, Typography, Link } from '@mui/material';
import { useState } from 'react';
import { getPublicConfig } from 'services/common-services';
import { useQuery } from 'react-query';
import company from '../../assets/images/company.png';

const ActionBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  zIndex: 999,
  backgroundColor: theme.palette.primary.main,
  height: '48px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  left: 0,
}));

const ActionBar = ({ children }: any) => {
  const [footerText, setFooterText] = useState('');
  useQuery(['getPublicConfig'], getPublicConfig, {
    onSuccess: (res: any) => {
      res.forEach((e: any) => {
        if (e.configKey === 'COPY_RIGHT_MSG') {
          const value = e?.configValue.replace(
            'GlobnX',
            `<img style="height: 20px; width: 50px" src="${company}" />`
          );
          setFooterText(value);
        }
      });
    },
  });

  return (
    <ActionBox>
      <Box sx={{ width: '30%', margin: '14px' }}>
        <Typography variant="caption" align="center" sx={{ mt: 5, color: '#f7f9fd', fontSize: '13px' }}>
          <div style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: footerText }} />
        </Typography>
      </Box>

      <Box sx={{ margin: '7px 0px 5px 0' }}>{children}</Box>
    </ActionBox>
  );
};

export default ActionBar;
