import _ from 'lodash';
import { IRefData } from 'models/api';
import { useQuery } from 'react-query';
import { getRefDataByGroups } from 'services/common-services';

const useRefData = () => {
  const { data } = useQuery('getRefData', () =>
    getRefDataByGroups(
      'CurrencyList,LoanTypes,SourceOfRePayment,FinancialYearTypes,RequestTypes,TypeOfMoratorium,PaymentType,SubIndustryTypes,ProjectTemplate,InterestCalcTypes,DepricationMethodTypes,IndustryTypes,EntityTypes,CountryList,MemoTypes,ApproverTypes,RecommendedTypes,CopyTypes,ApprovalTypes,LoanSubTypes-Both,LoanSubTypes-Term-Loan,LoanSubTypes-Working-Capital'
    )
  );

  const currencyData: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'CurrencyList');
  const requestType: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'RequestTypes');
  const loanType: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'LoanTypes');
  const loanSubType: IRefData[] = _.filter(data, (el: IRefData) => ["LoanSubTypes-Both","LoanSubTypes-Term-Loan","LoanSubTypes-Working-Capital"].includes(el.groupName));
  const sourceOfRePayment: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'SourceOfRePayment');
  const fiscalYear: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'FinancialYearTypes');
  const financialYearTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'FinancialYearTypes');
  const typeOfMoratorium: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'TypeOfMoratorium');
  const paymentType: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'PaymentType');
  const projectionTemplate: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'ProjectTemplate');
  const interestCalcTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'InterestCalcTypes');
  const depreciationMethods: IRefData[] = _.filter(
    data,
    (el: IRefData) => el.groupName === 'DepricationMethodTypes'
  );
  const industryTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'IndustryTypes');
  const subIndustryTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'SubIndustryTypes');
  const entityTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'EntityTypes');
  const countryList: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'CountryList');
  const memoTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'MemoTypes');
  const approverTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'ApproverTypes');
  const recommendedTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'RecommendedTypes');
  const copyTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'CopyTypes');
  const approvalTypes: IRefData[] = _.filter(data, (el: IRefData) => el.groupName === 'ApprovalTypes');

  const mapIndustryType = (industryId: number) => {
    if (!industryId) return;
    const _found = _.find(industryTypes, (el) => el.id.toString() === industryId.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapSubIndustryType = (subIndustryTypeId: number) => {
    if (!subIndustryTypeId) return;
    const _found = _.find(subIndustryTypes, (el) => el.id.toString() === subIndustryTypeId.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapCurrencyData = (currencyId: number) => {
    if (!currencyId) return null;
    const _found = _.find(currencyData, (el) => el?.id?.toString() === currencyId?.toString());

    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapRequestType = (requestTypeId: number) => {
    const _found = _.find(requestType, (el) => el?.id?.toString() === requestTypeId?.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapLoanType = (loanTypeId: number) => {
    const _found = _.find(loanType, (el) => el?.id?.toString() === loanTypeId?.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapLoanSubType = (subLoanTypeId: any) => {
    const _found = _.filter(loanSubType, (el) => subLoanTypeId?.toString().split(",")?.includes(el?.id?.toString()));
    if (_found) {
      return _found?.map((ls:any) => ls?.displayText).join(", ");
    }
    return 'N/A';
  };

  function mapSourceOfRepayment(sourceOfRePaymentId: number) {
    if (!sourceOfRePaymentId || sourceOfRePayment === null) return 'N/A';
    const _found = _.find(sourceOfRePayment, (el) => el.id.toString() === sourceOfRePaymentId.toString());
    if (_found) {
      return _found.displayText;
    }
    return 'N/A';
  }

  function mapFiscalYear(fiscalYearId: number) {
    if (!fiscalYearId) return 'March 31';
    const _found = _.find(fiscalYear, (el) => el.id.toString() === fiscalYearId.toString());
    if (_found) {
      return _found.displayText;
    }
    return 'March 31';
  }

  const mapProjectionTemplate = (projectionTemplateId: number) => {
    if (!projectionTemplateId) return 'N/A';
    const _found = _.find(projectionTemplate, (el) => el?.id?.toString() === projectionTemplateId?.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };

  const mapMemoType = (memoTypeId: number) => {
    if (!memoTypeId) return 'N/A';
    const _found = _.find(memoTypes, (el) => el?.id?.toString() === memoTypeId?.toString());
    if (_found) {
      return _found?.displayText;
    }
    return 'N/A';
  };


  return {
    fn: {
      mapCurrencyData,
      mapSourceOfRepayment,
      mapRequestType,
      mapLoanType,
      mapLoanSubType,
      mapFiscalYear,
      mapIndustryType,
      mapProjectionTemplate,
      mapMemoType,
      mapSubIndustryType
    },
    refData: {
      currencyData,
      requestType,
      sourceOfRePayment,
      fiscalYear,
      loanType,
      typeOfMoratorium,
      paymentType,
      projectionTemplate,
      interestCalcTypes,
      depreciationMethods,
      financialYearTypes,
      industryTypes,
      entityTypes,
      countryList,
      memoTypes,
      approvalTypes,
      approverTypes,
      recommendedTypes,
      copyTypes,
    },
  };
};

export default useRefData;
