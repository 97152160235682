import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { MENU_ITEMS } from './main-menu-schema';
import { ITabSchema } from 'models/common';
import { RootState } from '../../state/store';

function a11yProps(id: any) {
  return {
    itemNumber: id,
    'aria-controls': `simple-tabpanel-${id}`,
  };
}

function MainMenuTabs() {
  const { menuList }: any = useSelector((state: RootState) => state.user?.userDetails);
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname, state: loanState } = useLocation();
  const [state, setState] = useState(1);

  useEffect(() => {
    for (let i = 0; i < MENU_ITEMS.length; i++) {
      if (pathname === MENU_ITEMS[i].value) {
        setState(MENU_ITEMS[i].id);
        break;
      }
    }
  }, [pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const route = MENU_ITEMS?.find((item) => item?.id === newValue);
    navigate(route?.value || '', {
      state: { ...loanState },
    });
    setState(newValue);
  };

  return (
    <Box>
      <Tabs value={state} onChange={handleChange} centered>
        {_.map(MENU_ITEMS, (item: ITabSchema) => {
          if (menuList?.includes(item?.name?.toUpperCase()?.replaceAll(' ', '_'))) {
            return (
              <Tab
                color={theme.palette.background.paper}
                value={item.id}
                label={item.name}
                {...a11yProps(item.id)}
                key={item.id}
                // disabled={!menuList?.includes(item?.name?.toUpperCase()?.replaceAll(' ', '_'))}
              />
            );
          }
        })}
      </Tabs>
    </Box>
  );
}

export default MainMenuTabs;
