import Grid from '@mui/material/Grid';
import MoM from './MoM';
import Sanction from './Sanction';

export default function SanctionLetter() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MoM />
      </Grid>
      <Grid item xs={6}>
        <Sanction />
      </Grid>
    </Grid>
  );
}
