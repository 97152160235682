export const DATE_PICKER = 'datepicker';
export const INPUT = 'textField';
export const MULTI_SELECT_DROPDOWN = 'multiSelectDropdown';
export const MULTI_LINE_INPUT = 'multiLineInput';
export const RADIO_BUTTONS = 'radio';
export const DROPDOWN = 'dropdown';
export const SLIDER = 'slider';
export const MULTI_SELECT_AUTOCOMPLETE = 'autocomplete';
export const MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION = 'autocompleteWithCreate';
export const TABLE_INPUT = 'tableInput';
export const YEAR_PICKER = 'yearpicker';
export const TABLE_BUTTON = 'tableButton';
export const SWITCH = 'switch';
