import { Box, IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material';
import _ from 'lodash';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { useFieldArray } from 'react-hook-form';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {}

export default function SourceAndUseOfFunds({ t, control, getValues, setValue, watchAllFields }: any) {
  const { state } = useLocation();

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <RichTextEditor
          value={watchAllFields?.detailsOfProject}
          onEditorChangeHandler={(newValue: any) => {
            setValue('detailsOfProject', newValue);
          }}
          disabled={state.viewOnly}
        />
      </Box>
    </>
  );
}
