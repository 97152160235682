import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CompanyDetailsTable from './company-details-table';
import ManagementDetails from './management-table';
import RatingSummaryTable from './rating-summary-table';
import SharemarketDetailsTable from './sharemarket-details-table';
import GroupFinancials from './group-financials';

const CompanyDetails = (basicProps: any) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <CompanyDetailsTable {...basicProps} />
      </Grid>
      <Grid item lg={12}>
        <RatingSummaryTable {...basicProps} />
      </Grid>
      <Grid item lg={12}>
        <SharemarketDetailsTable {...basicProps} />
      </Grid>
      <Grid item lg={12}>
        <ManagementDetails {...basicProps} />
      </Grid>
      <Grid item lg={12}>
        <GroupFinancials {...basicProps} />
      </Grid>
    </Grid>
  );
};

export default CompanyDetails;
