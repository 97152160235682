import { Editor } from '@tinymce/tinymce-react';
import axios, { AxiosResponse } from 'axios';

export const RichTextEditor = ({ value = '', onEditorChangeHandler, disabled = false, height = 300 }: any) => {
  const handleFilePicker = (callback: any, value: any, meta: any) => {

    const input: any = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target!.result as string;
        callback(imageDataUrl);
      };
      reader.readAsDataURL(file);
    };
    input.click();

  };

  return (
    <>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        value={value}
        disabled={disabled}
        init={{
          elementpath: false,
          promotion: false,
          branding: false,
          height: height,
          plugins:
            'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars',
          menubar: false,
          toolbar:
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | table insertfile image media link anchor',
          image_title: false,
          automatic_uploads: true,
          file_picker_types: 'image',
          /* and here's our custom image picker*/
          file_picker_callback: handleFilePicker,
          content_style: 'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:12px }',
          // content_style:
          //   "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; font-size:12px} h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif;}",
          quickbars_insert_toolbar: '',
          quickbars_selection_toolbar: '',
          paste_block_drop: false,
        }}
        onEditorChange={onEditorChangeHandler}
      />
    </>
  );
};
