import { useCallback } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { useMemo } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';

const KeyCompititor = ({ watchAllFields, setValue, getValues, t }: any) => {
  const { state } = useLocation();
  const theme = useTheme();

  const { applicantBasicDetails } = useSelector((state: RootState) => state.projections);

  const { actualsYears } = applicantBasicDetails;
  const actuals = actualsYears && [...actualsYears].sort();

  const defaultColDefs = useMemo(() => {
    var defaultObj: any = [
      {
        field: 'marketShare',
        headerName: 'Competitor Name - Market Share (%)',
        editable: state?.isEdit,
      },
    ];
    actuals?.forEach((year: string, idx: number) => {
      let obj = {
        headerName: `${year}`,
        field: `amount_${year}`,
        editable: state?.isEdit,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      };

      defaultObj.push(obj);
    });

    if (state?.isEdit) {
      defaultObj.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('productionPerformanceAnalysis.keyCompetitors').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('productionPerformanceAnalysis.keyCompetitors'), {}];
                      setValue('productionPerformanceAnalysis.keyCompetitors', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('productionPerformanceAnalysis.keyCompetitors').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('productionPerformanceAnalysis.keyCompetitors')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('productionPerformanceAnalysis.keyCompetitors', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }

    return defaultObj;
  }, [actuals]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('productionPerformanceAnalysis.keyCompetitors', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle
        formNumbering="3.3"
        title={t('loanProjection.businessBackgroud.productionPerformance.keyCompititors')}></FormTitle>
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={defaultColDefs}
          autoSize={true}
          rowData={watchAllFields?.productionPerformanceAnalysis.keyCompetitors}
          pagination={false}
          domLayout={'autoHeight'}
          sizeToFit={true}
          style={{ height: '100%' }}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
  );
};

export default KeyCompititor;
