import _ from 'lodash';
import { Map } from 'immutable';
import { unflatten } from 'flat';
import SimpleAccordion from 'components/common/simple-accordian';
import { generateFormProfitLoss, FormProfitLoss } from '../formprofitloss';
import { generateFormSales, FormSales } from '../formsales';
import { generateFormAdditionalInformation, FormAdditionalInformation } from '../formadditionalinformation';
import { generateFormContingentLiabilities, FormContingentLiabilities } from '../formcontingentliabilities';
import { generateFormDebtDetails, FormDebtDetails } from '../formdebtdetails';
import { generateFormCommonSizeProfitLoss, FormCommonSizeProfitLoss } from '../formcommonsizeprofitloss';
import { generateFormBalanceSheet, FormBalanceSheet } from '../formbalancesheet';
import { generateFormCommonSizeBalanceSheet, FormCommonSizeBalanceSheet } from '../formcommonsizebalancesheet';
import { generateFormCashFlowStatement, FormCashFlowStatement } from '../formcashflowstatement';
import { generateFormFundFlowStatement, FormFundFlowStatement } from '../formfundflowstatement';
import { generateFormSummaryProjections, FormSummaryProjections } from '../formsummaryprojections';
import { generateFormKeyPerformance, FormKeyPerformance } from '../formkeyperformance';
import { FormGroupType, FormType } from 'types/inputform.d';
import { addExpression, addFormula, getNumberRoundOff } from '../../common/FormUtil';
import formulaTemplate from './formula.json';

export const renderInputForms = (group: FormGroupType) => {
  return Map({
    FORMPROFITLOSS: (
      <SimpleAccordion
        title="Profit & Loss Statement"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormProfitLoss group={group} />
          </div>
        }
      />
    ),
    FORMSALES: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sales & Raw Materials Assumptions"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormSales group={group} />
          </div>
        }
      />
    ),
    FORMADDITIONALINFORMATION: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additional Information"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormAdditionalInformation group={group} />
          </div>
        }
      />
    ),
    FORMCONTINGENTLIABILITIES: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contingent Liabilities"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormContingentLiabilities group={group} />{' '}
          </div>
        }
      />
    ),
    FORMDEBTDETAILS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debt Details"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormDebtDetails group={group} />
          </div>
        }
      />
    ),
    FORMCOMMONSIZEPROFITLOSS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Common-size Profit & Loss"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCommonSizeProfitLoss group={group} />
          </div>
        }
      />
    ),
    FORMBALANCESHEET: (
      <SimpleAccordion
        title="Balance Sheet"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormBalanceSheet group={group} />
          </div>
        }
      />
    ),
    FORMCOMMONSIZEBALANCESHEET: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Common-size Balance Sheet"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCommonSizeBalanceSheet group={group} />
          </div>
        }
      />
    ),
    FORMCASHFLOWSTATEMENT: (
      <SimpleAccordion
        title="Cash Flow Statement"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCashFlowStatement group={group} />
          </div>
        }
      />
    ),
    FORMFUNDFLOWSTATEMENT: (
      <SimpleAccordion
        title="Fund Flow Statement"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormFundFlowStatement group={group} />
          </div>
        }
      />
    ),
    FORMSUMMARYPROJECTIONS: (
      <SimpleAccordion
        title="Summary Projections"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormSummaryProjections group={group} />
          </div>
        }
      />
    ),
    FORMKEYPERFORMANCE: (
      <SimpleAccordion
        title="Key Performance Indicators and Ratio's"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormKeyPerformance group={group} />
          </div>
        }
      />
    ),
  });
}

export const renderTable = (inputType: FormGroupType) => {
    return (
      <>
        {renderInputForms(inputType).get(FormType[FormType.FORMPROFITLOSS])}
        {renderInputForms(inputType).get(FormType[FormType.FORMSALES])}
        {renderInputForms(inputType).get(FormType[FormType.FORMADDITIONALINFORMATION])}
        {renderInputForms(inputType).get(FormType[FormType.FORMCONTINGENTLIABILITIES])}
        {renderInputForms(inputType).get(FormType[FormType.FORMDEBTDETAILS])}
        {renderInputForms(inputType).get(FormType[FormType.FORMCOMMONSIZEPROFITLOSS])}
        {renderInputForms(inputType).get(FormType[FormType.FORMBALANCESHEET])}
        {renderInputForms(inputType).get(FormType[FormType.FORMCOMMONSIZEBALANCESHEET])}
        {renderInputForms(inputType).get(FormType[FormType.FORMCASHFLOWSTATEMENT])}
        {renderInputForms(inputType).get(FormType[FormType.FORMFUNDFLOWSTATEMENT])}
        {renderInputForms(inputType).get(FormType[FormType.FORMSUMMARYPROJECTIONS])}
        {renderInputForms(inputType).get(FormType[FormType.FORMKEYPERFORMANCE])}
      </>
    );
  };



export const generateInputJsonforDB = (cells:any, loanRequestId:any, projectionId:any) => {
    const inputJson = _.cloneDeep(cells);

    if (!_.isEmpty(inputJson)) {
      const yearMap = Object.fromEntries(
        Object.assign(
          [],
          ...Object.values(inputJson).map((row: any) =>
            Object.entries(row)
              .filter(([k]) => k?.endsWith('.0'))
              .map(([k, v]: any) => [k?.replaceAll('.0', ''), `${v?.value}.${v?.group}`])
          )
        )
      );

      const finalData: any = unflatten(
        Object.assign(
          {},
          ...Object.entries(inputJson).map(([formkey, formvalue]: any) => {
            return Object.assign(
              {},
              ...Object.entries(formvalue)
                  .filter(([key]: any) => !key?.startsWith('0') && !key?.endsWith('.0'))
                  .map(([key, value]: any) => {
                    const [yearKey, ...restKey] = key.split('.');
                    return Object.assign(
                      {},
                      { [`${yearMap[yearKey]}.${formkey}.${restKey.join('.')}`]: { value: getNumberRoundOff(value?.value) } }
                    );
                  })
            );
          })
        ),
        { object: true }
      );

      let data = null;
      if (loanRequestId && projectionId) {
        data = {
          projection: { loanId: loanRequestId, projectionId: projectionId },
          finalData,
        };
      }

      return data;
    }
  };


  export const generateInputFormJson = (inputType:any, cells:any, dbJson:any, loanDetails:any) => {

  	const inputFormJson: any = {};

  	const profitLossCells = generateFormProfitLoss(inputType, { ...cells?.formprofitloss }, dbJson, loanDetails);
	  _.merge(inputFormJson, Object.assign({}, { formprofitloss: { ...profitLossCells } }));

	  const salesCells = generateFormSales(inputType, cells?.formsales, dbJson, loanDetails);
	  _.merge(inputFormJson, Object.assign({}, { formsales: { ...salesCells } }));

	  const additionalInformationCells = generateFormAdditionalInformation(
	    inputType,
	    { ...cells?.formadditionalinformation },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formadditionalinformation: { ...additionalInformationCells } }));

	  const contingentLiabilitiesCells = generateFormContingentLiabilities(
	    inputType,
	    { ...cells?.formcontingentliabilities },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formcontingentliabilities: { ...contingentLiabilitiesCells } }));

	  const debtDetailsCells = generateFormDebtDetails(
	    inputType,
	    { ...cells?.formdebtdetails },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formdebtdetails: { ...debtDetailsCells } }));

	  const commonSizeProfitLossCells = generateFormCommonSizeProfitLoss(
	    inputType,
	    { ...cells?.formcommonsizeprofitloss },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formcommonsizeprofitloss: { ...commonSizeProfitLossCells } }));

	  const balanceSheetCells = generateFormBalanceSheet(
	    inputType,
	    { ...cells?.formbalancesheet },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formbalancesheet: { ...balanceSheetCells } }));

	  const commonSizeBalanceSheetCells = generateFormCommonSizeBalanceSheet(
	    inputType,
	    { ...cells?.formcommonsizebalancesheet },
	    dbJson,
	    loanDetails
	  );
	  _.merge(
	    inputFormJson,
	    Object.assign({}, { formcommonsizebalancesheet: { ...commonSizeBalanceSheetCells } })
	  );

	  const cashFlowStatementCells = generateFormCashFlowStatement(
	    inputType,
	    { ...cells?.formcashflowstatement },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formcashflowstatement: { ...cashFlowStatementCells } }));

	  const fundFlowStatementCells = generateFormFundFlowStatement(
	    inputType,
	    { ...cells?.formfundflowstatement },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formfundflowstatement: { ...fundFlowStatementCells } }));

	  const summaryProjectionsCells = generateFormSummaryProjections(
	    inputType,
	    { ...cells?.formsummaryprojections },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formsummaryprojections: { ...summaryProjectionsCells } }));

	  const keyPerformanceCells = generateFormKeyPerformance(
	    inputType,
	    { ...cells?.formkeyperformance },
	    dbJson,
	    loanDetails
	  );
	  _.merge(inputFormJson, Object.assign({}, { formkeyperformance: { ...keyPerformanceCells } }));

	  if (_.isEmpty(cells)) {
	    addExpression(inputFormJson);
	  }
	
	 return inputFormJson
  }


 export const generateFormulaJson = (inputFormJson:any, loanDetails:any = {}) => {
    const formulaJsonTemplate = _.cloneDeep(formulaTemplate) as any;
  	const formulaJson: any = {};
    const formulaActualsJson: any = {};
    const formulaProjectionJson: any = {};

    if(!formulaJsonTemplate){
      const {main, product, rawMaterial} = formulaJsonTemplate
      const {actuals, projection} = main;

      _.merge(formulaActualsJson, Object.assign({}, actuals  ));
      _.merge(formulaProjectionJson, Object.assign({}, projection  ));
     
      if(loanDetails){
        
        const {products, rawMaterials} = loanDetails

        if(product){
          const productActualsStr = JSON.stringify(product?.actuals)  
          const productProjectionStr = JSON.stringify(product?.projection)  
            products?.map((p:any) => {              
              _.merge(formulaActualsJson, Object.assign({}, JSON.parse(productActualsStr?.replaceAll("__product__","product_"+p?.id))));
              _.merge(formulaProjectionJson, Object.assign({}, JSON.parse(productProjectionStr?.replaceAll("__product__","product_"+p?.id))));
            })            
        }


        if(rawMaterial){
          const rawMaterialActualsStr = JSON.stringify(rawMaterial?.actuals)  
          const rawMaterialProjectionStr = JSON.stringify(rawMaterial?.projection)  
            rawMaterials?.map((r:any) => {
              _.merge(formulaActualsJson, Object.assign({}, JSON.parse(rawMaterialActualsStr?.replaceAll("__rawMaterial__","rawMaterial_"+r?.id))));
              _.merge(formulaProjectionJson, Object.assign({}, JSON.parse(rawMaterialProjectionStr?.replaceAll("__rawMaterial__","rawMaterial_"+r?.id))));
          })
        }

        _.merge(formulaJson, Object.assign({}, { actuals: { ...formulaActualsJson } }));
        _.merge(formulaJson, Object.assign({}, { projection: { ...formulaProjectionJson } }));        
        
      }     

    }else{
      _.merge(formulaJson, addFormula(inputFormJson));   
    }
	 
   
   return formulaJson
}

