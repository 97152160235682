import { Box } from '@mui/material';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import AnalysisOfBalanceSheet from './analysis-of-balance-sheet';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

function AnalysisOfOffBalanceSheet(props: IPerformanceSummaryTableProps) {
  const { t } = props;
  const { state } = useLocation();
  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={t('loanProjection.performanceSummary.analysisOfBalanceSheet')} />
        <Box sx={{ mt: 0.5 }}>
          <AnalysisOfBalanceSheet {...props} />
          <Box sx={{ display: 'flex', mt: 2 }}>
            <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '75%' }}>
              {getFormFields(
                {
                  name: 'analysisOffBalanceSheet.comments',
                  component: MULTI_LINE_INPUT,
                  label: 'Comment',
                },
                props.control,
                state.viewOnly
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AnalysisOfOffBalanceSheet;
