import { Card, CardActionArea, CardContent, styled } from '@mui/material';

const DashboardCardItem = styled(CardContent)(() => ({
  flexDirection: 'column',
  height: 168,
  width: 223,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderRadius: '10px',
  boxShadow: '0 0 10px 0 rgba(47, 128, 237, 0.1)',
}));

const ItemCard = ({ children, onClick }: any) => {
  return (
    <Card sx={{ height: 168, width: 223 }} onClick={onClick}>
      <CardActionArea>
        <DashboardCardItem>{children}</DashboardCardItem>
      </CardActionArea>
    </Card>
  );
};

export default ItemCard;
