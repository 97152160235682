import { Box } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ENDING_YEAR } from 'constants/config-constants';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { RADIO_BUTTONS, MULTI_LINE_INPUT } from 'constants/form-components';
import DataTable from 'components/common/data-table';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
import { formatNumber } from 'utils/common-utils';


const SalesDetails = ({ control, data, setValue, getValues, watchAllFields }: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [productColDefs, setProductColDefs] = useState<any>([]);
  const [domesticColDefs, setDomesticColDefs] = useState<any>([]);
  const [productRows, setProductRows] = useState<any[]>();
  const [domesticRows, setDomesticRows] = useState<any[]>();

  const generateProductionCapacityRowData = (data: any, items: any) => {
    const generatedRowData: any = [];
    if (data.length > 0) {
      Object.values(data[0]).map((el) => {
        if (Array.isArray(el)) {
          el.forEach((product) => {
            let tempProduct = {
              ...product,
              installedCapacity: product.installedCapacity ? product.installedCapacity : '',
              production: product.production ? product.production : '',
              capacityUtilisation: product.capacityUtilisation ? product.capacityUtilisation : '',
            };
            for (let i in tempProduct) {
              if (i == 'productId') {
                generatedRowData.push({ name: product['productName'] });
              }
              if (items.includes(i)) {
                const yearData = data.map(({ year, products }: any) => {
                  let obj: any = {};
                  products.forEach((p: any) => {
                    if (product.productId === p.productId) {
                      let yearKey = `year_${year}`;
                      obj[yearKey] = p[i];
                    }
                  });
                  return obj;
                });
                generatedRowData.push(Object.assign({ name: i }, ...yearData));
              }
            }
          });
        }
      });
    }
    return generatedRowData;
  };

  const generateDomesticExportRowData = (data: any) => {
    const generatedDomesticRowData: any = [];
    if (data.length > 0) {
      Object.values(data[0]).map((el) => {
        if (Array.isArray(el)) {
          el.forEach((product) => {
            generatedDomesticRowData.push({ name: product['productName'] });

            if (product.hasOwnProperty('sales')) {
              generatedDomesticRowData.push(
                Object.assign({ name: 'Sales (MT)' }, ...getYearData(data, product, 'sales', 'salesValue'))
              );
              generatedDomesticRowData.push(
                Object.assign({ name: 'Domestic' }, ...getYearData(data, product, 'sales', 'domestic'))
              );
              generatedDomesticRowData.push(
                Object.assign({ name: 'Exports' }, ...getYearData(data, product, 'sales', 'exports'))
              );
            }

            if (product.hasOwnProperty('avgRevenueRealization')) {
              generatedDomesticRowData.push(
                Object.assign(
                  { name: 'Average Revenue Realization' },
                  ...getYearData(data, product, 'avgRevenueRealization', 'avgRevenueRealizationValue')
                )
              );
              generatedDomesticRowData.push(
                Object.assign(
                  { name: 'Domestic' },
                  ...getYearData(data, product, 'avgRevenueRealization', 'domestic')
                )
              );
              generatedDomesticRowData.push(
                Object.assign(
                  { name: 'Exports' },
                  ...getYearData(data, product, 'avgRevenueRealization', 'exports')
                )
              );
            }
          });
        }
      });
    }
    return generatedDomesticRowData;
  };

  function getYearData(data: any, product: any, objKey: any, key: any) {
    const yearData = data?.map(({ year, products }: any) => {
      let obj: any = {};
      products.forEach((p: any) => {
        if (product.productId === p.productId) {
          let yearKey = `year_${year}`;
          obj[yearKey] = p?.[objKey][key];
        }
      });
      return obj;
    });
    return yearData;
  }

  useEffect(() => {
    const productionCapacity = data?.capacityUtilisationSalesDetails?.manufacturingArray;
    const totalYears = _.map(productionCapacity, (el) => el.year).sort();

    const yearColumns = _.map(totalYears, (year: string, idx: number) => {
      return {
        headerName: `${year}`,
        field: `year_${year}`,
        cellStyle: { textAlign: 'right' },
        cellRenderer: (params: any) => {
          return formatNumber(params.value);
        },
      };
    });

    const productDefaultObj: any = [
      {
        headerName: 'Production, Capacity Utilisation for Manufacturing Sales',
        children: [
          {
            headerName: `Year ended ${ENDING_YEAR}`,
            field: 'name',
            cellRenderer: (params: any) => {
              if (params.value === 'installedCapacity') {
                return 'Installed capacity';
              } else if (params.value === 'production') {
                return 'Production (units)';
              } else if (params.value === 'capacityUtilisation') {
                return 'Capacity Utilization (%)';
              } else {
                return params.value;
              }
            },
            cellStyle: (params: any) => {
              if (params.value === 'installedCapacity') {
                return { marginLeft: '10px' };
              } else if (params.value === 'production') {
                return { marginLeft: '10px' };
              } else if (params.value === 'capacityUtilisation') {
                return { marginLeft: '10px' };
              } else {
                return null;
              }
            },
          },
          ...yearColumns,
        ],
      },
    ];

    const domesticDefaultObj: any = [
      {
        headerName: 'Domestic & Export Realisations',
        children: [
          {
            headerName: `Year ended ${ENDING_YEAR}`,
            field: 'name',
            cellStyle: (params: any) => {
              if (params.value === 'Sales (MT)') {
                return { marginLeft: '10px' };
              }
              if (params.value === 'Average Revenue Realization') {
                return { marginLeft: '10px' };
              } else if (params.value === 'Domestic') {
                return { marginLeft: '15px' };
              } else if (params.value === 'Exports') {
                return { marginLeft: '15px' };
              } else {
                return null;
              }
            },
          },
          ...yearColumns,
        ],
      },
    ];

    setProductColDefs(productDefaultObj);
    setDomesticColDefs(domesticDefaultObj);

    const domesticAndExportRealisations = data?.domesticAndExportRealisations?.array;
    if (productionCapacity) {
      setProductRows(() =>
        generateProductionCapacityRowData(productionCapacity, [
          'installedCapacity',
          'production',
          'capacityUtilisation',
        ])
      );
    }

    if (domesticAndExportRealisations) {
      setDomesticRows(() => generateDomesticExportRowData(domesticAndExportRealisations));
    }
  }, [data]);

  const onServicesChange = (newValue: any) => {
    setValue('productionPerformanceAnalysis.capacityUtilisationSalesDetails.services', newValue);
  };

  const onTradingChange = (newValue: any) => {
    setValue('productionPerformanceAnalysis.capacityUtilisationSalesDetails.trading', newValue);
  };

  if (data) {
    return (
      <>
        <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <FormTitle
            formNumbering="3.1"
            title={t('loanProjection.businessBackgroud.productionPerformance.heading1')}
          />
          <Box sx={{ mt: 1, display: 'flex' }}>
            <SimpleFormLabel label="Is Company into Manufacturing" sx={{ width: '20.5%', mb: 0.5 }} />
            {getFormFields(
              {
                name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoManufacturing',
                component: RADIO_BUTTONS,
                values: [
                  {
                    label: 'Applicable',
                    value: 'true',
                  },
                  {
                    label: 'Non-Applicable',
                    value: 'false',
                  },
                ],
              },
              control,
              !state?.isEdit ? true : false
            )}
          </Box>
          {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === 'true' ||
          watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === true ? (
            <Box sx={{ mt: 1 }}>
              <DataTable
                columnDefs={productColDefs}
                autoSize={true}
                rowData={productRows || []}
                pagination={false}
                domLayout={'autoHeight'}
                sizeToFit={true}
                style={{ height: '100%' }}
              />
            </Box>
          ) : null}
          {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === 'true' ||
          watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === true ? (
            <Box sx={{ mt: 1 }}>
              <DataTable
                columnDefs={domesticColDefs}
                autoSize={true}
                rowData={domesticRows || []}
                pagination={false}
                domLayout={'autoHeight'}
                sizeToFit={true}
                style={{ height: '100%' }}
              />
            </Box>
          ) : null}
          {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === 'true' ||
          watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoManufacturing === true ? (
            <Box sx={{ mt: 1 }}>
              {getFormFields(
                {
                  component: MULTI_LINE_INPUT,
                  name: 'comments.capacityUtilisationSalesDetails',
                  label: 'Comment',
                },
                control,
                !state?.isEdit
              )}
            </Box>
          ) : null}
          <Box sx={{ mt: 1, display: 'flex' }}>
            <SimpleFormLabel label="Is Company into Services" sx={{ width: '20.5%', mb: 0.5 }} />
            {getFormFields(
              {
                name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoServices',
                component: RADIO_BUTTONS,
                values: [
                  {
                    label: 'Applicable',
                    value: 'true',
                  },
                  {
                    label: 'Non-Applicable',
                    value: 'false',
                  },
                ],
              },
              control,
              !state?.isEdit ? true : false
            )}
          </Box>
          {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
            ?.isCompanyIntoServices === 'true' ||
          watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoServices ===
            true ? (
            <Box sx={{ mt: 1 }}>
              <RichTextEditor
                value={watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.services}
                onEditorChangeHandler={onServicesChange}
                disabled={!state?.isEdit}
              />
            </Box>
          ) : null}
          <Box sx={{ mt: 1, display: 'flex' }}>
            <SimpleFormLabel label="Is Company into Trading" sx={{ width: '20.5%', mb: 0.5 }} />
            {getFormFields(
              {
                name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoTrading',
                component: RADIO_BUTTONS,
                values: [
                  {
                    label: 'Applicable',
                    value: 'true',
                  },
                  {
                    label: 'Non-Applicable',
                    value: 'false',
                  },
                ],
              },
              control,
              !state?.isEdit ? true : false
            )}
          </Box>
          {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoTrading ===
            'true' ||
          watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoTrading ===
            true ? (
            <Box sx={{ mt: 1 }}>
              <RichTextEditor
                value={watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.trading}
                onEditorChangeHandler={onTradingChange}
                disabled={!state?.isEdit}
              />
            </Box>
          ) : null}
        </Box>
      </>
    );
  }
  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle
          formNumbering="3.1"
          title={t('loanProjection.businessBackgroud.productionPerformance.heading1')}
        />
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel label="Is Company into Manufacturing" sx={{ width: '20.5%', mb: 0.5, pl: 0 }} />
          {getFormFields(
            {
              name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoManufacturing',
              component: RADIO_BUTTONS,
              values: [
                {
                  label: 'Applicable',
                  value: 'true',
                },
                {
                  label: 'Non-Applicable',
                  value: 'false',
                },
              ],
            },
            control,
            !state?.isEdit ? true : false
          )}
        </Box>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
        {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
          ?.isCompanyIntoManufacturing === 'true' ||
        watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails
          ?.isCompanyIntoManufacturing === true ? (
          <Box sx={{ mt: 1 }}>
            {getFormFields(
              {
                component: MULTI_LINE_INPUT,
                name: 'comments.capacityUtilisationSalesDetails',
                label: 'Comment',
              },
              control,
              !state?.isEdit
            )}
          </Box>
        ) : null}
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel label="Is Company into Services" sx={{ width: '20.5%', mb: 0.5, pl: 0 }} />
          {getFormFields(
            {
              name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoServices',
              component: RADIO_BUTTONS,
              values: [
                {
                  label: 'Applicable',
                  value: 'true',
                },
                {
                  label: 'Non-Applicable',
                  value: 'false',
                },
              ],
            },
            control,
            !state?.isEdit ? true : false
          )}
        </Box>
        {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoServices ===
          'true' ||
        watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoServices ===
          true ? (
          <Box sx={{ mt: 1 }}>
            <RichTextEditor
              value={watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.services}
              onEditorChangeHandler={onServicesChange}
              disabled={!state?.isEdit}
            />
          </Box>
        ) : null}
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel label="Is Company into Trading" sx={{ width: '20.5%', mb: 0.5, pl: 0 }} />
          {getFormFields(
            {
              name: 'productionPerformanceAnalysis.capacityUtilisationSalesDetails.isCompanyIntoTrading',
              component: RADIO_BUTTONS,
              values: [
                {
                  label: 'Applicable',
                  value: 'true',
                },
                {
                  label: 'Non-Applicable',
                  value: 'false',
                },
              ],
            },
            control,
            !state?.isEdit ? true : false
          )}
        </Box>
        {watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoTrading ===
          'true' ||
        watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.isCompanyIntoTrading ===
          true ? (
          <Box sx={{ mt: 1 }}>
            <RichTextEditor
              value={watchAllFields?.productionPerformanceAnalysis?.capacityUtilisationSalesDetails?.trading}
              onEditorChangeHandler={onTradingChange}
              disabled={!state?.isEdit}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default SalesDetails;
