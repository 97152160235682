import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import ProjectionBasicDetails from './projection-basic-details';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateProjectionModal({ open, close, state }: any) {
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    // close();
    // dispatch(createProjection({ id: tabs.length + 1, projectionName: 'projection 1' }));
  };
  return (
    <Dialog open={open} fullWidth maxWidth="lg" TransitionComponent={Transition}>
      <DialogTitle>
        <Typography variant="subtitle1">Basic Details</Typography>
      </DialogTitle>
      <DialogContent>
        <ProjectionBasicDetails open={open} onCreateProjection={onSubmit} onCloseModal={close} />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
