import { FC } from 'react';
import { MenuItem, FormControl, Select } from '@mui/material';
import _ from 'lodash';
import { IDropdownProps } from '../../../models/formComponents';
import { Controller } from 'react-hook-form';

const style = {
  '& .MuiOutlinedInput-root ': {
    borderRadius: '0px',
  },
};

const Dropdown: FC<IDropdownProps> = ({ values, label, options, name, control, disabled, rules, ...input }) => {
  const select = {
    '& .MuiSelect-select': {
      padding: '7px 11px',
      width: '10.6rem',
      fontSize: '11px',
      ...input,
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl margin="dense" sx={{ ...style, padding: 0, margin: 0, ...input }} disabled={disabled}>
          <Select
            defaultValue=""
            value={`${field.value}`}
            sx={select}
            margin="dense"
            name={name}
            onChange={(e) => field.onChange(e)}>
            {_.map(options, (option: any, index) => {
              return (
                <MenuItem key={`${option}-${index}`} value={`${option.id}`}>
                  {option.displayText}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default Dropdown;
