import { ICrateLoanRequest } from '../models/service-models/loan-requests';
import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

const getTeamRequests = async () => {
  const response = await httpService.get('/loanProjections/teamRequests', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getInProgressRequests = async () => {
  const response = await httpService.get('/loanProjections/inProgressRequests', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getClosedRequests = async () => {
  const response = await httpService.get('/loanProjections/closedRequests', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getPendingRequests = async () => {
  const response = await httpService.get('/loanProjections/pendingActions', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getMyRequestsCount = async () => {
  const response = await httpService.get('/loanProjections/myRequestCounts', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getLoanRequestByLoanRequestId = async (loanRequestId: string | number) => {
  const response = await httpService.get(`/loanRequest/${loanRequestId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response;
};

const createLoanRequest = async (data: ICrateLoanRequest) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await httpService.post('/loanRequest', data, headers);
  return res;
};

const saveDataByScreenId = async (loanId: string, projectionId: string, screenId: string, data: any) => {
  const response = await httpService.post(`/loanRequest/saveData/${loanId}/${projectionId}/${screenId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response;
};

export {
  getInProgressRequests,
  getClosedRequests,
  getPendingRequests,
  getMyRequestsCount,
  createLoanRequest,
  saveDataByScreenId,
  getLoanRequestByLoanRequestId,
  getTeamRequests,
};
