import { useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import _ from 'lodash';
import CompanyDetails from './company-details';
import ProductionPerformance from './production-performance-analysis';
import BusinessDescription from './business-description';
import { getLoanProposalData, postLoanProposalData } from 'services/loan-proposal-services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { getCompanyDataById } from 'services/company-services';
import useRefData from 'hooks/ref-data';
import moment from 'moment';
import ActionBar from 'components/styled-components/ActionBar';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { MENU_ITEMS } from '../projection-menu-schema';
import { changeMenuTab } from 'state/reducers/tabReducer';
import SimpleAccordion from '../../../common/simple-accordian';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';
import { filterGridEmptyData } from 'utils/common-utils';
import { getApplicantProjectionBasicDetailsByLoanReqId } from 'services/loan-projection-services';
import { storeApplicantBasicDetail } from '../projection-components/financial-projection-reducer';

const BusinessBackground = () => {
  const { companyId }: any = useSelector((state: RootState) => state.loanProposal);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { fn } = useRefData();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { getRequestStatusCode } = useLoanTable();
  const navigate = useNavigate();
  const defaultValues = {
    companyAndGroupDetails: {
      managementDetails: [{}],
      shareHoldings: [{}],
      groupFinancials: [{}],
      shareMarketInformation: {
        informationAsOf: moment().format('DD-MM-YYYY'),
        listed: 'No',
      },
      externalRatingSummary: [
        {
          ratingSummary: 'Term Loan',
          type: '',
          limit: '',
          outstanding: '',
          prev_year: '',
          curr_year: '',
        },
        {
          ratingSummary: 'Working Capital Limits',
          type: '',
          limit: '',
          outstanding: '',
          prev_year: '',
          curr_year: '',
        },
        {
          ratingSummary: 'Fund-Based Limits',
          type: '',
          limit: '',
          outstanding: '',
          prev_year: '',
          curr_year: '',
        },
        {
          ratingSummary: 'Non-Fund Limits',
          type: '',
          limit: '',
          outstanding: '',
          prev_year: '',
          curr_year: '',
        },
        {
          ratingSummary: 'Fully Interchangeable',
          type: '',
          limit: '',
          outstanding: '',
          prev_year: '',
          curr_year: '',
        },
      ],
    },
    businessDescription: {
      topCustomers: [{}],
      topProducts: [{}],
      businessModel: '',
    },
    productionPerformanceAnalysis: {
      capacityUtilisationSalesDetails: {
        isCompanyIntoManufacturing: 'true',
        isCompanyIntoServices: 'false',
        isCompanyIntoTrading: 'false',
      },
      rawMaterialAndProcurementArrangement: {
        groupSupport: [{}],
        topSupplier: [{}],
      },
      keyCompetitors: [{}],
      specificAdvantages: {
        detailsThereoff: '',
      },
      swotAnalysis: {},
    },
    comments: {},
  };

  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm<any>({
    defaultValues: defaultValues,
  });

  const [businessBackgroundId, setBgId] = useState('');

  const watchAllFields = watch();

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  useEffect(() => {
    dispatch(toggleLoader(true));
  }, []);

  const res = useQueries([
    {
      queryKey: '',
      queryFn: () => getApplicantProjectionBasicDetailsByLoanReqId(state.loanRequestId),
      onSuccess: (res: any) => {
        if (res.status === 200) {
          dispatch(storeApplicantBasicDetail(res.data));
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    },
    {
      queryKey: 'companyData',
      queryFn: () => getCompanyDataById(companyId),
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    },
    {
      queryKey: 'getBusinessBackground',
      queryFn: () => getLoanProposalData(state.loanRequestId, 'businessBackgorund'),
      onSuccess: (data: any) => {
        if (data) {
          setBgId(data.id);
          const copyData: any = { ...data.businessBackground };
          reset({
            companyAndGroupDetails: {
              managementDetails:
                copyData?.companyAndGroupDetails?.managementDetails &&
                copyData?.companyAndGroupDetails?.managementDetails.length > 0
                  ? copyData?.companyAndGroupDetails?.managementDetails
                  : [{}],
              shareHoldings:
                copyData?.companyAndGroupDetails?.shareHoldings &&
                copyData?.companyAndGroupDetails?.shareHoldings.length > 0
                  ? copyData?.companyAndGroupDetails?.shareHoldings
                  : [{}],
              groupFinancials:
                copyData?.companyAndGroupDetails?.groupFinancials &&
                copyData?.companyAndGroupDetails?.groupFinancials.length > 0
                  ? copyData?.companyAndGroupDetails?.groupFinancials
                  : [{}],
              shareMarketInformation: Object.assign(
                {...getValues()?.companyAndGroupDetails?.shareMarketInformation},
                {...copyData?.companyAndGroupDetails?.shareMarketInformation},
                {marketCapitalisation: (Number(
                  copyData?.companyAndGroupDetails?.shareMarketInformation
                    ?.marketCapitalisation?.replaceAll(',','')
                )||'')?.toLocaleString()},
              ),
              externalRatingSummary: copyData?.companyAndGroupDetails?.externalRatingSummary || [
                {
                  ratingSummary: 'Term Loan',
                  type: '',
                  limit: '',
                  outstanding: '',
                  prev_year: '',
                  curr_year: '',
                },
                {
                  ratingSummary: 'Working Capital Limits',
                  type: '',
                  limit: '',
                  outstanding: '',
                  prev_year: '',
                  curr_year: '',
                },
                {
                  ratingSummary: 'Fund-Based Limits',
                  type: '',
                  limit: '',
                  outstanding: '',
                  prev_year: '',
                  curr_year: '',
                },
                {
                  ratingSummary: 'Non-Fund Limits',
                  type: '',
                  limit: '',
                  outstanding: '',
                  prev_year: '',
                  curr_year: '',
                },
                {
                  ratingSummary: 'Fully Interchangeable',
                  type: '',
                  limit: '',
                  outstanding: '',
                  prev_year: '',
                  curr_year: '',
                },
              ],
              companyDetails: copyData?.companyAndGroupDetails?.companyDetails,
            },
            businessDescription: {
              topCustomers:
                copyData?.businessDescription?.topCustomers &&
                copyData?.businessDescription?.topCustomers.length > 0
                  ? copyData?.businessDescription?.topCustomers
                  : [{}],
              topProducts:
                copyData?.businessDescription?.topProducts && copyData?.businessDescription?.topProducts.length > 0
                  ? copyData?.businessDescription?.topProducts
                  : [{}],
              businessModel: copyData?.businessDescription?.businessModel || '',
            },
            productionPerformanceAnalysis: {
              capacityUtilisationSalesDetails: copyData?.productionPerformanceAnalysis
                ?.capacityUtilisationSalesDetails || {
                isCompanyIntoManufacturing: 'true',
                isCompanyIntoServices: 'false',
                isCompanyIntoTrading: 'false',
              },
              rawMaterialAndProcurementArrangement: {
                rawMaterialBreakUp:
                  copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement
                    ?.rawMaterialBreakUp || {},
                groupSupport:
                  copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.groupSupport &&
                  copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.groupSupport
                    .length > 0
                    ? copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.groupSupport
                    : [{}],
                topSupplier:
                  copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.topSupplier &&
                  copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.topSupplier
                    .length > 0
                    ? copyData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.topSupplier
                    : [{}],
              },
              keyCompetitors:
                copyData?.productionPerformanceAnalysis?.keyCompetitors &&
                copyData?.productionPerformanceAnalysis?.keyCompetitors.length > 0
                  ? copyData?.productionPerformanceAnalysis?.keyCompetitors
                  : [{}],
              specificAdvantages: copyData?.productionPerformanceAnalysis?.specificAdvantages || {
                detailsThereoff: '',
              },
              swotAnalysis: copyData?.productionPerformanceAnalysis?.swotAnalysis || {},
            },
            comments: copyData?.comments || {},
          });
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    },
  ]);

  const saveMutation = useMutation(
    (data: any) => postLoanProposalData(state.loanRequestId, 'businessBackgorund', data),
    {
      onSuccess: async (data: any) => {
        enqueueSnackbar('Successfully Updated', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      },
      onSettled: () => {
        res[2].refetch();
        dispatch(toggleLoader(false));
      },
    }
  );

  const onSubmit = (data: any) => {
    const industryType = fn.mapIndustryType(res[1].data?.industryTypeId);
    data.companyAndGroupDetails.companyDetails.industryType = industryType;

    const filteredData = {
      ...data,
      companyAndGroupDetails: filterGridEmptyData(data?.companyAndGroupDetails, [
        'managementDetails',
        'shareHoldings',
        'groupFinancials',
      ]),
      businessDescription: filterGridEmptyData(data?.businessDescription, ['topCustomers', 'topProducts']),
      productionPerformanceAnalysis: filterGridEmptyData(data?.productionPerformanceAnalysis, ['keyCompetitors']),
    };

    filteredData.productionPerformanceAnalysis = {
      ...filteredData?.productionPerformanceAnalysis,
      rawMaterialAndProcurementArrangement: filterGridEmptyData(
        filteredData?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement,
        ['groupSupport', 'topSupplier']
      ),
    };

    const payload: {
      businessBackground: any;
      loanRequestId: number;
      id?: any;
    } = {
      businessBackground: {
        ...data,
      },
      loanRequestId: state.loanRequestId,
    };

    if (businessBackgroundId !== '') {
      payload['id'] = businessBackgroundId;
    }

    saveMutation.mutate(payload);

    if (saveMutation?.isLoading) {
      dispatch(toggleLoader(true));
    }
  };

  const { productionPerformanceAnalysis } = res[2].data?.businessBackground || {};

  const _renderSubmitBtn = () => {
    if (state?.viewOnly)
      return (
        <ReviewCamButton
          onClick={() => {
            navigate(routes.CAM_ROUTE, {
              state: { ...state, loanRequestId: state.loanRequestId },
            });
            const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
            camId && camId?.id && dispatch(changeMenuTab(camId?.id));
          }}
        />
      );
    if (
      state?.statusCode === getRequestStatusCode('DRAFT') ||
      state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
      state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
      !state?.statusCode
    ) {
      return (
        <>
          <ActionBar>
            <Button variant="contained" sx={{ mr: 2, p: 1 }} type="submit">
              Save Draft
            </Button>
            <CamButton
              sx={{ mr: 2, p: 1 }}
              onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
              camState={state}
            />
          </ActionBar>
        </>
      );
    }
  };

  const basicProps = {
    control,
    watchAllFields,
    getValues,
    setValue,
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ height: 'calc(100vh - 130px)', overflowY: 'scroll', pb: 6 }}>
        <CardContent>
          <Grid container>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'1. Company Background'}
                expand={true}
                children={<CompanyDetails {...basicProps} />}
              />
            </Grid>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'2. Business Description'}
                children={<BusinessDescription {...basicProps} />}
              />
            </Grid>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'3. Production Performance Analysis'}
                children={
                  <ProductionPerformance
                    {...basicProps}
                    productionPerformanceData={productionPerformanceAnalysis}
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {_renderSubmitBtn()}
    </form>
  );
};

export default BusinessBackground;
