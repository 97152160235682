import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  styled,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Button,
  Typography,
} from '@mui/material';

import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import queryClient from '../../../../../../services/react-query-service';
import Autocomplete from '@mui/material/Autocomplete';
import Dropdown from 'components/common/form-components/Dropdown';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useLoanRequestFields } from './request-type-schema';
import GridForm from '../../../../../common/containers/grid-form-container';
import { strings } from '../../../../../../constants/strings';
import { IFormSchema } from '../../../../../../models/forms-schema-types';
import { searchCompany } from '../../../../../../services/company-services';
import { createLoanRequest } from '../../../../../../services/loan-request-services';
import { ICrateLoanRequest } from '../../../../../../models/service-models/loan-requests';
import { toggleLoader } from '../../../../../../state/reducers/common-actions';
import { grey } from '@mui/material/colors';
import useRefData from 'hooks/ref-data';
import getIcon from 'icons/icons';
import { GO_BACK } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import ActionBar from 'components/styled-components/ActionBar';
import useLoanTable from 'hooks/useLoanTable';

const DetailContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 150,
  marginLeft: 10,
}));

const StyledBox = styled(Box)(() => ({
  height: 'calc(100vh - 100px)',
}));

const cardHeader = {
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
  },
};
const CreateNewLoanRequest = () => {
  const theme = useTheme();
  const { control, handleSubmit, resetField } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [prevLoanId, setPrevLoanId] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { getHomePage } = useLoanTable();
  const { fn } = useRefData();
  const { mapRequestType } = fn;
  const [selectedCompany, setSelectedCompany] = useState({
    id: null,
    name: '',
    panNumber: '',
    previousApprovedLoanReq: [],
  });

  const requestType = useWatch({ control, name: 'requestType' });
  const loanRequestFields = useLoanRequestFields(control, resetField);
  const mutation = useMutation((data) => searchCompany(data));
  const createLoanMutation = useMutation((loanData: ICrateLoanRequest) => createLoanRequest(loanData), {
    onSuccess: (result: any) => {
      enqueueSnackbar(`Loan Request ${result?.data?.id} created Successfully`, {
        variant: 'success',
      });
      navigate(getHomePage(), { replace: true });
    },
    onError: (error: any) => {
      enqueueSnackbar(`Error while creating Loan Request`, {
        variant: 'error',
      });
    },
    onSettled: (data: any) => {
      queryClient.invalidateQueries('getMyOpenRequests');
      dispatch(toggleLoader(false));
    },
  });

  const validateData = (data: any) => {
    if (mapRequestType(requestType) === 'Renewal' && _.isEmpty(data?.parentLoanId)) return false;
    if (data.requestType === '' || data.requestType === 'undefined' || data.requestType === undefined)
      return false;
    if (
      data.purposeOfFacility === '' ||
      data.purposeOfFacility === 'undefined' ||
      data.purposeOfFacility === undefined
    )
      return false;
    if (data.loanCurrency === '' || data.loanCurrency === 'undefined' || data.loanCurrency === undefined)
      return false;
    if (data.loanAmount === '' || data.loanAmount === 'undefined' || data.loanAmount === undefined) return false;
    if (data.sourceRepayment === '' || data.sourceRepayment === 'undefined' || data.sourceRepayment === undefined)
      return false;
    if (data.loanType === '' || data.loanType === 'undefined' || data.loanType === undefined) return false;

    return true;
  };

  const _onSubmit = async (data: any) => {
    if (!validateData(data)) {
      return enqueueSnackbar('All fields are required', {
        variant: 'error',
      });
    }
    if (!selectedCompany?.id) {
      return enqueueSnackbar('Company not Selected', {
        variant: 'error',
      });
    }
    if (data.loanAmount <= 0) {
      return enqueueSnackbar('Loan Amount should be greater than zero', {
        variant: 'error',
      });
    }

    // dispatch(toggleLoader(true));
    data.orgId = selectedCompany?.id;

    data.loanAmount = Number(data.loanAmount);
    data.loanCurrency = Number(data.loanCurrency);
    data.loanType = Number(data.loanType);
    data.loanSubType = data?.loanSubType?.map((l:any) => l?.id)?.join();
    data.requestType = Number(data.requestType);
    data.sourceRepayment = Number(data.sourceRepayment);
    data.parentLoanId = Number(data.parentLoanId);

    createLoanMutation.mutate(data);
    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    if (mapRequestType(requestType) === 'Renewal' && !_.isEmpty(selectedCompany?.previousApprovedLoanReq)) {
      const prevId = selectedCompany?.previousApprovedLoanReq?.map((pl: any) =>
        Object.assign(
          {},
          { id: pl?.id, displayText: `${pl?.id} ${pl.loanRequestDate ? `(${pl.loanRequestDate})` : ''}` }
        )
      );
      setPrevLoanId(prevId);
    }
  }, [requestType, selectedCompany?.id]);

  const companySearch = _.debounce((value: string) => {
    setLoading(true);
    const data = {
      empty: true,
      idExp: value,
      nameExp: value,
      panExp: value,
    };
    //@ts-ignore
    mutation.mutate(data);
  }, 100);

  return (
    <>
      <form>
        <StyledBox>
          <Card style={{ borderRadius: 10 }}>
            <CardHeader
              sx={{
                p: 1,
                ...cardHeader,
                pl: 2,
                background: grey[50],
                // height: 80,
                '& .MuiCardHeader-action': {
                  alignSelf: 'center !important',
                },
              }}
              title={strings.NEW_LOAN_REQUEST_BTN}
              action={
                <TooltipIconButton
                  title="Go Back"
                  icon={getIcon(GO_BACK, theme.palette.primary.main)}
                  onClick={() => {
                    navigate(getHomePage());
                  }}
                />
              }
            />
          </Card>

          <Card sx={{ marginTop: '1%', borderRadius: '10px' }}>
            <CardContent>
              <Grid
                container
                spacing={2}
                sx={{ paddingBottom: '12px', borderBottom: '1px #babfc7 solid', marginBottom: '18px' }}>
                <Grid item xs={5}>
                  <Box sx={{ display: 'flex' }} className="required">
                    <SimpleFormLabel label="Company" sx={{ width: '48%' }} />
                    <Box>
                      <Autocomplete
                        disablePortal
                        freeSolo
                        id="company-search-box"
                        options={mutation?.data || []}
                        filterOptions={(options, state) => options}
                        getOptionLabel={(option: any) => option?.name+' ('+option?.id+')'}
                        onChange={(event, newValue) => {
                          setSelectedCompany(newValue);
                        }}
                        onClose={() => {
                          setLoading(false);
                        }}
                        sx={{
                          padding: 0,
                          '& .MuiAutocomplete-inputRoot': {
                            padding: '3px 11px',
                          },
                          '& .MuiChip-label': {
                            fontSize: '12px',
                          },
                          width: '13.3rem',
                        }}
                        renderInput={(params) => (
                          <TextField
                            margin="dense"
                            placeholder="Search Company"
                            {...params}
                            sx={{
                              margin: 0,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={18} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            variant="standard"
                            onChange={(e) => {
                              companySearch(e.target.value);
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={7} sx={{ paddingLeft: "120px !important" }}>
                  {mapRequestType(requestType) === 'Renewal' && (
                    <Box className="required" sx={{ display: 'flex', borderLeft:"1 solid grey" }}>
                      <SimpleFormLabel label="Renewal Loan Id" sx={{ width: '40%' }} />
                      <Box>
                        <Dropdown
                          label="Renewal Loan Id"
                          name="parentLoanId"
                          options={prevLoanId || []}
                          control={control}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {_.map(loanRequestFields, (input: IFormSchema, idx: number) => {
                  return <GridForm key={idx} input={input} control={control} required={true} />;
                })}
              </Grid>
            </CardContent>
          </Card>
          <ActionBar>
            <Button variant="contained" sx={{ p: 1, right: 10 }} onClick={handleSubmit(_onSubmit)} type="submit">
              {strings.SUBMIT_BTN}
            </Button>
            <Button
              variant="contained"
              sx={{ p: 1, mr: 1 }}
              onClick={() => {
                navigate(getHomePage());
              }}>
              Cancel
            </Button>
          </ActionBar>
        </StyledBox>
      </form>
    </>
  );
};

export default CreateNewLoanRequest;
