import { FC } from 'react';
import { Box } from '@mui/material';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import EbidtaAnalysis from './ebidta-analysis';
import KeyNumbers from './key-numbers';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  keyNumberData: any;
  ebidtaData: any;
}

const ProfitabilityAnalysis: FC<IProps> = (props) => {
  const { state } = useLocation();
  const { t, keyNumberData, ebidtaData } = props;
  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={t('loanProjection.performanceSummary.profitabilityAnalysis')} />
        <Box sx={{ mt: 0.5 }}>
          <KeyNumbers {...props} keyNumberData={keyNumberData} />
          <EbidtaAnalysis {...props} data={ebidtaData} />
          <Box sx={{ display: 'flex', mt: 2 }}>
            <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '75%' }}>
              {getFormFields(
                {
                  name: 'profatabilityAnalysis.comments',
                  component: MULTI_LINE_INPUT,
                  label: 'Comment',
                },
                props.control,
                state.viewOnly
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfitabilityAnalysis;
