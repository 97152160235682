import { Outlet } from 'react-router-dom';
import ApprovalScreen from './approval-screen';

const ApprovalDetails = () => {
  return (
    <ApprovalScreen>
      <Outlet />
    </ApprovalScreen>
  );
};

export default ApprovalDetails;
