import { Box, Button, Grid, Typography, Stack, Switch } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect, useCallback } from 'react';
import DataTable from 'components/common/data-table';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { useLocation } from 'react-router-dom';
import { Controller } from 'react-hook-form';

interface IRow {
  name: string;
  label: string;
  component: string;
}

const ExternalCompliance = ({ control, getValues, setValue, watchAllFields }: any) => {
  const { t } = useTranslation();
  const externalDedupeRow: any[] = [
    {
      label: 'AML Check',
      name: 'amlCheck',
      component: 'textField',
      width: 10,
    },
    {
      label: 'GST Return check',
      name: 'gstRerurnCheck',
      component: 'textField',
      width: 10,
    },
  ];
  // const [rowData, setRowData] = useState<any>([]);
  const { state } = useLocation();

  // useEffect(() => {
  //   setRowData(() => getValues('defaultersList'));
  // }, [watchAllFields]);

  const externalComplianceColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'particulars',
        headerName: 'Particulars',
        sortable: false,
        suppressMenu: true,
      },
      {
        field: 'yesNo',
        headerName: 'Yes/No',
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: (params: any) => {
          const name = `defaultersList[${params.rowIndex}].yesNo`;
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      params.api.stopEditing();
                      field.onChange(e.target.checked);
                      setTimeout(() => {
                        let fields = [...getValues('defaultersList')];
                        setValue('defaultersList', fields);
                        params.api.setRowData(fields);
                      }, 100);
                    }}
                    disabled={!state?.isEdit}
                  />
                )}
              />
              <Typography>Yes</Typography>
            </Stack>
          );
        },
        width: 10,
      },
      {
        field: 'details',
        headerName: 'If yes, please provide details',
        sortable: false,
        suppressMenu: true,
        editable: state?.isEdit,
      },
    ];
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('defaultersList', updatedRowData);
  }, []);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <FormTitle title="Defaulters List" />
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={externalComplianceColDef}
            autoSize={true}
            rowData={watchAllFields?.defaultersList}
            domLayout={'autoHeight'}
            sizeToFit={true}
            pagination={false}
            onCellValueChanged={onCellValueChanged}
            editType="single"
          />
        </Box>
      </Box>
    </>
  );
};

export default ExternalCompliance;
