import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import DataTable from 'components/common/data-table';
import { FileUploadProps } from 'models/fileupload';
import { getRiskDocuments, downloadDocument, uploadDocument, saveDocumentMeta, deleteDocument } from 'services/document-services';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useState } from 'react';
import { ColGroupDef } from 'ag-grid-community';
import { FileUpload } from 'components/common/fileupload';
import { toggleLoader } from 'state/reducers/common-actions';
import { Grid } from '@mui/material';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};

const getRows = (data: any): ColGroupDef[] => {
  let rows = null;
  if (data) {
    rows = data?.filter((d:any) => d?.isSoftDelete !== true)?.map((doc: any, i: number) => {
      return { ...doc, srNo: i + 1 };
    });
  }
  return rows;
};

const RiskCalculations = ({ viewMode }: any) => {
  const [file, setFile] = useState<any | null>(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { loanRequestId } = state;
  const { data } = useQuery(['getRiskDocuments', loanRequestId], () => getRiskDocuments(loanRequestId));

  const deleteDocumentMutation = useMutation((data: any) => deleteDocument(data?.id), {
    onSuccess: async (data: any) => {
     enqueueSnackbar(`Document ${data?.docFileName} deleted`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(`Error while deleting ${data?.docFileName}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getRiskDocuments', loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });


  const columns = [
  {
    field: 'srNo',
    headerName: 'Sr. No.',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    pinned: 'left',
    resizable: false,
  },
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'docFileName',
    headerName: 'File name',
  },
  {
    field: 'action',
    headerName: 'Action',
    pinned: 'right',
    align: 'center',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    sortable: false,
    cellRendererFramework: (params: any) => {
      return (
        <>
        <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center',  float:'left' }}
          onClick={() => downloadDocuments(params?.data)}>
          <DownloadForOfflineIcon fontSize="small" />
        </div>
         {!viewMode && <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center', float:'left' }}
          onClick={() => deleteDocumentMutation.mutate(params?.data)}>
          <DeleteForeverIcon fontSize="small" />
        </div>}
        </>
      );
    },
  },
];


  const fileUploadProp: FileUploadProps = {
    accept:
      'doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setFile(null);
      if (event.target.files !== null && event.target?.files?.length > 0) {
        console.log(`Saving ${event.target.value}`);
        setFile(event?.target?.files[0]);
      } else {
        throw `Error ${event?.target?.files![0]?.name}`;
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      setFile(null);
      console.log(`Drop ${event?.dataTransfer?.files[0].name}`);
      setFile(event?.dataTransfer?.files[0]);
    },
  };

  const saveDocumentMetaMutation = useMutation((data: any) => saveDocumentMeta(data), {
    onSuccess: async (data: any) => {
      let upload = await uploadDocument(data?.id, file);
      if (upload) {
        enqueueSnackbar(`Document ${file?.name} Saved`, {
          variant: 'success',
        });
        setFile(null);
      } else {
        enqueueSnackbar(`Error while saving Document ${file?.name}`, {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar(`Error while uploading ${file?.name}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getRiskDocuments', loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });

  const handleSubmit = async () => {
    if (!file?.size) {
      enqueueSnackbar(`Please fill all details`, {
        variant: 'error',
      });
      return false;
    } else {
      saveDocumentMetaMutation.mutate({
        docDesc: 'RISK',
        docTypeCode: 'OTHERS',
        docFileName: file?.name,
        docXrefId: loanRequestId,
        docXrefType: 'LOAN_REQUEST',
      });

      if (saveDocumentMetaMutation?.isLoading) {
        dispatch(toggleLoader(true));
      }
    }
  };

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="5. Risk Calculations" />
      <Box sx={{ mt: 1 }}>
        <Grid container rowSpacing={1}>
          <Grid item xs={!viewMode ? 8 : 12}>
            <Box
              sx={{
                height: '260px',
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <DataTable columnDefs={columns} autoSize={false} rowData={getRows(data)} pagination={false} />
            </Box>
          </Grid>
          {!viewMode && (
            <Grid item xs={4}>
              <Box sx={{ padding: 1, pr: 2 }}>
                {!file && (
                  <FileUpload width="100%" {...fileUploadProp} hoverLabel={'Click or Drop file here'} fileButton />
                )}
                {file && <FileUpload {...fileUploadProp} hoverLabel={file?.name} fileButton />}
              </Box>
              <Box sx={{ padding: 1 }}>
                <Button variant="contained" component="label" onClick={handleSubmit}>
                  Upload
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default RiskCalculations;
