import { Autocomplete, TextField, Typography } from '@mui/material';

const MultiSelectAutocomplete = ({
  label,
  options,
  name,
  value,
  setValue,
  disabled = false,
  rules,
  ...input
}: {
  label: string;
  options: any;
  name: string;
  value: any;
  setValue: any;
  disabled?: boolean;
  rules?: any;
}) => {
  return (
    <Autocomplete
      disablePortal
      multiple
      value={value}
      options={options || []}
      getOptionLabel={(option: any) => {
        return option?.displayText;
      }}
      disabled={disabled}
      onChange={(event, newValue: any) => {
        setValue(newValue);
      }}
      isOptionEqualToValue={(option, value) => option?.displayText === value?.displayText}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography>{option?.displayText}</Typography>
        </li>
      )}
      sx={{
        padding: 0,
        '& .MuiAutocomplete-inputRoot': {
          padding: '7px 11px',
        },
        '& .MuiChip-label': {
          fontSize: '12px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '0px',
        },
        width: '33rem',
      }}
      renderInput={(params) => <TextField name={name} sx={input} {...params} />}
    />
  );
};

export default MultiSelectAutocomplete;
