import { useMemo, useCallback } from 'react';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import DataTable from 'components/common/data-table';
import { FormTitle } from 'components/common/form-components/FormTitle';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';


const TC = ({ setValue, watchAllFields, getValues }: any) => {
  const { state } = useLocation();
  const theme = useTheme();

  const colDefs = useMemo(
    () => [
      { field: 'type', headerName: 'Type of Covenant', editable: state?.isEdit },
      { field: 'approvedValue', headerName: 'Approved Value', editable: state?.isEdit },
      { field: 'testingFrequency', headerName: 'Testing Frequency', editable: state?.isEdit },
      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          if (!state?.isEdit)
            return null;
          return (
            <>
              {params.rowIndex ===
                getValues('covenants')
                  .length -
                  1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [
                        ...getValues(
                          'covenants'
                        ),
                        {},
                      ];
                      setValue(
                        'covenants',
                        fields
                      );
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('covenants')
                .length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [
                      ...getValues(
                        'covenants'
                      ),
                    ];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue(
                        'covenants',
                        updatedFields
                      );
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('covenants', updatedRowData);
  }, []);

  return (
    <>
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="7.5.a Covenants"></FormTitle>
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={colDefs}
          autoSize={true}
          sizeToFit={true}
          rowData={watchAllFields?.covenants}
          pagination={false}
          domLayout={'autoHeight'}
          style={{ height: '100%' }}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="7.5.b Other Terms & Conditions"></FormTitle>
      <Box sx={{ mt: 1 }}>
         <RichTextEditor
          value={watchAllFields?.tc}
          onEditorChangeHandler={(newValue: any) => {
            setValue('tc', newValue);
          }}
          disabled={!state?.isEdit}
        />
      </Box>
    </Box>
    </>
  );
};

export default TC;
