export const arTurnOverOption = ({xAxis, data} :any) => { 
	return {
	  title: {
	    text: 'Account Receivable Turnover\nvs\nAccount Payable Turnover',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  legend: {
	    bottom: '5%',
	    left: 'center'
	  },
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '20%',
	    containLabel: true
	  },
	  xAxis: {   
	   type: 'category',
	   data: xAxis
	  },
	  yAxis: {
	    type: 'value',
	    boundaryGap: [0, 0.01]
	  },
	  series: data?.map((ser:any) => Object.assign({
			      type: 'bar'
			  },{name:ser?.name}, {data:ser?.data}))
	};
}

export const arReceivableOption = ({xAxis, data} :any) => { 
	return {
	  title: {
	    text: 'Account Receivable Quality',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  legend: {
	    bottom: '5%',
	    left: 'center'
	  },
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '20%',
	    containLabel: true
	  },
	  xAxis: {
	    type: 'category',
	    data: xAxis
	  },
	  yAxis: {
	    type: 'value'
	  },
	  series: data?.map((ser:any) => Object.assign({
			      type: 'bar',
			      stack: 'total',
			      emphasis: {
			        focus: 'series'
			      },
			  },{name:ser?.name}, {data:ser?.data}))	    
	};
}

export const dioOption = ({data} :any) => { 
	return {
	  title: {
	    text: 'Days Inventory\nOutstanding (DIO)',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    top: '10%',
	    left: 'center',
	    selectedMode: false
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: (data === 0) ? { text: "No data available" } : null
	  },
	  series: [
	    {
	      name: 'Used',
	      type: 'pie',
	      bottom: '5%',
	      radius: ['40%', '70%'],
	      center: ['50%', '70%'],
	      startAngle: 180,
	      label: {
	        show: true,
	        formatter(param:any) {
	          return param.value + ' days';
	        },
	        position: 'center',
	        alignTo: 'none',
	      },
	      data: [
	        { value: data  },
	        {
	          value: 100,
	          itemStyle: {
	            color: 'none',
	            decal: {
	              symbol: 'none'
	            }
	          },
	          label: {
	            show: false
	          }
	        }
	      ]
	    }
	  ]
	};
}

export const dsoOption = ({data} :any) => { 
	return {
	  title: {
	    text: 'Days Sales\nOutstanding (DSO)',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    top: '10%',
	    left: 'center',
	    selectedMode: false
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: (data === 0) ? { text: "No data available" } : null
	  },
	  series: [
	    {
	      name: 'Used',
	      type: 'pie',
	      bottom: '5%',
	      radius: ['40%', '70%'],
	      center: ['50%', '70%'],
	      startAngle: 180,
	      label: {
	        show: true,
	        formatter(param:any) {
	          return param.value + ' days';
	        },
	        position: 'center',
	        alignTo: 'none',
	      },
	      data: [
	        { value: data },
	        {
	          value: 100,
	          itemStyle: {
	            color: 'none',
	            decal: {
	              symbol: 'none'
	            }
	          },
	          label: {
	            show: false
	          }
	        }
	      ]
	    }
	  ]
	};
}

export const dpoOption = ({data} :any) => { 
	return {
	  title: {
	    text: 'Days Payable\nOutstanding (DPO)',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    top: '10%',
	    left: 'center',
	    selectedMode: false
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: (data === 0) ? { text: "No data available" } : null
	  },	  
	  series: [
	    {
	      name: 'Used',
	      type: 'pie',
	      bottom: '5%',
	      radius: ['40%', '70%'],
	      center: ['50%', '70%'],
	      startAngle: 180,
	      label: {
	        show: true,
	        formatter(param:any) {
	          return param.value + ' days';
	        },
	        position: 'center',
	        alignTo: 'none',
	      },
	      data: [
	        { value: data},
	        {
	          value: 100,
	          itemStyle: {
	            color: 'none',
	            decal: {
	              symbol: 'none'
	            }
	          },
	          label: {
	            show: false
	          }
	        }
	      ]
	    }
	  ]
	};
}

export const cashConversionCycleOption = ({data} :any) => { 
	return {
	  title: {
	    text: 'Cash Conversion\nCycle',
	    left: 'center'
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    top: '10%',
	    left: 'center',
	    selectedMode: false
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: (data === 0) ? { text: "No data available" } : null
	  },	  
	  series: [
	    {
	      name: 'Used',
	      type: 'pie',
	      bottom: '5%',
	      radius: ['40%', '70%'],
	      center: ['50%', '70%'],
	      startAngle: 180,
	      label: {
	        show: true,
	        formatter(param:any) {
	          return param.value;
	        },
	        position: 'center',
	        alignTo: 'none',
	      },
	      data: [
	        { value: data },
	        {
	          value: 100,
	          itemStyle: {
	            color: 'none',
	            decal: {
	              symbol: 'none'
	            }
	          },
	          label: {
	            show: false
	          }
	        }
	      ]
	    }
	  ]
	};
}

export const breakupData = (breakupData:any) => breakupData