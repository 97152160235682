import _ from 'lodash';
import { Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { useLocation } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { toggleLoader } from 'state/reducers/common-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useRefData from 'hooks/ref-data';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { RootState } from 'state/store';
import { getProjectFinance, saveProjectFinance } from 'services/loan-projection-services';
import { YEAR_PICKER, RADIO_BUTTONS } from 'constants/form-components';
import moment from 'moment';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

export default function ProjectIrr({ control, watchAllFields, npvIrr, updateBusinessBgData }: any) {
  const { state } = useLocation();
  const { tabs, selectedTab }: any = useSelector((state: RootState) => state.projections);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { refData, fn } = useRefData();
  const minDate = moment(`01/01/${tabs[selectedTab]?.actualsYears[0]}`).toDate();
  const maxDate = moment(`12/31/${tabs[selectedTab]?.endYear}`).toDate();
  const fy = refData.financialYearTypes;
  const fiscalYear: any = _.map(fy, (fy) => {
    return { label: fy.displayText, value: fy.id };
  });
  const mutation = useMutation((data: any) =>
    saveProjectFinance(state?.loanRequestId, tabs![selectedTab]?.id, data)
  );
  const queryClient = useQueryClient();

  const {
    control: generateIrrControl,
    reset,
    handleSubmit: handleSubmit2,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      assumptions: {
        taxRate: '',
        discountRate: '',
        riskFreeRate: '',
        perGrowthRate: '',
        fiscalYearTypeId: tabs[selectedTab]?.fiscalYearTypeId,
        startYear: tabs[selectedTab]?.actualsYears[0],
        endYear: tabs[selectedTab]?.endYear,
      },
      discountedCashFlow: [],
    },
  });

  const showErrors = () => {
    enqueueSnackbar('Please fill all Assumptions', {
      variant: 'error',
      autoHideDuration: 5000,
    });
  };

  const handleClose = () => {
    setOpen(false);
    queryClient.invalidateQueries(['getPerformanceSummary']);
  };

  const { data: projectFinanceData, refetch } = useQuery(
    ['getProjectFinance', state?.loanRequestId, tabs![selectedTab]?.id],
    () => getProjectFinance(state?.loanRequestId, tabs![selectedTab]?.id),
    {
      onSuccess: (data: any) => {
        if (data) {
          reset({
            ...data,
            assumptions: {
              ...data.assumptions,
              fiscalYearTypeId: tabs[selectedTab]?.fiscalYearTypeId,
            },
            discountedCashFlow: [...data?.discountedCashFlow],
          });
        }
      },
    }
  );

  const openGeneratePopup = () => {
    updateBusinessBgData(watchAllFields);
    setOpen(true);
    refetch();
  };

  const generateIrrHandler = (data: any) => {
    function getMappedData(type: string) {
      const obj: any = {};
      _.map(data?.discountedCashFlow, (row: any) => {
        if (
          (type === 'yearFraction' && row.caption === 'Year Fractions') ||
          (type === 'capex' && row.caption === 'Less: Capex')
        ) {
          for (const key in row) {
            if (key != 'caption') {
              let year = key.split('_')[1];
              obj[year] = Number(row[key]);
            }
          }
        }
      });
      return obj;
    }

    let dataObj = {
      ...data.assumptions,
      yearFractionMap: getMappedData('yearFraction'),
      capexMap: getMappedData('capex'),
      startYear:
        typeof data.assumptions.startYear === 'object'
          ? new Date(data.assumptions.startYear).getFullYear()
          : data.assumptions.startYear,
    };

    dispatch(toggleLoader(true));
    mutation.mutate(dataObj, {
      onSuccess: (res: AxiosResponse<any, any>) => {
        if (res) {
          enqueueSnackbar('Discounted Cash Flow Updated', {
            variant: 'success',
          });
          enqueueSnackbar('IRR And NPV Generated', {
            variant: 'success',
          });
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
        queryClient.invalidateQueries(['getProjectFinance']);
      },
    });
  };

  const getColumnDefs = () => {
    const columnDefs: any = [];
    for (const key in projectFinanceData?.discountedCashFlow[0]) {
      let obj: any = {
        field: key,
        headerName: projectFinanceData?.discountedCashFlow[0][key],
        editable:
          key === 'caption'
            ? false
            : function (params: any) {
                return params.data.caption === 'Year Fractions' || params.data.caption === 'Less: Capex'
                  ? true
                  : false;
              },
      };

      if (key !== 'caption') {
        obj['valueFormatter'] = formatGridNumber;
        obj['cellEditor'] = NumericEditor;
        obj['cellStyle'] = { textAlign: 'right' };
      }

      columnDefs.push(obj);
    }
    return columnDefs;
  };

  const getRowData = () => {
    let rows = [...projectFinanceData?.discountedCashFlow];
    rows.shift();
    return rows;
  };

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('discountedCashFlow', updatedRowData);
  }, []);

  const getRowStyle = (params: any) => {
    if (params.data.caption === 'Year Fractions' || params.data.caption === 'Less: Capex') {
      return { background: '#fff' };
    } else {
      return { background: '#eee' };
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label={'Project purpose'} sx={{ width: '40%' }} />
            <Box sx={{ width: '60%' }}>
              {getFormFields(
                {
                  name: 'projectPurpose',
                  component: 'textField',
                },
                control,
                state.viewOnly
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label={'Project Cost (IDR Million)'} sx={{ width: '40%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '60%' }}>
              {getFormFields(
                {
                  name: 'projectCost',
                  component: 'textField',
                },
                control,
                state.viewOnly
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5} sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label={'Project NPV'} sx={{ width: '40%' }} />
            <Box sx={{ width: '60%' }}>
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '7px 11px',
                    width: '12rem',
                    fontSize: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px',
                  },
                }}
                value={npvIrr?.npv || ''}
                variant="outlined"
                disabled={true}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label={'Project IRR'} sx={{ width: '40%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '60%' }}>
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '7px 11px',
                    width: '12rem',
                    fontSize: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px',
                  },
                }}
                value={npvIrr?.irr || ''}
                variant="outlined"
                disabled={true}
              />
              {state?.isEdit && (
                <Button variant="text" sx={{ ml: 1, p: 1 }} onClick={openGeneratePopup}>
                  Generate
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <form>
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'xl'} scroll={'paper'}>
          <DialogTitle>DCF: FCFF Model</DialogTitle>
          {projectFinanceData ? (
            <DialogContent dividers={true}>
              <Box>
                <FormTitle title="Assumptions" />
                <Box>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Tax Rate'} sx={{ width: '40%' }} />
                      {getFormFields(
                        { name: 'assumptions.taxRate', component: 'textField', type: 'number' },
                        generateIrrControl,
                        !state?.isEdit,
                        { required: true }
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Discount Rate (WACC)'} sx={{ width: '40%' }} />
                      {getFormFields(
                        { name: 'assumptions.discountRate', component: 'textField', type: 'number' },
                        generateIrrControl,
                        !state?.isEdit,
                        { required: true }
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Risk Free Rate'} sx={{ width: '40%' }} />
                      {getFormFields(
                        { name: 'assumptions.riskFreeRate', component: 'textField', type: 'number' },
                        generateIrrControl,
                        !state?.isEdit,
                        { required: true }
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Perpetural Growth Rate'} sx={{ width: '40%' }} />
                      {getFormFields(
                        { name: 'assumptions.perpeturalGrowthRate', component: 'textField', type: 'number' },
                        generateIrrControl,
                        !state?.isEdit,
                        { required: true }
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Fiscal Year'} sx={{ width: '40%' }} />
                      {getFormFields(
                        {
                          name: 'assumptions.fiscalYearTypeId',
                          component: RADIO_BUTTONS,
                          radioGroup: 'fiscalYear',
                          values: fiscalYear,
                        },
                        generateIrrControl,
                        true
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Start Year'} sx={{ width: '40%' }} />
                      {getFormFields(
                        {
                          name: 'assumptions.startYear',
                          component: YEAR_PICKER,
                          minDate: minDate,
                          maxDate: maxDate,
                        },
                        generateIrrControl,
                        !state?.isEdit,
                        { required: true }
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <Box className="required" sx={{ display: 'flex' }}>
                      <SimpleFormLabel label={'Ending Year'} sx={{ width: '40%' }} />
                      {getFormFields(
                        { name: 'assumptions.endYear', component: YEAR_PICKER },
                        generateIrrControl,
                        true
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <FormTitle title="Discounted Cash Flow" />
                {projectFinanceData?.discountedCashFlow && (
                  <Box
                    sx={{
                      height: '490px',
                      padding: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <DataTable
                      columnDefs={getColumnDefs()}
                      autoSize={false}
                      rowData={getRowData()}
                      pagination={false}
                      onCellValueChanged={onCellValueChanged}
                      getRowStyle={getRowStyle}
                    />
                  </Box>
                )}
              </Box>
            </DialogContent>
          ) : (
            <DialogContent dividers={true}>
              <Box>
                <p>No Actual Data Found</p>
              </Box>
            </DialogContent>
          )}
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label={'NPV'} sx={{ width: '15%' }} />
                  {getFormFields(
                    { name: 'npvIrr.npv', component: 'textField', type: 'number' },
                    generateIrrControl,
                    true
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label={'IRR'} sx={{ width: '20%', placeSelf: 'flex-start' }} />
                  {getFormFields(
                    { name: 'npvIrr.irr', component: 'textField', type: 'number' },
                    generateIrrControl,
                    true
                  )}
                </Box>
              </Grid>
            </Grid>
            <Button variant="contained" onClick={handleSubmit2(generateIrrHandler, showErrors)} sx={{ p: 1 }}>
              Generate
            </Button>
            <Button variant="contained" onClick={handleClose} sx={{ marginRight: 2, p: 1 }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
}
