import { FC } from 'react';
import { Switch } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface ISwitchProps {
  name: string;
  control: Control<any>;
  style?: any;
  disabled?: boolean;
  rules?: any;
}

const MuiSwitch: FC<ISwitchProps> = ({ name, control, style, disabled, rules, ...input }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} disabled={disabled} />
      )}
    />
  );
};

export default MuiSwitch;
