import { makeStyles, createStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import { RootState } from '../../../state/store';
import { deepOrange, blue } from '@mui/material/colors';
import { downloadDocument } from 'services/document-services';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const useStyles = makeStyles((theme) =>
  createStyles({
    chatMainApp: {
      paddingRight: '12px',
    },
    chatApp: {
      padding: '3px',
    },
    chatPanel: {
      border: '1px solid #97C6E3',
      height: '60vh',
      overflowX: 'scroll',
      padding: '8px',
    },
    messageRow: {
      display: 'flex',
      marginBottom: '8px',
    },
    messageRowRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
      marginBottom: '8px',
    },
    messageBlue: {
      position: 'relative',
      marginLeft: '20px',
      marginBottom: '10px',
      wordBreak: 'break-word',
      padding: '10px',
      backgroundColor: '#A8DDFD',
      width: '90%',
      textAlign: 'left',
      border: '1px solid #97C6E3',
      borderRadius: '10px',
      '&:after': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: '15px solid #A8DDFD',
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        top: '0',
        left: '-15px',
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: '17px solid #97C6E3',
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        top: '-1px',
        left: '-17px',
      },
    },
    messageOrange: {
      position: 'relative',
      wordBreak: 'break-word',
      marginRight: '20px',
      marginBottom: '10px',
      padding: '10px',
      backgroundColor: '#f8e896',
      width: '90%',
      textAlign: 'left',
      border: '1px solid #dfd087',
      borderRadius: '10px',
      '&:after': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: '15px solid #f8e896',
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        top: '0',
        right: '-15px',
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: '17px solid #dfd087',
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        top: '-1px',
        right: '-17px',
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      textAlign: 'right',
      marginTop: '15px',
      bottom: '5px',
      right: '5px',
    },
    messageDocRight: {
      textAlign: 'left',
      bottom: '7px',
      left: '5px',
      position: 'absolute', 
      width: '40%'
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    blue: {
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: 'transparent',
      backgroundColor: 'transparent',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: '20px',
    },
    displayNameRight: {
      margin: '0 20px 0 20px',
      textAlign: 'right'
    },
  })
);


const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};


const Chat = ({ comments, heading, status, isLoading }: any) => {
  const { username }: any = useSelector((state: RootState) => state.user?.userDetails);

  const classes = useStyles();

  const chatBubbles =
    comments &&
    comments
      ?.sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime())
      ?.map((obj: any, i = 0) => (
        <div
          key={`${i}`}
          className={
            i % 2 !== 0
              ? classes.messageRow
              : classes.messageRowRight
          }>
          <Avatar
            alt={obj?.commentAddedBy || obj?.userId}
            className={
              i % 2 !== 0
                ? classes.blue
                : classes.orange
            }>
            <Typography>
              {obj?.commentAddedBy?.charAt(0)?.toUpperCase() || obj?.userId?.charAt(0)?.toUpperCase()}
            </Typography>
          </Avatar>
          <div style={{ width: '90%' }}>
            <div
              className={
                i % 2 !== 0
                  ? classes.displayName
                  : classes.displayNameRight
              }>
              <Typography variant="subtitle2">{obj?.commentAddedBy || obj?.userId}</Typography>
            </div>
            <div
              className={
                i % 2 !== 0
                  ? classes.messageBlue
                  : classes.messageOrange
              }>
              <div>
                <p className={classes.messageContent}>
                  <Typography>{obj?.comment || obj?.fullMessage}</Typography>
                </p>
              </div>
              {obj?.docMeta?.docFileName && <div className={classes.messageDocRight}><a href="#"
                title={obj?.docMeta?.docFileName}
                style={{ cursor: 'pointer', paddingTop:"4px",
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      display: 'block' }}
                onClick={() => downloadDocuments(obj?.docMeta)}>
                {obj?.docMeta?.docFileName}<span style={{ cursor: 'pointer', top: "5px", position: "relative", paddingLeft:"4px" }}><DownloadForOfflineIcon style={{fontSize: "16px"}} /></span>
              </a></div>}
              <div className={classes.messageTimeStampRight}>
                <Typography sx={{ fontSize: '10px' }}>{obj?.commentAddedOn?.split('.')[0] || obj?.time?.split('.')[0]}</Typography>
              </div>
            </div>
          </div>
        </div>
      ));
  return (
    <div key={heading} className={classes.chatMainApp}>
      <div className={classes.chatApp}>
        <Typography variant="h5" component="span" gutterBottom>
          {heading}
        </Typography>
        <Typography component="span" gutterBottom>
          {status ? ` : ${status}` : ''}
        </Typography>
        <div className={classes.chatPanel}>
          {isLoading && <CircularProgress />}
          {chatBubbles && chatBubbles}
        </div>
      </div>
    </div>
  );
};

export default Chat;
