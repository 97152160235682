import { Breadcrumbs, Divider, Link, Typography, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export const BreadCrumbs = ({ title, loanRequestId, company, amount, route }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();

  const item = {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    padding: '5px 10px',
    marginRight: '10px !important',
    borderRadius: '5px',
    margin: 'auto',
    height: 'fit-content',
  };

  const itemHeading = {
    color: '#676767',
  };

  const itemValue = {
    color: theme.palette.primary.main,
  };

  const gotoPath = () => {
    if (state?.from?.includes('archive') || state?.from?.includes('memos')) {
      navigate(-1);
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F3F4FA',
          height: '50px',
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} color="inherit">
          <HomeOutlinedIcon
            sx={{
              mr: 0.5,
              background: '#FFFFFF',
              color: theme.palette.primary.main,
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            fontSize="small"
            onClick={gotoPath}
          />
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '2.5em',
              letterSpacing: '0.02857em',
            }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={item} color="inherit">
            <Typography variant="subtitle1">
              <span style={itemHeading}>Loan Request ID:</span> <span style={itemValue}>{loanRequestId}</span>
            </Typography>
          </Box>
          <Box sx={item} color="inherit">
            <Typography variant="subtitle1">
              <span style={itemHeading}>Company:</span> <span style={itemValue}>{company}</span>
            </Typography>
          </Box>
          <Box sx={item} color="inherit">
            <Typography variant="subtitle1">
              <span style={itemHeading}>Amount: IDR</span> <span style={itemValue}>{amount} (Million)</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
