import { Box, Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { getFormFields } from 'utils/form-utils';
import { DATE_PICKER, RADIO_BUTTONS } from 'constants/form-components';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useState, useEffect } from 'react';

const SharemarketDetailsTable = ({ control, watchAllFields }: { control: Control; watchAllFields: any }) => {
  const { state } = useLocation();
  const [showListedAttr, setListToggle] = useState(false);

  useEffect(() => {
    if (watchAllFields?.companyAndGroupDetails?.shareMarketInformation?.listed) {
      setListToggle(watchAllFields?.companyAndGroupDetails?.shareMarketInformation?.listed == 'No' ? false : true);
    }
  }, [watchAllFields?.companyAndGroupDetails?.shareMarketInformation?.listed]);

  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle formNumbering="1.3" title="Share Market Information"></FormTitle>
        <Box>
          <Grid container rowSpacing={1} sx={{ mt: 0 }}>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label="Listed" sx={{ width: '40%' }} />
                {getFormFields(
                  {
                    name: 'companyAndGroupDetails.shareMarketInformation.listed',
                    component: RADIO_BUTTONS,
                    values: [
                      {
                        label: 'Yes',
                        value: 'Yes',
                      },
                      {
                        label: 'No',
                        value: 'No',
                      },
                    ],
                  },
                  control,
                  !state?.isEdit ? true : false
                )}
              </Box>
            </Grid>
            {showListedAttr && (
              <>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel label="Information as of" sx={{ width: '40%' }} />
                    {getFormFields(
                      {
                        name: 'companyAndGroupDetails.shareMarketInformation.informationAsOf',
                        component: DATE_PICKER
                      },
                      control,
                      (watchAllFields?.companyAndGroupDetails?.shareMarketInformation?.listed==='Yes' && !state?.isEdit) ? true : false
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel label="Last Trade Price" sx={{ width: '40%' }} />
                    {getFormFields(
                      {
                        name: 'companyAndGroupDetails.shareMarketInformation.lastTradedPrice',
                        component: 'textField',
                        type: 'number',
                      },
                      control,
                      !state?.isEdit ? true : false
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel label="52 Weeks (High/Low)" sx={{ width: '40%' }} />
                    {getFormFields(
                      { name: 'companyAndGroupDetails.shareMarketInformation.high/low', component: 'textField' },
                      control,
                      !state?.isEdit ? true : false
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel label="Market Capitalisation (Full/ Float)" sx={{ width: '40%' }} />
                    {getFormFields(
                      {
                        name: 'companyAndGroupDetails.shareMarketInformation.marketCapitalisation',
                        component: 'textField',
                      },
                      control,
                      !state?.isEdit ? true : false
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel label="Promoter Holding (%)" sx={{ width: '40%' }} />
                    {getFormFields(
                      {
                        name: 'companyAndGroupDetails.shareMarketInformation.promoterHolding',
                        component: 'textField',
                        type: 'number',
                      },
                      control,
                      !state?.isEdit ? true : false
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <SimpleFormLabel
                      label="Promoter Shared Pledged (%) (Including own bank)"
                      sx={{ width: '40%' }}
                    />
                    {getFormFields(
                      {
                        name: 'companyAndGroupDetails.shareMarketInformation.promoterShares',
                        component: 'textField',
                        type: 'number',
                      },
                      control,
                      !state?.isEdit ? true : false
                    )}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {/* <TableContainer sx={{ overflowX: 'hidden' }}>
        <Table size="small" sx={{ minWidth: 400, border: '0.5px solid #f2f2f2' }}>
          <TableBody>
            <Row
              title="Listed"
              data={
                <DenseCellSm viewOnly={!state?.isEdit}>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.listed',
                      component: RADIO_BUTTONS,
                      values: [
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="Information as of"
              data={
                <DenseCellSm>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.informationAsOf',
                      component: DATE_PICKER,
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="Last Trade Price"
              data={
                <DenseCellSm>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.lastTradedPrice',
                      component: TABLE_INPUT,
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="52 Weeks (High/Low)"
              data={
                <DenseCellSm>
                  {getFormFields(
                    { name: 'companyAndGroupDetails.shareMarketInformation.high/low', component: TABLE_INPUT },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="Market Capitalisation (Full/ Float)"
              data={
                <DenseCellSm>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.marketCapitalisation',
                      component: TABLE_INPUT,
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="Promoter Holding"
              data={
                <DenseCellSm>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.promoterHolding',
                      component: TABLE_INPUT,
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
            <Row
              title="Promoter Shares pledged to others"
              data={
                <DenseCellSm>
                  {getFormFields(
                    {
                      name: 'companyAndGroupDetails.shareMarketInformation.promoterShares',
                      component: TABLE_INPUT,
                    },
                    control,
                    !state?.isEdit ? true : false
                  )}
                </DenseCellSm>
              }
            />
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
};

export default SharemarketDetailsTable;
