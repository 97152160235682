import _ from 'lodash';
import { Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import useRefData from 'hooks/ref-data';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import CssBaseline from '@mui/material/CssBaseline';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs)(() => ({
  boxShadow: 'none !important',
}));

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  paddingLeft: '10px',
  padding: 0,
  margin: 0,
  '&.MuiTab-root': {
    minHeight: '44px !important',
  },
}));

export default function ProposalSideMenu({ value, handleChange }: any) {
  const { fn } = useRefData();
  const { mapProjectionTemplate } = fn;
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);

  const applicantTabs =
    !_.isEmpty(tabs) && mapProjectionTemplate(tabs![selectedTab]?.projectionTemplate) === 'Manufacturing'
      ? [
          'Basic Details',
          'Actuals',
          'Projection',
          'Performance Summary',
          'Projection Summary',
          'Profit & Loss',
          'Balance Sheet',
          'Current Assets & Liabilities',
          'Assessed Bank Finance',
          'Fund Flow Analysis',
          'WC Analysis',
        ]
      : mapProjectionTemplate &&
        !_.isEmpty(tabs) &&
        mapProjectionTemplate(tabs![selectedTab]?.projectionTemplate) === 'Finance'
      ? ['Basic Details', 'Actuals', 'Projection', 'BS', 'PL', 'Cash Flow', 'ALM', 'Fee Income']
      : [];

  const tabLabel = (label: string) => (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
      <Typography variant="button">{label}</Typography>
    </Box>
  );

  return (
    <StyledTabs
      orientation="vertical"
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          left: 0,
        },
      }}
      aria-label="Projection"
      sx={{
        borderRight: 1,
        borderColor: 'divider',
        width: '100%',
      }}>
      {applicantTabs?.map((tab: string, index: number) => (
        <StyledTab key={tab} label={tabLabel(tab)} {...a11yProps(index)} />
      ))}
    </StyledTabs>
  );
}
