import { Box, Switch, Stack, Typography } from '@mui/material';
import DataTable from 'components/common/data-table';
import _ from 'lodash';
import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller } from 'react-hook-form';

const KeyRiskTrigger = ({
  getValues,
  setValue,
  watchAllFields,
  control,
}: {
  getValues: any;
  setValue: any;
  watchAllFields: any;
  control: any;
}) => {
  const { state } = useLocation();

  const keyRiskTriggerColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'item',
        headerName: 'Trigger Item',
        sortable: false,
        suppressMenu: true,
      },
      {
        field: 'details',
        headerName: 'Trigger Details',
        sortable: false,
        suppressMenu: true,
      },
      {
        field: 'crticical',
        headerName: 'Critical/Non-Critical',
        sortable: false,
        suppressMenu: true,
        width: 15,
      },
      {
        field: 'observed',
        headerName: 'Observed(Yes/No)',
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: (params: any) => {
          const name = `keyRiskTrigger[${params.rowIndex}].observed`;
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      params.api.stopEditing();
                      field.onChange(e.target.checked);
                      setTimeout(() => {
                        let fields = [...getValues('keyRiskTrigger')];
                        setValue('keyRiskTrigger', fields);
                        params.api.setRowData(fields);
                      }, 100);
                    }}
                    disabled={!state?.isEdit}
                  />
                )}
              />
              <Typography>Yes</Typography>
            </Stack>
          );
        },
        width: 15,
      },
      {
        field: 'explanation',
        headerName: 'Explanation',
        sortable: false,
        suppressMenu: true,
        editable: state?.isEdit,
      },
    ];
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('keyRiskTrigger', updatedRowData);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <DataTable
        columnDefs={keyRiskTriggerColDef}
        autoSize={true}
        rowData={watchAllFields?.keyRiskTrigger}
        domLayout={'autoHeight'}
        sizeToFit={true}
        pagination={false}
        onCellValueChanged={onCellValueChanged}
        editType="single"
      />
    </Box>
  );
};

export default KeyRiskTrigger;
