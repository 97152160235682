import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';

interface ITableFilterContainer {
  children: any;
  title: string;
}

const ContentLayout: FC<ITableFilterContainer> = ({ children, title }: any) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: 'calc(100vh - 120px)',
      }}>
      <CardHeader
        sx={{
          p: 1,
          pl: 2,
          background: theme.palette.grey[50],
          '& .MuiCardHeader-title': {
            fontSize: '20px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.3,
            letterSpacing: 'normal',
          },
        }}
        title={title}
      />
      <Divider />
      <CardContent sx={{ p: 1 }}>{children}</CardContent>
    </Card>
  );
};

export default ContentLayout;
