import { useState } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { Box, Divider, Button } from '@mui/material';
import { getRefDataByGroupName } from 'services/common-services';
import { useQuery } from 'react-query';
import { ColGroupDef, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { approveWithDocList, postLegalDocDetails, getLegalDocDetails } from 'services/legal-service';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import useLoanTable from 'hooks/useLoanTable';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import ActionBar from 'components/styled-components/ActionBar';
import queryClient from 'services/react-query-service';

export default function DocumentList() {
  const { getHomePage } = useLoanTable();
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const { data, isSuccess } = useQuery(['getRefDataByGroupName'], () =>
    getRefDataByGroupName('LegalMasterDocList')
  );
  const { state } = useLocation();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const [gridApi, setGridApi] = useState<any | undefined>();
  const { data: legalDocDetails, isSuccess: isLegalSuccess } = useQuery(
    ['getLegalDocDetails', state?.loanRequestId],
    () => getLegalDocDetails(state?.loanRequestId)
  );

  const getLegalDoc = (legalDocDetails: any) => {
    return legalDocDetails?.docList?.map((doc: any) => doc?.legalDocumentName);
  };

  const columnDefs = [
    {
      field: 'srNo',
      headerName: 'Sr. No',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      pinned: 'left',
      resizable: false,
    },
    { field: 'selectSrNo', headerName: 'Sr. No', hide: _.isEmpty(getLegalDoc(legalDocDetails)) },
    { field: 'id', hide: true },
    {
      field: 'displayText',
      headerName: 'List of Documents',
    },
    { field: 'descriptionText', headerName: 'Description' },
  ];

  const getRows = (data: any): ColGroupDef[] => {
    let rows = null;
    if (data) {
      rows = data?.map((doc: any, i: number) => {
        return { ...doc, srNo: i + 1, selectSrNo: i + 1 };
      });
    }
    return rows;
  };

  useEffect(() => {
    if (_.isEmpty(legalDocDetails) && data) {
      setRowData(getRows(data));
      gridApi?.columnApi?.setColumnVisible('selectSrNo', false);
      gridApi?.columnApi?.setColumnVisible('srNo', true);
    }
  }, [data]);

  useEffect(() => {
    if (isLegalSuccess && !_.isEmpty(legalDocDetails)) {
      const legalDoc = getLegalDoc(legalDocDetails);
      setRowData(getRows(legalDoc));
      gridApi?.columnApi?.setColumnVisible('selectSrNo', true);
      gridApi?.columnApi?.setColumnVisible('srNo', false);
    }
  }, [legalDocDetails]);

  const approveWithDocListMutation = useMutation((approvalData: any) => approveWithDocList(approvalData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(
        `Loan Request ID : ${state?.loanRequestId} has been ${
          variables?.approvalStatus ? 'Approved' : 'Returned'
        }`,
        {
          variant: 'success',
        }
      );
      navigate(getHomePage());
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
      queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
    },
  });

  const saveDocumentList = useMutation(
    ({ documentDetails, approvalData }: any) =>
      postLegalDocDetails(state?.loanRequestId, { documentDetails, approvalData }),
    {
      onSuccess: (data, variables) => {
        approveWithDocListMutation.mutate({
          ...variables?.approvalData,
        });
      },
      onError: (data) => {
        enqueueSnackbar(`Unable to prepare Document list for Loan Request ID : ${state?.loanRequestId}`, {
          variant: 'error',
        });
      },
      onSettled: (data) => {
        dispatch(toggleLoader(false));
        setOpen(false);
        queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
      },
    }
  );
  const onClickApproveReturnHandler = (comment: string) => {
    if (state?.loanRequestId && approveReturn != null && state?.flowableTaskId) {
      if (!_.isEmpty(gridApi?.api?.getSelectedRows())) {
        const documentDetails: any = gridApi?.api
          ?.getSelectedRows()
          .map((doc: any) => Object.assign({}, { legalDocumentName: doc }));

        saveDocumentList.mutate({
          documentDetails: { loanRequestId: state?.loanRequestId, docList: documentDetails },
          approvalData: {
            approvalStatus: approveReturn,
            loanId: state?.loanRequestId,
            approvalComment: comment,
            approvalTaskId: state?.flowableTaskId,
          },
        });
      } else {
        enqueueSnackbar(`Please select atleast 1 document for Loan Request ID : ${state?.loanRequestId}`, {
          variant: 'error',
        });
        setOpen(false);
      }
    } else {
      enqueueSnackbar(`Unable to approve request for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });
    }
  };

  const handleClickOpen = (approveReturn: boolean | null) => {
    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      setMessage('Confirmation');
      setOpen(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sx={{ height: 'calc(100vh - 144px)' }}>
        <div style={{ height: 'calc(100% - 25px)' }} className="ag-theme-alpine">
          <div
            style={{
              height: '100%',
              width: '100%',
            }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              onGridReady={(event) => {
                setGridApi(event);
                event.api.sizeColumnsToFit();
              }}
              animateRows={true}
              reactUi={true}
              enableRangeSelection={true}
              paginationPageSize={10}
              pagination={true}
              editType={'fullRow'}
              stopEditingWhenCellsLoseFocus={true}
              rowSelection={'multiple'}
            />
          </div>
        </div>
      </Grid>

      <ActionBar>
        {!state?.viewOnly && (
          <Button type="submit" onClick={() => handleClickOpen(true)} variant="contained" sx={{ p: 1, right: 10 }}>
            Submit
          </Button>
        )}
      </ActionBar>

      <DialogWithComment
        open={open}
        setOpen={setOpen}
        title={message}
        description={`Do you want to ${approveReturn ? 'Submit' : 'Return'} for Loan Request ID: ${
          state?.loanRequestId
        } ?`}
        type={'info'}
        onClickHandler={onClickApproveReturnHandler}
      />
    </Grid>
  );
}
