import { Typography } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';

export const NumberDenseCell = ({ children, heading }: any) => {
  return (
    <DenseCellSm align="right" sx={{ minWidth: '70px' }}>
      <Typography sx={{ fontWeight: heading ? 700 : null }}>{children}</Typography>
    </DenseCellSm>
  );
};
