import _ from 'lodash';
import { useEffect } from 'react';
import { unflatten } from 'flat';
import { Button, CardContent, Card, Box } from '@mui/material';
import { Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormGroupType, FinanceFormType, IInputTable } from '../../../../../../../types/inputform.d';
import SimpleAccordion from '../../../../../../common/simple-accordian';
import { updateFinanceInputScreen } from './inputScreenSlice';
import { updateFinanceFormula } from './formulaSlice';
import { RootState } from '../../../../../../../state/store';
import { generateFormProfitLoss, FormProfitLoss } from '../formprofitloss';
import { generateFormLoans, FormLoans } from '../formloans';
import { generateFormDeposits, FormDeposits } from '../formdeposits';
import { generateFormCapitalInjection, FormCapitalInjection } from '../formcapitalinjection';
import { generateFormDebtDetails, FormDebtDetails } from '../formdebtdetails';
import { generateFormKeyPlAssumptionsCells, FormKeyPlAssumptions } from '../formkeyplassumptions';
import { generateFormBalanceSheet, FormBalanceSheet } from '../formbalancesheet';
import { generateFormOtherAssumptions, FormOtherAssumptions } from '../formotherassumptions';
import { generateFormCashFlowStatement, FormCashFlowStatement } from '../formcashflowstatement';
import { generatekeyBsAssumptions, KeyBsAssumptions } from '../formkeybsassumptions';
import { generatecapitalAdequeacyRatio, FormCapitalAdequeacyRatio } from '../formcapitaladequeacyratio';
import { generateFinancialRatios, FinancialRatios } from '../formfinancialratios';
import { addExpression, addFormula, getInputValue } from '../../common/FormUtil';
import { createInputScreen, getInputScreen } from 'services/loan-projection-services';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import { MENU_ITEMS } from '../../../../../loan-projection-and-proposal/projection-menu-schema';
import ActionBar from 'components/styled-components/ActionBar';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';

function renderInputForms(group: FormGroupType) {
  return Map({
    FORMPROFITLOSS: (
      <SimpleAccordion
        title="Profit & Loss Statement"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormProfitLoss group={group} />
          </div>
        }
      />
    ),
    FORMLOANS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Interest Income-Loans"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormLoans group={group} />
          </div>
        }
      />
    ),
    FORMDEPOSITS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deposits"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormDeposits group={group} />
          </div>
        }
      />
    ),
    FORMDEBTDETAILS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Debt Details"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormDebtDetails group={group} />{' '}
          </div>
        }
      />
    ),
    FORMCAPITALINJECTION: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Capital Injections"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCapitalInjection group={group} />
          </div>
        }
      />
    ),
    FORMKEYPLASSUMPTIONS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Key Pl Assumptions"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormKeyPlAssumptions group={group} />
          </div>
        }
      />
    ),
    FORMOTHERASSUMPTIONS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Other Assumptions"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormOtherAssumptions group={group} />
          </div>
        }
      />
    ),
    FORMBALANCESHEET: (
      <SimpleAccordion
        title="Balance Sheet"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormBalanceSheet group={group} />
          </div>
        }
      />
    ),
    FORMKEYBSASSUMPTIONS: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Key Bs Assumptions"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <KeyBsAssumptions group={group} />
          </div>
        }
      />
    ),
    FORMCAPITALADEQUEACYRATIO: (
      <SimpleAccordion
        title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Capital Adequeacy Ratio (CAR)"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCapitalAdequeacyRatio group={group} />
          </div>
        }
      />
    ),
    FORMCASHFLOWSTATEMENT: (
      <SimpleAccordion
        title="Cash Flow Statement"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FormCashFlowStatement group={group} />
          </div>
        }
      />
    ),
    FORMFINANCIALRATIOS: (
      <SimpleAccordion
        title="Financial Ratio's"
        children={
          <div style={{ overflowX: 'scroll' }}>
            <FinancialRatios group={group} />
          </div>
        }
      />
    ),
  });
}

const FinanceTable = ({ inputType }: IInputTable) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { state: camState } = useLocation();

  const { loanRequestId, status, viewOnly }: any = useSelector((state: RootState) => state.loanProposal);
  const { tabs, selectedTab }: any = useSelector((state: RootState) => state.projections);
  const { data, isSuccess } = useQuery(['getFinanceScreen', selectedTab, loanRequestId], () =>
    getInputScreen({ loanId: tabs![selectedTab]?.loanRequestId, projectionId: tabs![selectedTab]?.id })
  );
  const cells = useSelector((state: RootState) => state?.financeInputscreen?.cells);
  const formula = useSelector((state: RootState) => state?.financeFormula?.rows);
  const { enqueueSnackbar } = useSnackbar();
  const { getRequestStatusCode } = useLoanTable();
  const inputScreenMutation = useMutation((data: any) => createInputScreen(data?.projection, data?.finalData), {
    onSuccess: (data) => {
      enqueueSnackbar(
        `Data saved for Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'success',
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(
        `Error while saving Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'error',
        }
      );
    },
    onSettled: (data) => {
      queryClient.invalidateQueries(['getFinanceScreen', selectedTab, loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });

  useEffect(() => {
    dispatch(toggleLoader(true));
  }, []);

  const generateInputJsonforDB = () => {
    const inputJson = _.cloneDeep(cells);

    if (!_.isEmpty(inputJson)) {
      const yearMap = Object.fromEntries(
        Object.assign(
          [],
          ...Object.values(inputJson).map((row: any) =>
            Object.entries(row)
              .filter(([k]) => k?.endsWith('.0'))
              .map(([k, v]: any) => [k?.replaceAll('.0', ''), `${v?.value}.${v?.group}`])
          )
        )
      );

      const finalData: any = unflatten(
        Object.assign(
          {},
          ...Object.entries(inputJson).map(([formkey, formvalue]: any) => {
            return Object.assign(
              {},
              ...Object.entries(formvalue)
                .filter(([key]: any) => !key?.startsWith('0') && !key?.endsWith('.0'))
                .map(([key, value]: any) => {
                  const [yearKey, ...restKey] = key.split('.');
                  return Object.assign(
                    {},
                    { [`${yearMap[yearKey]}.${formkey}.${restKey.join('.')}`]: { value: value?.value } }
                  );
                })
            );
          })
        ),
        { object: true }
      );

      let data = null;
      if (!_.isEmpty(tabs)) {
        data = {
          projection: { loanId: tabs![selectedTab]?.loanRequestId, projectionId: tabs![selectedTab]?.id },
          finalData,
        };
      }

      return data;
    }
  };
  const onClickHandler = () => {
    const data = generateInputJsonforDB();
    dispatch(toggleLoader(true));
    data && inputScreenMutation.mutate(data);
  };

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  useEffect(() => {
    const inputFormJson: any = {};
    const formulaJson: any = {};

    if (isSuccess) {
      const dbJson: any = data && getInputValue(data);
      const loanDetails = tabs && { ...tabs[selectedTab] };

      const profitLossCells = generateFormProfitLoss(inputType, cells?.formprofitloss, dbJson, loanDetails);
      _.merge(inputFormJson, Object.assign({}, { formprofitloss: profitLossCells }));

      const loansCells = generateFormLoans(inputType, cells?.formloans, dbJson, loanDetails);
      _.merge(inputFormJson, Object.assign({}, { formloans: loansCells }));

      const depositCells = generateFormDeposits(inputType, cells?.formdeposits, dbJson, loanDetails);
      _.merge(inputFormJson, Object.assign({}, { formdeposits: depositCells }));

      const debtDetailsCells = generateFormDebtDetails(inputType, cells?.formdebtdetails, dbJson, loanDetails);
      _.merge(inputFormJson, Object.assign({}, { formdebtdetails: debtDetailsCells }));

      const capitalInjectionCells = generateFormCapitalInjection(
        inputType,
        cells?.formcapitalinjection,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formcapitalinjection: capitalInjectionCells }));

      const keyPlAssumptionsCells = generateFormKeyPlAssumptionsCells(
        inputType,
        cells?.formkeyplassumptions,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formkeyplassumptions: keyPlAssumptionsCells }));

      const otherAssumptionsCells = generateFormOtherAssumptions(
        inputType,
        cells?.formotherassumptions,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formotherassumptions: otherAssumptionsCells }));

      const balanceSheetCells = generateFormBalanceSheet(inputType, cells?.formbalancesheet, dbJson, loanDetails);
      _.merge(inputFormJson, Object.assign({}, { formbalancesheet: balanceSheetCells }));

      const keyBsAssumptionsCells = generatekeyBsAssumptions(
        inputType,
        cells?.formkeybsassumptions,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formkeybsassumptions: keyBsAssumptionsCells }));

      const capitalAdequeacyRatioCells = generatecapitalAdequeacyRatio(
        inputType,
        cells?.formcapitaladequeacyratio,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formcapitaladequeacyratio: capitalAdequeacyRatioCells }));

      const cashFlowStatementCells = generateFormCashFlowStatement(
        inputType,
        cells?.formcashflowstatement,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formcashflowstatement: cashFlowStatementCells }));

      const financialRatiosCells = generateFinancialRatios(
        inputType,
        cells?.formfinancialratios,
        dbJson,
        loanDetails
      );
      _.merge(inputFormJson, Object.assign({}, { formfinancialratios: financialRatiosCells }));

      if (_.isEmpty(cells)) {
        addExpression(inputFormJson);
      }

      if (_.isEmpty(Object.keys(formula))) {
        _.merge(formulaJson, addFormula(inputFormJson));
      } else {
        _.merge(formulaJson, formula);
      }

      dispatch(updateFinanceInputScreen(inputFormJson));
      dispatch(updateFinanceFormula(formulaJson));

      console.dir(inputFormJson, { depth: null, colors: true });
      console.dir(formulaJson, { depth: null, colors: true });
      dispatch(toggleLoader(false));
    }
  }, [isSuccess, inputType, selectedTab, loanRequestId]);

  useEffect(() => {
    return () => {
      dispatch(updateFinanceInputScreen({}));
      dispatch(updateFinanceFormula({}));
    };
  }, [selectedTab, loanRequestId]);

  const renderTable = (inputType: FormGroupType) => {
    return (
      <>
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMPROFITLOSS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMLOANS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMDEPOSITS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMDEBTDETAILS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMCAPITALINJECTION])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMKEYPLASSUMPTIONS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMOTHERASSUMPTIONS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMBALANCESHEET])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMKEYBSASSUMPTIONS])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMCAPITALADEQUEACYRATIO])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMCASHFLOWSTATEMENT])}
        {renderInputForms(inputType).get(FinanceFormType[FinanceFormType.FORMFINANCIALRATIOS])}
      </>
    );
  };

  return (
    <>
      <Card sx={{ height: 'calc(100vh - 240px)', width: 'calc(100vw - 200px)' }}>
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ width: 'calc(100vw - 220px)', height: 'calc(100vh - 400px)', overflowY: 'scroll' }}>
            {renderTable(inputType)}
          </Box>
        </CardContent>

        {!viewOnly &&
          (camState?.statusCode === getRequestStatusCode('DRAFT') ||
            camState?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
            camState?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
            !camState?.statusCode) && (
            <ActionBar>
              <Button onClick={onClickHandler} variant="contained" sx={{ p: 1, right: 12 }}>
                Save and Update
              </Button>
              <CamButton
                sx={{ p: 1, right: 8 }}
                onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
                camState={camState}
              />
            </ActionBar>
          )}
      </Card>
    </>
  );
};
export default FinanceTable;
