import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Grid,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
//user defied paths
import { getFormFields } from 'utils/form-utils';
import { IComplianceControl } from '..';
import TableDenseCell from 'components/common/table/TableDenseCell';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useLocation } from 'react-router-dom';

const headers = ['', ''];

const highestCr = [
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
];

const highestDr = [
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
  {
    data: '',
    data2: '',
    data3: '',
  },
];

const ConductOfAccounts = ({ control }: { control: Control<IComplianceControl> }) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Grid item lg={12}>
      <FormTitle title={'Summary Of Transactions With The Bank'} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {_.map(headers, (header: string, idx: number) => {
                return (
                  <TableCell
                    align="center"
                    width={'30%'}
                    key={idx}
                    sx={{
                      background: '#F8F8F8',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}>
                    <Typography>
                      <b>{header || <br />}</b>
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableDenseCell rowSpan={5}>
                Summary of 5 highest credits and any observations on them, as in our belief all of them were normal
                business transactions in Q3-FY2014
              </TableDenseCell>
              <TableDenseCell rowSpan={5} align="center">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Date</b>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Amount</b>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Source</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(highestCr, ({ data, data2, data3 }: any) => (
                      <TableRow>
                        <TableDenseCell>{data || <br />}</TableDenseCell>
                        <TableDenseCell>{data2 || <br />}</TableDenseCell>
                        <TableDenseCell>{data3 || <br />}</TableDenseCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableDenseCell>
            </TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>

            {/** highest debits */}
            <TableRow>
              <TableDenseCell rowSpan={5}>
                Summary of 5 highest debits and any observations on them, as in our belief all of them were normal
                business transactions in Q3-FY2014
              </TableDenseCell>
              <TableDenseCell>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Date</b>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Amount</b>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        width={'30%'}
                        sx={{
                          background: '#F8F8F8',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}>
                        <Typography>
                          <b>Source</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(highestCr, ({ data, data2, data3 }: any) => (
                      <TableRow>
                        <TableDenseCell>{data || <br />}</TableDenseCell>
                        <TableDenseCell>{data2 || <br />}</TableDenseCell>
                        <TableDenseCell>{data3 || <br />}</TableDenseCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableDenseCell>
            </TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12}>
            <Box className="full-width--input" sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Any large cash withdrawals" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.largeCashWithdrawals',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box className="full-width--input" sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Funds transfer to group companies" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.fundTransferGroup',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Any unusual transaction are observed" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.unusualTransaction',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Insurance done on time and adequacy of insurance" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.adequecyOfInsurance',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Any key management change in the Company" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.keyManagementChange',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box sx={{ mt: 1, display: 'flex' }}>
              <SimpleFormLabel sx={{ width: '25%' }} label="Any other adverse findings in the account" />
              {getFormFields(
                {
                  name: 'summaryOfTransaction.adverseFinding',
                  component: 'textField',
                  style: { width: '73%' },
                },
                control,
                !state?.isEdit
              )}
            </Box>
          </Grid>
        </Grid>
      </TableContainer>
    </Grid>
  );
};

export default ConductOfAccounts;
