import { Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getRarocRating } from 'services/common-services';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { toggleLoader } from 'state/reducers/common-actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import useRefData from 'hooks/ref-data';

export const Raroc = ({ control, setValue }: any) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const { control: generateRoracControl, handleSubmit: handleSubmit2 } = useForm({
    defaultValues: {
      limit: '',
      limitType: '',
      tenor: '',
      utilizationRate: '',
      interestRate: '',
      frontEndFee: '',
      cof: '',
      opex: '',
      expLoss: '',
      ecoCapital: '',
      riskFreeRate: '',
    },
  });
  const mutation = useMutation((data: any) => getRarocRating(data));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { refData } = useRefData();

  const handleClose = () => {
    setOpen(false);
  };

  const generateRarocHandler = (data: any) => {
    dispatch(toggleLoader(true));
    mutation.mutate(data, {
      onSuccess: (res: AxiosResponse<any, any>) => {
        if (res) {
          enqueueSnackbar('RAROC Generated', {
            variant: 'success',
          });
          setValue('rarocRating', parseFloat(res.toString()).toFixed(2));
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
        handleClose();
      },
    });
  };

  return (
    <>
      <Grid item xs={12} sx={{ mt: 0.5 }}>
        <Box sx={{ display: 'flex' }}>
          <SimpleFormLabel label={'RAROC for current proposal'} sx={{ width: '30%' }} />
          {getFormFields({ name: 'rarocRating', component: 'textField' }, control, true)}
          {state?.isEdit && (
            <Button variant="text" sx={{ ml: 1, p: 1 }} onClick={() => setOpen(true)}>
              Generate
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex' }}>
          <SimpleFormLabel
            label={'Justification if the ROE for current proposal is below 12%'}
            sx={{ width: '30%', placeSelf: 'flex-start' }}
          />
          <Box sx={{ width: '70%' }}>
            {getFormFields(
              {
                name: 'rarocJustification',
                component: MULTI_LINE_INPUT,
                label: 'Comment',
              },
              control,
              !state?.isEdit
            )}
          </Box>
        </Box>
      </Grid>

      <Dialog onClose={handleClose} open={open} fullWidth>
        <form>
          <DialogTitle>Generate RAROC for current proposal</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Limit'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'limit', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Type of Limit'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'limitType', component: 'dropdown', options: refData?.loanType || [] },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Tenor'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'tenor', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Utilisation Rate'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'utilizationRate', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Interest Rate'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'interestRate', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Front-end Fee %'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'frontEndFee', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'CoF'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'cof', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Opex'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'opex', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Expected Loss'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'expLoss', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Economic Capital'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'ecoCapital', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label={'Risk Free Rate'} sx={{ width: '30%' }} />
                {getFormFields(
                  { name: 'riskFreeRate', component: 'textField', type: 'number' },
                  generateRoracControl,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit2(generateRarocHandler)} sx={{ p: 1 }}>
              Generate
            </Button>
            <Button variant="contained" onClick={handleClose} sx={{ marginRight: 2, p: 1 }}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
