import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || '';
const getToken = () => {
  const userValue = localStorage.getItem("user");
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue) 
  }
  return user?.jwtToken;
}

export default axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);

    return Promise.reject(error);
  }
);
