import { Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const DialogWithUser = ({ open, setOpen, title, description, type, onClickHandler, users }: any) => {
  const [user, setUser] = useState<any | null>(null);

  const handleClose = () => {
    setOpen(false);
    setUser(null);
  };

  const getUser = () => {
    if (users) {
      return users.map((u: any) => {
        return { label: `${u?.lastName},${u?.firstName}`, value: u?.id };
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs">
      <Alert severity={type}>
        <Typography variant="subtitle1">{title}</Typography>
      </Alert>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2">{description}</Typography>
          <br />

          <Autocomplete
            disablePortal={false}
            id="user-box"
            options={getUser()}
            sx={{ width: 300 }}
            value={user}
            renderInput={(params) => <TextField {...params} label="User" size="small" />}
            onChange={(e: any, v: any) => setUser(v)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={() => onClickHandler(user?.value)} disabled={!user?.value} autoFocus>
          <Typography>Assign</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogWithUser;
