import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

const searchProducts = async (searchText: string) => {
  const res = await httpService.get(`/masterData/product/${searchText}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

const getAllProducts = async () => {
  const res = await httpService.get(`/masterData/getAllProducts`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

const createProduct = async (data: any) => {
  const res = await httpService.post('/masterData/product', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res;
};

const getAllRawMaterials = async () => {
  try {
    const res = await httpService.get('/masterData/getAllRawMaterials', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log('getAllRawMaterials" Error', error);
  }
};

const createRawMaterial = async (data: any) => {
  const res = await httpService.post('/masterData/rawMaterial', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res;
};

const getAllNonConsumer = async () => {
  try {
    const res = await httpService.get('/masterData/getAllNonConsumerItems', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log('getAllNonConsumer" Error', error);
  }
};

const createNonConsumerItem = async (data: any) => {
  const res = await httpService.post('/masterData/nonConsumerItem', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res;
};

const getAllConsumer = async () => {
  try {
    const res = await httpService.get('/masterData/getAllConsumerItems', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log('getAllNonConsumer" Error', error);
  }
};

const createConsumerItem = async (data: any) => {
  const res = await httpService.post('/masterData/consumerItem', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res;
};

export {
  searchProducts,
  getAllProducts,
  createProduct,
  createRawMaterial,
  getAllRawMaterials,
  getAllNonConsumer,
  getAllConsumer,
  createNonConsumerItem,
  createConsumerItem,
};
