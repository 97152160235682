import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { Box, Grid, styled, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ActionCell } from 'components/common/data-table/table-action-cell';
import DataTable from 'components/common/data-table';
import ContentLayout from 'components/common/containers/content-layout';
import { getMemos, getOpenMemos, getClosedMemos,  getLoanProposalOpenMemos, getLoanProposalClosedMemos } from 'services/memo-service';
import { claimReviewTask } from 'services/loan-projection-services';
import BackdropLoader from 'components/common/backdrop-loader';
import { useTranslation } from 'react-i18next';
import { MEMO_TABLE, LOAN_MEMO_TABLE } from '../credit-analysis/loan-proposal/LoanProposalConsts';
import { useSnackbar } from 'notistack';
import useLoanTable from 'hooks/useLoanTable';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { routes, strings } from 'constants/strings';
import { viewDocument } from 'services/document-services';
import ActionBar from 'components/styled-components/ActionBar';

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 16,
  marginRight: 16,
}));

const MemosList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { username }: any = useSelector((state: RootState) => state?.user?.userDetails);
  const { getLoanRequestState, _requestData, getAccessMode, getRequestStatusCode, isRisk } = useLoanTable();
  const [tab, setTab] = useState('MEMO_REQUEST');
  const [memoTab, setMemoTab] = useState('PENDING_ACTIONS');
  const [loanMemoTab, setLoanMemoTab] = useState('PENDING_PROPOSAL_REQUEST');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleMemoTab = (event: React.SyntheticEvent, newValue: string) => {
    setMemoTab(newValue);
  };

  const handleLoanMemoTab = (event: React.SyntheticEvent, newValue: string) => {
    setLoanMemoTab(newValue);
  };

  const claimReviewTaskMutation = useMutation((loanTask: any) => claimReviewTask(loanTask), {
    onSuccess: (data, variables) => {
      navigate(routes.MEMOS_REQUEST_ROUTE, {
        state: {
          ...getLoanRequestState(variables, false, false),
          from: pathname,
        },
      });
      enqueueSnackbar(`Loan Request ID: ${variables.loanRequestId} has been claimed by you`, {
        variant: 'success',
      });
    },
    onError: (err, variables) => {
      enqueueSnackbar(`Loan Request ID: ${variables.loanRequestId} claim has been failed`, {
        variant: 'error',
      });
    },
  });

  const { data: loanRequests } = useQuery(['getMemos', username], getMemos);
  const { data: openMemoData } = useQuery(['getOpenMemos', username], getOpenMemos);
  const { data: closeMemoData } = useQuery(['getClosedMemos', username], getClosedMemos);
  const { data: loanProposalOpenMemoData } = useQuery(['getLoanProposalOpenMemos', username], getLoanProposalOpenMemos);
  const { data: loanProposalClosedMemoData } = useQuery(['getLoanProposalClosedMemos', username], getLoanProposalClosedMemos);

  const handleClaimReviewTask = (loanTask: any) => {
    if (loanTask?.loanRequestId && loanTask?.flowableTaskId) {
      claimReviewTaskMutation.mutate(loanTask);
    } else {
      enqueueSnackbar(`Failed to claim task`, {
        variant: 'error',
      });
    }
  };

  const getPath = (loanData: any) => {
    let path = routes.LOAN_PROPOSAL_ROUTE;
    if (
      loanData?.statusCode === getRequestStatusCode('PENDING_WITH_RM') ||
      loanData?.statusCode?.toUpperCase() === getRequestStatusCode('PENDING_WITH_REVIEWER')
    ) {
      path = routes.MEMOS_REQUEST_ROUTE;
    } else if (isClaimedEnabled(loanData) || isClaimEnabled(loanData)) {
      path = routes.REVIEW_COMMENT_ROUTE;
    }
    return path;
  };

  const gotoLoanRequest = (loanData: any, viewOnly = true, isEdit = false) => {
    navigate(getPath(loanData), {
      state: {
        ...getLoanRequestState(loanData, viewOnly, isEdit),
        from: pathname,
      },
    });
  };

  const gotoMemoRequest = (loanData: any, viewOnly: any | null = true) => {
    navigate(routes.CREATE_MEMOS_ROUTE, {
      state: {
        ...getLoanRequestState(loanData, viewOnly),
        from: pathname,
      },
    });
  };

  const isViewEnabled = (loanData: any) => {
    return loanData?.accessMode === getAccessMode('READ_ONLY');
  };

  const isClaimEnabled = (loanData: any) => {
    return loanData?.accessMode === getAccessMode('CLAIM');
  };

  const isClaimedEnabled = (loanData: any) => {
    return loanData?.accessMode === getAccessMode('CLAIMED');
  };

  const loanColumnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      ...LOAN_MEMO_TABLE,
      {
        field: 'action',
        pinned: 'right',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        resizable: false,
        sortable: false,
        filter: false,
        cellRendererFramework: (params: any) => {
          return ActionCell({
            view: {
              viewBtnTitle: 'View Memo',
              onView: () => gotoLoanRequest(params?.data, true, false),
              enabled: isViewEnabled(params?.data),
            },
            claim: {
              claimBtnTitle: params?.data?.accessMode,
              onClaim: () => handleClaimReviewTask(params?.data),
              enabled: isClaimEnabled(params?.data),
            },
            claimed: {
              claimedBtnTitle: params?.data?.accessMode,
              onClaimed: () =>
                gotoLoanRequest(params?.data, !isClaimedEnabled(params?.data), isClaimedEnabled(params?.data)),
              enabled: isClaimedEnabled(params?.data),
            },
          });
        },
      },
    ],
    []
  );

  const downloadDocuments = async (docId: any) => {
    if (docId) {
      const docFile = await viewDocument(docId);
      window.open(URL.createObjectURL(docFile));
      return false;
    } else {
      enqueueSnackbar(`Unable to download Memo`, {
        variant: 'error',
      });
    }
  };

  const memoColumnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      ...MEMO_TABLE,
      {
        field: 'action',
        pinned: 'right',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        resizable: false,
        sortable: false,
        filter: false,
        cellRendererFramework: (params: any) => {
          return ActionCell({
            view: {
              viewBtnTitle: 'View Memo',
              onView: () => gotoMemoRequest(params?.data),
              enabled:
                params?.data?.accessMode !== getAccessMode('EDIT') &&
                params?.data?.accessMode !== getAccessMode('APPROVE') &&
                params?.data?.accessMode !== getAccessMode('REWORK') &&
                params?.data?.accessMode !== getAccessMode('REJECT'),
            },
            edit: {
              editBtnTitle: params?.data?.accessMode,
              onEdit: () => gotoMemoRequest(params?.data, !(params?.data?.accessMode === getAccessMode('EDIT'))),
              enabled: params?.data?.accessMode === getAccessMode('EDIT'),
            },
            approve: {
              approveBtnTitle: params?.data?.accessMode,
              onApprove: () =>
                gotoMemoRequest(params?.data, !(params?.data?.accessMode === getAccessMode('APPROVE'))),
              enabled: params?.data?.accessMode === getAccessMode('APPROVE'),
            },
            memo: {
              memoBtnTitle: 'Memo',
              onMemo: () => downloadDocuments(params?.data?.memoDocId),
              enabled: !!params?.data?.memoDocId,
            },
          });
        },
      },
    ],
    [memoTab]
  );

  if (loanRequests?.isLoading) {
    return <BackdropLoader isLoading={loanRequests?.isLoading} />;
  }

  const onNewRequest = () => navigate(routes.NEW_MEMOS_REQUEST_ROUTE);

  return (
    <ContentLayout title="Memos">
      <Grid container spacing={1}>
        <Grid item md={12}>
          <FilterContainer>
            {tab === 'MEMO_REQUEST' && (
              <Button
                variant="contained"
                onClick={onNewRequest}
                sx={{ position: 'absolute', top: '65px', right: '20px', zIndex: 999 }}>
                {strings.CREATE_MEMO_BUTTON}
              </Button>
            )}
          </FilterContainer>
        </Grid>
        <Grid item md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTab} aria-label="memo request">
                <Tab label="Memo Request" value="MEMO_REQUEST" />
                {!isRisk() && <Tab label="Proposal Review Request" value="PROPOSAL_REQUEST" /> }
              </TabList>
            </Box>
            <TabPanel value="MEMO_REQUEST" sx={{ padding: '0px' }}>
              {
                <TabContext value={memoTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleMemoTab} aria-label="memo request">
                      <Tab label="Pending Actions" value="PENDING_ACTIONS" />
                      <Tab label="Completed" value="COMPLETED" />
                    </TabList>
                  </Box>
                  <TabPanel value="PENDING_ACTIONS" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={memoColumnDefs}
                        autoSize={false}
                        rowData={_requestData(openMemoData)}
                      />
                    }
                  </TabPanel>
                  <TabPanel value="COMPLETED" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={memoColumnDefs}
                        autoSize={false}
                        rowData={_requestData(closeMemoData)}
                      />
                    }
                  </TabPanel>
                </TabContext>
              }
            </TabPanel>
            {!isRisk() && <TabPanel value="PROPOSAL_REQUEST" sx={{ padding: '0px' }}>
              <TabContext value={loanMemoTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleLoanMemoTab} aria-label="memo loan request">
                      <Tab label="Pending Actions" value="PENDING_PROPOSAL_REQUEST" />
                      <Tab label="Completed" value="COMPLETED_PROPOSAL_REQUEST" />
                    </TabList>
                  </Box>
                  <TabPanel value="PENDING_PROPOSAL_REQUEST" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={loanColumnDefs}
                        autoSize={false}
                        rowData={_requestData(loanProposalOpenMemoData)}
                      />
                    }
                  </TabPanel>  
                  <TabPanel value="COMPLETED_PROPOSAL_REQUEST" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={loanColumnDefs}
                        autoSize={false}
                        rowData={_requestData(loanProposalClosedMemoData)}
                      />
                    }
                  </TabPanel>
                </TabContext>
            </TabPanel>}
          </TabContext>
        </Grid>
        <Grid item md={12}>
          <ActionBar></ActionBar>
        </Grid>
      </Grid>
    </ContentLayout>
  );
};

export default MemosList;
