import { Box, Typography, Divider } from '@mui/material';
import _ from 'lodash';
import DataTable from 'components/common/data-table';
import { useEffect, useMemo, useState } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const RawMaterialsBreakup = ({ control, t, data }: any) => {
  const [rowData, setRowData] = useState<any[]>();
  const [cols, setCols] = useState<any[]>([]);

  useEffect(() => {
    const _yearsToDisplay: any = data?.rawMaterialBreakUp?.years || [];

    const yearColumns = _.map(_yearsToDisplay, (year: any) => {
      return {
        headerName: `${year}`,
        children: [
          {
            field: `cost_${year}`,
            headerName: 'Cost',
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: `percentageOfRM_${year}`,
            headerName: '% of RM',
          },
          {
            field: `unitPrice_${year}`,
            headerName: 'Unit Price/MT',
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      };
    });

    const defaultCols: any = [
      {
        field: 'itemName',
        headerName: 'Items',
        editable: false,
        pinned: 'left',
      },
      ...yearColumns,
    ];

    setCols(defaultCols);
    const rawMaterialData = data?.rawMaterialBreakUp?.array || [];
    setRowData(rawMaterialData);
  }, [data]);

  if (data) {
    return (
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 0.5 }}>
        <Box>
          <Typography variant="subtitle2">
            {t('loanProjection.businessBackgroud.productionPerformance.rawMaterials')}
          </Typography>
          <Divider />
        </Box>
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={cols}
            autoSize={true}
            rowData={rowData || []}
            pagination={false}
            domLayout={'autoHeight'}
            style={{ height: '100%' }}
            sizeToFit={true}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ pl: 0.5, pr: 0.5, mt: 0.5 }}>
      <Box>
        <Typography variant="subtitle2">
          {t('loanProjection.businessBackgroud.productionPerformance.rawMaterials')}
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ mt: 1 }}>
        No Data Found
      </Box>
    </Box>
  );
    
};

export default RawMaterialsBreakup;
