import { styled } from '@mui/material';
import { TableCell } from '@mui/material';
import { CSSProperties } from 'react';
import { Typography } from '@mui/material';

const StyledCell = styled(TableCell)(() => ({
  paddingLeft: 30,
  paddingRight: 4,
}));

const SubCell = ({ children, style }: { children: any; style?: CSSProperties }) => {
  return (
    <StyledCell padding="none" style={style}>
      <Typography>{children}</Typography>
    </StyledCell>
  );
};

export default SubCell;
