import { Card, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { RootState } from '../../../../state/store';
import TabPanel from '../../../common/tab-panel';
import EmptyProjectionList from '../empty-list';
import ProjectionsList from './projections-list';
import CreateProjectionModal from './create-new-projection-modal';
import ProjectionForm from './projection-forms';
import { getProjectionByLoanId } from 'services/loan-projection-services';
import { updateTabs, updateSelectedTab } from './financial-projection-reducer';
import useRefData from 'hooks/ref-data';
import { toggleLoader } from 'state/reducers/common-actions';

// used for the 1st tab in the loan proposal menu.
const Projection = () => {
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const [newProjection, setNewProjection] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { refData } = useRefData();

  const renderTabContent = () => {
    return _.map(tabs, (tab: any, idx: number) => {
      return (
        <TabPanel value={selectedTab} index={idx} key={idx}>
          <ProjectionForm />
        </TabPanel>
      );
    });
  };

  const onCreateNewProjection = () => setNewProjection(true);

  const mapYear = (yearId: number) => {
    return _.filter(refData.fiscalYear, (el) => el.id === yearId)[0];
  };
  const { isLoading, data } = useQuery(
    ['getProjectionsByLoanId', location.state?.loanRequestId, tabs],
    () => getProjectionByLoanId(location.state?.loanRequestId),
    {
      onSuccess: (data: any) => {
        if (data && refData.fiscalYear) {
          const parsedData = _.map(data, (d) => JSON.parse(d));

          const mappedData = _.map(parsedData, (data, i: number) => {
            const dataCopy = { ...data };
            return dataCopy;
          });
          
          dispatch(
            updateTabs({
              tabs: mappedData,
            })
          );
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    }
  );

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }

  return (
    <>
      <CreateProjectionModal
        open={newProjection}
        close={(currentTab:any) => {
          setNewProjection(false);
          dispatch(updateSelectedTab(currentTab<0?0:currentTab))
        }}
        state={location.state}
      />

      <Card sx={{ height: 'calc(100vh - 100px)', width: '100vw' }}>
        {(!location?.state?.viewOnly || (location?.state?.viewOnly && tabs?.length !== 0)) && (
          <Grid container>
            <Grid item lg={12}>
              {/** LIST OF PROJECTIONS */}
              <ProjectionsList onCreateNewProjection={onCreateNewProjection} listItems={data} />
            </Grid>
            <Grid item lg={12}>
              {/** if no  projection show message and button to create a new projection */}
              {!tabs.length && (
                <EmptyProjectionList
                  createNewProjection={() => setNewProjection(true)}
                  viewOnly={location?.state?.viewOnly}
                />
              )}
              {/** new Projection forms */}
              {renderTabContent()}
            </Grid>
          </Grid>
        )}
        {location?.state?.viewOnly && tabs?.length == 0 && (
          <EmptyProjectionList viewOnly={location?.state?.viewOnly} />
        )}
      </Card>
    </>
  );
};

export default Projection;
