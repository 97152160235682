import { useLocation } from 'react-router-dom';
import KeyRiskTrigger from '../internal-compliance/KeyRiskTrigger';

const KeyRiskCompliance = (props: any) => {
  const { state } = useLocation();

  return <KeyRiskTrigger {...props} disabled={!state?.isEdit} />;
};

export default KeyRiskCompliance;
