import { Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import TableHeading from 'components/common/table-header';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { Row } from '../../../../../common/table/table-data-row/rows';
import useLocalStore from 'hooks/user-local-store';
import { roles, routes } from 'constants/strings';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

function FormV() {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formV'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );
  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form5, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form5[idx].actuals) {
      return data?.form5[idx].actuals;
    }
    return data?.form5[idx]?.projection;
  });

  return (
    <MainCard>
      <Table size="small">
        <TableHeading title="Maximum Permissible Bank Finance for Working Capital" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Assessment of working capital requirement</b>
              </Typography>
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>First Method of Lending</HeadingDenseCell>
          </TableRow>
          <Row
            title="Total Current Assets (Form-IV-A)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.totalCurrentAsset)}
              </NumberDenseCell>
            ))}
          />

          <Row
            title="Other Current Liabilities (other than bank borrowings (Form-IV-14)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.otherCurrentLiabilities)}
              </NumberDenseCell>
            ))}
          />

          <Row
            title="Working Capital Gap (WCG) (1-2)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.workingCapitalGap)}
              </NumberDenseCell>
            ))}
          />

          <Row
            title="Min. stipulated net working capital (25% of WCG excluding export receivables)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.minStipulatedNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Actual / Projected net working capital (Form-III-49)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.actualNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Item-3 minus Item-4"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.item3MinusItem4)}
              </NumberDenseCell>
            ))}
          />

          <Row
            title="Item-3 minus Item-5"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.item3MinusItem5)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Max. permissible bank finance (item-6 or 7, whichever is lower)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.maxPermissibleBankFinance)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Excess borrowings representing shortfall in NWC (4 - 5)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].firstMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>Second Method of Lending</HeadingDenseCell>
          </TableRow>
          <Row
            title="Total Current Assets (Form-IV-9)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.totalCurrentAsset)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Other Current Liabilities (other than bank borrowings (Form-IV-14)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.otherCurrentLiabilities)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Working Capital Gap (WCG) (1-2)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.workingCapitalGap)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Min. stipulated net working capital (25% of total Current Assets excluding export receivables)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.minStipulatedNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Actual / Projected net working capital (Form-III-49)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.actualNetWorkingCapital)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Item-3 minus Item-4"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.item3MinusItem4)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Item-3 minus Item-5"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.item3MinusItem5)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Max. permissible bank finance (item-6 or 7, whichever is lower)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.maxPermissibleBankFinance)}
              </NumberDenseCell>
            ))}
          />
          <Row
            title="Excess borrowings representing shortfall in NWC (4 - 5)"
            data={_.map(numberOfYears, (_, idx: number) => (
              <NumberDenseCell key={idx}>
                {formatNumber(dataArr[idx].secondMethodOfLending.excessBorrowing)}
              </NumberDenseCell>
            ))}
          />
        </TableBody>
      </Table>
      <ReviewCamButton
        onClick={() => {
          navigate(routes.CAM_ROUTE, {
            state: { ...state, loanRequestId: state.loanRequestId },
          });
          const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
          camId && camId?.id && dispatch(changeMenuTab(camId?.id));
        }}
      />
    </MainCard>
  );
}

export default FormV;
