export const nextChar = (c: string, length = 1) => {
    var u = c.toUpperCase();
    if (same(u, 'Z')) {
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return (txt + 'A');
    } else {
        var p = "";
        var q = "";
        if (u.length > 1) {
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l, length);
        if (z === 'A' || z === '0') {
            return p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0), length) + z;
        } else {
            return p + z;
        }
    }
}

const nextLetter = (l: number, length: number) => {
    if (l < 90 && l !== 57) {
        return String.fromCharCode(l + length);
    } else if (l === 57) {
        return "0";
    }
    else {
        return 'A';
    }
}

const same = (str: string, char: string) => {
    var i = str.length;
    while (i--) {
        if (str[i] !== char) {
            return false;
        }
    }
    return true;
}



export const prevChar = (c: string, length = 1) => {
    var u = c.toUpperCase();
    if (same(u, 'A')) {
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'Z';
        }
        return (txt + 'Z');
    } else {
        var p = "";
        var q = "";
        if (u.length > 1) {
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = prevLetter(l, length);
        if (z === 'Z' || z === '0') {
            return p.slice(0, -1) + prevLetter(q.slice(-1).charCodeAt(0), length) + z;
        } else {
            return p + z;
        }
    }
}

const prevLetter = (l: number, length: number) => {
    if (l < 90 && l !== 57) {
        return String.fromCharCode(l - length);
    } else if (l === 57) {
        return "0";
    }
    else {
        return 'Z';
    }
}

