import { Table, TableBody, TableHead, TableRow, Typography, TableCell, Box, Divider } from '@mui/material';
import _ from 'lodash';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  keyNumberData: any;
}

export default function KeyNumbers({ t, totalYears, control, keyNumberData }: IProps) {
  return (
    <Box>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.keyNumber')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Particulars</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell>Gross Sales</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].grossSales)}</NumberDenseCell>;
            })}
          </TableRow>

          <TableRow>
            <DenseCellSm>Net Sales</DenseCellSm>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].netSales)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other Operating Income</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i} heading>
                  {formatNumber(keyNumberData[i].netOperatingIncome)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Total Operating Income</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].totalOperatingIncome)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Cost of Goods Sold</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].costOfGoodsSold)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Selling General & Admin Expenses</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i} heading>
                  {formatNumber(keyNumberData[i].sellingGenAdminExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>

          <TableRow>
            <HeadingDenseCell>EBDITA</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].ebitda)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>EBDITA %</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].ebitdaPercentage)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Depreciation</DenseCellSm>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell heading>{formatNumber(keyNumberData[i].depreciation)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>EBIT</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].ebit)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>EBIT%</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].ebitPercentage)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Interest</DenseCellSm>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell heading>{formatNumber(keyNumberData[i].interest)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net Non-Operating Income/Expenses</DenseCellSm>

            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i} heading>
                  {formatNumber(keyNumberData[i].netNonOperatingIncExp)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Profit before Taxation (PBT)</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].profitBeforeTax)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net Profit</DenseCellSm>

            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell heading>{formatNumber(keyNumberData[i].netProfit)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Net Profit (%)</HeadingDenseCell>

            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>{formatNumber(keyNumberData[i].netProfitPercentage)}</NumberDenseCell>
              );
            })}
          </TableRow>
          {/* <TableCommentRow title="Comments" fieldName="keyNumbers.comments" control={control} /> */}
        </TableBody>
      </Table>
    </Box>
  );
}
