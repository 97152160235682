export const borrowerSummaryRow: any[] = [
  {
    label: 'Loan Request Id',
    name: 'loanRequestId',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Proposal ROE',
    name: 'financialAssessment.borrowerSummary.proposalROE',
    component: 'textField',
  },
  {
    label: 'Borrower/Borrower Group',
    name: 'financialAssessment.borrowerSummary.borrower',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'External Rating',
    name: 'financialAssessment.borrowerSummary.externalRating',
    component: 'textField',
  },
  {
    label: 'Industry/Sub-Industry',
    name: 'financialAssessment.borrowerSummary.industrySubIndustry',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Rated By',
    name: 'financialAssessment.borrowerSummary.ratedBy',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Industry Outlook',
    name: 'financialAssessment.borrowerSummary.industryOutlook',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Country of risk',
    name: 'financialAssessment.borrowerSummary.countryOfRisk',
    component: 'textField',
  },
  {
    label: 'Previous Rating',
    name: 'financialAssessment.borrowerSummary.previousRating',
    component: 'textField',
  },
  {
    label: 'Relationship Manager (Emp id)',
    name: 'financialAssessment.borrowerSummary.empId',
    component: 'textField',
  },
  {
    label: 'Current Rating',
    name: 'finalRatingDetails.finalRiskRating',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Final Score',
    name: 'finalRatingDetails.finalScore',
    component: 'textField',
    disabled: true,
  },
  {
    label: 'Team Lead (Business)',
    name: 'financialAssessment.borrowerSummary.teamLead',
    component: 'textField',
  },
  {
    label: 'Risk Weight',
    name: 'financialAssessment.borrowerSummary.riskWeight',
    component: 'textField',
  },
  {
    label: 'Rating Date',
    type: 'date',
    name: 'financialAssessment.borrowerSummary.ratingDate',
    component: 'datepicker',
  },
  {
    label: 'Head of Risk',
    name: 'financialAssessment.borrowerSummary.headOfRisk',
    component: 'textField',
  },
];
