export const totalIncomeOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: "Total Income"
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Total Income',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
}

export const ebidtaOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'EBIDTA'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'EBIDTA',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}


export const ebidtaMarginOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'EBIDTA Margin (%)'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value',
	      axisLabel: {
	        formatter: '{value} %'
	      }
	    }
	  ],
	  series: [
	    {
	      name: 'EBIDTA Margin',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
}

export const netProfitLossOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Net Profit/Loss'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Net Profit/Loss',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
}

export const profitMarginOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Profit Margin (%)'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value',
	      axisLabel: {
	        formatter : '{value} %'
	      }
	    }
	  ],
	  series: [
	    {
	      name: 'Profit Margin (%)',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}

export const earningPerShareOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Earning Per Share (EPS)'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Earning Per Share (EPS)',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}


export const totalAssetsOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Total Assets'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Total Assets',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}


export const netFixedAssetsOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Net Fixed Assets'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Net Fixed Assets',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
}


export const netWorthOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Net Worth'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Net Worth',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}


export const debtToEquityOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'Debt to Equity'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: [
	    {
	      name: 'Debt to Equity',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}

export const roeOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'ROE (%)'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value',
	      axisLabel: {
	        formatter : '{value} %'
	      }
	    }
	  ],
	  series: [
	    {
	      name: 'ROE (%)',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}


export const roceOption = ({xAxis, yAxis} :any) => { 
	return {
	  title: {
	    text: 'ROCE (%)'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'shadow'
	    }
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '3%',
	    containLabel: true
	  },
	  graphic: {
	  	 type: 'text',
		 left: 'center',
		 top: 'middle',
		 style: yAxis?.every((v:any) => v === 0) ? { text: "No data available" } : null
	  },
	  xAxis: [
	    {
	      type: 'category',
	      data: xAxis,
	      axisTick: {
	        alignWithLabel: true
	      }
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value',
	      axisLabel: {
	        formatter : '{value} %'
	      }
	    }
	  ],
	  series: [
	    {
	      name: 'ROCE (%)',
	      type: 'bar',
	      barWidth: '60%',
	      data: yAxis
	    }
	  ]
	 };
	}

export const sourceOfFundsOption = ({data}:any) => { 
	return {
	  title: {
	    text: 'Source of Funds (2021)',
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    bottom: '5%',
	    left: 'center'
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },  
	  label: {
	    show: true,
	    formatter: function(param:any) {
	      return param.percent + '%';
	    }
	  },
	  series: [
	    {
	      name: 'Funds',
	      type: 'pie',
	      radius: '25%',
	      top: '-20%',
	      data: data,
	      emphasis: {
	        itemStyle: {
	          shadowBlur: 10,
	          shadowOffsetX: 0,
	          shadowColor: 'rgba(0, 0, 0, 0.5)'
	        }
	      }
	    }
	  ]
	 };
	}

export const usesOfFundsOption = ({data}:any) => { 
	return {
	  title: {
	    text: 'Use of Funds (2021)',
	  },
	  tooltip: {
	    trigger: 'item'
	  },
	  legend: {
	    bottom: '10',
	    left: 'center'
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },
	  label: {
	    show: true,
	    formatter: function(param:any) {
	      return param.percent + '%';
	    }
	  },
	  series: [
	    {
	      name: 'Funds',
	      type: 'pie',
	      radius: '25%',
	      top: '-20%',
	      data: data,
	      emphasis: {
	        itemStyle: {
	          shadowBlur: 10,
	          shadowOffsetX: 0,
	          shadowColor: 'rgba(0, 0, 0, 0.5)'
	        }
	      }
	    }
	  ]
	 };
}

export const criticalAnalysisOption = ({data, xAxis}:any) => { 
	return {
	  title: {
	    text: 'Other Information'
	  },
	  tooltip: {
	    trigger: 'axis',
	    axisPointer: {
	      type: 'cross',
	      label: {
	        backgroundColor: '#6a7985'
	      }
	    }
	  },
	  legend: {
	    data: data?.map((n:any) => n?.name),
	    bottom: '5%',
	    left: 'center'
	  },
	  toolbox: {
	    feature: {
	      saveAsImage: {}
	    }
	  },
	  grid: {
	    left: '3%',
	    right: '4%',
	    bottom: '20%',
	    containLabel: true
	  },
	  xAxis: [
	    {
	      type: 'category',
	      boundaryGap: false,
	      data: xAxis
	    }
	  ],
	  yAxis: [
	    {
	      type: 'value'
	    }
	  ],
	  series: data?.map((ser:any) => Object.assign({
			      type: 'line',
			      stack: 'Total',
			      areaStyle: {},
			      emphasis: {
			        focus: 'series'
			      }
			  },{name:ser?.name}, {data:ser?.data}))
	 };
}