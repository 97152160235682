import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISanction {
  trigger: boolean;
}

const initialState: ISanction = {
  trigger: false,
};

const sanctionSlice = createSlice({
  name: 'sanction',
  initialState,
  reducers: {
    retriggerSanction: (state: ISanction, action: PayloadAction<boolean>) => {
      state.trigger = action.payload;
    },
  },
});

const { actions, reducer } = sanctionSlice;

export const { retriggerSanction } = actions;
export default reducer;
