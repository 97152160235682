import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import TableCommentRow from 'components/common/table/table-comment-row';
import _ from 'lodash';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function PCFCLimitAssessment({ t, totalYears, data, control }: IProps) {
  const { state } = useLocation();
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>{t('loanProjection.performanceSummary.pcfcLimitAssesment')}</b>
          </Typography>
          <Divider />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#F8F8F8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Year ended / ending March 31</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  key={year}
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <DenseCellSm>Exports</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].exports)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Net Sales</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].netSales)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Months cost of Sales</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].monthlyCostOfSales)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Lead time @ 45 days</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].leadTime45Days)}</NumberDenseCell>;
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <SimpleFormLabel label={'Limit Proposed'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
        <Box sx={{ width: '75%' }}>
          {getFormFields(
            {
              name: 'assessmentLimits.pcfcLimit',
              component: 'textField',
              type: 'number',
            },
            control,
            state.viewOnly
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
        <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
        <Box sx={{ width: '75%' }}>
          {getFormFields(
            {
              name: 'assessmentLimits.commentsFundBased',
              component: MULTI_LINE_INPUT,
              label: 'Comment',
            },
            control,
            state.viewOnly
          )}
        </Box>
      </Box>
    </>
  );
}
