import Layout from '../../layout/layout';
import { Outlet } from 'react-router';

/**Loan officer */
function CreditAnalysis() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default CreditAnalysis;
