import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import InputForm from '../InputForm';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../../../../state/store';
import formProfitTemplate from './formProfitTemplate.json';
import { updateInputScreen } from '../ManufacturingTable/inputScreenSlice';
import useRefData from 'hooks/ref-data';
import {
  getFormDataFromState,
  getForm,
  getItemsAndAppend,
  updateJson,
  getRowTemplate,
  getInputValue
} from '../../common/FormUtil';
import { FormGroup, FormType, FormGroupType, FormGroupI } from '../../../../../../../types/inputform.d';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';
import { importFormExcel } from 'services/loan-projection-services';
import { toggleLoader } from 'state/reducers/common-actions';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import useLoanTable from 'hooks/useLoanTable';
import {  generateInputFormJson  } from '../ManufacturingTable/ManufacturingCommon'


export const generateFormProfitLoss = (
  group: FormGroupType,
  profitLossCells: any,
  dbJson: any,
  loanDetails: any
) => {
  const formCells = _.cloneDeep(profitLossCells);

  let allRows: any[] = [];
  let formDataCells: any;

  if (!_.isEmpty(formCells)) {
    formDataCells = getFormDataFromState(formCells, group);
  } else if (_.isEmpty(formCells)) {
    const { form: formProfit, productDomesticSales, productExportSales } = _.cloneDeep(formProfitTemplate) as any;
    const { products } = loanDetails;

    updateJson(
      'type',
      'productsWithDomestic',
      getItemsAndAppend(products, productDomesticSales, 'product'),
      formProfit
    );
    updateJson(
      'type',
      'productsWithExport',
      getItemsAndAppend(products, productExportSales, 'product'),
      formProfit
    );

    // Total Rows //
    allRows = getRowTemplate('label', formProfit);
    formDataCells = getForm(
      formCells,
      FormType[FormType.FORMPROFITLOSS].toLowerCase(),
      group,
      allRows,
      dbJson,
      loanDetails
    );
  }

  return formDataCells;
};


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const FormProfitLoss = ({ group }: FormGroupI) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { state: camState } = useLocation();
  const { getRequestStatusCode } = useLoanTable();
  const { fn } = useRefData();
  const { mapFiscalYear } = fn;
  const { loanRequestId, status, viewOnly }: any = useSelector((state: RootState) => state.loanProposal);

  const { tabs, selectedTab }: any = useSelector((state: RootState) => state.projections);

  const cells = useSelector(
    (state: RootState) => state?.inputscreen?.cells![FormType[FormType.FORMPROFITLOSS].toLowerCase()]
  );

  const handleFileUpload = async (event:any) => {
    dispatch(toggleLoader(true))
    const file = event.target.files[0];
    
    const imported = await importFormExcel(tabs![selectedTab]?.loanRequestId, tabs![selectedTab]?.id, file)

    if(imported){
       enqueueSnackbar(
        `Excel has been imported for Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'success',
        }
      )
       
       await queryClient.invalidateQueries(['getManufacturingScreen', selectedTab, tabs![selectedTab]?.loanRequestId])
       const data = queryClient.getQueryData(['getManufacturingScreen', selectedTab, tabs![selectedTab]?.loanRequestId])
       const dbJson: any = data && { ...getInputValue(data) };
      const loanDetails = tabs && { ...tabs[selectedTab], fiscalYear: mapFiscalYear(tabs[selectedTab]?.fiscalYearTypeId)};

      const inputFormJson = generateInputFormJson(group, {}, dbJson, loanDetails)
      dispatch(updateInputScreen(inputFormJson));
      

     } else{
       enqueueSnackbar(
        `Unable to import excel for Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'error',
        }
      );
     }
      event.target.value = null 
      dispatch(toggleLoader(false))
  }

  if (!group || !cells) return <></>;

  const cellData: any = {};

  const actualsCells = Object.fromEntries(
    Object.entries(cells).filter(([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase())
  );
  _.merge(cellData, actualsCells);

  if (group === FormGroup.PROJECTION) {
    const projectionCells = Object.fromEntries(
      Object.entries(cells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()
      )
    );
    _.merge(cellData, projectionCells);
  }

  return <>
          {!viewOnly &&
            (camState?.statusCode === getRequestStatusCode('DRAFT') ||
              camState?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
              camState?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
              !camState?.statusCode) && <div style={{float:"right", paddingBottom: "8px"}}>
            <Button component="label" variant="contained" startIcon={<UploadFileIcon />}>
              Import Excel
              <VisuallyHiddenInput type="file"  onChange={handleFileUpload} />
            </Button>
          </div>}
          <InputForm cells={cellData} />
        </>;
};
