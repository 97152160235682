import { useEffect } from 'react';
import { Container, Grid, Card, Paper, Typography, Stack, Divider, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactECharts from 'echarts-for-react'; 
import { getOverallAnalysis } from 'services/visualization-services';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toggleLoader } from 'state/reducers/common-actions';
import { useDispatch } from 'react-redux';
import { totalIncomeOption,ebidtaOption,ebidtaMarginOption,netProfitLossOption,profitMarginOption,earningPerShareOption,totalAssetsOption,netFixedAssetsOption,netWorthOption,debtToEquityOption,roeOption,roceOption,sourceOfFundsOption,usesOfFundsOption,criticalAnalysisOption } from "./overallData";


const OverallVisualization = (theme: any) => {

  const dispatch = useDispatch();
  const { state } = useLocation();  
  const { data, isLoading, isSuccess } = useQuery(['getOverallAnalysis',state?.loanRequestId], () => getOverallAnalysis(state?.loanRequestId));

  useEffect(()=>{
    if(isLoading){
       dispatch(toggleLoader(true))
    }else {
      dispatch(toggleLoader(false))
    }
    
  },[isLoading])

  return (
    <>
    {isSuccess && !data && (
        <Alert severity="warning" sx={{ height: '100vh' }}>
          <AlertTitle>
            <Typography variant="h6">Warning</Typography>
          </AlertTitle>
          <Typography variant="subtitle1">No data available.</Typography>
        </Alert>
      )}
    {isSuccess && data && (
    <Container sx={{ pt: 2, width: '100%', height: '100%' }}>
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs>
            <Box sx={{textAlign:"center"}}>
              <Typography gutterBottom variant="h4" component="div" >
                Profit & Loss Trend
              </Typography>            
              </Box>
            </Grid>
            <Divider variant="middle" />
         </Grid>
         <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={totalIncomeOption(data?.totalIncomeOption)}
                notMerge={true}
                lazyUpdate={true}
              />
              </Box>
            </Grid>
            <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={ebidtaOption(data?.ebidtaOption)}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
           </Grid>
           <Grid item xs={4}>
           <Box sx={{m:2}}>
            <ReactECharts
              option={ebidtaMarginOption(data?.ebidtaMarginOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>
         <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={netProfitLossOption(data?.netProfitLossOption)}
                notMerge={true}
                lazyUpdate={true}
              />
              </Box>
            </Grid>
            <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={profitMarginOption(data?.profitMarginOption)}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
           </Grid>
           <Grid item xs={4}>
           <Box sx={{m:2}}>
            <ReactECharts
              option={earningPerShareOption(data?.earningPerShareOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>
       </Grid>      

       <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs>
            <Box sx={{textAlign:"center"}}>
              <Typography gutterBottom variant="h4" component="div" >
                Balance Sheet Trend
              </Typography>            
              </Box>
            </Grid>
            <Divider variant="middle" />
         </Grid>
         <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={totalAssetsOption(data?.totalAssetsOption)}
                notMerge={true}
                lazyUpdate={true}
              />
              </Box>
            </Grid>
            <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={netFixedAssetsOption(data?.netFixedAssetsOption)}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
           </Grid>
           <Grid item xs={4}>
           <Box sx={{m:2}}>
            <ReactECharts
              option={netWorthOption(data?.netWorthOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>
           <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={debtToEquityOption(data?.debtToEquityOption)}
                notMerge={true}
                lazyUpdate={true}
              />
              </Box>
            </Grid>
            <Grid item xs={4}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={roeOption(data?.roeOption)}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
           </Grid>
           <Grid item xs={4}>
           <Box sx={{m:2}}>
            <ReactECharts
              option={roceOption(data?.roceOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>
       </Grid> 

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs>
            <Box sx={{textAlign:"center"}}>
              <Typography gutterBottom variant="h4" component="div" >
                Funds
              </Typography>            
              </Box>
            </Grid>
            <Divider variant="middle" />
         </Grid>
         <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={3}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={sourceOfFundsOption(data?.sourceOfFundsOption)}
                notMerge={true}
                lazyUpdate={true}
                style={{height:'400px'}}
              />
              </Box>
            </Grid>
            <Grid item xs={3}>
            <Box sx={{m:2}}>
              <ReactECharts
                option={usesOfFundsOption(data?.useOfFundsOption)}
                notMerge={true}
                lazyUpdate={true}
                style={{height:'390px'}}
              />
            </Box>
           </Grid>
           <Grid item xs={6}>
           <Box sx={{m:2}}>
            <ReactECharts
              option={criticalAnalysisOption(data?.criticalAnalysisOption)}
              notMerge={true}
              lazyUpdate={true}
              style={{height:'390px'}}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>      
       </Grid> 
      </Grid>
    </Container>
      )}
    </>
  );
};

export default OverallVisualization;
