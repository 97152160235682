import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import CustomTableContainer from 'components/common/table/CustomTableContainer';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import TableCommentRow from 'components/common/table/table-comment-row';
import _ from 'lodash';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps {
  totalYears: Array<string>;
  data: any;
  control: Control<any>;
}

export default function NetWorkingCapitalAnalysis({ totalYears, data, control }: IProps) {
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.netWorkingCapitalAnalysis')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Particulars</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>NWC / TCA </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].nwcByTca)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>ABF / TCA</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].abfByTca)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Sundry Creditors & Other Current Liabilities / TCA</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].sundryCreditorsByTca)}</NumberDenseCell>;
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
