import { Button } from '@mui/material';
import { FC } from 'react';
import { IButtonProps } from '../../../models/formComponents';

const SolidButton: FC<IButtonProps> = ({ title, onClick, style = {} }) => (
  <Button variant="contained" color="primary" onClick={onClick} style={style}>
    {title}
  </Button>
);

export default SolidButton;
