import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'constants/strings';
import { MENU_ITEMS } from './projection-menu-schema';
import { ITabSchema } from 'models/common';
import { RootState } from 'state/reducers';
import { changeMenuTab } from 'state/reducers/tabReducer';
import useLoanTable from 'hooks/useLoanTable';

function a11yProps(id: any) {
  return {
    itemNumber: id,
    'aria-controls': `simple-tabpanel-${id}`,
  };
}

const LoanProposalMainMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRisk, getRequestStatusCode } = useLoanTable();
  const { pathname, state } = useLocation();
  const theme = useTheme();
  const { selectedIndex }: any = useSelector((state: RootState) => state.mainMenu);

  const MENU_BY_ROLE = !isRisk()
    ? MENU_ITEMS?.filter((item: any) => item.value != routes?.RISK_RATIONALE_ROUTE)
    : MENU_ITEMS;

  const MENU_BY_STATUS =
    state?.statusCode === getRequestStatusCode('PENDING_WITH_RM_DOC_PREP')
      ? MENU_BY_ROLE
      : MENU_BY_ROLE?.filter((item: any) => item?.value != routes?.DOCUMENT_PREPARATION);

  useEffect(() => {
    for (let i = 0; i < MENU_BY_STATUS.length; i++) {
      if (pathname === MENU_BY_STATUS[i].value) {
        dispatch(changeMenuTab(MENU_BY_STATUS[i].id));
        break;
      }
    }
  }, [pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const route = MENU_BY_STATUS?.find((item: any) => item?.id === newValue);
    navigate(route?.value || '', {
      state: { ...state },
    });
    dispatch(changeMenuTab(newValue));
  };

  const _renderTabs = () => {
    return _.map(MENU_BY_STATUS, (item: ITabSchema) => {
      return <Tab color="#ffffff" label={item.name} value={item.id} {...a11yProps(item.id)} key={item.id} />;
    });
  };

  return (
    <Box>
      <Tabs value={selectedIndex} onChange={handleChange}>
        {_renderTabs()}
      </Tabs>
    </Box>
  );
};

export default LoanProposalMainMenu;
