import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import App from './App';
import { MemoryRouter } from 'react-router-dom';
import './services/i18n-service';

ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
        <App />
    </MemoryRouter>      
  </React.StrictMode>,
  document.getElementById('root')
);

