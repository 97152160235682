import useRefData from 'hooks/ref-data';
import { useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getRefDataByParentRef } from 'services/common-services';
import { IFormSchema } from '../../../../../../models/forms-schema-types';

function useLoanRequestFields(control: any, resetField: any): IFormSchema[] {
  const { refData } = useRefData();

  const selectedLoanType = useWatch({ control, name: 'loanType', defaultValue: '' });

  const parentRefData = useQuery(
    ['getRefDataByParentRef', selectedLoanType],
    () => getRefDataByParentRef(selectedLoanType),
    {
      enabled: !!selectedLoanType,
      onSuccess: (result: any) => {
        resetField("loanSubType")
      }
    }
  );

  return [
    {
      label: 'Loan Type',
      type: 'text',
      name: 'loanType',
      component: 'dropdown',
      options: refData?.loanType || [],
    },
    {
      label: 'Loan Sub Type',
      type: 'text',
      name: 'loanSubType',
      component: 'multiSelectDropdown',
      options: parentRefData.data || [],
      width: '400px'
    },
    {
      label: 'Request Type',
      name: 'requestType',
      type: 'text',
      component: 'dropdown',
      options: refData?.requestType || [],
    },

    {
      label: 'Purpose of Facility',
      name: 'purposeOfFacility',
      type: 'text',
      component: 'textField',
    },
    {
      label: 'Currency of Loan',
      name: 'loanCurrency',
      type: 'dropdown',
      component: 'dropdown',
      options: refData.currencyData || [],
    },
    {
      label: 'Loan Amount (Million)',
      type: 'number',
      name: 'loanAmount',
      component: 'textField',
    },
    {
      label: 'Source of Repayment',
      type: 'text',
      name: 'sourceRepayment',
      component: 'dropdown',
      options: refData.sourceOfRePayment || [],
    },
  ];
}

export { useLoanRequestFields };
