import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import _ from 'lodash';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function CapitalStructure({ t, totalYears, data, control }: IProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.capitalStructure')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Particulars</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>Tangible Net Worth (A)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>
                  {formatNumber(data[i].tangibleNetWorth.tangibleNetWorthValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Quasi Equity (B)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>{formatNumber(data[i].tangibleNetWorth.quasiEquity)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>
              <b>Exposure to Associate/Subsidiaries ('C)</b>
            </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell heading key={i}>
                  {formatNumber(data[i].exposureToAssociate.exposureToAssociateValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Investment </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>{formatNumber(data[i].exposureToAssociate.investment)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Loans to Subsidiaries</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>
                  {formatNumber(data[i].exposureToAssociate.loanAndAdvances)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>
              <b>Adjusted Net Worth (A+B-C)</b>
            </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell heading key={i}>
                  {formatNumber(data[i].adjustedNetWorth)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Total Debt/TNW</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].totalDebtByTnw)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Total Debt/ATNW</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].totalDebtByAtnw)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>
              <b>ROCE (%)</b>
            </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell heading key={i}>
                  {formatNumber(data[i].rocePercentage)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
