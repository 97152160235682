import { useMemo } from 'react';
import _ from 'lodash';
import { Box, styled, Grid, Button, Typography, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useQuery } from 'react-query';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DataTable from '../../../../common/data-table';
import ContentLayout from '../../../../common/containers/content-layout';
import { getRegisteredOrgList } from '../../../../../services/company-services';
import { screenActions, strings } from '../../../../../constants/strings';
import { ActionCell } from '../../../../common/data-table/table-action-cell';
import { toggleLoader } from '../../../../../state/reducers/common-actions';
import { useAppDispatch } from '../../../../../state/store';
import useRefData from 'hooks/ref-data';
import ActionBar from 'components/styled-components/ActionBar';

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: '8px 0px',
}));

export interface OrgDataResponse {
  id: number;
  org_name: string;
  industry_type_id: number;
  orgEmail: string;
  orgTypeId: number;
  cif: string;
  panNumber: string;
  sub_industry_type_id: number;
  status: string;
}

interface ICompanyDataList {
  companyId: number;
  company: string;
  companyType: string;
  industryType: string | undefined;
  subIndustryTypeId: string | undefined;
  cif: string | undefined;
  panNumber: string;
  status: string;
}

const ListedCompanies = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { fn } = useRefData();

  const { data, isLoading } = useQuery('getOrgList', getRegisteredOrgList, {
    onSuccess: () => {
      dispatch(toggleLoader(false));
    },
  });

  const addNewCompany = () => {
    navigate(pathname + '/add-company', {
      state: {
        screenAction: screenActions.CREATE_NEW,
      },
    });
  };

  const _companyData = () => {
    const orgData: Array<ICompanyDataList> = _.map(data, (row: OrgDataResponse) => {
      const tempData: ICompanyDataList = {
        companyId: row.id,
        company: row.org_name,
        companyType: '',
        cif: row?.cif,
        industryType: fn.mapIndustryType(row?.industry_type_id),
        subIndustryTypeId: fn.mapSubIndustryType(row?.sub_industry_type_id),
        panNumber: row.panNumber,
        status: row?.status,
      };

      return tempData;
    });

    return orgData;
  };
  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      {
        field: 'companyId',
        headerName: 'Company ID',
      },
      {
        field: 'company',
        headerName: 'Company Name',
      },
      { field: 'cif', headerName: 'CIF' },
      { field: 'industryType' },
      { field: 'subIndustryTypeId', headerName: 'Sub Industry Type' },
      {
        field: 'status',
      },
      {
        field: 'action',
        pinned: 'right',
        width: 150,
        cellRendererFramework: (params: any) => {
          return ActionCell({
            view: {
              viewBtnTitle: strings.VIEW_COMPANY,
              onView: () =>
                navigate(`${pathname}/view-company`, {
                  state: {
                    viewType: 'disabled',
                    screenAction: screenActions.UPDATE_DATA,
                    companyId: params?.data?.companyId,
                  },
                }),
              enabled: true,
            },
            edit: {
              editBtnTitle: strings.EDIT_COMPANY,
              onEdit: () =>
                navigate(`${pathname}/update-company`, {
                  state: {
                    viewType: 'editable',
                    screenAction: screenActions.UPDATE_DATA,
                    companyId: params?.data?.companyId,
                  },
                }),
              enabled: true,
            },
          });
        },
      },
    ],
    [navigate, pathname]
  );

  if (isLoading) {
    dispatch(toggleLoader(true));
  }

  return (
    // <Box sx={{ margin: '25px 50px' }}>
    //   <Box>
    //     <Typography variant="h3">Company Master</Typography>
    //     <Divider />
    //   </Box>
    //   <Grid container>
    //     <Grid item md={12}>
    //       <FilterContainer>
    //         <Button variant="contained" onClick={addNewCompany} sx={{ p: 1 }}>
    //           {strings.ADD_NEW_COMPANY}
    //         </Button>
    //       </FilterContainer>
    //     </Grid>
    //     <Grid item md={12} sx={{ height: 'calc(100vh - 240px)' }}>
    //       <DataTable columnDefs={columnDefs} autoSize={true} rowData={_companyData()} sizeToFit={true} />
    //     </Grid>
    //     <Grid item md={12}>
    //       <ActionBar></ActionBar>
    //     </Grid>
    //   </Grid>
    // </Box>
    <Box sx={{ m: 1.5 }}>
      <ContentLayout title="Company Master">
        <Grid container spacing={1}>
          <Grid item md={12}>
            <FilterContainer>
              <Button variant="contained" onClick={addNewCompany} sx={{ p: 1 }}>
                {strings.ADD_NEW_COMPANY}
              </Button>
            </FilterContainer>
          </Grid>
          <Grid item md={12} sx={{ height: 'calc(100vh - 240px)' }}>
            <DataTable columnDefs={columnDefs} autoSize={true} rowData={_companyData()} sizeToFit={true} />
          </Grid>
          <Grid item md={12}>
            <ActionBar></ActionBar>
          </Grid>
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default ListedCompanies;
