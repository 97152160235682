import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropLoaderLabel = ({ isLoading, label = null }: { isLoading: boolean, label?:string|null }) => {

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => 9999 }}
      open={isLoading}>
      <Box sx={{ display: 'inline-flex' }}>
        <CircularProgress color="inherit" />
        {label && <Box
          sx={{
            top: 0,
            left: 0,
            bottom: -65,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            variant="caption"
            component="div"
          >{label}</Typography>
        </Box>}
      </Box>    
    </Backdrop>
  );
};

export default BackdropLoaderLabel;
