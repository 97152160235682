import { ITabSchema } from 'models/common';

const MENU_ITEMS: Array<ITabSchema> = [
  { id: 1, name: 'Financial Projection & Summary', pathName: '', value: '/dashboard/loan-proposal' },
  {
    id: 2,
    name: 'Business Background',
    pathName: 'business-background',
    value: '/dashboard/loan-proposal/business-background',
  },
  { id: 3, name: 'Proposal & TC', pathName: 'tc', value: '/dashboard/loan-proposal/tc' },
  { id: 4, name: 'Compliance', pathName: 'compliance', value: '/dashboard/loan-proposal/compliance' },
  { id: 5, name: 'Visualisation', pathName: 'visualisation', value: '/dashboard/loan-proposal/visualisation' },
  { id: 6, name: 'CAM', pathName: 'cam', value: '/dashboard/loan-proposal/cam' },
  { id: 7, name: 'Annexure', pathName: 'annexure', value: '/dashboard/loan-proposal/annexure' },
  { id: 8, name: 'Review', pathName: 'review', value: '/dashboard/loan-proposal/review-comment' },
  { id: 10, name: 'Risk Rationale', pathName: 'risk', value: '/dashboard/loan-proposal/risk' },
  {
    id: 11,
    name: 'Document Preparation',
    pathName: 'document-preparation',
    value: '/dashboard/loan-proposal/document-preparation',
  },
];

export { MENU_ITEMS };
