import { FC, useMemo, useState, useEffect } from 'react';
import { Box, Grid, Button, Card, CardHeader, CircularProgress, TextField, InputAdornment, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import moment from 'moment';
import { IFormSchema } from 'models/forms-schema-types';
import { GridSingleForm } from 'components/common/containers/grid-form-container';
import ContentLayout from 'components/common/containers/content-layout';
import ActionBar from 'components/styled-components/ActionBar';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import { grey } from '@mui/material/colors';
import { strings, routes } from 'constants/strings';
import { GO_BACK } from 'icons/icon-names';
import getIcon from 'icons/icons';
import { useTheme } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchCompany } from 'services/company-services';
import { RootState } from 'state/store';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import MemoUpload from './MemoUpload';
import { useMemoDetails } from './create-memo-schema';
import SimpleFormLabel from 'components/common/form-components/form-label';
import PrintIcon from '@mui/icons-material/Print';
import { useSnackbar } from 'notistack';
import { saveMemo, getMemoById, approveMemo, updateMemo } from 'services/memo-service';
import { toggleLoader } from 'state/reducers/common-actions';
import queryClient from 'services/react-query-service';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import useLoanTable from 'hooks/useLoanTable';

export interface IMemotable {
  control?: any;
  memoName?: string;
  deleteMemo?: () => void;
}

const cardHeader = {
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
  },
};

const CreateMemo: FC<IMemotable> = () => {
  const { username }: any = useSelector((state: RootState) => state.user?.userDetails);
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { getAccessMode } = useLoanTable();
  const memoFields = useMemoDetails();
  const [fileId, setFileId] = useState<any | null>(null);
  const [open, setOpen] = useState(false);
  const [approveReturn, setApproveReturn] = useState<string>('');
  const [message, setMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCompany, setSelectedCompany] = useState<any>({
    id: null,
    name: ''
  });
  const mutation = useMutation((data) => searchCompany(data));
  const dispatch = useDispatch();
  const { data, isSuccess, refetch } = useQuery(['getMemoById', state?.memoId], () => getMemoById(state?.memoId), {
    enabled: !!state?.memoId,
  });


  const companySearch = _.debounce((value: string) => {
    setLoading(true);
    const data = {
      empty: true,
      idExp: value,
      nameExp: value,
      panExp: value,
    };
    //@ts-ignore
    mutation.mutate(data);
  }, 100);

  const _onSubmit: SubmitHandler<any> = (data: any) => {
    if (Object.values(data).some((x) => x === null || x === '') || !selectedCompany?.id) {
      enqueueSnackbar('Please fill all details', {
        variant: 'error',
      });
    } else {
      saveMemoMutation.mutate({ ...data, documentList: fileId, companyId: selectedCompany?.id, companyName: selectedCompany?.name });
    }
  };

  const _onUpdate: SubmitHandler<any> = (data: any) => {
    const { documentList, memoDocId, approvalComment, ...memoData } = data;
    if (Object.values(memoData).some((x) => x === null || x === '') || !selectedCompany?.id) {
      enqueueSnackbar('Please fill all details', {
        variant: 'error',
      });
    } else {
      updateMemoMutation.mutate({ ...data, documentList: fileId, memoId: state?.memoId, id: selectedCompany?.id, companyName: selectedCompany?.name });
    }
  };

  const saveMemoMutation = useMutation((memoData: any) => saveMemo(memoData), {
    onSuccess: (data, variables) => {
      const memoIds = data?.map((m: any) => JSON.parse(m)?.memoId).join(', ');
      enqueueSnackbar(`Memos : ${memoIds} has been created`, {
        variant: 'success',
      });

      navigate(routes.MEMOS_ROUTE);
    },
    onError: (data, variables) => {
      enqueueSnackbar(`Failed to create Memo`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      state?.memoId && refetch();
      queryClient.invalidateQueries(['getOpenMemos']);
      dispatch(toggleLoader(false));
    },
  });

  const updateMemoMutation = useMutation((memoData: any) => updateMemo(memoData?.memoId, memoData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`Memos : ${variables?.memoId} has been updated`, {
        variant: 'success',
      });

      navigate(routes.MEMOS_ROUTE);
    },
    onError: (data, variables) => {
      enqueueSnackbar(`Failed to update Memo`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      state?.memoId && refetch();
      queryClient.invalidateQueries(['getOpenMemos']);

      dispatch(toggleLoader(false));
    },
  });

  const getApproval = (approveReturn: string) => {
    if (approveReturn === 'REJECT') {
      return 'has been Rejected';
    } else if (approveReturn === 'REWORK') {
      return 'need to Rework';
    } else {
      return 'has been Approved';
    }
  };

  const approveReturnMutation = useMutation((approvalData: any) => approveMemo(approvalData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`Memo Request ID : ${state?.memoId} ${getApproval(variables?.approvalStatus)}`, {
        variant: 'success',
      });
      navigate(routes.MEMOS_ROUTE);
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const onClickApproveReturnHandler = (comment: string) => {
    if (state?.memoId && approveReturn != null) {
      approveReturnMutation.mutate({
        approvalStatus: approveReturn,
        memoId: state?.memoId,
        approvalComment: comment,
      });
    }
  };

  const handleClickOpen = (approveReturn: string | null) => {
    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      setMessage('Confirmation');
      setOpen(true);
    }
  };

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      typeOfMemo: '',
      date: moment().toDate(),
      from: username,
      to: '',
      cc: '',
      subject: '',
      companyDetails: '',
      proposal: '',
    },
  });

  useEffect(() => {
    if (state?.memoId && data) {
      reset(data);
      setFileId(data?.documentList);
      const {companyId, companyName} = data
      setSelectedCompany({id: companyId, name: companyName})
    } else {
      reset({});
      setFileId([]);
      setSelectedCompany({ id: null, name: ''})
    }
  }, [isSuccess, state?.memoId]);

  return (
    <div style={{ overflowY: 'scroll', height: '100vh' }}>
      <form
        onSubmit={
          state?.memoId && state?.accessMode === getAccessMode('EDIT')
            ? handleSubmit(_onUpdate)
            : handleSubmit(_onSubmit)
        }>
        <Card style={{ borderRadius: 10 }}>
          <CardHeader
            sx={{
              p: 1,
              ...cardHeader,
              pl: 2,
              background: grey[50],
              '& .MuiCardHeader-action': {
                alignSelf: 'center !important',
              },
            }}
            title={state?.memoId ? 'Memo' : strings.CREATE_MEMO_BUTTON}
            action={
              <>
                <TooltipIconButton
                  title="Go Back"
                  icon={getIcon(GO_BACK, theme.palette.primary.main)}
                  onClick={() => {
                    navigate(routes.MEMOS_ROUTE);
                  }}
                />
                <TooltipIconButton
                  title="Print"
                  icon={<PrintIcon />}
                  onClick={() => {
                    window.print();
                  }}
                />
              </>
            }
          />
        </Card>
        <Grid container>
          <Grid item lg={12} md={12}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Box sx={{ display: 'flex' }} className={(!state?.viewOnly && state?.accessMode !== getAccessMode('APPROVE'))?"required":""}>
                    <SimpleFormLabel label="Company" sx={{ width: '40%' }} />
                    {!state?.viewOnly && state?.accessMode !== getAccessMode('APPROVE') && <Box>
                      <Autocomplete
                        disablePortal
                        freeSolo
                        value={selectedCompany}
                        id="company-search-box"
                        options={mutation?.data || []}
                        filterOptions={(options, state) => options}
                        getOptionLabel={(option: any) => option?.name}
                        onChange={(event, newValue) => {
                          setSelectedCompany(newValue);
                        }}
                        onClose={() => {
                          setLoading(false);
                        }}
                        sx={{
                          padding: 0,
                          '& .MuiAutocomplete-inputRoot': {
                            padding: '3px 11px',
                          },
                          '& .MuiChip-label': {
                            fontSize: '12px',
                          },
                          width: '13.3rem',
                        }}
                        renderInput={(params) => (
                          <TextField
                            margin="dense"
                            placeholder="Search Company"
                            {...params}
                            sx={{
                              margin: 0,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={18} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            variant="standard"
                            onChange={(e) => {
                              companySearch(e.target.value);
                              setSelectedCompany({name:e.target.value, id:null});
                            }}
                          />
                        )}
                      />
                      </Box>
                    }
                    {(state?.viewOnly || state?.accessMode === getAccessMode('APPROVE'))  && selectedCompany?.name && (<Box sx={{ display: 'flex', float: 'right' }}>
                        <Box sx={{ m: '6px' }}>
                          <Typography>{selectedCompany?.name}</Typography>
                        </Box>
                      </Box>)
                    }
                    {selectedCompany?.id && (
                    
                      <Box sx={{ display: 'flex', float: 'right' }}>
                        <SimpleFormLabel label="Company ID" sx={{ width: '90%' }} />
                        <Box sx={{ m: '6px' }}>
                          <Typography>{selectedCompany?.id}</Typography>
                        </Box>
                      </Box>
                      
                  )}
                  </Box>
                  
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  
                </Grid>
                {_.map(memoFields, (schema: IFormSchema, idx: number) => {
                  return (
                    <GridSingleForm
                      key={idx}
                      control={control}
                      input={schema}
                      disabled={
                        schema?.name === 'from' ||
                        state?.viewOnly ||
                        state?.accessMode === getAccessMode('APPROVE') ||
                        (schema?.name === 'to' && state?.viewOnly === false) ||
                        (schema?.name === 'cc' && state?.viewOnly === false)
                      }
                    />
                  );
                })}
                {((state?.memoId && state?.accessMode === getAccessMode('APPROVE')) ||
                  (state?.memoId && state?.accessMode === getAccessMode('EDIT'))) && (
                  <Grid xs={12} item>
                    <Box sx={{ display: 'flex' }}>
                      <SimpleFormLabel label="Comments" sx={{ minWidth: '20%' }} />
                      <div style={{ padding: '2px' }}>{data?.approvalComment}</div>
                    </Box>
                  </Grid>
                )}
                <Grid xs={12} item>
                  <Box sx={{ display: 'flex' }}>
                    <SimpleFormLabel label="Document List" sx={{ minWidth: '100%' }} />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <MemoUpload
                    parentMemoId={state?.parentMemoId}
                    viewOnly={state?.viewOnly || state?.accessMode === getAccessMode('APPROVE')}
                    fileId={fileId}
                    setFileId={setFileId}
                  />

                  <div style={{ height: '250px' }} />
                </Grid>
              </Grid>
              <ActionBar>
                {!state?.viewOnly && (
                  <>
                    {!state?.memoId && (
                      <Button
                        variant="contained"
                        sx={{ p: 1, right: 10 }}
                        onClick={() => handleSubmit(_onSubmit)}
                        type="submit">
                        {strings.CREATE_MEMO_BUTTON}
                      </Button>
                    )}
                    {state?.memoId && state?.accessMode === getAccessMode('APPROVE') && (
                      <>
                        <Button
                          variant="contained"
                          sx={{ p: 1, right: 25 }}
                          onClick={() => handleClickOpen('REJECT')}>
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ p: 1, right: 20 }}
                          onClick={() => handleClickOpen('REWORK')}>
                          Rework
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ p: 1, right: 15 }}
                          onClick={() => handleClickOpen('APPROVED')}>
                          Approve
                        </Button>
                      </>
                    )}
                    {state?.memoId && state?.accessMode === getAccessMode('EDIT') && (
                      <>
                        <Button
                          variant="contained"
                          sx={{ p: 1, right: 10 }}
                          onClick={() => handleSubmit(_onUpdate)}
                          type="submit">
                          Update Memo
                        </Button>
                      </>
                    )}
                  </>
                )}
                {state?.viewOnly && (
                  <>
                    <Button
                      variant="contained"
                      sx={{ p: 1, right: 10 }}
                      onClick={() => navigate(routes.MEMOS_ROUTE)}
                      type="submit">
                      Close
                    </Button>
                  </>
                )}
              </ActionBar>

              <DialogWithComment
                open={open}
                setOpen={setOpen}
                title={message}
                description={`Do you want to ${_.startCase(_.camelCase(approveReturn))} for Memo Request ID: ${
                  state?.memoId
                } ?`}
                type={'info'}
                onClickHandler={onClickApproveReturnHandler}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateMemo;
