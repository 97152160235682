import { Box, Card, styled } from '@mui/material';

export const MainBox = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 96px)',
  overflow: 'hidden',
}));

export const MainCard = styled(Card)(({ theme }) => ({
  height: 'calc(100vh - 250px) !important',
  overflowY: 'scroll',
  marginTop: '3px',
  width: '100%',
}));
