import { Grid, Box } from '@mui/material';
import _ from 'lodash';
import CurrentProposal from './current-proposal';
import ExposureSummary from './exposure-summary-table';
import { borrowerSummaryRow } from './financial-assesment-schemas';
import RiskMitigation from './risk-mitigation';
import { getFormFields } from 'utils/form-utils';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { FormTitle } from 'components/common/form-components/FormTitle';
import RiskCalculations from './risk-calculations';

interface IRow {
  name: string;
  label: string;
  component: string;
  disabled?: boolean;
  rules?: any;
}

const FinancialAssesment = (props: any) => {
  const renderBorrowerContent = () => {
    return (
      <>
        {_.map(borrowerSummaryRow, (row: IRow, index: number) => {
          return (
            <Grid key={index} item xs={6}>
              <Box>
                <SimpleFormLabel label={row.label} sx={{ width: '40%' }} />
                {getFormFields(
                  {
                    ...row,
                  },
                  props.control,
                  props.viewMode || row.disabled
                )}
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <FormTitle title="1. Borrower Summary" />
          <Grid container rowSpacing={1} sx={{ mt: 0 }}>
            {renderBorrowerContent()}
          </Grid>
        </Box>
      </Grid>
      <Grid item md={12}>
        <ExposureSummary {...props} />
      </Grid>
      <Grid item md={12}>
        <CurrentProposal {...props} />
      </Grid>
       <Grid item md={12}>
        <RiskMitigation {...props} />
      </Grid>
      <Grid item md={12}>
        <RiskCalculations {...props} />
      </Grid>
    </Grid>
  );
};

export default FinancialAssesment;
