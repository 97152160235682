import Layout from '../../layout/layout';
import LoanRequests from '../credit-analysis/loan-proposal/loan-requests';


export default function RiskUnit() {
  return (
    <Layout>
      <LoanRequests />
    </Layout>
  );
}
