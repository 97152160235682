import { useEffect, useState } from 'react';
import _ from 'lodash';
import { unflatten } from 'flat';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { triggerCAM, rejectLoanProposal } from 'services/loan-proposal-services';
import { getMeta, viewDocument } from 'services/document-services';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../../state/store';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { submitInputScreen, getInputScreen } from 'services/loan-projection-services';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ActionBar from 'components/styled-components/ActionBar';
import { toggleLoader } from 'state/reducers/common-actions';
import DialogWithCheckBox from 'components/common/modals/DialogWithCheckBox';
import useLoanTable from 'hooks/useLoanTable';

const CAM = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [camsPDF, setCamsPDF] = useState<any>();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { getRequestStatusCode, getHomePage } = useLoanTable();

  const { tabs, selectedTab }: any = useSelector((state: RootState) => state.projections);
  const { roles }: any = useSelector((state: RootState) => state.user?.userDetails);

  const cells = useSelector((state: RootState) => state?.inputscreen?.cells);

  const {
    data,
    isSuccess,
    isLoading: screenLoading,
  } = useQuery(
    ['getScreen', selectedTab, state?.loanRequestId],
    () => getInputScreen({ loanId: state?.loanRequestId, projectionId: tabs![selectedTab]?.id }),
    { enabled: !!tabs![selectedTab]?.id }
  );
  const { enqueueSnackbar } = useSnackbar();

  const submitScreenMutation = useMutation((data: any) => submitInputScreen(data?.projection, data?.finalData), {
    onSuccess: (data) => {
      enqueueSnackbar(
        `Proposal submitted for Loan Request ID : ${state?.loanRequestId} / Projection: ${tabs![selectedTab]?.id}`,
        {
          variant: 'success',
        }
      );

      navigate(getHomePage(), { replace: true });
    },
    onError: (data) => {
      enqueueSnackbar(
        `Failed to submit Proposal for Loan Request ID : ${state?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'error',
        }
      );
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTriggerCam = async () => {
    dispatch(toggleLoader(true));
    const data = await triggerCAM(state?.loanRequestId);

    if (data) {
      dispatch(toggleLoader(false));
      enqueueSnackbar(`CAMS retriggered for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'success',
      });

      setLoading(true);

      const camData = await getMeta(state?.loanRequestId, 'CAM', 'LOAN_REQUEST');

      if (camData?.id) {
        const camFile = await viewDocument(camData?.id);
        const file = new File([camFile], `CAMS_${state?.loanRequestId}`, {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(file);
        camData && setCamsPDF(`${url}#view=FitH`);
      }

      setLoading(false);
      // dispatch(toggleLoader(false));
    } else {
      enqueueSnackbar(`Unable to retriggered CAMS for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });

      dispatch(toggleLoader(false));
    }
  };

  const handleRejectProposal = useMutation(() => rejectLoanProposal(state?.loanRequestId), {
    onSuccess: (data) => {
      enqueueSnackbar(`Loan proposal rejected for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'success',
      });
      navigate(getHomePage());
    },
    onError: (data) => {
      enqueueSnackbar(`Failed to reject loan proposal for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const generateInputJsonforDB = () => {
    const inputJson = _.cloneDeep(cells);

    if (!_.isEmpty(inputJson)) {
      const yearMap = Object.fromEntries(
        Object.assign(
          [],
          ...Object.values(inputJson).map((row: any) =>
            Object.entries(row)
              .filter(([k]) => k?.endsWith('.0'))
              .map(([k, v]: any) => [k?.replaceAll('.0', ''), `${v?.value}.${v?.group}`])
          )
        )
      );

      const finalData: any = unflatten(
        Object.assign(
          {},
          ...Object.entries(inputJson).map(([formkey, formvalue]: any) => {
            return Object.assign(
              {},
              ...Object.entries(formvalue)
                .filter(([key]: any) => !key?.startsWith('0') && !key?.endsWith('.0'))
                .map(([key, value]: any) => {
                  const [yearKey, ...restKey] = key.split('.');
                  return Object.assign(
                    {},
                    { [`${yearMap[yearKey]}.${formkey}.${restKey.join('.')}`]: { value: value?.value } }
                  );
                })
            );
          })
        ),
        { object: true }
      );

      let submitData = null;
      if (!_.isEmpty(tabs)) {
        submitData = {
          projection: { loanId: state?.loanRequestId, projectionId: tabs![selectedTab]?.id },
          finalData,
        };
      }

      return submitData;
    }
  };

  const onClickSubmitHandler = () => {
    let submitData = null;

    if (!_.isEmpty(generateInputJsonforDB()) || !_.isEmpty(data)) {
      const finalData = generateInputJsonforDB() || data;
      submitData = {
        projection: { loanId: state?.loanRequestId, projectionId: tabs![selectedTab]?.id },
        finalData,
      };

      submitScreenMutation.mutate(submitData);
    } else {
      enqueueSnackbar(
        `No data to submit Proposal for Loan Request ID : ${state?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id || 'Not Found'
        }`,
        {
          variant: 'error',
        }
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getMeta(state?.loanRequestId, 'CAM', 'LOAN_REQUEST');
      if (data?.id) {
        const camFile = await viewDocument(data?.id);
        const file = new File([camFile], `CAMS_${state?.loanRequestId}`, {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(file);
        data && setCamsPDF(`${url}#view=FitH`);
      }
      dispatch(toggleLoader(false));
      setLoading(false);
    }
    setLoading(true);
    dispatch(toggleLoader(true));
    fetchData();
  }, []);

  const _renderSubmitButton = () => {
    //if the user is approver button will not render
    const found = _.find(roles, ({ role }) => role === 'Approver');
    if (found) {
      return null;
    }

    if (
      state?.statusCode === getRequestStatusCode('DRAFT') ||
      state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
      state?.statusCode === getRequestStatusCode('REWORK_WITH_RM')
    ) {
      return (
        <>
          <ActionBar>
            <Button type="submit" variant="contained" onClick={handleTriggerCam} sx={{ p: 1, marginRight: 1 }}>
              Retrigger CAMS
            </Button>
            <Button type="submit" variant="contained" onClick={handleClickOpen} sx={{ p: 1, marginRight: 1 }}>
              Review and Submit
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                handleRejectProposal.mutate();
              }}
              sx={{ p: 1, marginRight: 1 }}>
              Reject
            </Button>
          </ActionBar>
        </>
      );
    }
  };

  return (
    <div style={{ alignItems: 'center' }}>
      {camsPDF && (
        <>
          <object data={camsPDF} type="application/pdf" style={{ width: '100%', height: 'calc(100vh - 198px)' }}>
            <Alert severity="warning" sx={{ height: '100vh' }}>
              <AlertTitle>
                <Typography variant="h6">Warning</Typography>
              </AlertTitle>
              <Typography variant="subtitle1">CAMS is not yet created, please submit form.</Typography>
            </Alert>
          </object>
          {!state?.viewOnly && _renderSubmitButton()}
        </>
      )}
      {!isLoading && !camsPDF && (
        <Alert severity="warning" sx={{ height: '100vh' }}>
          <AlertTitle>
            <Typography variant="h6">Warning</Typography>
          </AlertTitle>
          <Typography variant="subtitle1">CAMS is not yet created, please submit form.</Typography>
          {!state?.viewOnly && _renderSubmitButton()}
        </Alert>
      )}
      {isLoading && (
        <Alert severity="info" sx={{ height: '100vh', '& .MuiAlert-message': { width: '100%' } }}>
          <AlertTitle>
            <LinearProgress color="inherit" />
          </AlertTitle>
          <Typography variant="subtitle1">Please wait while CAMS get loaded</Typography>
        </Alert>
      )}
      <DialogWithCheckBox
        open={open}
        setOpen={setOpen}
        title={'Confirmation'}
        description={`Do you want to submit Loan Request ID: ${state?.loanRequestId} for business approval?`}
        type={'info'}
        onClickHandler={onClickSubmitHandler}
      />
    </div>
  );
};

export default CAM;
