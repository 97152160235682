import { useQuery } from 'react-query';
import DataTable from 'components/common/data-table';
import { downloadDocument } from 'services/document-services';
import { getRiskDocument } from 'services/approval-service';
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ArticleIcon from '@mui/icons-material/Article';
import { ColGroupDef } from 'ag-grid-community';
import { useNavigate, useLocation } from 'react-router-dom';

const columns = [
  {
    field: 'srNo',
    headerName: 'Sr. No.',
    width: 90,
    maxWidth: 120,
    sortable: true,
    resizable: false,
    pinned: 'left',
  },
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'docDesc',
    headerName: 'Type',
    sortable: true,
  },
  {
    field: 'docFileName',
    headerName: 'File Name',
    sortable: true,
  },
  {
    field: 'updatedBy',
    headerName: 'User',
    sortable: true,
  },

  {
    field: 'action',
    headerName: 'Action',
    pinned: 'right',
    width: 50,
    sortable: false,
    cellRendererFramework: (params: any) => {
      return (
        <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center' }}
          onClick={() => downloadDocuments(params?.data)}>
          <DownloadForOfflineIcon fontSize="small" />
        </div>
      );
    },
  },
];

const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};

const getRows = (data: any): ColGroupDef[] => {
  let rows = null;
  if (data) {
    rows = data?.map((doc: any, i: number) => {
      return { ...doc, srNo: i + 1 };
    });
  }
  return rows;
};

const AuthorityDocuments = () => {

  const { state } = useLocation();
  const { data } = useQuery(['getRiskDocument'], () => getRiskDocument(state?.loanRequestId));

  return (
    
      <Box
        sx={{
          height: 'calc(100vh - 135px)',
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <DataTable columnDefs={columns} autoSize={false} rowData={getRows(data)} />
      </Box>
    
  );
};

export default AuthorityDocuments;
