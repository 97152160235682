import { Outlet } from 'react-router-dom';
import LegalLayout from './LegalLayout';

const Legal = () => {
  return (
    <LegalLayout>
      <Outlet />
    </LegalLayout>
  );
};

export default Legal;
