import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const createOrUpdateProjection = async (data: any) => {
  const res = await httpService.post('/loanProjections/basicDetails', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

export const getProjectionByLoanId = async (loanReqId: string) => {
  try {
    const res = await httpService.get(`/loanProjections/basicDetails/byLoanId/${loanReqId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('getProjectionByLoanId: Error', error);
  }
};

export const getProjectionSummary = async (loanReqId: number, projectionId: number) => {
  try {
    const res = await httpService.get(`/loanProjections/projectionSummary/${loanReqId}/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('getProjectionSummary: ERROR', error);
  }
};

export const updateProjectionSummary = async (loanReqId: number, projectionId: number, data: any) => {
  try {
    const res = await httpService.post(`/loanProjections/projectionSummary/${loanReqId}/${projectionId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('updateProjectionSummary: ERROR', error);
  }
};

export const getInputScreen = async ({ loanId, projectionId }: any) => {
  try {
    const response = await httpService.get(`/loanProjections/inputData/${loanId}/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getInputScreen: Error', error);
  }
};

export const getPerformanceSummary = async (loanId: number, projectionId: number) => {
  try {
    const response = await httpService.get(`/loanProjections/performanceSummary/${loanId}/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getPerformanceSummary: Error', error);
  }
};

export const updatePerformanceSummary = async (loanReqId: number, projectionId: number, data: any) => {
  try {
    const res = await httpService.post(`/loanProjections/performanceSummary/${loanReqId}/${projectionId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('updatePerformanceSummary: ERROR', error);
  }
};

export const createInputScreen = async ({ loanId, projectionId }: any, data: any) => {
  const response = await httpService.post(`/loanProjections/inputData/${loanId}/${projectionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const submitInputScreen = async ({ loanId, projectionId }: any, data: any) => {
  const response = await httpService.post(`/loanProjections/submitInputData/${loanId}/${projectionId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const startProposal = async (loanDetails: any) => {
  const response = await httpService.get(`/workflow/startProposal/${loanDetails?.loanRequestId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const claimReviewTask = async (loanTask: any) => {
  const response = await httpService.get(`/workflow/claimReviewTasks/${loanTask?.flowableTaskId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const completeReviewTask = async (flowableTaskId: any) => {
  const response = await httpService.get(`/workflow/completeReviewTask/${flowableTaskId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const viewTaskComments = async (flowableTaskId: any) => {
  const response = await httpService.get(`/workflow/viewTaskComments/${flowableTaskId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const addReviewComment = async (commentDetails: any) => {
  const response = await httpService.post(`/workflow/addReviewComment`, commentDetails, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const riskReviewTasks = async (loanRequestId: any) => {
  const response = await httpService.get(`/workflow/riskReviewTasks/${loanRequestId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const riskUnitApproval = async (loanRequestDetail: any) => {
  const response = await httpService.post(`/workflow/riskUnitApproval`, loanRequestDetail, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const approveWithDocListRM = async (data: any) => {
  const response = await httpService.post('/workflow/rmApprovalDocPrep', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const getApplicantProjectionBasicDetailsByLoanReqId = async (loanId: string | number) => {
  try {
    const response = await httpService.get(`/loanProjections/applicant/basicDetails/byLoanId/${loanId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response;
  } catch (error) {}
};

export const getFormsData = async (loanReqId: number, projectionId: number, formNumber: string) => {
  try {
    const response = await httpService.get(`/loanProjections/${formNumber}/${loanReqId}/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getFormsData: ERROR', error);
  }
};

export const getExistingBasicDetails = async (loanReqId: number) => {
  try {
    const response = await httpService.get(`/loanProjections/existingBasicDetails/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getExistingBasicDetails: ERROR', error);
  }
};

export const getFormulaJsonByProjectionId = async (projectionId: string) => {
  try {
    const res = await httpService.get(`/loanProjections/formulaJson/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('getFormulaJsonByProjectionId: Error', error);
  }
};

export const saveFormulaJsonByProjectionId = async ({ projectionId, formulaJson }: any) => {
  try {
    const res = await httpService.post(`/loanProjections/formulaJson/${projectionId}`, formulaJson, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.error('saveFormulaJsonByProjectionId: Error', error);
  }
};

export const saveFormulaPartByProjectionId = async ({ projectionId, formulaString, partNo, totalParts }: any) => {
  try {
    const res = await httpService.post(
      `/loanProjections/formulaJsonParts/${projectionId}/${partNo}/${totalParts}`,
      formulaString,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}` || '',
        },
      }
    );
    return res;
  } catch (error) {
    console.error('saveFormulaPartByProjectionId: Error', error);
  }
};

export const getProjectFinance = async (loanRequestId: any, projectionId: any) => {
  try {
    const response = await httpService.get(`/projectFinance/${loanRequestId}/${projectionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getProjectFinance: Error', error);
  }
};

export const saveProjectFinance = async (loanRequestId: any, projectionId: any, data: any) => {
  try {
    const response = await httpService.post(`/projectFinance/generate/${loanRequestId}/${projectionId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('saveProjectFinance: Error', error);
  }
};

export const generateWCAnalysys = async ({ loanId, projectionId, ...wcAnalysysConfig }: any) => {
  try {
    const res = await httpService.post(`/wcAnalysis/generate/${loanId}/${projectionId}`, wcAnalysysConfig, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res;
  } catch (error) {
    console.error('generateWCAnalysys: Error', error);
  }
};

export const generateSensitiveAnalysis = async ({ loanId, projectionId, ...sensitiveAnalysisConfig }: any) => {
  try {
    const res = await httpService.patch(`/projectionSummary/generate/${loanId}/${projectionId}`, sensitiveAnalysisConfig, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res;
  } catch (error) {
    console.error('sensitiveAnalysisConfig: ERROR', error);
  }
};

export const importFormExcel = async (loanId: string, projectionId:string, file: any): Promise<Boolean> => {
  let upload: Boolean = false;
  try {
    const formData = new FormData();
    formData.append('file', file);

    await httpService.post(`/loanProjections/inputDataViaExcel/${loanId}/${projectionId}`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
        'Content-Type': 'multipart/form-data',
      } ,
    });
    upload = true;
  } catch (error) {
    upload = false;
    console.error('importFormExcel: ERROR', error);
  }
  return upload;
};