import _ from "lodash";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TooltipIconButton from '../tooltip-icon-button';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import FlakyIcon from '@mui/icons-material/Flaky';
import CommentIcon from '@mui/icons-material/Comment';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

export const ActionCell = (actionBtn: any) => {
  const { view, edit, start, claim, claimed, approve, assign, mom, cams, memo } = actionBtn;

  return (
    <>
      {view?.enabled && (
        <TooltipIconButton
          onClick={view?.onView}
          icon={<VisibilityIcon fontSize="small" />}
          title={_.startCase(_.camelCase(view?.viewBtnTitle))}
        />
      )}
      {edit?.enabled && (
        <TooltipIconButton
          onClick={edit?.onEdit}
          icon={<EditIcon fontSize="small" />}
          title={_.startCase(_.camelCase(edit?.editBtnTitle))}
        />
      )}
      {start?.enabled && (
        <TooltipIconButton
          onClick={start?.onStart}
          icon={<NotStartedIcon fontSize="small" />}
          title={_.startCase(_.camelCase(start?.startBtnTitle))}
        />
      )}
      {claim?.enabled && (
        <TooltipIconButton
          onClick={claim?.onClaim}
          icon={<AssignmentTurnedInIcon fontSize="small" />}
          title={_.startCase(_.camelCase(claim?.claimBtnTitle))}
        />
      )}
      {claimed?.enabled && (
        <TooltipIconButton 
        onClick={claimed?.onClaimed} 
        icon={<CommentIcon fontSize="small" />} 
        title="Comment" />
      )}
      {approve?.enabled && (
        <TooltipIconButton
          onClick={approve?.onApprove}
          icon={<FlakyIcon fontSize="small" />}
          title={_.startCase(_.camelCase(approve?.approveBtnTitle))}
        />
      )}
      {assign?.enabled && (
        <TooltipIconButton
          onClick={assign?.onAssign}
          icon={<AssignmentIndIcon fontSize="small" />}
          title={_.startCase(_.camelCase(assign?.assignBtnTitle))}
        />
      )}
      {mom?.enabled && (
        <TooltipIconButton
          onClick={mom?.onMom}
          icon={<DescriptionIcon fontSize="small" />}
          title={_.startCase(_.camelCase(mom?.momBtnTitle))}
        />
      )}
      {cams?.enabled && (
        <TooltipIconButton
          onClick={cams?.onCams}
          icon={<ReceiptLongIcon fontSize="small" />}
          title={_.startCase(_.camelCase(cams?.camsBtnTitle))}
        />
      )}
      {memo?.enabled && (
        <TooltipIconButton
          onClick={memo?.onMemo}
          icon={<TextSnippetIcon fontSize="small" />}
          title={_.startCase(_.camelCase(memo?.memoBtnTitle))}
        />
      )}
    </>
  );
};
