import { Grid} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DirectorsList from './directors-list';
import Shareholdings from './shareholding';

const ShareholdingAndDirectors = (props: any) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12}>
        <Shareholdings {...props} t={t} />
      </Grid>
      <Grid item lg={12}>
        <DirectorsList {...props} />
      </Grid>
    </Grid>
  );
};
export default ShareholdingAndDirectors;
