import { Button } from '@mui/material';
import ActionBar from 'components/styled-components/ActionBar';

const ReviewCamButton = ({ onClick }: any) => {
  return (
    <ActionBar>
      <Button variant="contained" sx={{ p: 1, right: 20 }} onClick={onClick}>
        Review CAM
      </Button>
    </ActionBar>
  );
};

export default ReviewCamButton;
