import { Box } from '@mui/material';
import TableHeading from 'components/common/table-header';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import DebtDetails from './debt-detail';
import DebtServiceability from './debt-serviceability';
import ForeignCurrencyExposure from './foreign-currency-exposure';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  debtDetailsData: any;
  debtServiceabilityData: any;
  foreignCurrencyExposureData: any;
}
function LeverageDetails(props: IProps) {
  const { state } = useLocation();
  const { t, debtDetailsData, debtServiceabilityData, foreignCurrencyExposureData } = props;
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={t('loanProjection.performanceSummary.leverageDetails')} />
      <Box sx={{ mt: 0.5 }}>
        <DebtDetails {...props} data={debtDetailsData} />
        <DebtServiceability {...props} data={debtServiceabilityData} />
        <ForeignCurrencyExposure {...props} data={foreignCurrencyExposureData} />

        <Box sx={{ display: 'flex', mt: 2 }}>
          <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
          <Box sx={{ width: '75%' }}>
            {getFormFields(
              {
                name: 'leverageDetails.comments',
                component: MULTI_LINE_INPUT,
                label: 'Comment',
              },
              props.control,
              state.viewOnly
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LeverageDetails;
