import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import _ from 'lodash';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function AccountReceivablesQuality({ t, totalYears, data, control }: IProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.accountReceivablesQuality')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Particulars</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <DenseCellSm>Total A/R</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].totalAr)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Allowance for Doubtful Account</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell key={i}>{formatNumber(data[i].allowanceForDoubtfulAccount)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net A/R</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].netAr)}</NumberDenseCell>;
            })}
          </TableRow>

          <TableRow>
            <DenseCellSm>{`Ageing Ratio > 90days`}</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].agingRatioGrTh180Days)}</NumberDenseCell>;
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
