import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const TableInput = ({
  control,
  name,
  defaultValue,
  isComment = false,
  placeholder,
  style,
  disabled,
  rules,
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => {
        if (isComment) {
          return (
            <TextField
              multiline
              {...field}
              disabled={disabled}
              variant="outlined"
              sx={{
                padding: '7px 11px',
                width: '100%',
                p: 1,
                pb: 1,
                '& .MuiTextField-root': {
                  borderRadius: 0,
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0,
                },
                ...style,
              }}
              placeholder={placeholder}
            />
          );
        }
        return (
          <TextField
            {...field}
            variant="standard"
            disabled={disabled}
            sx={{
              width: '100%',
              p: 1,
              borderBottom: 0,
              '& .MuiTextField-root': {
                borderRadius: 0,
              },
              '& .MuiInput-root': {
                borderRadius: 0,
                padding: '7px 11px',
                width: '12rem',
                fontSize: '11px',
                borderBottom: 0,
              },
              ...style,
            }}
            InputProps={{
              disableUnderline: true,
            }}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default TableInput;
