import { Box } from '@mui/material';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { useMemo, useState, useEffect, useCallback } from 'react';
import DataTable from 'components/common/data-table';

const CorporateGovPractices = ({ getValues, setValue, watchAllFields }: any) => {
  const { state } = useLocation();
  const [rowData, setRowData] = useState<any>([]);
  const corpGovPracticeColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'facets',
        headerName: 'Facets',
        sortable: false,
        suppressMenu: true,
        width: 10,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        sortable: false,
        suppressMenu: true,
        editable: state?.isEdit,
      },
    ];
    return colDef;
  }, []);

  useEffect(() => {
    setRowData(() => getValues('regulatorsRequirement.corpGovtPractices'));
  }, [watchAllFields]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setRowData(updatedRowData);
    setValue('regulatorsRequirement.corpGovtPractices', updatedRowData);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={corpGovPracticeColDef}
          autoSize={true}
          rowData={rowData}
          domLayout={'autoHeight'}
          sizeToFit={true}
          pagination={false}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
  );
};

export default CorporateGovPractices;
