import { Grid, Box } from '@mui/material';
import _ from 'lodash';
import { getFormFields } from 'utils/form-utils';
import { DATE_PICKER, DROPDOWN } from 'constants/form-components';
import useRefData from 'hooks/ref-data';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRefDataByParentRef } from 'services/common-services';
import { getLoanRequestByLoanRequestId } from 'services/loan-request-services';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {}

export default function TermLoans({ control, watchAllFields, setValue }: IProps) {
  const { refData } = useRefData();
  const [loanSubTypes, setLoanSubTypes] = useState<any>([]);
  const [selectedLoanType, setSelectedLoanType] = useState<any>('');
  const [isProjectLoan, setIsProjectLoan] = useState(false);
  const { state } = useLocation();

  const { data: loanRequestData } = useQuery(
    'getLoanRequestByLoanRequestId',
    () => getLoanRequestByLoanRequestId(state?.loanRequestId),
    {
      onSuccess: (res: any) => {
        console.log('getLoanRequestByLoanRequestId', res);
        setSelectedLoanType(res.data.loanType);
      },
    }
  );

  const parentRefData = useQuery('getRefDataByParentRef', () => getRefDataByParentRef(selectedLoanType), {
    enabled: false,
    onSuccess: (res: any) => {
      setLoanSubTypes(res);
      setIsProjectLoan(false);
      setValue('assessmentLimits.termLoans.typeOfLoan.loanType', loanRequestData.data.loanSubType);
      res.forEach((obj: any) => {
        if (loanRequestData.data.loanSubType == obj.id && obj.displayText == 'Project Loan') {
          setIsProjectLoan(true);
        }
      });
    },
  });

  useEffect(() => {
    if (selectedLoanType) parentRefData.refetch();
  }, [selectedLoanType]);

  return (
    <Grid container sx={{ mb: 0.5 }}>
      {loanSubTypes && (
        <Grid item xs={6}>
          <Box>
            <SimpleFormLabel label={'Type Of Loans'} sx={{ width: '40%' }} />
            {getFormFields(
              {
                component: DROPDOWN,
                name: 'assessmentLimits.termLoans.typeOfLoan.loanType',
                options: loanSubTypes,
              },
              control,
              true
            )}
          </Box>
        </Grid>
      )}
      {isProjectLoan && (
        <Grid item xs={6}>
          <Box>
            <SimpleFormLabel label={'If Project Loan-Commencement Date'} sx={{ width: '50%' }} />
            {getFormFields(
              {
                component: DATE_PICKER,
                name: 'assessmentLimits.termLoans.typeOfLoan.commencementDate',
                type: 'date',
              },
              control
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
