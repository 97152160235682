import { Box } from '@mui/material';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { FC } from 'react';
import AccountReceivablesQuality from './account-receivables-quality';
import TurnoverRatio from './turnover-ratio';
import WorkingCapitalManagement from './working-capital-management';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  workingCapitalData: any;
  turnoverData: any;
  accReceivableData: any;
}

const ActivityEfficiency: FC<IProps> = (props) => {
  const { state } = useLocation();
  const { t, workingCapitalData, turnoverData, accReceivableData } = props;
  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={t('loanProjection.performanceSummary.activityAndEfficiency')} />
        <Box sx={{ mt: 0.5 }}>
          <WorkingCapitalManagement data={workingCapitalData} {...props} />
          <TurnoverRatio data={turnoverData} {...props} />
          <AccountReceivablesQuality data={accReceivableData} {...props} />
          <Box sx={{ display: 'flex', mt: 2 }}>
            <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '75%' }}>
              {getFormFields(
                {
                  name: 'activityEfficiency.comments',
                  component: MULTI_LINE_INPUT,
                  label: 'Comment',
                },
                props.control,
                state.viewOnly
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ActivityEfficiency;
