import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import DateRangeIcon from '@mui/icons-material/DateRange';

const inputStyle = {
  '& .MuiInputBase-input': {
    padding: '7px 11px',
    width: '8.930rem',
    fontSize: '12px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
  },
};

interface IYearPicker {
  name: string;
  control: Control<any>;
  disabled?: boolean;
  sx?: any;
  rules?: any;
  minDate?: any;
  maxDate?: any;
}

const CustomDateRangeIcon = () => {
  return <DateRangeIcon fontSize="small" />;
};

const YearPicker = ({ name, control, disabled, sx = { margin: 0 }, rules, minDate, maxDate, ...input }: IYearPicker) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ margin: 0 }}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <DesktopDatePicker
              components={{
                OpenPickerIcon: CustomDateRangeIcon,
              }}
              minDate={minDate || undefined}
              maxDate={maxDate || undefined}
              disabled={disabled}
              views={['year']}
              inputFormat="yyyy"
              value={`${field.value}`}
              onChange={field.onChange}
              renderInput={(params) => (
                <TextField sx={{ ...sx, ...inputStyle }} value={field.value} {...params} margin="dense" />
              )}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default YearPicker;
