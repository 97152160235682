import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { getMeta, viewDocument } from 'services/document-services';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';
import { toggleLoader } from 'state/reducers/common-actions';

const RiskRationaleDoc = () => {
  const [isLoading, setLoading] = useState(false);
  const [riskRationaleDocPDF, setRiskRationaleDocPDF] = useState<any>();
  const { state } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      const data = await getMeta(state?.loanRequestId, 'RISKRATIONALE', 'LOAN_REQUEST');
      if (data?.id) {
        const riskRationaleFile = await viewDocument(data?.id);
        const file = new File([riskRationaleFile], `RISKRATIONALE_${state?.loanRequestId}`, {
          type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(file);
        data && setRiskRationaleDocPDF(`${url}#view=FitH`);
      }
      dispatch(toggleLoader(false));
      setLoading(false);
    }
    setLoading(true);
    dispatch(toggleLoader(true));
    fetchData();
  }, [state?.loanRequestId]);

  return (
    <div style={{ alignItems: 'center' }}>
      {riskRationaleDocPDF && (
        <>
          <object data={riskRationaleDocPDF} type="application/pdf" style={{ width: '100%', height: '100vh' }}>
            <Alert severity="warning" variant="outlined" sx={{ height: '100vh' }}>
              <AlertTitle>
                <Typography variant="h6">Warning</Typography>
              </AlertTitle>
              <Typography variant="subtitle1">Risk Rationale is not yet created, please submit form.</Typography>
            </Alert>
          </object>
        </>
      )}
      {!isLoading && !riskRationaleDocPDF && (
        <Alert severity="warning" variant="outlined" sx={{ height: '100vh' }}>
          <AlertTitle>
            <Typography variant="h6">Warning</Typography>
          </AlertTitle>
          <Typography variant="subtitle1">Risk Rationale is not yet created, please submit form.</Typography>
        </Alert>
      )}
      {isLoading && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{ height: '100vh', '& .MuiAlert-message': { width: '100%' } }}>
          <AlertTitle>
            <LinearProgress color="inherit" />
          </AlertTitle>
          <Typography variant="subtitle1">Please wait while Risk Rationale get loaded</Typography>
        </Alert>
      )}
    </div>
  );
};

export default RiskRationaleDoc;
