import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = { "rows": {} };

const formula = createSlice({
	name: 'formula',
	initialState,
	reducers: {
		updateFormula: (state, action: PayloadAction<any>) => {
			state.rows = action.payload
		}
	},
});

const { actions, reducer } = formula;

export const { updateFormula } = actions;

export default reducer;
