import { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { useMutation, useQueries, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ProjectionLayout from '../../layout/projection-layout';
import LoanProposalMainMenu from './projection-menu';
import { useDispatch, useSelector } from 'react-redux';
import { storeLoanProposalDetails, storeLoanRequestData } from './loan-proposal-reducer';
import { RootState } from '../../../state/store';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import { approveReject, assignUser, approveApprovalAuthorityTask } from 'services/approval-service';
import { getLoanRequestByLoanRequestId } from 'services/loan-request-services';
import { Button } from '@mui/material';
import { BreadCrumbs } from 'components/common/pages/BreadCrumbs';
import ActionBar from 'components/styled-components/ActionBar';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import DialogWithUser from 'components/common/modals/DialogWithUser';
import { riskTeam } from 'services/approval-service';
import useLoanTable from 'hooks/useLoanTable';

const LoanProjection = (props: any) => {
  const { getRequestStatusCode, getHomePage, getAccessMode } = useLoanTable();
  const { data } = useQuery(['riskTeam'], () => riskTeam());
  const [open, setOpen] = useState(false);
  const [authorityOpen, setAuthorityOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { statusCode, viewOnly, accessMode } = state;

  useQueries([
    {
      queryKey: 'getLoanRequestByLoanRequestId',
      queryFn: () => getLoanRequestByLoanRequestId(state?.loanRequestId),
      onSuccess: (res: any) => {
        if (res.status === 200) {
          dispatch(storeLoanRequestData(res.data));
        }
      },
    },
  ]);

  const approveRejectMutation = useMutation((approvalData: any) => approveReject(approvalData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(
        `Loan Request ID : ${state?.loanRequestId} has been ${
          variables?.approvalStatus ? 'Approved' : 'Returned'
        }`,
        {
          variant: 'success',
        }
      );
      navigate(getHomePage());
    },
    onError: (data, variables) => {
      enqueueSnackbar(
        `Failed to ${variables?.approvalStatus ? 'approve' : 'return'} Loan Request ID : ${state?.loanRequestId}`,
        {
          variant: 'error',
        }
      );
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const assignUserMutation = useMutation((userId: any) => assignUser(state?.loanRequestId, userId), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`Loan Request ID : ${state?.loanRequestId} has been assigned to ${variables} `, {
        variant: 'success',
      });
      navigate(getHomePage());
    },
    onError: (data, variables) => {
      enqueueSnackbar(`Failed to assign user for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const approveApprovalAuthorityMutation = useMutation(
    (loanRequestDetail: any) => approveApprovalAuthorityTask(loanRequestDetail),
    {
      onSuccess: (data, variables) => {
        enqueueSnackbar(
          `Loan Request ID : ${state?.loanRequestId} has been ${
            variables?.approvalStatus ? 'Approved' : 'Returned'
          }`,
          {
            variant: 'success',
          }
        );

        navigate(getHomePage());
      },
      onError: (data, variables) => {
        enqueueSnackbar(
          `Failed to ${variables?.approvalStatus ? 'approve' : 'return'} Loan Request ID : ${
            state?.loanRequestId
          }`,
          {
            variant: 'error',
          }
        );
      },
      onSettled: (data) => {
        dispatch(toggleLoader(false));
      },
    }
  );

  const onClickAuthorityHandler = (comment: string) => {
    if (state?.loanRequestId && approveReturn != null && comment && state?.flowableTaskId) {
      approveApprovalAuthorityMutation.mutate({
        approvalStatus: approveReturn,
        loanId: state?.loanRequestId,
        approvalComment: comment,
        approvalTaskId: state?.flowableTaskId,
      });
    }
  };

  const handleClickOpen = (approveReturn: boolean | null) => {
    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      if (statusCode === getRequestStatusCode('PENDING_FOR_BUS_SUP_APPROVAL')) {
        setMessage('Confirmation');
        setOpen(true);
      } else if (statusCode === getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT')) {
        setMessage('Assignment');
        setAssignOpen(true);
      } else if (statusCode === getRequestStatusCode('PENDING_WITH_APPROVAL_AUTHORITY')) {
        setMessage('Confirmation');
        setAuthorityOpen(true);
      } else {
        setMessage('Confirmation');
        setOpen(true);
      }
    }
  };

  const onClickHandler = () => {
    if (state?.loanRequestId && approveReturn != null) {
      approveRejectMutation.mutate({ approvalStatus: approveReturn, loanId: state?.loanRequestId });
    } else {
      enqueueSnackbar(`Failed to approve or return`, {
        variant: 'error',
      });
    }
  };

  const onClickAssignHandler = (user: string) => {
    if (state?.loanRequestId && user) {
      assignUserMutation.mutate(user);
    } else {
      enqueueSnackbar(`Failed to assign`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (state) {
      dispatch(storeLoanProposalDetails({ state }));
    }
  }, [state]);

  return (
    <ProjectionLayout>
      <Box>
        <BreadCrumbs
          title={t('screenHeaders.loanProposal.heading')}
          loanRequestId={state?.loanRequestId}
          company={state?.company}
          amount={state?.amount}
          route={getHomePage()}
        />
        <LoanProposalMainMenu />
        <Divider />

        {props.children}

        {!state?.viewOnly &&
          (statusCode === getRequestStatusCode('PENDING_FOR_BUS_SUP_APPROVAL') ||
            statusCode === getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') ||
            statusCode === getRequestStatusCode('PENDING_WITH_APPROVAL_AUTHORITY')) && (
          // || statusCode === getRequestStatusCode('REWORK_WITH_RM')
            <ActionBar>
              <>
                <Button variant="contained" onClick={() => handleClickOpen(false)} sx={{ p: 1, right: 20 }}>
                  Return
                </Button>
                <Button
                  type="submit"
                  onClick={() => handleClickOpen(true)}
                  variant="contained"
                  sx={{ p: 1, right: 10 }}>
                  {statusCode === getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') ? 'Assign' : 'Approve'}
                </Button>
              </>
            </ActionBar>
          )}

        <DialogWithComment
          open={open}
          setOpen={setOpen}
          title={message}
          description={`Do you want to ${approveReturn ? 'Approve' : 'Return'} for Loan Request ID: ${
            state?.loanRequestId
          } ?`}
          type={'info'}
          onClickHandler={onClickHandler}
        />
        <DialogWithComment
          open={authorityOpen}
          setOpen={setAuthorityOpen}
          title={message}
          description={`Do you want to ${approveReturn ? 'Approve' : 'Return'} for Loan Request ID: ${
            state?.loanRequestId
          } ?`}
          type={'info'}
          onClickHandler={onClickAuthorityHandler}
        />
        <DialogWithUser
          open={assignOpen}
          setOpen={setAssignOpen}
          title={message}
          description={`Please assign user for Loan Request ID: ${state?.loanRequestId} ?`}
          type={'info'}
          onClickHandler={onClickAssignHandler}
          users={data}
        />
      </Box>
    </ProjectionLayout>
  );
};

export default LoanProjection;
