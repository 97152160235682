import { useSelector } from 'react-redux';
import _ from 'lodash';
import InputForm from '../InputForm';
import { RootState } from '../../../../../../../state/store';
import formKeyPerformanceTemplate from './formKeyPerformanceTemplate.json';
import { getRowTemplate, getFormDataFromState, getForm } from '../../common/FormUtil';
import { FormGroup, FormType, FormGroupType, FormGroupI } from '../../../../../../../types/inputform.d';

export const generateFormKeyPerformance = (group: FormGroupType, keyPerformanceCells: any, dbJson: any, loanDetails: any) => {
  
  const formCells = _.cloneDeep(keyPerformanceCells);
  
  let allRows: any[] = [];  
  let formDataCells: any;

  if (!_.isEmpty(formCells)) {
    formDataCells = getFormDataFromState(formCells, group)
  } else if (_.isEmpty(formCells)) {
    const formKeyPerformance = _.cloneDeep(formKeyPerformanceTemplate) as any

    // Total Rows //
    allRows = getRowTemplate('label', formKeyPerformance)

    formDataCells = getForm(formCells, FormType[FormType.FORMKEYPERFORMANCE].toLowerCase(), group, allRows, dbJson, loanDetails)

  }

  
  // if (group === FormGroup.PROJECTION) {
  //   Object.entries(formJson).map(([, cell]) => cell?.expr && cellUpdate(copyCells, cell, cell?.expr));
  // }
  return formDataCells
}

export const FormKeyPerformance = ({ group }: FormGroupI) => {

  const cells = useSelector((state: RootState) => state?.inputscreen?.cells![FormType[FormType.FORMKEYPERFORMANCE].toLowerCase()]);

  if (!group || !cells) return <></>

  const cellData: any = {}

  const actualsCells = Object.fromEntries(Object.entries(cells).filter(([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase()))  
  _.merge(cellData, actualsCells)

  if (group === FormGroup.PROJECTION) {
    const projectionCells = Object.fromEntries(Object.entries(cells).filter(([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()))
    _.merge(cellData, projectionCells)
  } 
  
  return <InputForm cells={cellData} />
};

