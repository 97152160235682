import { useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';

const GroupFinancials = ({
  watchAllFields,
  getValues,
  setValue,
}: {
  watchAllFields: any;
  getValues: any;
  setValue: any;
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const theme = useTheme();

  const defaultColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'company',
        headerName: 'Company',
        editable: state?.isEdit,
      },
      {
        field: 'business',
        headerName: 'Business',
        editable: state?.isEdit,
      },
      {
        headerName: 'Key financials for Group Companies for the Year',
        children: [
          {
            field: 'fy20',
            headerName: 'Year',
            editable: state?.isEdit,
          },
          {
            field: 'toi',
            headerName: 'TOI',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'pat',
            headerName: 'PAT',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'tnw',
            headerName: 'TNW',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'atnw',
            headerName: 'TD/ATNW',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'nca',
            headerName: 'TD/NCA',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      },
    ];
    if (state?.isEdit) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('companyAndGroupDetails.groupFinancials').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('companyAndGroupDetails.groupFinancials'), {}];
                      setValue('companyAndGroupDetails.groupFinancials', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('companyAndGroupDetails.groupFinancials').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('companyAndGroupDetails.groupFinancials')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('companyAndGroupDetails.groupFinancials', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('companyAndGroupDetails.groupFinancials', updatedRowData);
  }, []);

  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle
          formNumbering="1.5"
          title={t('loanProjection.businessBackgroud.companyDetails.groupFinancials')}></FormTitle>
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={watchAllFields?.companyAndGroupDetails?.groupFinancials}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>
    </>
  );
};

export default GroupFinancials;
