import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = { cells: {} };

const financeInputScreen = createSlice({
  name: 'financeInputScreen',
  initialState,
  reducers: {
    updateFinanceInputScreen: (state, action: PayloadAction<any>) => {
      state.cells = action.payload;
    },
  },
});

const { actions, reducer } = financeInputScreen;

export const { updateFinanceInputScreen } = actions;

export default reducer;
