import Box from '@mui/material/Box';
import { ILayoutProps } from '../../models/layoutModal';
import { MainBox } from './styles';
import MainMenuTabs from 'components/dashboard/MainMenuTabs';

export default function Layout({
  children,
  Drawer,
  showMenu = true,
  sx = { marginLeft: '12px', marginRight: '12px', marginTop: '12px', width: '100vw' },
}: ILayoutProps) {
  return (
    <Box>
      <Box>{showMenu && <MainMenuTabs />}</Box>
      <MainBox component="main">
        <Box sx={{ display: 'flex' }}>
          {Drawer}
          <Box sx={sx}>{children}</Box>
        </Box>
      </MainBox>
    </Box>
  );
}
