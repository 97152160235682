import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const approveWithDocList = async (data: any) => {
  const response = await httpService.post('/workflow/legalManagerCompletionForDocPrep', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const approveWithDocUpload = async (data: any) => {
  const response = await httpService.post('/workflow/legalManagerCompletionForDocUpload', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const getLegalDocDetails = async (loanId: any) => {
  const resposne = await httpService.get(`/loanProposal/legalDocDetails/${loanId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return resposne.data;
};

export const postLegalDocDetails = async (loanId: any, data: any) => {
  const response = await httpService.post(`/loanProposal/legalDocDetails/${loanId}`, data?.documentDetails, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};
