import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentLayout from 'components/common/containers/content-layout';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import { COLOR_WHITE, PRIMARY_COLOR } from 'constants/color';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { Row, SubRow, SubSubRow } from '../../../../../common/table/table-data-row/rows';
import useLocalStore from 'hooks/user-local-store';
import { roles, routes } from 'constants/strings';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { changeMenuTab } from 'state/reducers/tabReducer';

const FeeIncome = () => {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formV'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );
  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form5, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form5[idx].actuals) {
      return data?.form5[idx].actuals;
    }
    return data?.form5[idx]?.projection;
  });

  return (
    <ContentLayout title="FORM VI- FUNDS FLOW STATEMENT">
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: PRIMARY_COLOR }}>
            <HeadingDenseCell
              sx={{
                color: COLOR_WHITE,
                '&.MuiTableCell-root': {
                  width: 500,
                },
              }}>
              Assessment of working capital requirement
            </HeadingDenseCell>
            {_.map(numberOfYears, (year: string, idx: number) => {
              return (
                <HeadingDenseCell sx={{ color: COLOR_WHITE }} align="right" key={`$${year}-${idx}`}>
                  {year}
                </HeadingDenseCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell colSpan={10}>Sources</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Net Profit"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
              </DenseCellSm>
            ))}
          />

          <SubRow
            title="Depreciation"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.otherCurrentLiabilities}
              </DenseCellSm>
            ))}
          />

          <SubRow
            title="Increase in Capital"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.workingCapitalGap}
              </DenseCellSm>
            ))}
          />

          <SubRow
            title="Increase in Term Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.minStipulatedNetWorkingCapital}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Decrease in"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.actualNetWorkingCapital}
              </DenseCellSm>
            ))}
          />
          <SubSubRow
            title="Fixed Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.item3MinusItem4}
              </DenseCellSm>
            ))}
          />

          <SubSubRow
            title="Other non-current Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.item3MinusItem5}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase in networth"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.maxPermissibleBankFinance}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Total Sources"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].firstMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <TableRow>
            <HeadingDenseCell colSpan={10}>USES</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Net loss"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.totalCurrentAsset}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Decrease in Term Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.otherCurrentLiabilities}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase in"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.workingCapitalGap}
              </DenseCellSm>
            ))}
          />
          <SubSubRow
            title="Fixed Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.minStipulatedNetWorkingCapital}
              </DenseCellSm>
            ))}
          />
          <SubSubRow
            title="Other non-current Assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.actualNetWorkingCapital}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Dividend Payments"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.item3MinusItem4}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Decrease in networth"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.item3MinusItem5}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Total Uses"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.maxPermissibleBankFinance}
              </DenseCellSm>
            ))}
          />

          <Row
            title="Long Term Surplus (+) / Deficit (-) [1-2]"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase/decrease in current assets (as per details given below)*"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase/decrease in current liabilities other than bank borrowings"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase/decrease in working capital gap"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Net Surplus / Deficit (-) [3-6]"
            heading
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase/decrease in bank borrowings"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <Row
            title="Increase/decrease in NET SALES"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <TableRow>
            <HeadingDenseCell colSpan={10}>* Break up of item-4</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Increase/decrease in Raw Materials"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase/decrease in Stocks-in-Process"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase/decrease in Finished Goods"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase/decrease in Receivables"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubSubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubSubRow
            title="Export"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase/decrease in Stores & Spares"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
          <SubRow
            title="Increase/decrease in other current assets"
            data={_.map(numberOfYears, (_, idx: number) => (
              <DenseCellSm key={`${idx}`} align="right">
                {dataArr[idx].secondMethodOfLending.excessBorrowing}
              </DenseCellSm>
            ))}
          />
        </TableBody>
      </Table>
      <ReviewCamButton
        onClick={() => {
          navigate(routes.CAM_ROUTE, {
            state: { ...state, loanRequestId: state.loanRequestId },
          });
          const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
          camId && camId?.id && dispatch(changeMenuTab(camId?.id));
        }}
      />
    </ContentLayout>
  );
};

export default FeeIncome;
