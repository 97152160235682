import { useCallback } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import _ from 'lodash';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { getFormFields } from 'utils/form-utils';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { useLocation } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import { useMemo } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';

const GroupSupport = ({ control, t, watchAllFields, getValues, setValue }: any) => {
  const { state } = useLocation();
  const { applicantBasicDetails } = useSelector((state: RootState) => state.projections);

  const { actualsYears } = applicantBasicDetails;
  const actuals = actualsYears && [...actualsYears].sort();
  const theme = useTheme();

  const defaultColDefs = useMemo(() => {
    var defaultObj: any = [
      {
        headerName: 'Item Name',
        field: 'particulars',
        editable: state?.isEdit,
      },
    ];
    actuals?.forEach((year: string, idx: number) => {
      let obj = {
        headerName: `${year}`,
        children: [
          {
            field: `intersegment_${year}`,
            headerName: 'Inter segment',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: `externalSale_${year}`,
            headerName: 'External sale',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: `total_${year}`,
            headerName: 'Total',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      };
      defaultObj.push(obj);
    });

    if (state?.isEdit) {
      defaultObj.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex ===
                getValues('productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport')
                  .length -
                  1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [
                        ...getValues(
                          'productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport'
                        ),
                        {},
                      ];
                      setValue(
                        'productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport',
                        fields
                      );
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport')
                .length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [
                      ...getValues(
                        'productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport'
                      ),
                    ];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue(
                        'productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport',
                        updatedFields
                      );
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }

    return defaultObj;
  }, [actuals]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('productionPerformanceAnalysis.rawMaterialAndProcurementArrangement.groupSupport', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5, mt: 0.5 }}>
      <Box>
        <Typography variant="subtitle2">
          {t('loanProjection.businessBackgroud.productionPerformance.groupSupport')}
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={defaultColDefs}
          autoSize={true}
          rowData={
            watchAllFields?.productionPerformanceAnalysis?.rawMaterialAndProcurementArrangement?.groupSupport
          }
          pagination={false}
          domLayout={'autoHeight'}
          sizeToFit={true}
          style={{ height: '100%' }}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        {getFormFields(
          {
            component: MULTI_LINE_INPUT,
            name: 'comments.groupSupport',
            label: 'Comment',
          },
          control,
          !state?.isEdit
        )}
      </Box>
    </Box>
  );
};

export default GroupSupport;
