import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILoanProposalState {
  loanRequestId: number | null;
  companyId: number | null;
  company: string | null;
  amount: number | null;
  loanRequestData: any;
}

const initialState = {
  loanRequestId: null,
  companyId: null,
  company: null,
  amount: null,
  loanRequestData: {},
};

const LoanProposalReducer = createSlice({
  name: 'loanProposal',
  initialState,
  reducers: {
    storeLoanProposalDetails: (state: ILoanProposalState, action: PayloadAction<any>) => {
      return (state = { ...state, ...action.payload.state });
    },

    storeLoanRequestData: (state: ILoanProposalState, action: PayloadAction<any>) => {
      state.loanRequestData = action.payload;
    },
  },
});

const { reducer, actions } = LoanProposalReducer;

export const { storeLoanProposalDetails, storeLoanRequestData } = actions;
export default reducer;
