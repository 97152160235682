import { useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import AddIcon from '@mui/icons-material/Add';
import { getPortfolioData, savePortfolio } from 'services/common-services';
import ActionBar from 'components/styled-components/ActionBar';

const Portfolio = () => {
  const { state } = useLocation();
  const [rowData, setRowData] = useState([]);

  const portfolioColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'id',
        headerName: 'SL.No',
      },
      {
        field: 'cif',
        headerName: 'CIF',
        editable: true,
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        editable: true,
      },
      {
        field: 'customerGroup',
        headerName: 'Customer Group',
        editable: true,
      },
      {
        field: 'teamLeadId',
        headerName: 'TL Name',
        editable: true,
      },
      {
        field: 'rmUserId',
        headerName: 'RM Local Name',
        editable: true,
      },
      {
        field: 'customerType',
        headerName: 'Customer Type',
        editable: true,
      },
      {
        field: 'leadSource',
        headerName: 'Lead Source',
        editable: true,
      },
      {
        field: 'industry',
        headerName: 'Industry',
        editable: true,
      },
      {
        field: 'subIndustry',
        headerName: 'Sub-Industry',
        editable: true,
      },
      {
        field: 'facilityType',
        headerName: 'Facility Type',
        editable: true,
      },
      {
        field: 'opportunityApproach',
        headerName: 'Opportunity Approach',
        editable: true,
      },
      {
        field: 'ccy',
        headerName: 'CCY',
        editable: true,
      },
      {
        field: 'bidrLimit',
        headerName: 'BIDR Limit',
        editable: true,
        width: 150,
      },
      {
        field: 'bidrOs',
        headerName: 'BIDR O/S(Equiv)',
        editable: true,
      },
      {
        field: 'ca',
        headerName: 'CA',
        editable: true,
      },
      {
        field: 'sa',
        headerName: 'SA',
        editable: true,
      },
      {
        field: 'totalCasa',
        headerName: 'Total CASA',
        editable: true,
      },
      {
        field: 'companyContact',
        headerName: 'Company Contact',
        editable: true,
      },
      {
        field: 'opportunityApproach',
        headerName: 'Opportunity Approach',
        editable: true,
      },
    ];
    return colDef;
  }, []);

  const { data: portfolioViewData, refetch } = useQuery('getPortfolioData', () => getPortfolioData(), {
    onSuccess: (data: any) => {
      setRowData(data);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  // const _renderAddButton = () => {
  //   return (
  //     <Box sx={{ width: '100%' }}>
  //       <Button
  //         sx={{ p: 0, pl: 1, pr: 1, mt: 1, mb: 1, float: 'right' }}
  //         onClick={() => {}}
  //         variant="outlined"
  //         startIcon={<AddIcon fontSize="small" />}>
  //         Add new row
  //       </Button>
  //     </Box>
  //   );
  // };

  const handleSave = () => {
    let portfolio: any = savePortfolio(rowData);
  };

  return (
    <>
      <Box sx={{ p: 1, height: 'calc(100vh - 180px)' }}>
        <Box sx={{ height: '100%', mt: 1 }}>
          <DataTable
            columnDefs={portfolioColDef}
            rowData={rowData}
            suppressColumnVirtualisation={true}
            paginationPageSize={15}
          />
        </Box>
        <ActionBar>
          <Button variant="contained" sx={{ mr: 2, p: 1 }} type="submit" onClick={handleSave}>
            Save Draft
          </Button>
        </ActionBar>
      </Box>
    </>
  );
};

export default Portfolio;
