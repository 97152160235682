import { combineReducers } from 'redux';
import tabReducer from './tabReducer';
import sideBarReducer from './sideBarReducer';
import inputscreen from '../../components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable/inputScreenSlice';
import financeInputscreen from '../../components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Finance/FinanceTable/inputScreenSlice';
import formula from '../../components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable/formulaSlice';
import financeFormula from '../../components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Finance/FinanceTable/formulaSlice';
import common from './common-actions';
import projections from '../../components/dashboard/loan-projection-and-proposal/projection-components/financial-projection-reducer';
import user from './user-reducer';
import LoanProposalReducer from '../../components/dashboard/loan-projection-and-proposal/loan-proposal-reducer';
import RegisterCompanyReducer from 'components/dashboard/credit-analysis/company-master/register-company-form/register-company-reducer';
import sanctionReducer from './sanctionReducer';

const rootReducer = combineReducers({
  mainMenu: tabReducer,
  sidebar: sideBarReducer,
  common,
  inputscreen,
  financeInputscreen,
  projections,
  formula,
  financeFormula,
  user,
  loanProposal: LoanProposalReducer,
  registerCompany: RegisterCompanyReducer,
  sanction: sanctionReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
