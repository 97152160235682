import Layout from '../../layout/layout';
import { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { useLocation,useNavigate } from 'react-router-dom';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ActionCell } from 'components/common/data-table/table-action-cell';
import DataTable from 'components/common/data-table';
import ContentLayout from 'components/common/containers/content-layout';
import {  getInProgressRequests,
  getClosedRequests,
  getPendingRequests,
  getTeamRequests } from 'services/loan-request-services';
import BackdropLoader from 'components/common/backdrop-loader';
import { useTranslation } from 'react-i18next';
import { LOAN_TABLE } from '../credit-analysis/loan-proposal/LoanProposalConsts';
import useLoanTable from 'hooks/useLoanTable';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { routes } from 'constants/strings';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


export default function Legal() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { username }: any = useSelector((state: RootState) => state?.user?.userDetails);
  const { getLoanRequestState, _requestData, getRequestStatusCode, getAccessMode } = useLoanTable();
  const { state: tabState, pathname } = useLocation();
  const [tab, setTab] = useState('MY_REQUEST');
  const [myRequestTab, setMyRequestTab] = useState('PENDING_ACTIONS');
  
  const { data: inProgressRequestsData } = useQuery(['getInProgressRequests', username], getInProgressRequests);
  const { data: closedRequestsData } = useQuery(['getClosedRequests', username], getClosedRequests);
  const { data: pendingRequestsData } = useQuery(['getPendingRequests', username], getPendingRequests);
  const { data: teamRequestsData } = useQuery(['getTeamRequests', username], getTeamRequests);

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleMyRequestTab = (event: React.SyntheticEvent, newValue: string) => {
    setMyRequestTab(newValue);
  };

  const getPath = (loanData: any) => {
    let path = routes.DOCUMENT_LIST_ROUTE;
    if (loanData?.statusCode === getRequestStatusCode('PENDING_WITH_LEGAL_DOC_PREP')) {
      path = routes.DOCUMENT_LIST_ROUTE;
    } else if (loanData?.statusCode === getRequestStatusCode('PENDING_WITH_LEGAL_DOC_UPLOAD')) {
      path = routes.FINAL_DOCUMENT_ROUTE;
    }
    return path;
  };

  const gotoLoanRequest = (loanData: any, viewOnly = true, isEdit = false) => {
    navigate(getPath(loanData), {
      state: {
        ...getLoanRequestState(loanData, viewOnly, isEdit),
      },
    });
  };


  useEffect(() => {
    tabState?.tabId && setTab("MY_REQUEST");
    tabState?.tabId && setMyRequestTab(tabState?.tabId);

  }, [tabState?.tabId]);

  const isViewEnabled = (loanData:any) =>{
    return (loanData?.accessMode === getAccessMode('READ_ONLY') && 
                loanData?.statusCode !== getRequestStatusCode('PENDING_WITH_LEGAL_DOC_PREP') &&
                loanData?.statusCode !== getRequestStatusCode('PENDING_WITH_LEGAL_DOC_UPLOAD') &&
                (myRequestTab !== 'PENDING_ACTIONS' || tab === 'TEAM_REQUEST')) ||
                (loanData?.statusCode === getRequestStatusCode('DRAFT') && tab === 'TEAM_REQUEST')
  }

  const isApproveEnabled = (loanData:any) =>{
    return  loanData?.statusCode === getRequestStatusCode('PENDING_WITH_LEGAL_DOC_PREP') ||
                loanData?.statusCode === getRequestStatusCode('PENDING_WITH_LEGAL_DOC_UPLOAD')
  }

  const ACTION = {
        field: 'action',
        pinned: 'right',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        resizable: false,
        sortable: false,
        filter: false,
        cellRendererFramework: (params: any) => {
          return ActionCell({
            view: {
              viewBtnTitle: 'View',
              onView: () => gotoLoanRequest(params?.data, true, false),
              enabled: isViewEnabled(params?.data)                
            },
            approve: {
              approveBtnTitle: 'Approve',
              onApprove: () => gotoLoanRequest(params?.data, false, isApproveEnabled(params?.data)),
              enabled: isApproveEnabled(params?.data)
               
            },
          });
        },
  }


  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      ...LOAN_TABLE,
      {field:"currentAssignee"},
      ACTION
    ],
    [myRequestTab]
  );

  const completedColumnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      ...LOAN_TABLE,
      ACTION
    ],
    [myRequestTab]
  );

  if (pendingRequestsData?.isLoading) {
    return <BackdropLoader isLoading={pendingRequestsData?.isLoading} />;
  }

  return (
    <Layout>
      <ContentLayout title="Legal Admin">
        <Grid container spacing={1}>
          <Grid item md={12}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTab} aria-label="loan request">
                    <Tab label="My Request" value="MY_REQUEST" />
                    <Tab label="Team Request" value="TEAM_REQUEST" />
                  </TabList>
                </Box>
                <TabPanel value="MY_REQUEST" sx={{ padding: '0px' }}>
                  {
                    <TabContext value={myRequestTab}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleMyRequestTab} aria-label="loan request">
                          <Tab label="Pending Actions" value="PENDING_ACTIONS" />
                          <Tab label="In Progress" value="IN_PROGRESS" />
                          <Tab label="Completed" value="COMPLETED" />
                        </TabList>
                      </Box>
                      <TabPanel value="PENDING_ACTIONS" sx={{ padding: '12px', height: 'calc(100vh - 250px)' }}>
                        {
                          <DataTable
                            columnDefs={columnDefs}
                            autoSize={true}
                            rowData={_requestData(pendingRequestsData)}
                          />
                        }
                      </TabPanel>
                      <TabPanel value="IN_PROGRESS" sx={{ padding: '12px', height: 'calc(100vh - 250px)' }}>
                        {
                          <DataTable
                            columnDefs={columnDefs}
                            autoSize={true}
                            rowData={_requestData(inProgressRequestsData)}
                          />
                        }
                      </TabPanel>
                      <TabPanel value="COMPLETED" sx={{ padding: '12px', height: 'calc(100vh - 250px)' }}>
                        {
                          <DataTable
                            columnDefs={completedColumnDefs}
                            autoSize={true}
                            rowData={_requestData(closedRequestsData)}
                          />
                        }
                      </TabPanel>
                    </TabContext>
                  }
                </TabPanel>
                <TabPanel value="TEAM_REQUEST" sx={{ padding: '12px', height: 'calc(100vh - 250px)' }}>
                  {<DataTable columnDefs={columnDefs} autoSize={true} rowData={_requestData(teamRequestsData)} />}
                </TabPanel>
              </TabContext>
            </Grid>
        </Grid>
      </ContentLayout>
    </Layout>
  );
}
