interface IExposureDetails {
  name: string;
  label: string;
  label2?: string;
}

export const classification: IExposureDetails[] = [
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isCIC',
    label:
      'a)Whether borrower is Core Investment Company (CIC) (please refer RBI Master Circular on Regulatory Framework for CIC dated July 1, 2011)?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isNBFC',
    label: 'Whether borrower is Non Bank Finance Company (NBFC)?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isIFC',
    label:
      'a)Is it an Infrastructure finance company (IFC) as defined by RBI in its circular on NBFCs (RBI/2009-10/316 DNBS.PD. CC No. 168 / 03.02.089 /2009-10) dated  February  12, 2010),in its circular on NBFCs?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.trans',
    label: 'Whether it is related party transaction?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.provision',
    label:
      'Whether Have the provisions of Section 19(2) (whether Bank is not permitted to hold more than 30% of shares company) of the Banking Regulation Act 1949 been complied with?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.unhedged',
    label: 'Whether borrower has unhedged foreign currency exposure?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.takeOver',
    label:
      'Whether the new/enhancement is an account of take over from other Bank/FI? (Takeover may be defined as the sanctioning of a credit facility involving the taking over of the credit facility already availed by the borrower from another Bank/FI, without any change in the purpose of the facility)',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.projectType',
    label: 'Whether this is a Project Loan/Finance?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isClient',
    label: 'Is the promoter and key executive our Private Banking Client (Yes/No)',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.lendingIden',
    label: 'Priority Sector lending opportunity identification*#',
  },
];

export const companyEngagedIn: IExposureDetails[] = [
  {
    name: 'regulatorsRequirement.exposureDetails.companyEngagedIn.norm1',
    label:
      'a)Does the company/group company procure agriculture produce directly from farmers/producer companies/co-operative society?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.companyEngagedIn.norm2',
    label:
      'b) Is there any contract farming/any other arrangement which binds these farmers to the company/group company?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.companyEngagedIn.norm3',
    label: 'c) Is the opportunity of extending farmer finance explored with the company/group company?',
  },
];

export const microAndSme: IExposureDetails[] = [
  {
    name: 'regulatorsRequirement.exposureDetails.microAndSme.sme1',
    label:
      'Is the borrower/ group companies engaged in procuring of goods/ services from Micro and Small Enterprises.?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.microAndSme.sme2',
    label:
      'Is the borrower engaged in any manufacturing activity (including export of goods produced by them) or is in the industry of food and agro based processing? If yes, is the investment (gross block) in plant and machinery less than 5 crore?',
  },
];

export const capitalMarketExposure: IExposureDetails[] = [
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme1',
    label: 'Have the provisions of Section 19 (2) of the Banking Regulation Act 1949 been complied with: ',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme2',
    label: 'Have the provisions of Section 19 (3) of the Banking Regulation Act 1949 been complied with:',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme3',
    label: 'Sharing of information, where borrower is availing credit facilities from more than one banker:',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme4',
    label:
      'Declaration from borrower regarding information with regard to credit facilities availed from other bankers is obtained as per Annexure I of the RBI Circular ‘Lending under consortium arrangement / Multiple Banking arrangements’',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme5',
    label:
      'Quarterly exchange of information about the conduct of the borrowers account with other lending bankers is done as per Annexure II of the RBI Circular Lending under consortium arrangement / Multiple Banking arrangements',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.capitalMarketExposure.cme6',
    label:
      'Half-yearly certification by professional with regard to compliance of various statutory prescriptions is obtained as per Annexure III of the RBI Circular ‘Lending under consortium arrangement / Multiple Banking arrangements',
  },
];
