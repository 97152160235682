import { styled, Typography } from '@mui/material';
import { TableCell } from '@mui/material';
import { SxProps } from '@mui/system';

const StyledCell = styled(TableCell)(({ align }: any) => ({
  paddingLeft: align !== 'right' ? '12px' : 0,
  paddingRight: align === 'right' ? '4px' : 0,
  fontWeight: 700,
}));

interface IProps {
  children: any;
  colSpan?: number;
  sx?: SxProps;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
}
const HeadingDenseCell = ({ children, colSpan, sx, align }: IProps) => {
  return (
    <StyledCell
      padding="none"
      sx={{ ...sx, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
      colSpan={colSpan}
      align={align}>
      <Typography>
        <b>{children}</b>
      </Typography>
    </StyledCell>
  );
};

export default HeadingDenseCell;
