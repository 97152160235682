import _ from 'lodash';
import { Button, Table, TableBody, TableHead, TableRow, TableCell, Typography, Grid, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectionSummary, updateProjectionSummary, generateSensitiveAnalysis } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import KeyPerformance from './key-performance';
import ProfitabilityAssumptions, { ProfitabilityAssumptionsGuarantor } from './profitability-assumption';
import SensitivityAnalysis from './sensitivity-analysis';
import SummaryOfProjection from './summary-of-projection';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import ActionBar from 'components/styled-components/ActionBar';
import { MainCard } from 'components/layout/styles';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import SimpleAccordion from 'components/common/simple-accordian';
import SimpleFormLabel from 'components/common/form-components/form-label';
import Input from 'components/common/form-components/Input';
import { useTheme } from '@mui/material/styles';
import queryClient from 'services/react-query-service';

const ProjectionSummary = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { getRequestStatusCode } = useLoanTable();
  const { control, handleSubmit, setValue, getValues, reset, watch } = useForm({
    defaultValues: {
      keyAssumptions: '',
      keyAssumptionsGuarantor: '',
    },
  });

  const defaultValues = {
      whenSalesGoDownPercentage: 0,
      whenVariableCostGoDownPercentage: 0,
      whenRmCostGoUpPercentage: 0,
      rmPercentagePassedToCustomer: 0,
      othVariableCostGoUpPercentage: 0,
      othVarCostPercentagePassedToCustomer: 0,
      dscrWhenSalesGoDownPercentage: 0,
      dscrWhenVariableCostGoDownPercentage: 0,
      dscrWhenRmCostGoUpPercentage: 0,
      dscrRmPercentagePassedToCustomer: 0,
      dscrOthVariableCostGoUpPercentage: 0,
      dscrOthVarCostPercentagePassedToCustomer: 0,
    };
  const {
    control:projectionControl,
    handleSubmit:projectionHandleSubmit,
    reset:projectionReset,
    setValue:projectionSetValue,
    getValues:projectionGetValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  })
  const watchAllFields = watch();
  const { enqueueSnackbar } = useSnackbar();
  const yearEnded = 'March 31';
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);

  const projectionId = tabs[selectedTab].id;

  const { status }: any = useSelector((state: RootState) => state.loanProposal);

  const { data, isLoading, isError } = useQuery(
    ['getProjectionSummary', projectionId],
    () => getProjectionSummary(state.loanRequestId, projectionId),
    {
      onSuccess: (data) => {
        reset({ ...data });
         if(data?.inputValues){
            projectionReset({...data?.inputValues})  
          }else{
            projectionReset({...defaultValues})
          }   
      },
    }
  );

  const onUpdateProjectionSummary = (formData: any) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        enqueueSnackbar('Projection Summary Updated', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('Error while updating Projection Summary', {
          variant: 'error',
        });
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    });
  };

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  const mutation = useMutation((data: any) => updateProjectionSummary(state.loanRequestId, projectionId, data));
  const generateSensitiveAnalysisMutation = useMutation((data: any) => generateSensitiveAnalysis(data), {
    onSuccess: (data) => {
      enqueueSnackbar('Sensitive Analysis has been configured', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error while configuring Sensitive Analysis', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getProjectionSummary']);
      dispatch(toggleLoader(false));
    },
  });

  const onSensitiveAnalysisSubmit = (data:any) =>{
    if (Object.values(data).some((x:any) => x === null || x === '' || isNaN(parseFloat(x)) || !isFinite(x))) {
      enqueueSnackbar('Please fill all details in number', {
        variant: 'error',
      });
    } else {
      dispatch(toggleLoader(true));
      generateSensitiveAnalysisMutation.mutate({loanId:state.loanRequestId, projectionId, ...data});
    }
    
  };

  if (!isLoading && !isError && data) {
    dispatch(toggleLoader(false));
  }
  if (isLoading) {
    dispatch(toggleLoader(true));
  }
  if (isLoading || !data) {
    return null;
  }

  const _renderProjectionSummary = () => {
    if (isLoading || !data) {
      return null;
    }

    const years: string[] = _.map(data.projectionSummary, (el: any) => el.year);

    const filteredYears = _.filter(years, (year: string) => Boolean(year));

    const summaryOfProjectionData = _.map(data.projectionSummary, (projectionSummary) => {
      if (_.has(projectionSummary, 'actuals')) {
        return projectionSummary.actuals.summaryOfProjection;
      }
      return projectionSummary.projection.summaryOfProjection;
    });

    const summaryOfProjectionGuarantorData = data?.guarantorProjectionSummary
      ? _.map(data.guarantorProjectionSummary, (projectionSummary) => {
          if (_.has(projectionSummary, 'actuals')) {
            return projectionSummary.actuals.summaryOfProjection;
          }
          return projectionSummary.projection.summaryOfProjection;
        })
      : [];

    const keyPerformanceData = _.map(data.projectionSummary, (projectionSummary) => {
      if (_.has(projectionSummary, 'actuals')) {
        return projectionSummary.actuals.keyPerformance;
      }
      return projectionSummary.projection.keyPerformance;
    });

    const sensitivityAnalysisData = _.map(data.projectionSummary, (projectionSummary) => {
      if (_.has(projectionSummary, 'actuals')) {
        return projectionSummary.actuals.sensitivityAnalysis;
      }
      return projectionSummary.projection.sensitivityAnalysis;
    });

    // const sensitivityAnalysisData = _.map(filteredYears, (year: string) => {
    //   return data[year]?.projection?.sensitivityAnalysis;
    // });
    const basicProps = {
      control,
      setValue,
      getValues,
      watchAllFields,
    };

    const Configuration = () => {
      return <> <Box sx={{ pt: 0.5, pb: 0.5, background: theme.palette.primary.main, color: '#ffffff'}} >
       <Typography  variant="subtitle2" sx={{ pt: 0.5, pb: 0.5, paddingLeft:"12px" }}>
            Configuration
        </Typography>
      </Box>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6"  sx={{ paddingLeft: "12px"}}>
              Sensitivity to BEP
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="When sales go down" sx={{ width: '42%' }} />
              <Box>
                <Input name="whenSalesGoDownPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Variable costs also go down by" sx={{ width: '42%' }} />
              <Box>
                <Input name="whenVariableCostGoDownPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>      
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="When RM cost goes up by" sx={{ width: '42%' }} />
              <Box>
                <Input name="whenRmCostGoUpPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% RM cost can be passed on to customer" sx={{ width: '42%' }} />
              <Box>
                <Input name="rmPercentagePassedToCustomer" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Other Variable costs sales go up to" sx={{ width: '42%' }} />
              <Box>
                <Input name="othVariableCostGoUpPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% Other Variable costs can be passed on to customer" sx={{ width: '42%' }} />
              <Box>
                <Input name="othVarCostPercentagePassedToCustomer" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>   
      </Grid>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" sx={{ paddingLeft: "12px"}}>
              Sensitivity to DSCR
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="When Sales go down" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrWhenSalesGoDownPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Variable costs also go down by" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrWhenVariableCostGoDownPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>       
           <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="When RM cost goes up by" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrWhenRmCostGoUpPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% RM cost can be passed on to customer" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrRmPercentagePassedToCustomer" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>       
           <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Other Variable costs sales go up to" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrOthVariableCostGoUpPercentage" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% Other Variable costs can be passed on to customer" sx={{ width: '42%' }} />
              <Box>
                <Input name="dscrOthVarCostPercentagePassedToCustomer" control={projectionControl} type="number" />
              </Box>
            </Box>
          </Grid>    
      </Grid>
        </>
    }

    return (
      <form onSubmit={handleSubmit(onUpdateProjectionSummary)}>
        
          <ActionBar>
           {(state?.statusCode === getRequestStatusCode('DRAFT') ||
            state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
            state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
            !state?.statusCode) && (<>
            <Button sx={{ p: 1, mr: 2 }} variant="contained" onClick={projectionHandleSubmit(onSensitiveAnalysisSubmit)}>
             Generate
            </Button>
            <Button type="submit" variant="contained" sx={{ mr: 2, p: 1 }}>
              Save Draft
            </Button>
            </>)}
            <CamButton
              sx={{ p: 1, mr: 2 }}
              onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
              camState={state}
            />
          </ActionBar>
        <MainCard>
          <Grid container>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'6.1 Projections: Applicant'}
                expand={true}
                children={
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <ProfitabilityAssumptions {...basicProps} />
                    </Grid>
                    <Grid item md={12}>
                      <SummaryOfProjection
                        totalYears={filteredYears}
                        data={summaryOfProjectionData}
                        yearEnded={yearEnded}
                        title="6.1.b Summary Projections"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <KeyPerformance data={keyPerformanceData} yearEnded={yearEnded} totalYears={filteredYears} />
                    </Grid>
                    <Grid item md={12}>
                      <SensitivityAnalysis
                        data={sensitivityAnalysisData}
                        yearEnded={yearEnded}
                        totalYears={filteredYears}
                        configuration={<Configuration/>}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            {data?.guarantorProjectionSummary && (
              <Grid item lg={12}>
                <SimpleAccordion
                  title={'6.2 Projections: Guarantor'}
                  children={
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <ProfitabilityAssumptionsGuarantor
                          title="6.2.a Assumptions of Profitability"
                          {...basicProps}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <SummaryOfProjection
                          totalYears={filteredYears}
                          data={summaryOfProjectionGuarantorData}
                          yearEnded={yearEnded}
                          title="6.2.b Summary Projections"
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            )}
          </Grid>
        </MainCard>
      </form>
    );
  };

  return <>{_renderProjectionSummary()}</>;
};

export default ProjectionSummary;
