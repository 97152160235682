import { useState } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { Box, Divider, Button } from '@mui/material';
import { getRefDataByGroupName } from 'services/common-services';
import { useQuery } from 'react-query';
import { ColGroupDef, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { approveWithDocUpload, postLegalDocDetails, getLegalDocDetails } from 'services/legal-service';
import { approveAfterVerification } from 'services/credit-service';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import useLoanTable from 'hooks/useLoanTable';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import ActionBar from 'components/styled-components/ActionBar';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import queryClient from 'services/react-query-service';
import { getDocuments, downloadDocument, uploadDocument, saveDocumentMeta, deleteDocument } from 'services/document-services';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};

export default function DocumentSubmission() {
  const { getHomePage } = useLoanTable();
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const [doc, setDoc] = useState<any | null>(null);
  const { statusCode, viewOnly } = state;
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const [gridApi, setGridApi] = useState<any | undefined>();
  const { data: legalDocDetails, isSuccess: isLegalSuccess } = useQuery(
    ['getLegalDocDetails', state?.loanRequestId],
    () => getLegalDocDetails(state?.loanRequestId)
  );


  const deleteDocumentMutation = useMutation((data: any) => deleteDocument(data?.id), {
    onSuccess: async (data: any) => {
     enqueueSnackbar(`Document ${data?.docFileName} deleted`, {
        variant: 'success',
      });
     let delData = {...doc}
     delData = Object.entries(delData)?.filter(([k,v]:any) => v?.credAdminUploadedDocId !== data.id)
     setDoc(Object.fromEntries(delData));
    },
    onError: async (data: any) => {
      enqueueSnackbar(`Error while deleting ${data?.docFileName}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(toggleLoader(false));
    },
  });



  const getLegalDoc = () => {
    return { ...legalDocDetails }?.docList?.map((docm: any) =>
      Object.assign(
        {},
        {
          ...docm?.legalDocumentName,
          legalUploadedDocId: docm?.legalUploadedDocId,
          legalUploadedDocName: docm?.legalUploadedDocName,
          credAdminUploadedDocId: docm?.credAdminUploadedDocId,
          credAdminUploadedDocName: docm?.credAdminUploadedDocName,
        }
      )
    );
  };

  const updateFileName = (id: any, filename: any) => {
    var rowNode = gridApi?.api?.getRowNode(id);
    rowNode?.setDataValue('file', filename);
    gridApi?.api?.refreshCells({
      force: true,
      suppressFlash: false,
      columns: ['finalDocument'],
    });
    gridApi?.api?.sizeColumnsToFit();
  };

  useEffect(() => {
    if (legalDocDetails) {
      const docDetails = getLegalDoc()
        ?.filter((la: any) => la.credAdminUploadedDocId)
        .map((ld: any) =>
          Object.assign(
            {},
            {
              [ld?.id]: {
                credAdminUploadedDocId: ld?.credAdminUploadedDocId,
                credAdminUploadedDocName: ld?.credAdminUploadedDocName,
              },
            }
          )
        );
      !_.isEmpty(docDetails) &&
        setDoc(Object.fromEntries(docDetails?.map((a: any) => Object.entries(a)).flatMap((b: any) => b)));
    }
  }, [isLegalSuccess]);

  const columnDefs = [
    {
      field: 'srNo',
      headerName: 'Sr. No',
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      resizable: false,
    },
    { field: 'id', hide: true },
    {
      field: 'displayText',
      headerName: 'List of Documents',
      minWidth: 250,
      width: 250,
      maxWidth: 350,
      resizable: false
    },
    { field: 'legalUploadedDocId', hide: true },
    { field: 'legalUploadedDocName', hide: true },
    { field: 'credAdminUploadedDocId', hide: true },
    { field: 'credAdminUploadedDocName', hide: true },
    { 
      field: 'descriptionText', 
      headerName: 'Description', 
      minWidth: 350,
      width: 350,
      maxWidth: 450,
      resizable: false
    },
    {
      field: 'legalDocument',
      headerName: 'Legal Document',
      sortable: false,
      minWidth: 200,
      width: 200,
      maxWidth: 250,
      resizable: true,
      cellRendererFramework: (params: any) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadDocuments({
                id: params?.data?.legalUploadedDocId,
                docFileName: params?.data?.legalUploadedDocName,
              })
            }>
            <a
              href="#"
              onClick={() =>
                downloadDocuments({
                  id: params?.data?.legalUploadedDocId,
                  docFileName: params?.data?.legalUploadedDocName,
                })
              }>
              {_.truncate(params?.data?.legalUploadedDocName)}
            </a>
          </div>
        );
      },
    },
    {
      field: 'file',
      hide: true,
    },
    {
      field: 'finalDocument',
      headerName: 'Final Executed Document',
      minWidth: 300,
      width: 440,
      maxWidth: 440,
      cellRendererFramework: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', textAlign: 'center', position: 'absolute', top: '-2.5px' }}>
            {(!doc || (doc && !Object.keys(doc)?.includes(params?.data?.id?.toString()))) && (
              <label htmlFor={`attach_${params?.data?.id}`}>
                <Button variant="contained" component="span">
                {params?.data?.file?.name && 'Re-'}Attach
                  <input
                    hidden
                    id={`attach_${params?.data?.id}`}
                    type="file"
                    onChange={(e: any) => updateFileName(params?.data?.id, e?.target.files[0])}
                  />
                </Button>
              </label>
            )}
            {params?.data?.file?.name &&
              (!doc || (doc && !Object.keys(doc)?.includes(params?.data?.id?.toString()))) && (
                <>
                  <span>
                    &nbsp;&nbsp;&nbsp;{_.truncate(params?.data?.file?.name)} <b>selected</b>&nbsp;&nbsp;&nbsp;
                  </span>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={() => handleUpload(params?.data?.id, params?.data?.file)}>
                    Upload
                  </Button>
                </>
              )}
            {params?.data?.credAdminUploadedDocId && (
              <div style={{ cursor: 'pointer', padding: '8px', textAlign: 'center' }}>
                <a
                  href="#"
                  onClick={() =>
                    downloadDocuments({
                      id: params?.data?.credAdminUploadedDocId,
                      docFileName: params?.data?.credAdminUploadedDocName,
                    })
                  }>
                  {_.truncate(params?.data?.credAdminUploadedDocName)}
                </a>
              </div>
            )}
            {!params?.data?.credAdminUploadedDocId && doc && doc![params?.data?.id] && (
              <span style={{ cursor: 'pointer', float:'left' }}>
               <span style={{ cursor: 'pointer', float:'left' }}>
                &nbsp;&nbsp;&nbsp;
                <b>
                  <a
                    href="#"
                    onClick={() =>
                      downloadDocuments({ id: doc[params?.data?.id]?.credAdminUploadedDocId, docFileName: params?.data?.file?.name })
                    }>
                    {_.truncate(params?.data?.file?.name)}
                  </a>
                </b>{' '}
                &nbsp;&nbsp;&nbsp;<b>uploaded</b>
              </span>
              <span
                      style={{ cursor: 'pointer', float:'left', marginTop:"6px" }}
                      onClick={() => deleteDocumentMutation.mutate({ id: doc[params?.data?.id]?.credAdminUploadedDocId, docFileName: params?.data?.file?.name })}>
                        <DeleteForeverIcon fontSize="small" />
                  </span>  
                </span>  
            )}
          </div>
        );
      },
    },
  ];

  const getRows = (data: any): ColGroupDef[] => {
    let rows = null;
    if (data) {
      rows = data?.map((doc: any, i: number) => {
        return { ...doc, srNo: i + 1 };
      });
    }
    return rows;
  };

  const handleUpload = (refId: any, file: any) => {
    if (file) {
      uploadDocumentMetaMutation.mutate({
        refId: refId,
        fileDetails: {
          docFileName: file?.name,
          docXrefId: state?.loanRequestId,
          docXrefType: 'LOAN_REQUEST',
        },
        file: file,
      });

      if (uploadDocumentMetaMutation?.isLoading) {
        dispatch(toggleLoader(true));
      }
    } else {
      enqueueSnackbar(`Please select Document`, {
        variant: 'error',
      });
    }
  };

  const uploadDocumentMetaMutation = useMutation(
    ({ refId, fileDetails, file }: any) => saveDocumentMeta(fileDetails),
    {
      onSuccess: async (data: any, variables: any) => {
        let upload = await uploadDocument(data?.id, variables?.file);
        if (upload) {
          enqueueSnackbar(`Document ${variables?.file?.name} Saved`, {
            variant: 'success',
          });
          if (doc) {
            setDoc({ ...doc, [variables?.refId]: { credAdminUploadedDocId: data?.id, credAdminUploadedDocName: variables?.file?.name } });
          } else {
            setDoc({ [variables?.refId]: { credAdminUploadedDocId: data?.id, credAdminUploadedDocName: variables?.file?.name } });
          }
          gridApi?.api?.refreshCells({
            force: true,
            suppressFlash: false,
            columns: ['finalDocument'],
          });
        } else {
          enqueueSnackbar(`Error while saving Document ${variables?.file?.name}`, {
            variant: 'error',
          });
        }
      },
      onError: (variables: any) => {
        enqueueSnackbar(`Error while uploading ${variables?.file?.name}`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
        dispatch(toggleLoader(false));
      },
    }
  );

  const approveAfterVerificationMutation = useMutation(
    (approvalData: any) => approveAfterVerification(approvalData),
    {
      onSuccess: (data, variables) => {
        enqueueSnackbar(
          `Loan Request ID : ${state?.loanRequestId} has been ${
            variables?.approvalStatus ? 'Approved' : 'Returned'
          }`,
          {
            variant: 'success',
          }
        );
        navigate(getHomePage());
      },
      onSettled: (data) => {
        dispatch(toggleLoader(false));
        queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
      },
    }
  );
  const saveDocumentList = useMutation(
    ({ documentDetails, approvalData }: any) =>
      postLegalDocDetails(state?.loanRequestId, { documentDetails, approvalData }),
    {
      onSuccess: (data, variables) => {
        approveAfterVerificationMutation.mutate({
          ...variables?.approvalData,
        });
      },
      onError: (data) => {
        enqueueSnackbar(`Unable to prepare Document list for Loan Request ID : ${state?.loanRequestId}`, {
          variant: 'error',
        });
      },
      onSettled: (data) => {
        dispatch(toggleLoader(false));
        queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
        setOpen(false);
      },
    }
  );
  const onClickApproveReturnHandler = (comment: string) => {
    if (state?.loanRequestId && approveReturn != null && state?.flowableTaskId) {
      if (legalDocDetails?.docList?.length == (doc && Object.keys(doc).length)) {
        const documentDetails: any = { ...legalDocDetails }?.docList?.map((legaldoc: any) =>
          Object.assign(
            {},
            legaldoc,
            { credAdminUploadedDocId: doc![legaldoc?.legalDocumentName?.id]?.credAdminUploadedDocId },
            { credAdminUploadedDocName: doc![legaldoc?.legalDocumentName?.id]?.credAdminUploadedDocName }
          )
        );

        saveDocumentList.mutate({
          documentDetails: {
            id: legalDocDetails?.id,
            loanRequestId: state?.loanRequestId,
            docList: documentDetails,
          },
          approvalData: {
            approvalStatus: approveReturn,
            loanId: state?.loanRequestId,
            approvalComment: comment,
            approvalTaskId: state?.flowableTaskId,
          },
        });
      } else {
        enqueueSnackbar(`Please upload all document for Loan Request ID : ${state?.loanRequestId}`, {
          variant: 'error',
        });
        setOpen(false);
      }
    } else {
      enqueueSnackbar(`Unable to approve request for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });
    }
  };

  const handleClickOpen = (approveReturn: boolean | null) => {
    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      setMessage('Confirmation');
      setOpen(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sx={{ height: 'calc(100vh - 144px)' }}>
        <div style={{ height: 'calc(100% - 25px)' }} className="ag-theme-alpine">
          <div
            style={{
              height: '100%',
              width: '100%',
            }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={isLegalSuccess ? getRows(getLegalDoc()):[]}
              onGridReady={(event) => {
                setGridApi(event);
                event?.api?.sizeColumnsToFit();
              }}
              animateRows={true}
              reactUi={true}
              enableRangeSelection={true}
              paginationPageSize={10}
              pagination={true}
              editType={'fullRow'}
              stopEditingWhenCellsLoseFocus={true}
              rowSelection={'multiple'}
              getRowNodeId={(d: any) => {
                return d.id;
              }}
            />
          </div>
        </div>
      </Grid>
      {!state?.viewOnly &&
        <ActionBar>
          <Button type="submit" onClick={() => handleClickOpen(true)} variant="contained" sx={{ p: 1, right: 10 }}>
            Submit
          </Button>
        </ActionBar>
      }
      <DialogWithComment
        open={open}
        setOpen={setOpen}
        title={message}
        description={`Do you want to ${approveReturn ? 'Submit' : 'Return'} for Loan Request ID: ${
          state?.loanRequestId
        } ?`}
        type={'info'}
        onClickHandler={onClickApproveReturnHandler}
      />
    </Grid>
  );
}
