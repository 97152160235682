import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useState } from 'react';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ISimpleAccordionProps } from '../../../models/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '0px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  paddingLeft: '12px',
  minHeight: '42px',
  backgroundColor: '#ffffff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '10px !important',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: theme.palette.primary.main,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .Mui-expanded': {
    color: theme.palette.primary.main,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  pl: theme.spacing(1),
  pr: theme.spacing(1),
  pt: theme.spacing(0.5),
  pb: theme.spacing(0.5),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const RectagleBox = styled(Box)(({ theme }) => ({
  width: '3px',
  height: '22px',
  margin: '0 10px 0 0',
  borderRadius: '2px',
  backgroundColor: '#637381',
  position: 'absolute',
  left: '10px',
}));

export default function SimpleAccordion({ title, children, expand = false }: ISimpleAccordionProps) {
  const [expanded, setExpanded] = useState<boolean | false>(expand);
  const theme = useTheme();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange(title)}>
      <AccordionSummary
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        sx={expanded ? { color: 'blue' } : {}}>
        <RectagleBox sx={expanded ? { backgroundColor: theme.palette.primary.main } : null}></RectagleBox>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
