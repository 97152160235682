import { FormLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  padding: '5px 20px',
  textAlign: 'left',
  width: '20%',
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: '8px',
  color: '#2D2D2D',
}));

interface IProps {
  label: string;
  sx?: any;
  labelStyle?: any;
}

const SimpleFormLabel: FC<IProps> = ({ label, sx, labelStyle = { display: 'inline', fontWeight: 400 } }) => (
  <StyledFormLabel sx={{ ...sx }}>
    <Typography variant="subtitle1" sx={labelStyle}>
      {label}
    </Typography>
  </StyledFormLabel>
);

export default SimpleFormLabel;
