import _ from 'lodash';
import * as mathjs from 'mathjs';
import {
  InputFormDataType,
  InputFormRowType,
  FormGroup,
  FormGroupType,
} from '../../../../../../types/inputform.d';
import InputCell from './InputCell';
import { nextChar, prevChar } from '../../../../../../utils/char-utils';
import { flatten } from 'flat';

const FORMULA_REG_EXP = /([^A-Za-z0-9.#_$]+?)/;

const FORMULA_SPLIT_REG_EXP = /([^A-Za-z0-9.#_$-]+?)/;

const SCOPE_FORMULA_EXP = /([A-Z]+\.+[A-Za-z0-9._]+)/g;

const SCOPE_KEY_EXP = /[a-z.]+[A-Z][1-9]+/g;

const ALPHA_REG_EXP = /[^a-zA-Z]+/g;

export const DEPENDENCY_REG_EXP = /[a-zA-Z]+\.+-?[\d]\.+[\w_.]+/g;

const isOperator = (operator: any) =>
  ['+', '-', '*', '%', '/', '==', '===', ')', '(', '<', '?', ':', '!', '=', "'", "or","and"].some((item) => {
    return item === operator;
  });

export const getAlphabet = (text: any) => text?.replace(ALPHA_REG_EXP, '');

export const isNumeric = (num: any) => {
  if ((num && Number(num)) || Number(num) === 0) {
    return true;
  } else {
    return false;
  }
};

export const getNumberFormatted = (num: any) => {
  if (num && isNumeric(num)) {
    return Number(num).toFixed(2);
  } else {
    return '';
  }
};


export const getNumberRoundOff = (num: any) => {
  if (num && isNumeric(num)) {
    return Number(num).toFixed(2);
  } else {
    return num;
  }
};

export const getChildrenKey = (value: any, col: any) => {
  return value?.children?.map((key: any) => value?.form + '.' + col + '.' + key).join(',');
};

export const getChildren = (childJson: any, parent: any) => {
  const childObject = childJson && Object.entries(childJson).filter(([, value]: any) => value === Object(value));
  return childObject?.map(([key]: any) => parent?.join('.') + '.' + key);
};

export const getExpression = (cell: any, col: any, group: any) => {
  const formula = cell![group]?.startsWith('=') ? cell![group].substring(1) : cell![group];

  return formula
    ?.split(FORMULA_REG_EXP)
    .filter((k: any) => k)
    .map((f: any) => {
      let key;
      if (f?.startsWith('#')) {
        const idArray = f?.trim().split('.');
        const formname = idArray.shift().replace('#', '');
        const formrow = idArray.join('.');
        let newformname = formname;
        let newCol = col;
        if (newformname?.startsWith('$')) {
          newformname = newformname.replace('$', '');
          newCol = -1;
        }

        if (newformname && !isNumeric(formrow) && !isOperator(formrow) && group) {
          key = newformname + '.' + newCol + '.' + formrow;
        }
      } else {
        let newF = f?.trim();
        let newCol = col;
        if (newF?.startsWith('$')) {
          newF = newF.replace('$', '');
          newCol = -1;
        }
        if (newF && !isNumeric(newF) && !isOperator(newF) && group) {
          key = cell?.form + '.' + newCol + '.' + newF;
        }
      }
      return key ? key : f?.trim();
    })
    .filter((key: any) => key)
    .join('');
};

export const addExpression = (formJson: any) => {
  Object.entries(formJson).map(([, formvalue]: any) => {
    return Object.entries(formvalue).map(([key, value]: any) => {
      const formulaActuals = value![FormGroup[FormGroup.ACTUALS].toLowerCase()];
      const formulaProjection = value![FormGroup[FormGroup.PROJECTION].toLowerCase()];

      if (key?.startsWith('0')) {
        if (formulaActuals) {
          let actuals;

          if (formulaActuals === 'total') {
            const sumexpr = getChildrenKey(value, key?.charAt(0));
            actuals = sumexpr ? 'sum(' + sumexpr + ')' : null;
          } else if (formulaActuals?.startsWith('=')) {
            actuals = getExpression(value, key?.charAt(0), FormGroup[FormGroup.ACTUALS].toLowerCase());
          }

          actuals && Object.assign(value, { actuals: '=' + actuals });
        }

        if (formulaProjection) {
          let projection;
          if (formulaProjection === 'total') {
            const sumexpr = getChildrenKey(value, key?.charAt(0));
            projection = sumexpr ? 'sum(' + sumexpr + ')' : null;
          } else if (formulaProjection?.startsWith('=')) {
            projection = getExpression(value, key?.charAt(0), FormGroup[FormGroup.PROJECTION].toLowerCase());
          }

          projection && Object.assign(value, { projection: '=' + projection });
        }
      }

      delete value?.children;
      delete value?.level;

      return value;
    });
  });
};

export const getItems = (items: any, label: any) =>
  items.map((item: any) => {
    return { [`${label}_${item?.id}`]: { label: item?.displayText } };
  });

export const getItemsAndAppend = (items: any, newItem: any, label: any) =>
  items.map((item: any) => {
    const updatedItem = _.cloneDeep(newItem);
    addChildFormula(`${label}_${item?.id}`, updatedItem);
    return { [`${label}_${item?.id}`]: { label: item?.displayText, ...updatedItem } };
  });

const addChildFormula = (item: string, newObject: any): void => {
  for (const [k, v] of Object.entries(newObject)) {
    if (['actuals', 'projection'].includes(k) && (v as string)?.includes('{item}')) {
      Object.assign(newObject, { [k]: (v as string)?.replaceAll('{item}', item) });
    } else if (typeof v === 'object') {
      addChildFormula(item, v);
    }
  }
};

export const addCustomFormula = (items: any, newFormula: any) => {
  return Object.assign(
    [],
    Object.entries(newFormula)
      .filter(([key, value]) => ['actuals', 'projection'].includes(key) && (value as string)?.includes('{item}'))
      .map(([key, value]) =>
        Object.assign(
          {},
          {
            [key]:
              '=' +
              items.map((item: any) => (value as string)?.slice(1).replaceAll('{item}', item?.key)).join('+') +
              '+' +
              (newFormula?.common ? newFormula?.common : '0'),
          }
        )
      )
  );
};

export const updateJson = (type: string, value: string, newObject: any, formObject: any): void => {
  for (const [k, v] of Object.entries(formObject)) {
    if (k === type && v === value) {
      delete formObject[type];
      if (type === 'projectionFormula') {
        if (newObject.hasOwnProperty('actuals')) {
          formObject['actuals'] = newObject['actuals'];
        }
        if (newObject.hasOwnProperty('projection')) {
          formObject['projection'] = newObject['projection'];
        }
      } else {
        Object.assign(formObject, ...newObject);
      }
    } else if (typeof v === 'object') {
      updateJson(type, value, newObject, v);
    }
  }
};

export const iterateTemplate = (
  result = [] as any,
  type: string,
  formObject: any,
  parent: any = null,
  level = 0
): any => {
  for (const [k, v] of Object.entries(formObject)) {
    if (k === type) {
      const { label, actuals, projection, readOnly, className, defaultValue, ...rest } = formObject;
      result.push({
        label,
        readOnly,
        actuals,
        projection,
        className,
        level,
        defaultValue,
        children: getChildren(rest, parent),
        id: parent?.join('.'),
      });
    } else if (typeof v === 'object') {
      if (!level) {
        parent = [];
      } else if (parent.length > level) {
        parent.splice(level, parent.length);
      }
      parent.push(k);
      iterateTemplate(result, type, v, parent, level + 1);
    }
  }
  return result;
};

export const getRowTemplate = (type: string, formObject: any): any => {
  const result = [] as any;
  iterateTemplate(result, type, formObject);
  return result;
};

export const getRow = (form: any, row: any) => {
  return row![row?.group] === 'total'
    ? {
      [`0.${row?.id}`]: {
        key: `${row?.id}`,
        value: row?.label,
        readOnly: true,
        overflow: 'nowrap',
        form: `${form}`,
        ...row,
        className: row?.className || `total${row?.level}`,
      },
    }
    : {
      [`0.${row?.id}`]: {
        key: `${row?.id}`,
        value: row?.label,
        readOnly: true,
        overflow: 'nowrap',
        form: `${form}`,
        ...row,
        className: row?.className || `level${row?.level}`,
      },
    };
};

export const getRowData = (form: any, row: any, nextDataCol: any) => {
  return row![row?.group] === 'total'
    ? {
      [`${nextDataCol}.${row?.id}`]: {
        key: `${nextDataCol}.${row?.id}`,
        value: '0.00',
        expr: null,
        overflow: 'nowrap',
        form: `${form}`,
        ...row,
        className: row?.className || 'total',
      },
    }
    : {
      [`${nextDataCol}.${row?.id}`]: {
        key: `${nextDataCol}.${row?.id}`,
        value: row?.readOnly ? '' : row?.value || '0.00',
        dataEditor: null,
        form: `${form}`,
        ...row,
      },
    };
};

export const getHeader = (year: any, nextColCell: any) => {
  const cell = {
    [`${nextColCell}.0`]: {
      key: `${nextColCell}.0`,
      value: year,
      readOnly: true,
      className: 'title',
      overflow: 'nowrap',
    },
  };
  return cell;
};

const getCols = (formData: InputFormDataType) =>
  Array.from(new Set(Object.keys(formData).map((cell) => cell.charAt(0))));

const getRows = (formData: InputFormDataType) =>
  Object.values(
    Object.assign(
      {},
      ...Object.entries(formData)
        .filter(([key]) => key?.match(/^0./))
        .map(([_, filteredCell]: any) => Object.assign({}, { [filteredCell?.key]: filteredCell }))
    )
  );

export const generateGrid = (formData: InputFormDataType, viewOnly: Boolean) =>
  getRows(formData).map((row: any) =>
    getCols(formData).map((col, j) => {
      if (row?.key?.charAt(0) === '0' && j === 0) {
        return { ...row, readOnly: true, value: row.value };
      }

      if (j === 0) {
        return { ...row, readOnly: true, value: row.value };
      }
      const formRow = formData!['0.' + row?.key] as any;
      const expr = formRow && formRow?.group && formRow![formRow?.group]?.replaceAll('.0', `.${col}`);

      if (viewOnly) {
        if (row?.key && formData![col + '.' + row?.key] && 'dataEditor' in formData[col + '.' + row?.key]) {
          return Object.assign(
            {},
            { ...formData[col + '.' + row?.key], dataEditor: InputCell, expr, readOnly: viewOnly }
          );
        } else if (row?.key && formData![col + '.' + row?.key]) {
          return Object.assign({}, { ...formData[col + '.' + row?.key], expr, readOnly: viewOnly });
        }
      } else {
        if (row?.key && formData![col + '.' + row?.key] && 'dataEditor' in formData[col + '.' + row?.key]) {
          return Object.assign({}, { ...formData[col + '.' + row?.key], dataEditor: InputCell, expr });
        } else if (row?.key && formData![col + '.' + row?.key]) {
          return Object.assign({}, { ...formData[col + '.' + row?.key], expr });
        }
      }
    })
  );

export const validateExp = (copyCells: any, trailKeys: string[], expr: string) => {
  let valid = true;
  const matches = expr.match(SCOPE_KEY_EXP) || [];
  matches.map((match) => {
    if (trailKeys.indexOf(match) > -1) {
      valid = false;
    } else {
      const [formName, key, group] = match?.split('.');
      let cellsExpr = '';
      if (copyCells && formName && key && group) {
        cellsExpr = copyCells && copyCells[formName][group][key]?.expr;
      }
      valid = validateExp(copyCells, [...trailKeys, match], cellsExpr || '');
    }
    return undefined;
  });
  return valid;
};

export const computeExpr = (
  copyCells: any,
  cell: InputFormRowType | null,
  expr: string | null,
  scope: InputFormRowType
) => {
  let value = null;
  if (!expr?.startsWith('=')) {
    return { className: '', value: expr, expr: expr };
  } else {
    try {
      const val = mathjs.evaluate(expr.substring(1), scope);
      if (isNumeric(val)) {
        value = val;
        if (value === Infinity) {
          value = '';
        }
      } else {
        value = '';
      }
    } catch (e) {
      value = null;
    }

    if (value !== null && cell?.key && validateExp(copyCells, [`${cell?.form}.${cell?.key}`], expr)) {
      return { ...cell, className: 'equation', value, expr };
    } else {
      return { ...cell, className: 'error', value: 'error', expr };
    }
  }
};

export const cellUpdate = (
  stateCells: any,
  changeCell: InputFormRowType | null,
  expr: string | null | undefined,
  scope: any,
  formulaScope: any
) => {
  const updatedCell = Object.assign(
    {},
    changeCell,
    computeExpr(
      stateCells,
      changeCell,
      expr!.replace(SCOPE_FORMULA_EXP, (match: any) => match?.replaceAll('.', '_')),
      scope
    )
  );

  if (updatedCell && updatedCell!.key && updatedCell!.form && updatedCell!.group && scope) {
    stateCells[updatedCell!.form][updatedCell!.key] = updatedCell;
    const scopeKey = updatedCell?.key?.replaceAll('.', '_');
    scope[updatedCell!.form][scopeKey] = updatedCell?.value;
  }

  const nextCell = updateCurrent(stateCells, updatedCell, scope, formulaScope);
  updateNext(stateCells, updatedCell, scope, formulaScope);

  nextCell?.forEach((cell: any) => {
    updateNext(stateCells, cell, scope, formulaScope);
  })

  return stateCells;
};

const updateCurrent = (stateCells: any, updatedCell: InputFormRowType | null, scope: any, formulaScope: any) => {
  const updatedKey = updatedCell?.key?.split('.').shift();
  const formulaKey = `${updatedCell!.form}.${updatedCell!.key?.replace(`${updatedKey}.`, '0.')}`;
  const nextCell:any[] = [];

  if (updatedCell && updatedCell?.key && updatedCell?.group && formulaKey in formulaScope![updatedCell?.group]) {
    const dependentArray = formulaScope![updatedCell?.group][formulaKey];

    let prevKey = prevChar(updatedKey!);

    if (prevKey === 'ZZ') {
      prevKey = '-1';
    }

    dependentArray?.forEach((cell: any) => {
      const [form, ...restKey] = cell?.split('.');
      const scopeKey = restKey?.join('_')?.replace('0_', `${updatedKey}_`);
      const key = restKey?.join('.')?.replace('0.', `${updatedKey}.`);

      if (form && key && stateCells![form][key]) {
        const dependentCell = stateCells![form][key];

        const formulaCell = stateCells![form][restKey?.join('.')];

        const formulaExpr = formulaCell!
        [dependentCell?.group]!.replaceAll('.0.', `.${updatedKey || 0}.`)
          .replaceAll('.-1.', `.${prevKey || -1}.`)
          .split(FORMULA_SPLIT_REG_EXP)
          .map((k: any) => (k?.indexOf('.0.') !== -1 ? 0 : k))
          .map((k: any) => (k?.indexOf('.-1.') !== -1 ? 0 : k))
          .join('')
          .replace(SCOPE_FORMULA_EXP, (match: any) => match?.replaceAll('.', '_'));

        const updatedCellDep = Object.assign(
          {},
          dependentCell,
          computeExpr(stateCells, dependentCell, formulaExpr, scope)
        );

        if (dependentCell && scope) {
          stateCells[form][key] = updatedCellDep;
          scope[form][scopeKey] = updatedCellDep?.value;
          nextCell.push(updatedCellDep)
        }
      }
    });
  }
  return nextCell;
};

const updateNext = (stateCells: any, updatedCell: InputFormRowType | null, scope: any, formulaScope: any) => {
  const updatedKey = updatedCell?.key?.split('.').shift();
  const formulaKey = `${updatedCell!.form}.${updatedCell!.key?.replace(`${updatedKey}.`, '-1.')}`;

  const actualYears = Object.keys(
    Object.fromEntries(
      Object.assign(
        [],
        ...Object.values(stateCells).map((row) =>
          Object.entries(row as Object)
            .filter(([k]) => k?.endsWith('.0'))
            .map(([k, v]: any) => [k?.replaceAll('.0', ''), `${v?.group}`])
        )
      ).filter((k: any) => k?.includes(FormGroup[FormGroup.ACTUALS].toLowerCase()))
    )
  );

  const projectionYears = Object.keys(
    Object.fromEntries(
      Object.assign(
        [],
        ...Object.values(stateCells).map((row) =>
          Object.entries(row as Object)
            .filter(([k]) => k?.endsWith('.0'))
            .map(([k, v]: any) => [k?.replaceAll('.0', ''), `${v?.group}`])
        )
      ).filter((k: any) => k?.includes(FormGroup[FormGroup.PROJECTION].toLowerCase()))
    )
  );

  const nextActualsCell =
    updatedKey && actualYears?.includes(updatedKey) && actualYears?.slice(actualYears?.indexOf(updatedKey) + 1);

  if (
    nextActualsCell &&
    updatedCell &&
    updatedCell?.key &&
    updatedCell?.group &&
    formulaKey in formulaScope![FormGroup[FormGroup.ACTUALS].toLowerCase()]
  ) {
    let prevKey = updatedKey;
    nextActualsCell?.forEach((nextKey) => {
      const dependentArray = formulaScope![FormGroup[FormGroup.ACTUALS].toLowerCase()][formulaKey];

      dependentArray?.forEach((cell: any) => {
        const [form, ...restKey] = cell?.split('.');
        const scopeKey = restKey?.join('_')?.replace('0_', `${nextKey}_`);
        const key = restKey?.join('.')?.replace('0.', `${nextKey}.`);

        if (form && key && stateCells![form][key]) {
          const dependentCell = stateCells![form][key];

          const formulaCell = stateCells![form][restKey?.join('.')];

          const formulaExpr = formulaCell!
          [FormGroup[FormGroup.ACTUALS].toLowerCase()]!.replaceAll('.0.', `.${nextKey || 0}.`)
            .replaceAll('.-1.', `.${prevKey || -1}.`)
            .split(FORMULA_SPLIT_REG_EXP)
            .map((k: any) => (k?.indexOf('.0.') !== -1 ? 0 : k))
            .map((k: any) => (k?.indexOf('.-1.') !== -1 ? 0 : k))
            .join('')
            .replace(SCOPE_FORMULA_EXP, (match: any) => match?.replaceAll('.', '_'));

          const updatedCellDep = Object.assign(
            {},
            dependentCell,
            computeExpr(stateCells, dependentCell, formulaExpr, scope)
          );

          if (dependentCell && scope) {
            stateCells[form][key] = updatedCellDep;
            scope[form][scopeKey] = updatedCellDep?.value;
          }

          
        }
      });
      prevKey = nextKey;
    });
  }

  let nextProjectionCell = null;

  if (updatedKey && projectionYears?.includes(updatedKey)) {
    nextProjectionCell = projectionYears?.slice(projectionYears?.indexOf(updatedKey) + 1);
  } else if (_.isEmpty(nextActualsCell) && updatedKey && actualYears?.includes(updatedKey)) {
    nextProjectionCell = projectionYears;
  }

  if (
    nextProjectionCell &&
    updatedCell &&
    updatedCell?.key &&
    updatedCell?.group &&
    formulaKey in formulaScope![FormGroup[FormGroup.PROJECTION].toLowerCase()]
  ) {
    let prevKey = updatedKey;
    nextProjectionCell?.forEach((nextKey) => {
      const dependentArray = formulaScope![FormGroup[FormGroup.PROJECTION].toLowerCase()][formulaKey];

      dependentArray?.forEach((cell: any) => {
        const [form, ...restKey] = cell?.split('.');
        const scopeKey = restKey?.join('_')?.replace('0_', `${nextKey}_`);
        const key = restKey?.join('.')?.replace('0.', `${nextKey}.`);

        if (form && key && stateCells![form][key]) {
          const dependentCell = stateCells![form][key];

          const formulaCell = stateCells![form][restKey?.join('.')];

          const formulaExpr = formulaCell!
          [FormGroup[FormGroup.PROJECTION].toLowerCase()]!.replaceAll('.0.', `.${nextKey || 0}.`)
            .replaceAll('.-1.', `.${prevKey || -1}.`)
            .split(FORMULA_SPLIT_REG_EXP)
            .map((k: any) => (k?.indexOf('.0.') !== -1 ? 0 : k))
            .map((k: any) => (k?.indexOf('.-1.') !== -1 ? 0 : k))
            .join('')
            .replace(SCOPE_FORMULA_EXP, (match: any) => match?.replaceAll('.', '_'));

          const updatedCellDep = Object.assign(
            {},
            dependentCell,
            computeExpr(stateCells, dependentCell, formulaExpr, scope)
          );

          if (dependentCell && scope) {
            stateCells[form][key] = updatedCellDep;
            scope[form][scopeKey] = updatedCellDep?.value;
          }

          
        }
      });
      prevKey = nextKey;
    });
  }
};

export const getFormDataFromState = (formCells: any, group: FormGroupType) => {
  const actualsCells =
    formCells &&
    Object.fromEntries(
      Object.entries(formCells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase()
      )
    );

  if (formCells && !_.isEmpty(Object.keys(actualsCells))) {
    Object.entries(actualsCells).map(([, value]: any) => {
      const readOnly =
        group === FormGroup.PROJECTION
          ? true
          : value?.hasOwnProperty('disabled')
            ? value?.disabled
            : value?.readOnly;
      return Object.assign(value, { readOnly });
    });

    _.merge(formCells, Object.assign({}, actualsCells));
  }

  const projectionCells =
    formCells &&
    Object.fromEntries(
      Object.entries(formCells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()
      )
    );

  if (formCells && !_.isEmpty(Object.keys(projectionCells))) {
    _.merge(formCells, Object.assign({}, projectionCells));
  }

  return formCells;
};

export const getForm = (
  formCells: any,
  formType: any,
  group: FormGroupType,
  allRows: any[],
  dbJson: any,
  loanDetails: any
) => {
  const formJson = {};
  let nextColCell = 'A';
  const { actualsYears, projectionYears } = loanDetails;
  const prevYears = actualsYears && [...actualsYears].sort();
  const nextYears = projectionYears && [...projectionYears].sort();

  if (_.isEmpty(nextYears)) {
    return formJson;
  }

  const actualsCells =
    formCells &&
    Object.fromEntries(
      Object.entries(formCells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase()
      )
    );

  if (!formCells || _.isEmpty(Object.keys(actualsCells))) {
    _.merge(formJson, {
      '00': { key: '0', value: `Particulars at the end of ${loanDetails?.fiscalYear}`, width: '45%', group: FormGroup[FormGroup.ACTUALS].toLowerCase(), className: 'title' },
    });

    const formRows = allRows.map((row: any) =>
      getRow(formType, { ...row, group: FormGroup[FormGroup.ACTUALS].toLowerCase() })
    );

    _.merge(formJson, Object.assign({}, ...formRows));

    // Header //
    const formHeaderActual = prevYears.map((year: any) => {
      const cell: InputFormDataType = getHeader(year, nextColCell);
      cell[Object.keys(cell)[0]] = {
        ...cell[Object.keys(cell)[0]],
        group: FormGroup[FormGroup.ACTUALS].toLowerCase(),
      };
      nextColCell = nextChar(nextColCell);
      return cell;
    });

    _.merge(formJson, Object.assign({}, ...formHeaderActual));
  }

  const projectionCells =
    formCells &&
    Object.fromEntries(
      Object.entries(formCells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()
      )
    );

  if (!formCells || _.isEmpty(Object.keys(projectionCells))) {
    nextColCell = prevYears.length ? nextChar('A', prevYears.length):'A';
    const formHeaderProjection = nextYears.map((year: any) => {
      const cell: InputFormDataType = getHeader(year, nextColCell);
      cell[Object.keys(cell)[0]] = {
        ...cell[Object.keys(cell)[0]],
        group: FormGroup[FormGroup.PROJECTION].toLowerCase(),
      };
      nextColCell = nextChar(nextColCell);
      return cell;
    });
    _.merge(formJson, Object.assign({}, ...formHeaderProjection));
  }

  // Row Data //
  let nextDataCol = 'A';
  if (!formCells || _.isEmpty(Object.keys(actualsCells))) {
    prevYears.map((year: any) => {
      const formDataRows = allRows.map((row: any) => {
        const readOnly =
          row![FormGroup[FormGroup.ACTUALS].toLowerCase()] === 'total' ||
          (row?.readOnly ?? row![FormGroup[FormGroup.ACTUALS].toLowerCase()]?.startsWith('='));
        return getRowData(
          formType,
          {
            ...row,
            disabled: readOnly,
            readOnly: group === FormGroup.PROJECTION ? true : readOnly,
            group: FormGroup[FormGroup.ACTUALS].toLowerCase(),
            value: row?.readOnly
              ? ''
              : (!_.isEmpty(Object.keys(dbJson)) ? 
                dbJson![`${formType}.${FormGroup[FormGroup.ACTUALS].toLowerCase()}.${year}.${row?.id}`]
                : row?.defaultValue),
          },
          nextDataCol
        );
      });

      nextDataCol = nextChar(nextDataCol);
      _.merge(formJson, Object.assign({}, ...formDataRows));
    });
  }

  if (!formCells || _.isEmpty(Object.keys(projectionCells))) {
    nextDataCol = prevYears.length ? nextChar('A', prevYears.length):'A';
    nextYears.map((year: any) => {
      const formDataRows = allRows.map((row: any) => {
        const readOnly =
          row![FormGroup[FormGroup.PROJECTION].toLowerCase()] === 'total' ||
          (row?.readOnly ?? row![FormGroup[FormGroup.PROJECTION].toLowerCase()]?.startsWith('='));

        return getRowData(
          formType,
          {
            ...row,
            readOnly,
            group: FormGroup[FormGroup.PROJECTION].toLowerCase(),
            value: row?.readOnly
              ? ''
              : (!_.isEmpty(Object.keys(dbJson)) ? 
                dbJson![`${formType}.${FormGroup[FormGroup.PROJECTION].toLowerCase()}.${year}.${row?.id}`]
                : row?.defaultValue),
          },
          nextDataCol
        );
      });
      nextDataCol = nextChar(nextDataCol);
      _.merge(formJson, Object.assign({}, ...formDataRows));
    });
  }

  return formJson;
};

export const addFormula = (formJson: any) => {
  
  const formulaExp = Object.fromEntries(
    Object.entries(formJson as Object).map(([formkey, value]) => {
      return [
        [`${formkey}`],
        value &&
        Object.fromEntries(
          Object.entries(value)
            ?.filter(([key]: any) => key?.startsWith('0'))
            .filter(([, value]: any) => value?.actuals || value?.projection)
            .map(([key, { actuals, projection }]: any) => [
              formkey + '.' + key,
              Object.assign(
                {},
                { actuals: actuals?.match(DEPENDENCY_REG_EXP) },
                { projection: projection?.match(DEPENDENCY_REG_EXP) }
              ),
            ])           
        ),
      ];
    })
  );
  
  const actualsScope = Object.fromEntries(
    _.flatten(
      Object.entries(formulaExp).map(([, v]) =>
        Object.entries(v as Object)
          .filter(([, v]) => v?.actuals)
          .map(([k, v]) => [k, v?.actuals])
      )
    )
  );
  const actualsFormula = Array.from(new Set(_.flatten(Object.values(actualsScope as Object))));
  
  const projectionScope = Object.fromEntries(
    _.flatten(
      Object.entries(formulaExp).map(([, v]) =>
        Object.entries(v as Object)
          .filter(([, v]) => v?.projection)
          .map(([k, v]) => [k, v?.projection])
      )
    )
  );
  
  const projectionFormula = Array.from(new Set(_.flatten(Object.values(projectionScope as Object))));
  const formulaMatrix: any = {};
  const actualsformulaMatrix: any[] = [];

  
  actualsFormula?.forEach((row: any) => {
    actualsformulaMatrix[row] = uniqueKeepLast(getParent([], actualsScope, row, actualsformulaMatrix));
  });
  
  
  actualsformulaMatrix && Object.assign(formulaMatrix, { actuals: actualsformulaMatrix });
  
  const projectionformulaMatrix: any[] = [];
  
  projectionFormula?.forEach((row: any) => {
    projectionformulaMatrix[row] = uniqueKeepLast(getParent([], projectionScope, row, projectionformulaMatrix));
  });
  projectionformulaMatrix && Object.assign(formulaMatrix, { projection: projectionformulaMatrix });

  return formulaMatrix;
};

const getParent = (path: any = [], formulaScope: any, row: any, formulaMatrix: any[]) => {
  const formKey = row;

  const dependentArray = Object.entries(formulaScope)
    .filter(([, value]: any) => value?.includes(`${formKey}`))
    .map(([key]) => key);
  
  if (formKey in formulaMatrix) {
    path.push(...formulaMatrix![formKey]);
  } else if (!_.isEmpty(dependentArray)) {
    path.push(...dependentArray);
    dependentArray?.map((key: any) => {
      if(key in formulaScope){
        getParent(path, formulaScope, key, formulaMatrix);
      }
    });
  }
  
  return path;
};

export const getInputValue = (inputJson: any) => {
  return Object.assign(
    [],
    Object.fromEntries(
      Object.entries(flatten(inputJson))
        .filter(([k]: any) => k?.endsWith('.value'))
        .map(([k, v]: any) => {
          const formkey = k?.replaceAll('.value', '')?.split('.')?.slice(0, 3)?.reverse().join('.');
          const key = k?.replaceAll('.value', '')?.split('.')?.slice(3)?.join('.');
          return Object.assign([], [`${formkey}.${key}`, v]);
        })
    )
  );
};

const uniqueKeepLast = (rows: any) => {
  return rows?.filter((v: any, i: any, a: any) => a.lastIndexOf(v) === i);
};

export const getInputScope = (stateCells: any) => {
  const inputScope = Object.fromEntries(
    Object.entries(stateCells as Object).map(([key, value]) => {
      return [
        [`${key}`],
        value &&
        Object.fromEntries(
          Object.entries(value as Object)
            .filter(([k]) => !k?.startsWith('0'))
            .map(([key, { value }]) => [
              key?.replaceAll('.', '_'),
              (value !== null && value !== undefined) ? value : 0,
            ])
        ),
      ];
    })
  );
  return inputScope;
};
