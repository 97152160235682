import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { Box } from '@mui/material';
import _ from 'lodash';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';

import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const Shareholdings = ({ control, getValues, setValue, watchAllFields, t }: any) => {
  const { isViewOnly } = useSelector((state: RootState) => state.registerCompany);
  const theme = useTheme();

  const ShareholdingsColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'name',
        headerName: 'Shareholder Name',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'numberOfShares',
        headerName: 'Number of Shares',
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'stake',
        headerName: '% Stake',
        editable: isViewOnly ? false : true,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        width: 150,
      },
    ];
    if (!isViewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('shareholding').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('shareholding'), {}];
                      setValue('shareholding', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}

              {getValues('shareholding').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('shareholding')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('shareholding', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('shareholding', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={t('companyMaster.shareholdings.shareholdings')} />
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={ShareholdingsColDefs}
          autoSize={true}
          rowData={watchAllFields?.shareholding}
          pagination={false}
          domLayout={'autoHeight'}
          sizeToFit={true}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
  );
};

export default Shareholdings;
