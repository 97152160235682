import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Grid, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';
import { useOrganizationDetails } from './companyProfileSchema';
import { useQuery } from 'react-query';
import { IFormSchema } from '../../../../../../models/forms-schema-types';
import { getRefDataByParentRef } from '../../../../../../services/common-services';
import { useLocation } from 'react-router-dom';
import useRefData from 'hooks/ref-data';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const CompanyBasicDetails = ({ control, getValues, setValue, watchAllFields }: any) => {
  const { pathname } = useLocation();
  const { refData } = useRefData();
  const { basicDetails } = useOrganizationDetails();
  const [companyEngaged, setCompanyEngaged] = useState<any>([]);
  const [foreignOffices, setForeignOffices] = useState<any>([]);

  const industry = useWatch({ control, name: 'industryTypeId', defaultValue: '' });
  const foreignOperationsState = useWatch({ control, name: 'foreignOperations', defaultValue: 'no' });

  const parentRefData = useQuery('getRefDataByParentRef', () => getRefDataByParentRef(industry), {
    enabled: false,
  });

  useEffect(() => {
    setCompanyEngaged(watchAllFields?.companyEngagedIn || []);
    setForeignOffices(watchAllFields?.foreignOffices || []);
  }, [watchAllFields]);

  useEffect(() => {
    if (watchAllFields?.companyIncorporationDate != '') {
      let numberOfYears = Math.abs(
        new Date().getFullYear() - new Date(watchAllFields?.companyIncorporationDate).getFullYear()
      );
      setValue('yearsInBusiness', numberOfYears);
    }
  }, [watchAllFields?.companyIncorporationDate]);

  useEffect(() => {
    if (industry) {
      parentRefData.refetch();
    }
  }, [industry]);

  const foreignOperations = useMemo(() => {
    return [
      {
        label: 'Foreign Operations',
        type: 'none',
        name: 'foreignOperations',
        component: 'radio',
        radioGroup: 'foreignOperations',
        values: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
      {
        label: 'Countries Operating In',
        name: 'foreignOffices',
      },
    ];
  }, [foreignOperationsState]);

  const BusinessSegment: readonly IFormSchema[] = [
    {
      label: 'Industry',
      name: 'industryTypeId',
      component: 'dropdown',
      options: refData.industryTypes || [],
    },
    {
      label: 'Sub-Sector',
      name: 'subIndustryTypeId',
      component: 'dropdown',
      options: parentRefData.data || [],
    },
    { label: 'Business Activities', type: 'text', name: 'businessActivities', component: 'textField' },
    { label: 'Company is engaged in', type: 'text', name: 'companyEngagedIn', component: 'textField' },
  ];

  const onCompanyEngagedChange = (value: string) => {
    let selected = companyEngaged;
    const index = companyEngaged.findIndex((i: any) => i === value);

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }

    setCompanyEngaged([...selected]);
    setValue('companyEngagedIn', companyEngaged);
  };

  const renderForm = (input: IFormSchema) => {
    const isDisabled = pathname.includes('view-company') ? true : false;
    return (
      <Grid item xs={6}>
        {input.name !== 'companyEngagedIn' &&
        input.name !== 'foreignOffices' &&
        input.name !== 'yearsInBusiness' ? (
          <Box className="required" sx={{ display: 'flex' }}>
            <SimpleFormLabel label={input.label} sx={{ width: '40%' }} />
            {getFormFields(input, control, isDisabled, { required: true })}
          </Box>
        ) : null}
        {input.name === 'foreignOffices' && watchAllFields?.foreignOperations === 'yes' ? (
          <Box className="required" sx={{ display: 'flex' }}>
            <SimpleFormLabel label={input.label} sx={{ width: '40%', placeSelf: 'flex-start' }} />
            <Autocomplete
              sx={{
                padding: 0,
                '& .MuiAutocomplete-inputRoot': {
                  padding: '7px 11px',
                },
                '& .MuiChip-label': {
                  fontSize: '12px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0px',
                },
                width: '20rem',
              }}
              multiple
              id="tags-outlined"
              value={foreignOffices}
              options={refData?.countryList}
              getOptionLabel={(option) => option.displayText}
              disabled={isDisabled}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue: any) => {
                setForeignOffices(newValue);
                setValue('foreignOffices', newValue);
              }}
            />
          </Box>
        ) : null}
        {input.name === 'companyEngagedIn' && (
          <Box className="required" sx={{ display: 'flex' }}>
            <SimpleFormLabel label={input.label} sx={{ width: '40%' }} />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, pl: 1 }}
                  onChange={() => onCompanyEngagedChange('imports')}
                  checked={companyEngaged.includes('imports')}
                  disabled={isDisabled}
                />
              }
              label="Imports"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, pl: 1 }}
                  onChange={() => onCompanyEngagedChange('exports')}
                  checked={companyEngaged.includes('exports')}
                  disabled={isDisabled}
                />
              }
              label="Exports"
            />
          </Box>
        )}
        {input.name === 'yearsInBusiness' &&
        watchAllFields?.companyIncorporationDate !== '' &&
        watchAllFields?.yearsInBusiness > 0 ? (
          <Box className="required" sx={{ display: 'flex' }}>
            <SimpleFormLabel label={input.label} sx={{ width: '40%', placeSelf: 'flex-start' }} />
            <Typography variant="body1">
              {watchAllFields?.yearsInBusiness} {watchAllFields?.yearsInBusiness <= 1 ? 'year' : 'years'}
            </Typography>
          </Box>
        ) : null}
      </Grid>
    );
  };

  const renderFormFields = (schema: any) => {
    return _.map(schema, (input, index: number) => {
      if (index % 2 === 0) {
        return renderForm(input);
      }
      return renderForm(input);
    });
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <FormTitle title="Organization Details" />
        <Box>
          <Grid container rowSpacing={1} sx={{ mt: 0 }}>
            {renderFormFields(basicDetails)}
          </Grid>
        </Box>
      </Box>
      {/* BusinessSegment */}
      <Box sx={{ mt: 1 }}>
        <FormTitle title="Business Segment" />
        <Box>
          <Grid container rowSpacing={1} sx={{ mt: 0 }}>
            {renderFormFields(BusinessSegment)}
            {renderFormFields(foreignOperations)}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyBasicDetails;
