import { Outlet } from 'react-router-dom';
import LoanProjection from '../../../loan-projection-and-proposal';

const FinancialProjections = () => {
  // const steps = [
  //   { label: 'Basic Deatails', description: renderBasicDetails() },
  //   { label: 'Actual Inputs', description: renderInput(FormGroup.ACTUALS) },
  //   { label: 'Projected Inputs', description: renderInput(FormGroup.PROJECTION) },
  // ];

  return (
    <LoanProjection>
      <Outlet />
    </LoanProjection>
  );
};

export default FinancialProjections;
