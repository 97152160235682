import {
  Box,
  Typography,
  Divider
} from '@mui/material';
import _ from 'lodash';
import { getFormFields } from 'utils/form-utils';
import { RADIO_BUTTONS } from 'constants/form-components';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { useCallback } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';


export default function BankingArrangementAndAssesmentLimits({
  t,
  control,
  getValues,
  setValue,
  watchAllFields,
}: IPerformanceSummaryTableProps) {
  const { state } = useLocation();
  const theme = useTheme();
  const workingCapitalColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'bankName',
        headerName: 'Name of the Bank',
        editable: state.viewOnly ? false : true,
        width: 150,
      },
      {
        headerName: 'Present limits',
        children: [
          {
            field: 'fbPresentLimit',
            headerName: 'FB',
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
            editable: state.viewOnly ? false : true,
            width: 150,
          },
          {
            field: 'nfbPresentLimit',
            headerName: 'NFB',
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
            editable: state.viewOnly ? false : true,
            width: 150,
          },
        ],
      },
      {
        field: 'percentage',
        headerName: 'Percentage',
        editable: state.viewOnly ? false : true,
        width: 150,
      },
    ];
    if (!state.viewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('workingCapital').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('workingCapital'), {}];
                      setValue('workingCapital', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('workingCapital').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('workingCapital')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('workingCapital', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const termLoansColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'bankName',
        headerName: 'Name of the Bank',
        editable: state.viewOnly ? false : true,
        width: 150,
      },
      {
        field: 'limit',
        headerName: 'Limit',
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        editable: state.viewOnly ? false : true,
        width: 150,
      },
      {
        field: 'outstanding',
        headerName: 'Outstanding',
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        editable: state.viewOnly ? false : true,
        width: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        editable: state.viewOnly ? false : true,
        width: 150,
      },
    ];
    if (!state.viewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('termLoansBanking').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('termLoansBanking'), {}];
                      setValue('termLoansBanking', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('termLoansBanking').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('termLoansBanking')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('termLoansBanking', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onWorkingCapitalCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('workingCapital', updatedRowData);
  }, []);

  const onTermLoansCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('termLoansBanking', updatedRowData);
  }, []);

  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={'5.1 Banking Arrangement'} />
        <Box sx={{ mt: 0.5 }}>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <SimpleFormLabel label={'Type of Banking'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
            <Box sx={{ width: '75%' }}>
              {getFormFields(
                {
                  name: 'typeOfBanking',
                  component: RADIO_BUTTONS,
                  radioGroup: 'bankingType',
                  values: [
                    {
                      label: 'Consortium',
                      value: 'consortium',
                    },
                    { label: 'Multiple', value: 'multiple' },
                    { label: 'Solo', value: 'solo' },
                  ],
                },
                control
              )}
            </Box>
          </Box>
          <Box>
            <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
              <Typography variant="caption">
                <b>{t('loanProjection.performanceSummary.bankingArrangement.workingCapital')}</b>
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ pl: 0.5, pr: 0.5, mb: 7 }}>
              <Box sx={{ mt: 1 }}>
                <DataTable
                  columnDefs={workingCapitalColDefs}
                  autoSize={true}
                  rowData={watchAllFields?.workingCapital}
                  pagination={false}
                  domLayout={'autoHeight'}
                  sizeToFit={true}
                  style={{ height: '100%' }}
                  onCellValueChanged={onWorkingCapitalCellValueChanged}
                />
              </Box>
            </Box>
          </Box>
          {/**TERM LOAN BANKING DETAILS TABLE */}
          <Box>
            <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
              <Typography variant="caption">
                <b>{t('loanProjection.performanceSummary.bankingArrangement.termLoans')}</b>
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ pl: 0.5, pr: 0.5, mb: 7 }}>
              <Box sx={{ mt: 1 }}>
                <DataTable
                  columnDefs={termLoansColDefs}
                  autoSize={true}
                  rowData={watchAllFields?.termLoansBanking}
                  pagination={false}
                  domLayout={'autoHeight'}
                  sizeToFit={true}
                  style={{ height: '100%' }}
                  onCellValueChanged={onTermLoansCellValueChanged}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
