import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, IconButton, Avatar } from '@mui/material';
// components
import MenuPopover from 'components/common/pages/MenuPopover';

// ----------------------------------------------------------------------

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/store';
import { deleteUserData } from 'state/reducers/user-reducer';
import { routes } from 'constants/strings';

// ----------------------------------------------------------------------

export default function ProfilePopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { firstName, lastName, roles, permissionList, username }: any = useSelector(
    (state: RootState) => state.user?.userDetails
  );

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(deleteUserData());
    localStorage.removeItem('user');
    navigate(routes.LOGIN_ROUTE);
  };

  const getIconStyle = () => {
    if (open) {
      return {
        p: 0,
        '&:before': {
          zIndex: 1,
          content: "''",
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          position: 'absolute',
          bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.8),
        },
      };
    } else {
      return {
        p: 0,
        color: '#ffffff',
      };
    }
  };

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleOpen} sx={getIconStyle()}>
        <Avatar sx={{ width: '32px', height: '32px' }}>
          <Typography variant="h6">
            {firstName?.charAt(0)?.toUpperCase() + '' + lastName?.charAt(0)?.toUpperCase()}
          </Typography>
        </Avatar>
      </IconButton>

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.0,
          ml: 0.7,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {username}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {roles?.map((role: any, index: number): any => {
              return <span key={`demo_snap_${index}`}>Role: {(index ? ', ' : '') + role.role}</span>;
            })}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {permissionList?.map((permission: any, index: number): any => {
              return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + permission}</span>;
            })}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          <Typography>Profile</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          <Typography>My Account</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          <Typography>Administration</Typography>
        </MenuItem>
        <MenuItem onClick={() => navigate(routes.COMMUNICATION_ROUTE)} sx={{ m: 1 }}>
          <Typography>Customer Communication</Typography>
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Typography>Logout</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
