import { Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import _ from 'lodash';
import TableHeading from 'components/common/table-header';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { Row, SubRow, SubSubRow } from 'components/common/table/table-data-row/rows';
import { toggleLoader } from 'state/reducers/common-actions';
import useLocalStore from 'hooks/user-local-store';
import { roles, routes } from 'constants/strings';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import { changeMenuTab } from 'state/reducers/tabReducer';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

export default function FormIII() {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formIII'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form3, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form3[idx].actuals) {
      return data?.form3[idx].actuals;
    }
    return data?.form3[idx]?.projection;
  });
  console.log(data, 'form3 data');

  return (
    <MainCard>
      <Table size="small">
        <TableHeading title="Balance Sheet" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Assessment of working capital requirement</b>
              </Typography>
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm
              colSpan={numberOfYears.length + 1}
              sx={{ backgroundColor: '#333996', color: '#ffffff', p: 1, fontWeight: 800 }}>
              Assets
            </DenseCellSm>
          </TableRow>
          <Row
            heading
            title="Cash & Cash Equivalents"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.cashAndCashEquivalents)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Bank Balances"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherBankBalance)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Investments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.investments.investmentsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Investments in Government Securities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.investments.investmentInGovtSecurities)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Investments in Equity Instruments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.investments.investmentInEquity)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Investment in Mutual Funds"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.investments.investmentInMutualFund)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Inventories"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.inventories.inventoriesValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Raw Materials"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.rawMaterial)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.rawMaterialDomestic)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Imported"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.rawMaterialImported)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Stores & Spares"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.storesAndSpares)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.storesAndSparesDomestic)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Imported"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.storesAndSparesImported)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Stock-in-process"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.stockInProcess)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Finished Goods"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.finishedGoods)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Allowance for Inventory (-)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.inventories.allowanceForInventory)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Trade Receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.tradeReceivables.tradeReceivablesValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Domestic receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.tradeReceivables.receivablesLsTh6Months)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Export receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.tradeReceivables.receivablesMrTh6Months)}
                </NumberDenseCell>
              );
            })}
          />
          {/*<SubRow
            title="Unsecured Credit Impaired"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>                  {formatNumber(dataArr[idx]?.assets.tradeReceivables.unsecuredCreditImpaired)}</NumberDenseCell>
              );
            })}
          />*/}
          <SubRow
            title="Less: Impairment Allowance for Doubtfull Debt"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.tradeReceivables.impairedAllowanceForDoubtfulDebt)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="(of which Export receivables)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.tradeReceivables.ofWhichExportReceivables)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Financial Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherFinancialAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Operating Current Asset"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherOperatingCurrentAssets.otherOperatingCurrentAssetsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Advances to Suppliers (Excluding on Capital a/c)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.advanceToSupplier)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="GST tax"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.gstTax)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Loans & Advances to subsidiaries"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.loansAndAdvancesToSubs)}
                </NumberDenseCell>
              );
            })}
          />
          {/**need to discuss not found on json */}
          <SubRow
            title="Export Incentive receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.exportIncentives)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="VAT/Cenvat Receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.vat)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Advance royalty"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.advanceRoyalty)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Deposit and Advance with Authorities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.depositAndAdvanceToAuthority)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Prepaid expenses"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.prepaidExpense)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherOperatingCurrentAssets.others)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Total Current Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.totalCurrentAsset)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Gross Block"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.grossBlock.grossBlockValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Land, building, plant, machinery etc."
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.grossBlock.landPlantAndOtherFixedAsset)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Accumulated depreciation"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.grossBlock.accumulatedDepreciation)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Net Block"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.netBlock.netBlockValue)}
                </NumberDenseCell>
              );
            })}
          />
          {/**Need to discuss */}
          <SubRow
            title="Add: Capital work-in-process"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.netBlock.capitalWIP)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="less: Revaluation reserves"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>                  
                  {formatNumber(dataArr[idx]?.assets.netBlock.lessRevaluationReserves)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Net Fixed Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.netFixedAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="OTHER NON-CURRENT ASSETS"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell align="right">{}</HeadingDenseCell>;
            })}
          />
          <Row
            heading
            title="Other Assets/Right to Use"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.otherAssetsRightToUse)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Investment Property"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.otherInvestmentProperty)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Goodwill"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.goodwill)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Intangible Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.intangibleAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Exposure to Subsidiaries and Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <HeadingDenseCell align="right">
                  {formatNumber(
                    dataArr[idx].assets.otherNonCurrentAssets.exposureToSubsidiaries.exposureToSubsidiariesValue
                  )}
                </HeadingDenseCell>
              );
            })}
          />
          <SubRow
            title="Investment in Subisidiaries and Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.assets.otherNonCurrentAssets.exposureToSubsidiaries.investmentInSubsidiaries
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Loans to Subisidiaries and Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.assets.otherNonCurrentAssets.exposureToSubsidiaries.loanToSubsidiaries
                  )}
                </DenseCellSm>
              );
            })}
          />

          <Row
            heading
            title="Other Non-Current Investments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherNonCurrentAssets.otherNonCurrentInvestments)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Trade receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherNonCurrentAssets.otherTradeReceivables)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Loans & Advances"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.otherLoanAndAdvances)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Financial Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.otherFinancialAssetsNonCurr)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Deferred Tax Asset"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.deferredTaxAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Others Non-Current Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <HeadingDenseCell align="right">
                  {formatNumber(
                    dataArr[idx].assets.otherNonCurrentAssets.othersNonCurrentAssets.otherNonCurrentAssetsValue
                  )}
                </HeadingDenseCell>
              );
            })}
          />
          <SubRow
            title="Capital Advances"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherNonCurrentAssets.othersNonCurrentAssets.capitalAdvances)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Interest accrued but not due with Banks"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherNonCurrentAssets.othersNonCurrentAssets.interestAccrued)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other in lien under reqgulatory requirement"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.assets.otherNonCurrentAssets.othersNonCurrentAssets.regulatoryRequirement
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Deposit with authorities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.assets.otherNonCurrentAssets.othersNonCurrentAssets.depositWithAuthorities
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assets.otherNonCurrentAssets.othersNonCurrentAssets.others)}
                </NumberDenseCell>
              );
            })}
          />
          {/**need to discuss */}
          <Row
            heading
            title="Total Other Non-Current Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.totalOtherNonCurrentAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="TOTAL ASSETS"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].assets.otherNonCurrentAssets.totalAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <p></p>
          <p></p>
          <TableRow>
            <DenseCellSm
              colSpan={numberOfYears.length + 1}
              sx={{ backgroundColor: '#333996', color: '#ffffff', p: 1, fontWeight: 800 }}>
              LIABILITIES
            </DenseCellSm>
          </TableRow>
          <Row
            heading
            title="Bank Borrowings"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.bankBorrowings.bankBorrowingValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="From applicant bank"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.bankBorrowings.fromApplicationBank)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="From other banks"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.bankBorrowings.fromOtherBank)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Working Capital STD"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.otherWorkingCapitalStd.shortTermBorrowingValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Short term borrowings from others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherWorkingCapitalStd.shortTermBorrowing)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Mobilization advance- current portion"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherWorkingCapitalStd.mobilizationAdvance)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Trade Payable"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.tradePayable)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Installment of liabilities due within 1 Year"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.installmentOfLiabilities.installmentOfLiabilitiesValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Preference Capital (redeemed after 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.installmentOfLiabilities.preferenceCapital)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Debentures (maturity > 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.installmentOfLiabilities.debentures)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Current portion of LTD"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.installmentOfLiabilities.currentPortionOfLTD)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Borrowing from Subsidiaries & Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.installmentOfLiabilities.borrowingFromSubs)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Current portion of lease obligations"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.liabilities.installmentOfLiabilities.currentPortionOfLeaseObligations
                  )}
                </DenseCellSm>
              );
            })}
          />
          <Row
            heading
            title="Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.provisions.provisionsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Provison for Employee benefits"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.provisions.provisionForEmployeeBenefit)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.provisions.otherProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Financial Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.otherFinancialLiabilities.otherFinancialLiabilitiesValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Advances from Affiliates & Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.advancesFromAffiliates)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Tax Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.taxProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Dividends Payable"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.dividendsPayable)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Liability for Operating Expenses"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.liabilityForOperatingExpense)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Creditors for Capital Goods"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.creditorsForCapitalGoods)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Earnest money on security deposits"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.earnestMoney)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Payable to employees"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.payableToEmployees)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Accrued Interest"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.accruedInterest)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Accrued Interest on borrrowings"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.accruedInterestOnBorrowing)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Others (Derviatives and others)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.others)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Advance from customers"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.advanceFromCustomers)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Statutory Dues Payables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiabilities.statutoryDuesPayable)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Total Other Current Liabilities & Provisions (excl. Bank borrowing)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalOtherCurrentLiabilitiesAndProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Total Current Liabilities & Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalCurrentLiabilitiesAndProvisions)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Net Worth"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.netWorthValue)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Equity Capital"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.equityCapital)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Preference Capital (redemption > 12 years)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.preferenceCapitalInNetWorth)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="General Reserve"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.generalReserve)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Retained Earnings"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.retainedEarnings)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Revaluation Reserves"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.revaluationReserves)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other equity including OCI"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.otherEquityIncludingOci)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Equity"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.netWorth.otherEquity.otherEquityValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Share Premium"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.sharePremium)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Suspense account"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.suspenseAmount)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Capital Redemption reserve"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.capitalRedemptionReserve)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other Reserves & Surplus"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.otherReservesSurplus)}
                </NumberDenseCell>
              );
            })}
          />  
          <SubRow
            title="Minority Interest "
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.minorityInterest)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.netWorth.otherEquity.others)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Tangible Networth"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.tangibleNetWorth.tangibleNetWorthValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Less: Revaluation Reserve"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.tangibleNetWorth.revaluationReserves)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Less: Intangibles ( Enter as + ve)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.tangibleNetWorth.intangibles)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Total Long-term debt (> 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalLongTermDebt.totalLongTermDebtValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Preference Capital (redeemed after 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.totalLongTermDebt.preferenceCapital)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Debentures (maturity > 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.totalLongTermDebt.debentures)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Loans from Banks & FI's"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.totalLongTermDebt.loansFromBanksAndFi)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Borrowing from Affiliates & Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.totalLongTermDebt.borrowingFromAffiliates)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Lease Liaiblities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.leaseLiabilities)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other Financial Liability"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.otherFinancialLiability.otherFinancialLiabilitiesValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Electrical duty"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiability.electricalDuty)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.liabilities.otherFinancialLiability.others)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Deferred Tax Liability"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.deferredTaxLiability)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.nonCurrentProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Total Term Liabilities	"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalTermLiabilities)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Total Non-Current Liabilites"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalNonCurrentLiabilities)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Total Outside Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalOutsideLiabilities)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="TOTAL LIABILITIES"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].liabilities.totalLiabilities)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Summary"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell align="right">{}</HeadingDenseCell>;
            })}
          />
          {/**nees to discuss */}
          <SubRow
            title="Tangible Net Worth (25-21-24(e) -46)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx]?.summary.tangibleNetWorth)}</NumberDenseCell>
              );
            })}
          />
          {/**nees to discuss */}
          <SubRow
            title="Net Working Capital  (35 - 10)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.summary.netWorkingCapital)}
                </NumberDenseCell>
              );
            })}
          />
          {/**nees to discuss */}
          <SubRow
            title="Current Ratio (35 / 10)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}> {formatNumber(dataArr[idx]?.summary.currentRatio)}</NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Total Outside Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.summary.totalOutsideLiabilities)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Total Term Liabilities / Tangible Net Worth "
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.summary.totalTermLiabilities)}
                </NumberDenseCell>
              );
            })}
          />
        </TableBody>
      </Table>

      <ReviewCamButton
        onClick={() => {
          navigate(routes.CAM_ROUTE, {
            state: { ...state, loanRequestId: state.loanRequestId },
          });
          const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
          camId && camId?.id && dispatch(changeMenuTab(camId?.id));
        }}
      />
    </MainCard>
  );
}
