import {
  LOAN_STATUS,
  LOAN_ACCESS,
  LOAN_ROLE,
  MEMO_TYPE,
} from 'components/dashboard/credit-analysis/loan-proposal/LoanProposalConsts';
import useRefData from 'hooks/ref-data';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getRegisteredOrgList } from 'services/company-services';
import { RootState } from 'state/reducers';
import { routes } from 'constants/strings';

const useLoanTable = () => {
  const { data: companyData } = useQuery('getCompanyData', getRegisteredOrgList);
  const { fn } = useRefData();
  const { mapCurrencyData, mapRequestType, mapLoanType, mapMemoType, mapLoanSubType, mapSourceOfRepayment } = fn;
  const { roles, menuList }: any = useSelector((state: RootState) => state.user?.userDetails);

  const _requestData = (loanRequests: any | null) => {
    return _.map(loanRequests, (loanRequestData) => {
      const company = companyData?.filter(({ id }: any) => id === loanRequestData?.orgId);

      return {
        companyId: loanRequestData?.orgId,
        loanRequestId: loanRequestData?.id || loanRequestData?.loanId,
        loanId: loanRequestData?.loanId,
        memoId: loanRequestData?.memoId,
        loanRequestDate: loanRequestData?.loanRequestDate,
        parentMemoId: loanRequestData?.parentMemoId,
        company: company && company![0]?.org_name,
        requestType: mapRequestType(loanRequestData?.requestType),
        loanType: mapLoanType(loanRequestData?.loanType),
        loanSubType: mapLoanSubType(loanRequestData?.loanSubType),
        purposeOfFacility: loanRequestData?.purposeOfFacility,
        sourceRepayment: mapSourceOfRepayment(loanRequestData?.sourceRepayment),
        amount: loanRequestData?.loanAmount,
        tenor: loanRequestData?.loanTenor,
        rmTeamLead: loanRequestData?.rmTeamLead,
        loanCurrency: mapCurrencyData(loanRequestData?.loanCurrency),
        status: getRequestStatus(loanRequestData?.status),
        statusCode: loanRequestData?.status,
        approvalStatus: getRequestStatus(loanRequestData?.approvalStatus),
        accessMode: getAccessMode(loanRequestData?.accessMode),
        flowableTaskId: loanRequestData?.flowableTaskId || loanRequestData?.currentTaskId,
        currentAssignee: loanRequestData?.currentAssignee,
        rm: loanRequestData?.rmUserid,
        reviewType: getMemoType(loanRequestData?.reviewType),
        requestor: loanRequestData?.createdBy,
        slaTimestamp: loanRequestData?.slaTimestamp,
        momFlag: loanRequestData?.momFlag,
        camsFlag: loanRequestData?.camsFlag,
        date: loanRequestData?.date,
        from: loanRequestData?.from,
        approverType: loanRequestData?.approverType,
        subject: loanRequestData?.subject,
        typeOfMemo: mapMemoType(loanRequestData?.typeOfMemo),
        memoDocId: loanRequestData?.memoDocId,
        approvalTaskStatus: getRequestStatus(loanRequestData?.approvalTaskStatus),
      };
    });
  };
  const getLoanRequestState = (loanData: any, viewOnly: boolean = false, isEdit: boolean = false) => {
    return {
      companyId: loanData?.companyId,
      loanRequestId: loanData?.loanRequestId,
      loanId: loanData?.loanId,
      loanRequestDate: loanData?.loanRequestDate,
      memoId: loanData?.memoId,
      parentMemoId: loanData?.parentMemoId,
      company: loanData?.company,
      amount: loanData?.amount,
      loanCurrency: loanData?.loanCurrency,
      rmTeamLead: loanData?.rmTeamLead,
      status: loanData?.status,
      statusCode: loanData?.statusCode,
      approvalStatus: loanData?.approvalStatus,
      flowableTaskId: loanData?.flowableTaskId,
      reviewType: loanData?.reviewType,
      momFlag: loanData?.momFlag,
      camsFlag: loanData?.camsFlag,
      date: loanData?.date,
      from: loanData?.from,
      approverType: loanData?.approverType,
      requestType: loanData?.requestType,
      loanType: loanData?.loanType,
      loanSubType: loanData?.loanSubType,
      purposeOfFacility: loanData?.purposeOfFacility,
      sourceRepayment: loanData?.sourceRepayment,
      subject: loanData?.subject,
      typeOfMemo: loanData?.typeOfMemo,
      accessMode: loanData?.accessMode,
      memoDocId: loanData?.memoDocId,
      viewOnly: viewOnly,
      isEdit:
        isEdit &&
        (loanData?.statusCode === getRequestStatusCode('DRAFT') ||
          loanData?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
          loanData?.statusCode === getRequestStatusCode('REWORK_WITH_RM')),
    };
  };

  const getAccessMode = (access: string) => {
    if (LOAN_ACCESS && access) {
      return LOAN_ACCESS[access as keyof typeof LOAN_ACCESS] || '';
    }
    return '';
  };

  const getRequestStatus = (status: string) => {
    if (LOAN_STATUS && status) {
      return LOAN_STATUS[status.toUpperCase() as keyof typeof LOAN_STATUS] || status || '';
    }
    return status || '';
  };

  const getMemoType = (type: string) => {
    if (MEMO_TYPE && type) {
      return MEMO_TYPE[type.toUpperCase() as keyof typeof MEMO_TYPE] || type || '';
    }
    return type || '';
  };
  const getRequestStatusCode = (status: string) => {
    if (LOAN_STATUS && status) {
      return Object.keys(LOAN_STATUS)?.includes(status) ? status : '';
    }
    return '';
  };

  const isRole = (role: string) => {
    if (role) {
      return roles?.some((r: any) => r?.role === role);
    }
    return false;
  };

  const getCurrentRole = () => {
    const { role } = roles;
    return role;
  };

  const getHomePage = () => {
    let route = routes.LOAN_OFFICER_ROUTE;
    if (menuList?.includes('LOAN_OFFICER')) {
      route = routes.LOAN_OFFICER_ROUTE;
    } else if (menuList?.includes('RISK_UNIT')) {
      route = routes.RISK_UNIT_ROUTE;
    } else if (menuList?.includes('APPROVAL_AUTHORITY')) {
      route = routes.APPROVAL_AUTHORITY_ROUTE;
    } else if (menuList?.includes('LEGAL_ADMIN')) {
      route = routes.LEGAL_ADMIN_ROUTE;
    } else if (menuList?.includes('CREDIT_ADMIN')) {
      route = routes.CREDIT_ADMIN_ROUTE;
    }

    return route;
  };

  const isRM = () => {
    if (LOAN_ROLE) {
      return roles?.some((r: any) => r?.division?.toUpperCase() === LOAN_ROLE?.BUSINESS);
    }
    return false;
  };

  const isRisk = () => {
    if (LOAN_ROLE) {
      return roles?.some((r: any) => r?.division?.toUpperCase() === LOAN_ROLE?.RISK);
    }
    return false;
  };

  return {
    getLoanRequestState,
    _requestData,
    getAccessMode,
    getRequestStatusCode,
    isRole,
    isRM,
    isRisk,
    getCurrentRole,
    getHomePage,
  };
};

export default useLoanTable;
