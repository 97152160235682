import { useState } from 'react';
import { Box, Divider, Button } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { BreadCrumbs } from 'components/common/pages/BreadCrumbs';
import ProjectionLayout from '../../layout/projection-layout';
import { toggleLoader } from 'state/reducers/common-actions';
import { approveReject } from 'services/credit-service';
import CreditMenu from './CreditMenu';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import ActionBar from 'components/styled-components/ActionBar';
import DialogWithSanctionForm from 'components/common/modals/DialogWithSanctionForm';
import { RootState } from 'state/reducers';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';
import { getMeta} from 'services/document-services';

const CreditLayout = (props: any) => {
  const { getRequestStatusCode, getHomePage } = useLoanTable();
  const [open, setOpen] = useState(false);
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const [formOpen, setFormOpen] = useState<boolean | null>(null);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isSuccess } = useQuery(
    ['getMemo', state?.loanRequestId],
    () => getMeta(state?.loanRequestId, 'SANCTION_LETTER', 'LOAN_REQUEST')
  );

  const approveReturnMutation = useMutation((approvalData: any) => approveReject(approvalData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(
        `Loan Request ID : ${state?.loanRequestId} has been ${
          variables?.approvalStatus ? 'Approved' : 'Returned'
        }`,
        {
          variant: 'success',
        }
      );
      navigate(getHomePage());
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const onClickApproveReturnHandler = (comment: string) => {
    if (state?.loanRequestId && approveReturn != null && state?.flowableTaskId) {
      approveReturnMutation.mutate({
        approvalStatus: approveReturn,
        loanId: state?.loanRequestId,
        approvalComment: comment,
        approvalTaskId: state?.flowableTaskId,
      });
    } else {
      enqueueSnackbar(`Unable to approve request for Loan Request ID : ${state?.loanRequestId}`, {
        variant: 'error',
      });
    }
  };

  const handleClickFormOpen = () => {
    setMessage('Confirmation');
    setFormOpen(true);
  };

  const handleClickOpen = (approveReturn: boolean | null) => {
    if(isSuccess && data?.id){   

    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      setMessage('Confirmation');
      setOpen(true);
    }
  }else{
    enqueueSnackbar(`Please generate Sacntion Letter for Loan Request Id : ${state?.loanRequestId}`, {
        variant: 'error',
      });
  }

  };

  return (
    <ProjectionLayout>
      <Box>
        <BreadCrumbs
          title={t('screenHeaders.creditAdmin.heading')}
          loanRequestId={state?.loanRequestId}
          company={state?.company}
          amount={state?.amount}
          route={getHomePage()}
        />
        <CreditMenu />
        <Divider />
        {props.children}

        {!state?.viewOnly && pathname === routes.SANCTION_LETTER_ROUTE && (
          <ActionBar>
            <Button variant="contained" onClick={() => handleClickFormOpen()} sx={{ p: 1, right: 20 }}>
              Generate
            </Button>
            <Button
              type="submit"
              onClick={() => handleClickOpen(true)}
              variant="contained"
              sx={{ p: 1, right: 10 }}>
              Submit
            </Button>
          </ActionBar>
        )}

        <DialogWithComment
          open={open}
          setOpen={setOpen}
          title={message}
          description={`Do you want to ${approveReturn ? 'Submit' : 'Return'} for Loan Request ID: ${
            state?.loanRequestId
          } ?`}
          type={'info'}
          onClickHandler={onClickApproveReturnHandler}
        />
      </Box>

      <DialogWithSanctionForm
        open={formOpen}
        setOpen={setFormOpen}
        title={message}
        description={`Please fill in details to generate Sanction Letter:`}
        type={'info'}
      />
    </ProjectionLayout>
  );
};

export default CreditLayout;
