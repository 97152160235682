import { useMemo, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Box, Button, Grid, styled } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { ActionCell } from 'components/common/data-table/table-action-cell';
import DataTable from 'components/common/data-table';
import ContentLayout from 'components/common/containers/content-layout';
import { strings, routes } from 'constants/strings';
import {
  getInProgressRequests,
  getClosedRequests,
  getPendingRequests,
  getTeamRequests,
} from 'services/loan-request-services';
import BackdropLoader from 'components/common/backdrop-loader';
import { LOAN_TABLE } from '../LoanProposalConsts';
import { RootState } from '../../../../../state/store';
import { startProposal } from 'services/loan-projection-services';
import { useSnackbar } from 'notistack';
import useLoanTable from 'hooks/useLoanTable';
import ActionBar from 'components/styled-components/ActionBar';

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 16,
  marginRight: 16,
}));

const LoanRequests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getLoanRequestState, _requestData, getAccessMode, getRequestStatusCode, getHomePage } = useLoanTable();
  const { state: tabState, pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { permissionList, username }: any = useSelector((state: RootState) => state?.user?.userDetails);
  const [tab, setTab] = useState('MY_REQUEST');
  const [myRequestTab, setMyRequestTab] = useState('PENDING_ACTIONS');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleMyRequestTab = (event: React.SyntheticEvent, newValue: string) => {
    setMyRequestTab(newValue);
  };

  const { t } = useTranslation();

  const startProposalMutation = useMutation((loanDetails: any) => startProposal(loanDetails), {
    onSuccess: (data, variables) => {
      navigate(routes.LOAN_PROPOSAL_ROUTE, {
        state: {
          ...getLoanRequestState(variables, false, true),
        },
      });
      enqueueSnackbar(`Proposal Started for Loan Request ID: ${variables?.loanRequestId}`, {
        variant: 'success',
      });
    },
    onError: (err, variables) => {
      enqueueSnackbar(`Proposal failed to start for Loan Request ID: ${variables?.loanRequestId}`, {
        variant: 'error',
      });
    },
  });

  const { data: inProgressRequestsData } = useQuery(['getInProgressRequests', username], getInProgressRequests);
  const { data: closedRequestsData } = useQuery(['getClosedRequests', username], getClosedRequests);
  const { data: pendingRequestsData } = useQuery(['getPendingRequests', username], getPendingRequests);
  const { data: teamRequestsData } = useQuery(['getTeamRequests', username], getTeamRequests);

  const isCreateAccess = () => {
    return permissionList && getAccessMode('CREATE_REQUEST') === 'CREATE_REQUEST';
  };

  const handleStartProposal = (loanDetails: any) => {
    if (loanDetails?.loanRequestId) {
      startProposalMutation.mutate(loanDetails);
    }
  };

  const getPath = (loanData: any) => {
    let path = routes.LOAN_PROPOSAL_ROUTE;
    if (loanData?.statusCode === getRequestStatusCode('PENDING_FOR_RISK_APPROVAL')) {
      path = routes.RISK_RATIONALE_ROUTE;
      if (loanData?.accessMode === getAccessMode('READ_ONLY')) {
        path = routes.REVIEW_COMMENT_ROUTE;
      }
    } else if (loanData?.statusCode === getRequestStatusCode('PENDING_FOR_BUS_SUP_APPROVAL')) {
      path = routes.CAM_ROUTE;
    } else if (loanData?.statusCode === getRequestStatusCode('PENDING_WITH_RM_DOC_PREP')) {
      path = routes.DOCUMENT_PREPARATION;
    }
    return path;
  };

  useEffect(() => {
    tabState?.tabId && setTab("MY_REQUEST");
    tabState?.tabId && setMyRequestTab(tabState?.tabId);

  }, [tabState?.tabId]);

  const onNewRequest = () => navigate(routes.NEW_LOAN_REQUEST_ROUTE);

  const gotoLoanRequest = (loanData: any, viewOnly: boolean = true, isEdit = false) => {
    navigate(getPath(loanData), {
      state: {
        ...getLoanRequestState(loanData, viewOnly, isEdit),
      },
    });
  };

  const isViewEnabled = (loanData: any) => {
    return (loanData?.statusCode !== getRequestStatusCode('DRAFT') && (myRequestTab !== 'PENDING_ACTIONS' || tab === 'TEAM_REQUEST')) || (loanData?.statusCode === getRequestStatusCode('DRAFT') && tab === 'TEAM_REQUEST')
  }

  const isEditEnabled = (loanData: any) => {
    return loanData?.accessMode === getAccessMode('EDIT') &&
            (loanData?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
              loanData?.statusCode === getRequestStatusCode('REWORK_WITH_RM'))
  }

  const isStartEnabled = (loanData: any) => {
    return loanData?.statusCode === getRequestStatusCode('DRAFT') &&
            loanData?.accessMode === getAccessMode('EDIT')
  }

  const isApproveEnabled = (loanData: any) => {
    return (
      loanData?.statusCode !== getRequestStatusCode('DRAFT') &&
      loanData?.statusCode !== getRequestStatusCode('PROPOSAL_PENDING') &&
      loanData?.statusCode !== getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') &&
      loanData?.statusCode !== getRequestStatusCode('REWORK_WITH_RM') &&
      loanData?.statusCode !== getRequestStatusCode('APPROVED') &&
      loanData?.accessMode === getAccessMode('EDIT')
    );
  }

  const isAssignEnabled = (loanData: any) => {
    return loanData?.statusCode === getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') &&
            loanData?.accessMode === getAccessMode('EDIT')
  }

  const ACTION = {
    field: 'action',
    pinned: 'right',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    sortable: false,
    cellRendererFramework: (params: any) => {
      return ActionCell({
        view: {
          viewBtnTitle: t('constants.strings.VIEW_LOAN_PROPOSAL'),
          onView: () => gotoLoanRequest(params?.data, true, false),
          enabled: isViewEnabled(params?.data),
        },
        edit: {
          editBtnTitle: t('constants.strings.EDIT_LOAN_PROPOSAL'),
          onEdit: () => gotoLoanRequest(params?.data, false, isEditEnabled(params?.data)),
          enabled: isEditEnabled(params?.data),
        },
        start: {
          startBtnTitle: 'Start Proposal',
          onStart: () => handleStartProposal(params?.data),
          enabled: isStartEnabled(params?.data),
        },
        approve: {
          approveBtnTitle: 'Approve',
          onApprove: () => gotoLoanRequest(params?.data, false, isApproveEnabled(params?.data)),
          enabled: isApproveEnabled(params?.data),
        },
        assign: {
          assignBtnTitle: 'Assign',
          onAssign: () => gotoLoanRequest(params?.data, false, isAssignEnabled(params?.data)),
          enabled: isAssignEnabled(params?.data),
        },
      });
    },
  };

  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [...LOAN_TABLE, { field: 'currentAssignee' }, ACTION],
    [myRequestTab, tab]
  );

  const completedColumnDefs: (ColDef | ColGroupDef)[] = useMemo(() => [...LOAN_TABLE, ACTION], [myRequestTab, tab]);

  if (pendingRequestsData?.isLoading) {
    return <BackdropLoader isLoading={pendingRequestsData?.isLoading} />;
  }

  return (
    <ContentLayout title="Loan Proposals">
      <Grid container spacing={1}>
        {isCreateAccess() && (
          <Grid item md={12}>
            <FilterContainer>
              <Button
                variant="contained"
                onClick={onNewRequest}
                sx={{ position: 'absolute', top: '65px', right: '20px', zIndex: 999 }}>
                {strings.NEW_LOAN_REQUEST_BTN}
              </Button>
            </FilterContainer>
          </Grid>
        )}
        <Grid item md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTab} aria-label="loan request">
                <Tab label="My Request" value="MY_REQUEST" />
                <Tab label="Team Request" value="TEAM_REQUEST" />
              </TabList>
            </Box>
            <TabPanel value="MY_REQUEST" sx={{ padding: '0px' }}>
              {
                <TabContext value={myRequestTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleMyRequestTab} aria-label="loan request">
                      <Tab label="Pending Actions" value="PENDING_ACTIONS" />
                      <Tab label="In Progress" value="IN_PROGRESS" />
                      <Tab label="Completed" value="COMPLETED" />
                    </TabList>
                  </Box>
                  <TabPanel value="PENDING_ACTIONS" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={columnDefs}
                        autoSize={true}
                        rowData={_requestData(pendingRequestsData)}
                      />
                    }
                  </TabPanel>
                  <TabPanel value="IN_PROGRESS" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={columnDefs}
                        autoSize={true}
                        rowData={_requestData(inProgressRequestsData)}
                      />
                    }
                  </TabPanel>
                  <TabPanel value="COMPLETED" sx={{ padding: '12px', height: 'calc(100vh - 275px)' }}>
                    {
                      <DataTable
                        columnDefs={completedColumnDefs}
                        autoSize={true}
                        rowData={_requestData(closedRequestsData)}
                      />
                    }
                  </TabPanel>
                </TabContext>
              }
            </TabPanel>
            <TabPanel value="TEAM_REQUEST" sx={{ padding: '12px', height: 'calc(100vh - 230px)' }}>
              {<DataTable columnDefs={columnDefs} autoSize={true} rowData={_requestData(teamRequestsData)} />}
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid item md={12}>
          <ActionBar></ActionBar>
        </Grid>
      </Grid>
    </ContentLayout>
  );
};

export default LoanRequests;
