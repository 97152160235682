import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import DataTable from 'components/common/data-table';
import { FileUploadProps } from 'models/fileupload';
import { getDocuments, downloadDocument, uploadDocument, saveDocumentMeta, deleteDocument } from 'services/document-services';
import { getRefDataByGroupName } from 'services/common-services';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ArticleIcon from '@mui/icons-material/Article';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { ColGroupDef } from 'ag-grid-community';
import { FileUpload } from 'components/common/fileupload';
import { toggleLoader } from 'state/reducers/common-actions';
import { Grid } from '@mui/material';
import ActionBar from 'components/styled-components/ActionBar';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};

const getRows = (data: any): ColGroupDef[] => {
  let rows = null;
  if (data) {
    rows = data?.filter((d:any) => d?.isSoftDelete !== true)?.map((doc: any, i: number) => {
      return { ...doc, srNo: i + 1 };
    });
  }
  return rows;
};

const AnnexureTable = () => {
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<any | null>(null);
  const [type, setType] = useState('');
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { loanRequestId } = state;
  const { data } = useQuery(['getDocuments', loanRequestId], () => getDocuments(loanRequestId));
  const { data: documentData, isSuccess: isDocSuccess } = useQuery(['getRefDataByGroupName'], () =>
    getRefDataByGroupName('DocumentTypes')
  );

  const deleteDocumentMutation = useMutation((data: any) => deleteDocument(data?.id), {
    onSuccess: async (data: any) => {
     enqueueSnackbar(`Document ${data?.docFileName} deleted`, {
        variant: 'success',
      });
    },
    onError: async (data: any) => {
      enqueueSnackbar(`Error while deleting ${data?.docFileName}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getDocuments', loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });


  const columns = [
  {
    field: 'srNo',
    headerName: 'Sr. No.',
    minWidth: 120,
    width: 120,
    maxWidth: 120,
    pinned: 'left',
    resizable: false,
  },
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'docDesc',
    headerName: 'Description',
  },
  {
    field: 'docFileName',
    headerName: 'File name',
  },
  {
    field: 'docTypeCode',
    headerName: 'Type',
    minWidth: 100,
    maxWidth: 120,
    width: 100,
    resizable: false,
  },
  {
    field: 'action',
    headerName: 'Action',
    pinned: 'right',
    align: 'center',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    sortable: false,
    cellRendererFramework: (params: any) => {
      return (
        <>
        <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center',  float:'left' }}
          onClick={() => downloadDocuments(params?.data)}>
          <DownloadForOfflineIcon fontSize="small" />
        </div>
         {!state?.viewOnly && <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center', float:'left' }}
          onClick={() => deleteDocumentMutation.mutate(params?.data)}>
          <DeleteForeverIcon fontSize="small" />
        </div>}
        </>
      );
    },
  },
];


  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const fileUploadProp: FileUploadProps = {
    accept:
      'doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setFile(null);
      if (event.target.files !== null && event.target?.files?.length > 0) {
        console.log(`Saving ${event.target.value}`);
        setFile(event?.target?.files[0]);
      } else {
        throw `Error ${event?.target?.files![0]?.name}`;
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      setFile(null);
      console.log(`Drop ${event?.dataTransfer?.files[0].name}`);
      setFile(event?.dataTransfer?.files[0]);
    },
  };

  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const saveDocumentMetaMutation = useMutation((data: any) => saveDocumentMeta(data), {
    onSuccess: async (data: any) => {
      let upload = await uploadDocument(data?.id, file);
      if (upload) {
        enqueueSnackbar(`Document ${file?.name} Saved`, {
          variant: 'success',
        });
        setType('');
        setDescription('');
        setFile(null);
      } else {
        enqueueSnackbar(`Error while saving Document ${file?.name}`, {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar(`Error while uploading ${file?.name}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getDocuments', loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });

  
  const handleSubmit = async () => {
    if (!description || !type || !file?.size) {
      enqueueSnackbar(`Please fill all details`, {
        variant: 'error',
      });
      return false;
    } else {
      saveDocumentMetaMutation.mutate({
        docDesc: description,
        docTypeCode: type,
        docFileName: file?.name,
        docXrefId: loanRequestId,
        docXrefType: 'LOAN_REQUEST',
      });

      if (saveDocumentMetaMutation?.isLoading) {
        dispatch(toggleLoader(true));
      }
    }
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={!state?.viewOnly?8:12}>
        <Box
          sx={{
            height: 'calc(100vh - 150px)',
            padding: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <DataTable columnDefs={columns} autoSize={false} rowData={getRows(data)} />
        </Box>
      </Grid>
      {!state?.viewOnly && <Grid item xs={4}>
        <Box sx={{ padding: 1, pr: 2 }}>
          {!file && <FileUpload {...fileUploadProp} hoverLabel={'Click or Drop file here'} fileButton />}
          {file && <FileUpload {...fileUploadProp} hoverLabel={file?.name} fileButton />}
        </Box>
        <Box sx={{ padding: 1 }}>
          <div>
            <FormControl sx={{ p: 1 }} variant="filled" size="small">
              <InputLabel id="typeLabel">Type</InputLabel>
              <Select
                id="type"
                labelId="typeLabel"
                value={type}
                onChange={handleChange}
                sx={{ padding: '0 14px', minWidth: 300 }}
                displayEmpty
                autoWidth>
                {isDocSuccess &&
                  documentData?.map((d: any) => <MenuItem value={d?.code}>{d?.displayText}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ p: 1 }} variant="filled">
              <TextField
                id="description"
                label="Description"
                multiline
                sx={{ minWidth: 300 }}
                maxRows={4}
                value={description}
                onChange={handleDescription}
                inputProps={{
                  style: {
                    height: 40,
                  },
                }}
              />
            </FormControl>
          </div>
          <div style={{ padding: '9px' }}>
            <Button variant="contained" component="label" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Box>
      </Grid>
      }
      <Grid item md={12}>
        <ActionBar></ActionBar>
      </Grid>
    </Grid>
  );
};

export default AnnexureTable;
