import { Box } from '@mui/material';

function PowerBi() {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe title="PortfolioReport" 
      width="1024" 
      height="605" 
      src="https://app.powerbi.com/view?r=eyJrIjoiMjE2ODRmZWMtNjgxYy00NWU0LWEwNmEtNTY5YTEyZDYyMTgwIiwidCI6IjRiNTRiOTlkLTc4YjUtNDJiYi1hY2M1LWJmYjdkMGNkNzU4NiJ9" 
      frameBorder="0"
        allowFullScreen={true}></iframe>
    </Box>
  );
}

export default PowerBi;
