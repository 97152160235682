import { useWatch } from 'react-hook-form';
import { IFormSchema } from 'models/forms-schema-types';
import useRefData from 'hooks/ref-data';
import { createDropdownOptions } from '../../../../../../utils/common-utils';

export const useOrganizationDetails = () => {
  const { refData } = useRefData();
  const currentDate = new Date();
  const maxDate = currentDate.setDate(currentDate.getDate() - 1);

  const basicDetails = [
    { label: 'Company Name', type: 'text', name: 'orgName', component: 'textField' },
    { label: 'Group Name', type: 'text', name: 'grpName', component: 'textField' },
    {
      label: 'Relationship to Group',
      name: 'relationShipToGroup',
      component: 'dropdown',
      options: createDropdownOptions(['Group Company', 'Subsidiary', 'Step-down Subsidiary']),
    },
    { label: 'CIN', type: 'text', name: 'cin', component: 'textField' },
    {
      label: 'Company Incorporation Date',
      type: 'date',
      name: 'companyIncorporationDate',
      component: 'datepicker',
      maxDate: maxDate,
    },
    { label: 'Enterprise PAN #', type: 'text', name: 'panNumber', component: 'textField' },
    { label: 'TAN', type: 'text', name: 'tan', component: 'textField' },
    {
      label: 'Type of Entity',
      name: 'entityType',
      component: 'dropdown',
      options: refData.entityTypes || [],
    },
    {
      label: 'Number of Employees',
      name: 'numberOfEmployees',
      type: 'number',
      component: 'textField',
    },
    { label: 'Years in Business', type: 'number', name: 'yearsInBusiness', component: 'textField' },
  ];

  return { basicDetails };
};

export const useOperations = (control: any): IFormSchema[] => {
  const { refData } = useRefData();

  const foreignOperations = useWatch({ control, name: 'foreignOperations', defaultValue: 'no' });

  return [
    {
      label: 'Foreign Operations',
      type: 'none',
      name: 'foreignOperations',
      component: 'radio',
      radioGroup: 'foreignOperations',
      values: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      label: 'Foreign Offices',
      name: 'foreignOffices',
      component: 'dropdown',
      options: refData.countryList || [],
      disabled: foreignOperations === 'yes' ? false : true,
    },
  ];
};

export const ContactDetails: readonly IFormSchema[] = Object.freeze([
  { label: 'Contact Person', type: 'text', name: 'contactPerson', component: 'textField' },
  { label: 'Office Phone', type: 'number', name: 'officePhone', component: 'textField' },
  { label: 'Mobile Phone', type: 'number', name: 'mobilePhone', component: 'textField' },
  { label: 'Office Email', type: 'number', name: 'officeEmail', component: 'textField' },
  { label: 'Office Address', type: 'text', name: 'officeAddress', component: 'textField' },
]);
