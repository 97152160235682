const APP_HEADING = 'KreditAccel';

interface IStrings {
  [key: string]: string;
}

interface IScreenActions {
  UPDATE_DATA: string;
  VIEW_ONLY: string;
  CREATE_NEW: string;
}

export const strings: IStrings = Object.freeze({
  NEW_LOAN_REQUEST_BTN: 'New Loan Request',
  NEW_LOAN_REQUEST_HEADER: 'Create New Request',
  EDIT_LOAN_PROPOSAL: 'Edit/Create Loan Proposal',
  VIEW_LOAN_PROPOSAL: 'View Loan Proposal',
  CREATE_MEMO_BUTTON: 'Create Memo',
  EDIT_MEMO: 'Edit Memo',
  VIEW_MEMO: 'View Memo',
  ADD_NEW_COMPANY: 'Register Company',
  EDIT_COMPANY: 'Edit Company',
  VIEW_COMPANY: 'View Company',
  SUBMIT_BTN: 'Submit',
});

const roles: IStrings = Object.freeze({
  RISK_UNIT: 'risk-unit',
});

const routes: IStrings = Object.freeze({
  LOAN_PROPOSAL_ROUTE: '/dashboard/loan-proposal',
  LOAN_OFFICER_ROUTE: '/dashboard/credit-analysis',
  RISK_UNIT_ROUTE: '/dashboard/risk-unit',
  MEMOS_ROUTE: '/dashboard/memos',
  APPROVAL_AUTHORITY_ROUTE: '/dashboard/approval-authority',
  LEGAL_ADMIN_ROUTE: '/dashboard/legal-admin-list',
  CREDIT_ADMIN_ROUTE: '/dashboard/credit-admin-list',
  DASHBOARD_ROUTE: '/dashboard',
  LOGIN_ROUTE: '/',
  COMMUNICATION_ROUTE: '/customer-communication',
  CAM_ROUTE: '/dashboard/loan-proposal/cam',
  DOCUMENT_PREPARATION: '/dashboard/loan-proposal/document-preparation',
  APPR_AUTH_APPR_ROUTE: '/dashboard/approval-authority/approval/risk-rationale',
  SANCTION_LETTER_ROUTE: '/dashboard/credit-admin/sanction-letter',
  DOCUMENT_SUBMISSION_ROUTE: '/dashboard/credit-admin/document-submission',
  NEW_LOAN_REQUEST_ROUTE: '/dashboard/credit-analysis/new-loan-request',
  NEW_MEMOS_REQUEST_ROUTE: '/dashboard/memos/new-memos-request',
  RISK_RATIONALE_ROUTE: '/dashboard/loan-proposal/risk',
  REVIEW_COMMENT_ROUTE: '/dashboard/loan-proposal/review-comment',
  DOCUMENT_LIST_ROUTE: '/dashboard/legal-admin/list-of-documents',
  FINAL_DOCUMENT_ROUTE: '/dashboard/legal-admin/final-documents',
  MEMOS_REQUEST_ROUTE: '/dashboard/memos-request',
  CREATE_MEMOS_ROUTE: '/dashboard/memos/new-memos-request',
});

const screenActions: IScreenActions = Object.freeze({
  UPDATE_DATA: 'update',
  VIEW_ONLY: 'readonly',
  CREATE_NEW: 'newEntry',
});

export { APP_HEADING, roles, routes, screenActions };
