import { Grid, Box } from '@mui/material';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import DataTable from 'components/common/data-table';
import { ActionCell } from 'components/common/data-table/table-action-cell';
import _ from 'lodash';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getOpenRequests, getClosedRequests } from 'services/approval-service';
import { RootState } from 'state/reducers';
import ContentLayout from '../../common/containers/content-layout';
import Layout from '../../layout/layout';
import { LOAN_TABLE } from '../credit-analysis/loan-proposal/LoanProposalConsts';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import ActionBar from 'components/styled-components/ActionBar';

const ApprovalAuthority = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLoanRequestState, _requestData, getRequestStatusCode, getAccessMode } = useLoanTable();
  const { username }: any = useSelector((state: RootState) => state?.user?.userDetails);
  const { data: getOpenRequestsData } = useQuery(['getOpenRequests', username], getOpenRequests);
  const { data: getClosedRequestsData } = useQuery(['getClosedRequests', username], getClosedRequests);
  const [tab, setTab] = useState('PENDING_ACTIONS');
  const { state: tabState, pathname } = useLocation();

  useEffect(() => {
    tabState?.tabId && setTab(tabState?.tabId);
  }, [tabState?.tabId]);

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const getPath = (loanData: any) => {
    let path = routes.LOAN_PROPOSAL_ROUTE;
    if (loanData?.statusCode === getRequestStatusCode('PENDING_WITH_APPROVAL_AUTHORITY')) {
      path = routes.APPR_AUTH_APPR_ROUTE;
    }
    return path;
  };

  const gotoLoanRequest = (loanData: any, viewOnly = true, isEdit = false) => {
    navigate(getPath(loanData), {
      state: {
        ...getLoanRequestState(loanData, viewOnly, isEdit),
      },
    });
  };

  const isViewEnabled = (loanData: any) => {
    return loanData?.accessMode === getAccessMode('READ_ONLY');
  };

  const isApproveEnabled = (loanData: any) => {
    return (
      loanData?.statusCode !== getRequestStatusCode('APPROVED') && loanData?.accessMode === getAccessMode('EDIT')
    );
  };

  const ACTION = {
    field: 'action',
    pinned: 'right',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    sortable: false,
    filter: false,
    cellRendererFramework: (params: any) => {
      return ActionCell({
        view: {
          viewBtnTitle: 'View',
          onView: () => gotoLoanRequest(params?.data, true, false),
          enabled: isViewEnabled(params?.data),
        },
        approve: {
          approveBtnTitle: 'Approve',
          onApprove: () => gotoLoanRequest(params?.data, false, isApproveEnabled(params?.data)),
          enabled: isApproveEnabled(params?.data),
        },
      });
    },
  };

  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [...LOAN_TABLE, { field: 'currentAssignee' }, ACTION],
    [tab]
  );

  const closedColumnDefs: (ColDef | ColGroupDef)[] = useMemo(() => {
    let columns = [...LOAN_TABLE, ACTION];

    columns[11] = {
      field: 'status',
      headerName: 'Loan Proposal Status',
    };

    columns.splice(12, 0, {
      field: 'approvalTaskStatus',
      headerName: 'Approval Authority Status',
    });

    return columns;
  }, [tab]);

  return (
    <Layout>
      <ContentLayout title="Approval Authority">
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTab} aria-label="authority request">
                  <Tab label="Pending Actions" value="PENDING_ACTIONS" />
                  <Tab label="Completed" value="COMPLETED" />
                </TabList>
                <TabPanel value="PENDING_ACTIONS" sx={{ height: 'calc(100vh - 220px)', padding: '12px' }}>
                  <DataTable columnDefs={columnDefs} autoSize={true} rowData={_requestData(getOpenRequestsData)} />
                </TabPanel>
                <TabPanel value="COMPLETED" sx={{ height: 'calc(100vh - 220px)', padding: '12px' }}>
                  <DataTable
                    columnDefs={closedColumnDefs}
                    autoSize={true}
                    rowData={_requestData(getClosedRequestsData)}
                  />
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>
          <Grid item md={12}>
            <ActionBar></ActionBar>
          </Grid>
        </Grid>
      </ContentLayout>
    </Layout>
  );
};

export default ApprovalAuthority;
