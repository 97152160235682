import { useMemo, useCallback } from 'react';
import { Box, Button, Card, CardHeader } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import AddIcon from '@mui/icons-material/Add';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/styles';
import getIcon from 'icons/icons';
import { ADD, DELETE, GO_BACK } from 'icons/icon-names';

const cardHeader = {
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
  },
};

const AccountPlan = ({ setViewType, watchAllFields, setValue, getValues }: any) => {
  const { state } = useLocation();
  const theme = useTheme();

  const accountPlanColDef: any[] = [
    {
      headerName: 'Particulars',
      children: [
        {
          field: 'customerName',
          headerName: 'CustomerName',
          editable: true,
        },
      ],
    },
    {
      headerName: 'RelationShip Details',
      editable: true,
      children: [
        {
          field: 'cif',
          headerName: 'CIF',
          editable: true,
        },
        {
          field: 'relationshipSince',
          headerName: 'Relationship Since',
          editable: true,
        },
        {
          field: 'industry',
          headerName: 'Industry',
          editable: true,
        },
        {
          field: 'subIndustry',
          headerName: 'SubIndustry',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Turnover',
      suppressSizeToFit: true,
      children: [
        {
          field: 'domesticTurnOver',
          headerName: 'Domestic Turnover',
          editable: true,
        },
        {
          field: 'exportsTurnover',
          headerName: 'Exports Turnover',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Current Financial/Business Opportunity',
      suppressSizeToFit: true,
      children: [
        {
          field: 'interestExpense',
          headerName: 'Interest Expense',
          editable: true,
        },
        {
          field: 'bankingCharges',
          headerName: 'Banking Charges',
          editable: true,
        },
        {
          field: 'salaryExpense',
          headerName: 'Salary Expense',
          editable: true,
        },
        {
          field: 'tax',
          headerName: 'Tax',
          editable: true,
        },
        {
          field: 'insurance',
          headerName: 'Insurance',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Income Projection',
      suppressSizeToFit: true,
      children: [
        {
          field: 'interestIncome',
          headerName: 'Interest Income',
          editable: true,
        },
        {
          field: 'nonInterestIncome',
          headerName: 'Non-Interest Income',
          editable: true,
        },
        {
          field: 'projectedTotalIncome',
          headerName: 'Projected Total Income',
          editable: true,
        },
        {
          field: 'projectedROE',
          headerName: 'Projected ROE',
          editable: true,
        },
        {
          field: 'walletShare',
          headerName: 'Wallet Share(%)',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Banking Relationship',
      suppressSizeToFit: true,
      children: [
        {
          field: 'bank1',
          headerName: 'Bank 1',
          editable: true,
        },
        {
          field: 'bank2',
          headerName: 'Bank 2',
          editable: true,
        },
        {
          field: 'bank3',
          headerName: 'Bank 3',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Current Exposure',
      suppressSizeToFit: true,
      children: [
        {
          field: 'otherBanks',
          headerName: 'Other Banks',
          editable: true,
        },
        {
          field: 'ownBanks',
          headerName: 'Own Banks',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Avg CA Outstanding(From CBS)',
      suppressSizeToFit: true,
      children: [
        {
          field: 'ca',
          headerName: 'CA',
          editable: true,
        },
        {
          field: 'sa',
          headerName: 'SA',
          editable: true,
        },
        {
          field: 'termDeposits',
          headerName: 'TermDeposits',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Product Holdings',
      suppressSizeToFit: true,
      children: [
        {
          field: 'product1',
          headerName: 'Product 1',
          editable: true,
        },
        {
          field: 'product2',
          headerName: 'Product 2',
          editable: true,
        },
        {
          field: 'product3',
          headerName: 'Product 3',
          editable: true,
        },
      ],
    },
    {
      headerName: 'Key Persons',
      suppressSizeToFit: true,
      children: [
        {
          field: 'ceo',
          headerName: 'CEO',
          editable: true,
        },
        {
          field: 'cfo',
          headerName: 'CFO',
          editable: true,
        },
      ],
    },
    {
      field: '',
      headerName: 'Actions',
      pinned: 'right',
      suppressSizeToFit: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <TooltipIconButton
              onClick={() => {
                setValue('accountPlan', [...watchAllFields?.accountPlan, {}]);
              }}
              icon={getIcon(ADD, theme.palette.primary.main)}
              title="Add"
            />
            {params.rowIndex > 0 && (
              <TooltipIconButton
                onClick={(e: any) => {
                  let updatedFields = [...getValues('accountPlan')];
                  if (updatedFields.length > 0) {
                    updatedFields.splice(params.node.rowIndex, 1);
                    setValue('accountPlan', updatedFields);
                    params.api.setRowData(updatedFields);
                  }
                }}
                icon={getIcon(DELETE, theme.palette.error.main)}
                title="Delete"
              />
            )}
          </>
        );
      },
    },
  ];

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('accountPlan', updatedRowData);
  }, []);

  return (
    <>
      <Card style={{ borderRadius: 10 }}>
        <CardHeader
          sx={{
            p: 1,
            ...cardHeader,
            pl: 2,
            background: grey[50],
            '& .MuiCardHeader-action': {
              alignSelf: 'center !important',
            },
          }}
          title="Account Plan"
          action={
            <TooltipIconButton
              title="Go Back"
              icon={getIcon(GO_BACK, theme.palette.primary.main)}
              onClick={() => {
                setViewType();
              }}
            />
          }
        />
      </Card>
      <Box sx={{ p: 1, height: 'calc(100vh - 220px)' }}>
        <Box sx={{ height: '100%', mt: 1 }}>
          <DataTable
            columnDefs={accountPlanColDef}
            autoSize={true}
            rowData={watchAllFields?.accountPlan}
            suppressColumnVirtualisation={true}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>
    </>
  );
};

export default AccountPlan;
