import React, { FC } from 'react';
import { Typography, Box, Divider, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// const RectagleBox = styled(Box)(({ theme }) => ({
//   width: '3px',
//   height: '22px',
//   margin: '0 10px 0 0',
//   borderRadius: '2px',
//   backgroundColor: '#2f80ed',
// }));

const Circle = styled(Box)(({ theme }) => ({
  width: '27px',
  lineHeight: '27px',
  borderRadius: '50%',
  marginRight: '5px',
  textAlign: 'center',
  fontSize: '13px',
  fontWeight: 700,
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
}));

interface IProps {
  title: string;
  formNumbering?: string;
}

export const FormTitle: FC<IProps> = ({ title, formNumbering }) => {
  return (
    <Box sx={{ marginTop: '5px' }}>
      <Box
        sx={{
          display: 'flex',
          mb: 0.25,
        }}>
        {formNumbering && <Circle>{formNumbering}</Circle>}
        <Typography sx={{ p: 0.25, lineHeight: '23px' }} variant="h6">
          {title}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};
