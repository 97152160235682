import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserDetails {
  jwtToken: string;
  roles: string;
  firstName: string;
  lastName: string;
  permissionList: Array<string>;
  menuList: Array<string>;
  username: string;
}

interface IUser {
  userDetails: IUserDetails | null;
}

let userDetails: IUserDetails | null = null;

const userValue = localStorage.getItem("user");

if (typeof userValue === 'string') {
  userDetails = JSON.parse(userValue) 
}


const initialState: IUser = {
  userDetails
};


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUserData: (state: IUser, action: PayloadAction<IUserDetails>) => {
      state.userDetails = action.payload
    },
    deleteUserData: (state: IUser) => {
      state.userDetails = null;
    },
  },
});

const { actions, reducer } = userSlice;

export const { storeUserData, deleteUserData } = actions;
export default reducer;
