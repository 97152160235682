import { styled } from '@mui/material';
import { TableCell } from '@mui/material';
import fontSize from '../../../constants/fonts';

const DenseCell = styled(TableCell)(() => ({
  paddingLeft: 20,
  fontSize: fontSize.formLabel,
  lineHeight: '24px',
}));

export default DenseCell;
