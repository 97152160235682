import { useMemo, useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { getFormFields } from 'utils/form-utils';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { Control } from 'react-hook-form';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const GroupExposure = ({
  t,
  watchAllFields,
  control,
  setValue,
  getValues
}: any) => {
  const { state } = useLocation();

  const [rowData, setRowData] = useState<any>(watchAllFields?.groupExposure?.fields);

  const [nonProjectLoan, setNonProjectLoan] = useState({
    particulars: 'Non-Project Loan',
    limitA: '',
    outstandingA: '',
    limitB: '',
    outstandingB: '',
  });

  const [capitalFunds, setCapitalFunds] = useState({
    particulars: 'As % of Capital Funds',
    limitA: '',
    outstandingA: '',
    limitB: '',
    outstandingB: '',
  });

  useEffect(() => {
    const groupTotal = getValues('groupExposure.fields');
    groupTotal?.map((g: any) =>
      g?.particulars === 'Amount'
        ? Object.assign(g, {
            limitA: `${watchAllFields?.fundNonFundTotal?.proposedTotal}`,
          })
        : g
    );
    setValue('groupExposure.fields', groupTotal);
    setRowData(groupTotal)
  }, [watchAllFields?.fundNonFundTotal?.proposedTotal]);

  useEffect(() => {
    const groupExposure = getValues('groupExposure.fields');

    const amountObj = groupExposure?.find(
      (g: any) => g?.particulars === 'Amount'
    );

    const projectLoanObj = groupExposure?.find(
      (g: any) => g?.particulars === 'Project Loan'
    );

    const nonProjectLoanObj: any = amountObj ? Object.keys(amountObj).reduce(
      (a, k): any => {
        const temp: any = { ...a };
        temp[k] = (amountObj[k] || 0) - (projectLoanObj[k] || 0);
        return temp;
      },
      {}
    ):{};
   
    setNonProjectLoan({
      particulars: 'Non-Project Loan',
      limitA: nonProjectLoanObj?.limitA,
      outstandingA: nonProjectLoanObj?.outstandingA,
      limitB: nonProjectLoanObj?.limitB,
      outstandingB: nonProjectLoanObj?.outstandingB,
    });
    setValue('groupExposure.nonProjectLoan', {
      particulars: 'Non-Project Loan',
      limitA: nonProjectLoanObj?.limitA,
      outstandingA: nonProjectLoanObj?.outstandingA,
      limitB: nonProjectLoanObj?.limitB,
      outstandingB: nonProjectLoanObj?.outstandingB,
    });
    setCapitalFunds({
      particulars: 'As % of Capital Funds',
      limitA: `${(amountObj?.limitA || 0) / 1000}%`,
      outstandingA: `${(amountObj?.outstandingA || 0) / 1000}%`,
      limitB: `${(amountObj?.limitB || 0) / 1000}%`,
      outstandingB: `${(amountObj?.outstandingB || 0) / 1000}%`,
    });
    setValue('groupExposure.capitalFunds', {
      particulars: 'As % of Capital Funds',
      limitA: `${(amountObj?.limitA || 0) / 1000}%`,
      outstandingA: `${(amountObj?.outstandingA || 0) / 1000}%`,
      limitB: `${(amountObj?.limitB || 0) / 1000}%`,
      outstandingB: `${(amountObj?.outstandingB || 0) / 1000}%`,
    });
  }, [watchAllFields?.groupExposure?.fields]);

  const checkColumnEditable = (params: any) => {
    if (
      !state?.isEdit ||
      params?.data?.particulars === 'Non-Project Loan' ||
      params?.data?.particulars === 'As % of Capital Funds' ||
      (params?.colDef?.field === 'limitA' &&
        params?.data?.particulars === 'Amount')
    ) {
      return false;
    }

    return true;
  };

  const defaultColDefs = useMemo(
    () => [
      {
        field: 'particulars',
        headerName: 'Particulars',
        pinned: 'left',
        minWidth: 270,
        suppressSizeToFit: true,
        cellStyle: (params: any) => {
          if (
            params.value === 'Project Loan' ||
            params.value === 'Non-Project Loan'
          ) {
            return { paddingLeft: '30px' };
          }
          return null;
        },
      },
      {
        headerName: 'Borrower',
        children: [
          {
            headerName: 'Limit',
            field: 'limitA',
            editable: checkColumnEditable,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            headerName: 'Outstanding',
            field: 'outstandingA',
            editable: checkColumnEditable,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      },
      {
        headerName: 'Group Exposure (Including Borrower)',
        children: [
          {
            headerName: 'Limit',
            field: 'limitB',
            editable: checkColumnEditable,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            headerName: 'Outstanding',
            field: 'outstandingB',
            editable: checkColumnEditable,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      },
    ],
    []
  );

  const onCellValueChanged = useCallback((event: any) => {
    const updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('groupExposure.fields', updatedRowData);
    setRowData(updatedRowData)
  }, []);


  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <Box sx={{ mt: 1, height: '245px' }}>
            <DataTable
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={rowData}
            pagination={false}
            domLayout={'normal'}
            pinnedBottomRowData={[nonProjectLoan, capitalFunds]}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          {getFormFields(
            {
              component: MULTI_LINE_INPUT,
              name: 'comments.groupExposure',
              label: 'Comment',
            },
            control,
            !state?.isEdit
          )}
        </Box>
      </Box>
    </>
  );
};

export default GroupExposure;
