import { styled, Typography } from '@mui/material';
import { TableCell } from '@mui/material';
import { SxProps } from '@mui/system';

const StyledCell = styled(TableCell)(({ align }: any) => ({
  paddingLeft: align !== 'right' ? '12px' : 0,
  paddingRight: align === 'right' ? '4px' : 0,
}));

const DenseCellSm = ({
  children,
  colSpan,
  rowSpan,
  sx,
  align,
  borderRight = false,
  viewOnly = false,
}: {
  children?: any;
  colSpan?: number;
  rowSpan?: number;
  sx?: SxProps;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  borderRight?: boolean;
  viewOnly?: boolean;
}) => {
  return (
    <StyledCell
      align={align}
      sx={{
        ...sx,
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        lineHeight: '25px',
        background: viewOnly ? '#f2f2f2' : '#ffffff',
      }}
      padding="none"
      colSpan={colSpan}
      rowSpan={rowSpan}>
      <Typography>{children}</Typography>
    </StyledCell>
  );
};

export default DenseCellSm;
