import { Container, Grid, Card, Typography, Box, Tabs, Tab } from '@mui/material';
import useLoanTable from 'hooks/useLoanTable';
import OverallVisualization from './OverallVisualization';
import TabPanel from '@mui/lab/TabPanel';
import WCVisualization from './WCVisualization';
import ActionBar from 'components/styled-components/ActionBar';
import {useState} from 'react';
import TabContext from '@mui/lab/TabContext';


const Visualization = (theme: any) => {
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12} sx={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="visualization tabs">
          <Tab label="Overall Analysis" value="1" />
          <Tab label="Working Capital Analysis" value="2" />
        </Tabs>
        </Box>
        <TabPanel value="1">
          <OverallVisualization />
        </TabPanel>
        <TabPanel value="2">
          <WCVisualization />
        </TabPanel> 
        </TabContext>
      </Grid>
      <Grid item md={12}>
        <ActionBar></ActionBar>
      </Grid>
    </Grid>
  );
};

export default Visualization;
