import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Stack,
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TableDenseCell from 'components/common/table/TableDenseCell';
import { getFormFields } from '../../../../../utils/form-utils';
import { IFormSchema } from '../../../../../models/forms-schema-types';
import { useLocation } from 'react-router-dom';
import { capitalMarketExposure, classification, companyEngagedIn, microAndSme } from './exposure-fields-data';
import { SWITCH } from '../../../../../constants/form-components';

const headers = [
  'If above Priotity Sector Opportunity/ MSME Finance is true, then fill up the details		',
  'Opportunity',
];
const values = ['Yes', 'No', 'NA'];

interface IExposureDetails {
  name: string;
  label: string;
  label2?: string;
}

const classificationFields: IFormSchema[] = _.map(
  classification,
  (el: IExposureDetails, i: number): IFormSchema => {
    return {
      label: el?.label,
      type: 'none',
      name: el?.name,
      component: 'radio',
      radioGroup: `${i}`,
      defaultValue: 'no',
      values: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    };
  }
);
const exposureDetails1: IFormSchema[] = _.map(companyEngagedIn, (el: IExposureDetails, i: number): IFormSchema => {
  return {
    label: el?.label,
    type: 'none',
    name: el?.name,
    component: 'radio',
    radioGroup: `${i}`,
    defaultValue: 'no',
    values: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  };
});
const exposureDetails2: IFormSchema[] = _.map(microAndSme, (el: IExposureDetails, i: number): IFormSchema => {
  return {
    label: el?.label,
    type: 'none',
    name: el?.name,
    component: 'radio',
    defaultValue: 'no',
    radioGroup: `${i}`,
    values: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  };
});
const exposureDetails3: IFormSchema[] = _.map(
  capitalMarketExposure,
  (el: IExposureDetails, i: number): IFormSchema => {
    return {
      label: el?.label,
      type: 'none',
      name: el?.name,
      component: 'radio',
      defaultValue: 'no',
      radioGroup: `${i}`,
      values: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    };
  }
);

const ExposureDetail = ({ control }: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Grid item lg={12}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {_.map(headers, (header: string, idx: number) => {
                return (
                  <TableCell
                    align="left"
                    width={'80%'}
                    key={idx}
                    sx={{
                      background: '#F8F8F8',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}>
                    <Typography>
                      <b>{header}</b>
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {classificationFields.map((details: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <p>{details.label}</p>
                    <p>{classification[index]?.label2 || ''}</p>
                  </Typography></TableCell>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>{getFormFields(details, control, !state?.isEdit)}</Typography></TableCell>
                </TableRow>
              );
            })} */}

            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                <b>Is the company/group company engaged in any of the following activities</b>
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            {exposureDetails1.map((details: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <p>{details.label}</p>
                    <p>{classification[index]?.label2 || ''}</p>
                  </Typography></TableCell>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      {getFormFields(
                        {
                          name: details.name,
                          component: SWITCH,
                        },
                        control,
                        !state?.isEdit
                      )}
                      <Typography>Yes</Typography>
                    </Stack>
                  </Typography></TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                <b>Export of agriculture produce</b>
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow> */}
            {/* <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                <b>Does the borrower export its own agriculture produce?</b>
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                <b>Micro and SME</b>
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            {exposureDetails2.map((details: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <p>{details.label}</p>
                    <p>{classification[index]?.label2 || ''}</p>
                  </Typography></TableCell>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      {getFormFields(
                        {
                          name: details.name,
                          component: SWITCH,
                        },
                        control,
                        !state?.isEdit
                      )}
                      <Typography>Yes</Typography>
                    </Stack>
                  </Typography></TableCell>
                </TableRow>
              );
            })}

            {/* <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                <b>DECLARATON</b>
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>&nbsp;</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                Bank Group’s aggregate exposure to the borrower (including the exposure assumed by Overseas Banking
                Subsidiaries/ Joint Ventures etc.) at May 08, 2019 (including the proposed exposure) is
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                {getFormFields(
                  {
                    component: INPUT,
                    name: 'regulatorsRequirement.exposureDetails.declaration.aggExposure',
                    type: 'text',
                  },
                  control,
                  !state?.isEdit
                )}
              </Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                Bank’s aggregate exposure under programme sanctions involving exposure to dealers/ vendors of the
                borrowing company is
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                {getFormFields(
                  {
                    component: INPUT,
                    name: 'regulatorsRequirement.exposureDetails.declaration.bankAggExposure',
                    type: 'text',
                  },
                  control,
                  !state?.isEdit
                )}
              </Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                Exposure to industry at May 08, 2014 is of the total exposure at Dec 31, 2013
              </Typography></TableCell>
              <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                {getFormFields(
                  {
                    component: INPUT,
                    name: 'regulatorsRequirement.exposureDetails.declaration.industryExposure',
                    type: 'text',
                  },
                  control,
                  !state?.isEdit
                )}
              </Typography></TableCell>
            </TableRow>

            {exposureDetails3.map((details: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>
                    <p>{details.label}</p>
                    <p>{classification[index]?.label2 || ''}</p>
                  </Typography></TableCell>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>{getFormFields(details, control, !state?.isEdit)}</Typography></TableCell>
                </TableRow>
              );
            })} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ExposureDetail;
