import Layout from '../../layout/layout';
import { Outlet } from 'react-router';
import { Box, Button, Divider, Grid, styled, Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';

function PortfolioManagement() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [tab, setTab] = useState('');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    navigate(`/dashboard/portfolio-management/${newValue}`);
  };

  return (
    <Layout sx={{ width: '100vw' }}>
      <Divider sx={{ borderColor: '#C4CDD5' }} />
      <Box>
        <Tabs value={tab} onChange={handleTab} centered>
          <Tab label="My Portfolio" value="" />
          <Tab label="Status Tracker" value="status-tracker" />
          <Tab label="DrawDown Forecast" value="drawdown-forecast" />
          <Tab label="Portfolio Report" value="portfolio-report" />
        </Tabs>
      </Box>
      <Divider />
      <Outlet />
    </Layout>
  );
}

export default PortfolioManagement;
