import _ from 'lodash';
import DataSheet from 'react-datasheet';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../../../state/store';
import { updateFinanceInputScreen } from './FinanceTable/inputScreenSlice';
import { FinanceInputFormProps, InputFormRowType } from '../../../../../../types/inputform.d';
import { cellUpdate, generateGrid, getNumberFormatted, getInputScope, isNumeric } from '../common/FormUtil';
import { formatNumber } from 'utils/common-utils';

const InputForm = ({ cells }: FinanceInputFormProps) => {
  const inputscreencells = useSelector((state: RootState) => state?.financeInputscreen?.cells);
  const formula = useSelector((state: RootState) => state?.financeFormula?.rows);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { viewOnly } = state;

  const onCellsChanged = (changes: DataSheet.CellsChangedArgs<InputFormRowType>) => {
    const stateCells = _.cloneDeep(inputscreencells);
    const formulaScope = Object.assign({}, formula);

    const inputScope = getInputScope(stateCells);

    changes.forEach(({ cell, value }) => {
      cellUpdate(stateCells, cell, value, inputScope, formulaScope);
    });

    Object.entries(stateCells as Object).map(([formname, value]) => {
      value &&
        Object.entries(value as Object).map(([key, cell]) => {
          if ('dataEditor' in cell) {
            return (stateCells[formname][key] = Object.assign({}, { ...cell, dataEditor: null }));
          }
        });
    });

    dispatch(updateFinanceInputScreen(stateCells));
  };

  return (
    <DataSheet
      data={generateGrid(cells, viewOnly)}
      valueRenderer={(cell) => isNumeric(cell?.value) ? formatNumber(getNumberFormatted(cell?.value)) : cell?.value}
      dataRenderer={(cell) => isNumeric(cell?.expr) ? formatNumber(getNumberFormatted(cell?.expr)) : cell?.expr}
      onCellsChanged={onCellsChanged}
    />
  );
};

export default InputForm;
