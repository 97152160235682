import { useEffect, useState } from 'react';
import { Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';

const SpecificAdvantage = ({
  control,
  getValues,
  setValue,
  watchAllFields,
}: {
  control: Control;
  getValues: any;
  setValue: any;
  watchAllFields: any;
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [integration, setIntegration] = useState<any>([]);

  useEffect(() => {
    setIntegration(watchAllFields?.productionPerformanceAnalysis?.specificAdvantages?.integration || []);
  }, [watchAllFields]);

  const onIntegrationChange = (value: string) => {
    let selected = integration;
    const index = integration.findIndex((i: any) => i === value);

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }

    setIntegration([...selected]);
    setValue('productionPerformanceAnalysis.specificAdvantages.integration', integration);
  };

  return (
    <Box>
      <FormTitle
        formNumbering="3.4"
        title={t('loanProjection.businessBackgroud.productionPerformance.specificAdvantage')}></FormTitle>
      <Grid item lg={12}>
        <Grid item lg={12} md={12} pt={2}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel
              label={'Level of Integration'}
              sx={{
                width: '15%',
                height: '0%',
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, pl: 1 }}
                  onChange={() => onIntegrationChange('Backward Integration')}
                  checked={integration.includes('Backward Integration')}
                  disabled={!state?.isEdit}
                />
              }
              label="Backward Integration"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, pl: 1 }}
                  onChange={() => onIntegrationChange('Forward Integration')}
                  checked={integration.includes('Forward Integration')}
                  disabled={!state?.isEdit}
                />
              }
              label="Forward Integration"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, pl: 1 }}
                  onChange={() => onIntegrationChange('No Integration')}
                  checked={integration.includes('No Integration')}
                  disabled={!state?.isEdit}
                />
              }
              label="No Integration"
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} pt={2}>
          <Box>
            <SimpleFormLabel label="Details Thereoff" sx={{ width: '100%', mb: 0.5 }} />
            <Box>
              <RichTextEditor
                height={250}
                value={watchAllFields?.productionPerformanceAnalysis?.specificAdvantages?.detailsThereoff}
                onEditorChangeHandler={(newValue: any) => {
                  setValue('productionPerformanceAnalysis.specificAdvantages.detailsThereoff', newValue);
                }}
                disabled={!state?.isEdit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpecificAdvantage;
