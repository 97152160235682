import { Grid, Box } from '@mui/material';
import _ from 'lodash';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';

const MarketingArrangement = ({ control }: { control: Control }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle formNumbering='2.4' title={t('loanProjection.businessBackgroud.businessDescription.marketingArrangement')} />
      <Box sx={{ mt: 1 }}>
        {getFormFields(
          {
            component: MULTI_LINE_INPUT,
            name: 'comments.marketingArrangment',
            label: 'Comment',
          },
          control,
          !state.isEdit
        )}
      </Box>
    </Box>
  );
};

export default MarketingArrangement;
