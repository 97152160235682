import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function TableHeading({ title, style }: { title: string; style?: any }) {
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          colSpan={12}
          sx={{ pt: 0.5, pb: 0.5, background: theme.palette.primary.main, color: '#ffffff', ...style }}>
          <Typography
            variant="subtitle2"
            sx={{ pt: 0.5, pb: 0.5 }}>
            {title}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
