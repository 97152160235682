import { useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import { Delete } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { getDealPipeLine, saveDealPipeLine } from 'services/common-services';
import ActionBar from 'components/styled-components/ActionBar';
import EditIcon from '@mui/icons-material/Edit';

const StatusTracker = () => {
  const { state } = useLocation();
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [dropdownData, setDropDownData] = useState<any>({});
  const currentStatusColors: any = {
    Hot: '#0CA30F',
    Warm: '#00FF05',
    Cold: '#00ABD4',
    Delay: '#E7E101',
    Cancel: '#F20000',
    'Move Segment': '#F29100',
  };
  const navigate = useNavigate();

  const { data: statusTrackerData } = useQuery('getDealPipeLine', () => getDealPipeLine(), {
    onSuccess: (data: any) => {
      setRowData(data?.dealPipelineData);
      setDropDownData(data?.dropDownData);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const handleSave = () => {
    let dealPipeline: any = saveDealPipeLine(rowData);
  };

  const statusTrackerColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'id',
        headerName: 'SL.No',
      },
      {
        field: 'customerName',
        headerName: 'Customer Name',
        editable: true,
        suppressSizeToFit: true,
      },
      {
        field: 'cid',
        headerName: 'Company Id',
        editable: true,
        suppressSizeToFit: true,
      },
      {
        field: 'creditProcess',
        headerName: 'Credit Process',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        onCellValueChanged: (params: any) => {
          var rowNode = params.api.getRowNode(params.data.id);
          rowNode.setDataValue('currentStatus', '');
          params.api.refreshCells();
        },
        cellEditorParams: {
          values: dropdownData?.PortfolioCreditProcess,
        },
      },
      {
        field: 'currentStatus',
        headerName: 'Current Status',
        editable: true,
        singleClickEdit: true,
        cellStyle: (params: any) => {
          if (currentStatusColors.hasOwnProperty(params.value.trim())) {
            return { background: currentStatusColors[params.value.trim()] };
          }
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params: any) => {
          if (params.data.creditProcess.trim() == 'Prospect') {
            return {
              values: dropdownData?.PortfolioProspectStatus,
            };
          } else if (params.data.creditProcess.trim() == 'Submission') {
            return {
              values: dropdownData?.PortfolioSubmissionStatus,
            };
          } else if (params.data.creditProcess.trim() == 'Approved') {
            return {
              values: dropdownData?.PortfolioApprovedStatus,
            };
          } else if (params.data.creditProcess.trim() == 'Lost Deal') {
            return {
              values: dropdownData?.PortfolioLostDealStatus,
            };
          }
        },
      },
      {
        field: 'teamLeadId',
        headerName: 'TL Name',
        editable: true,
      },
      {
        field: 'rmUserId',
        headerName: 'RM Local Name',
        editable: true,
      },
      {
        field: 'rmThaiName',
        headerName: 'RM Thai Name',
        editable: true,
      },
      {
        field: 'customerType',
        headerName: 'Customer Type',
        editable: true,
      },
      {
        field: 'leadSource',
        headerName: 'Lead Source',
        editable: true,
      },
      {
        field: 'opportunityApproach',
        headerName: 'Opportunity/Approach',
        editable: true,
      },

      {
        field: 'facilityType',
        headerName: 'Facility Type',
        editable: true,
      },
      {
        field: 'ccy',
        headerName: 'CCY',
        editable: true,
      },
      {
        field: 'bidrEquivalent',
        headerName: 'BIDR Equivalent',
        editable: true,
      },
      {
        field: 'musdEquivalent',
        headerName: 'MUSD Equivalent',
        editable: true,
      },
      {
        field: 'interestRate',
        headerName: 'Interest Rate',
        editable: true,
      },
      {
        field: 'fee',
        headerName: 'Fee',
        editable: true,
      },
      {
        field: 'remarks',
        headerName: 'Remarks',
        editable: true,
        maxWidth: 200,
      },
      {
        field: 'callReport',
        headerName: 'Call Report',
        cellRendererFramework: (params: any) => {
          return (
            <TooltipIconButton
              onClick={() => {
                navigate('/dashboard/portfolio-management/call-report', {
                  state: { ...params.data },
                });
              }}
              icon={<EditIcon fontSize="small" />}
              title="View Call Report"
            />
          );
        },
      },
      {
        field: '',
        headerName: 'Actions',
        pinned: 'right',
        cellRendererFramework: (params: any) => {
          return <TooltipIconButton onClick={(e: any) => {}} icon={<Delete fontSize="small" />} title="Delete" />;
        },
      },
    ];
    return colDef;
  }, [dropdownData]);

  useEffect(() => {
    if (gridApi) {
      gridApi.columnModel.columnApi.autoSizeAllColumns();
    }
  }, [dropdownData]);

  return (
    <>
      <Box sx={{ p: 1, height: 'calc(100vh - 180px)' }}>
        <Box sx={{ height: '100%', mt: 1 }}>
          <DataTable
            onGridReady={(params: any) => {
              setGridApi(params.api);
              params.columnApi.autoSizeAllColumns();
            }}
            columnDefs={statusTrackerColDef}
            sizeToFit={true}
            rowData={rowData}
            getRowNodeId={function (data: any) {
              return data.id;
            }}
            editType=""
            suppressColumnVirtualisation={true}
            paginationPageSize={15}
          />
        </Box>
        <ActionBar>
          <Button variant="contained" sx={{ mr: 2, p: 1 }} type="submit" onClick={handleSave}>
            Save Draft
          </Button>
        </ActionBar>
      </Box>
    </>
  );
};

export default StatusTracker;
