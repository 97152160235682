import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TableHeading from 'components/common/table-header';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import SubCell from 'components/common/table/subcell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import { FormTitle } from 'components/common/form-components/FormTitle';

interface IProps {
  yearEnded: string;
  data: any;
  totalYears: string[];
  configuration: any;
}

export default function SensitivityAnalysis({ yearEnded, data, totalYears, configuration }: IProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>

      <FormTitle title={t('loanProjection.projectionSummary.sensitivityAnalysis')} />
          {configuration}
      <Box sx={{ mt: 0.5 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={totalYears?.length + 1}>
                <Typography>
                  <b>Break-even Calculations</b>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#F8F8F8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Year Ended {yearEnded}</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <HeadingDenseCell>Net Sales</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.netSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Fixed Cost</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.fixedCost)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>SGA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.sga)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Depreciation & Amortisation
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.breakEvenCalc.depreciationAndAmortization)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Interest Payments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.breakEvenCalc.interestPayment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Variable Cost</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.variableCost)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Raw Materials</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.breakEvenCalc.rawMaterials)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Other Variable Costs</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.breakEvenCalc.otherVariableCosts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Contribution</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.contribution)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Breakeven Sales</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.breakEvenSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Margin of Safety %</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.marginOfSafety)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Breakeven %</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.breakEvenPercentage)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Repayments</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.breakEvenCalc.repayment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell colSpan={totalYears?.length + 1}>&nbsp;</HeadingDenseCell>
            </TableRow>
            <TableRow>
              <HeadingDenseCell colSpan={totalYears?.length + 1}>Sensitivity to BEP</HeadingDenseCell>
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>When sales go down</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.value)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales (when down by)</SubCell>
              {_.map(totalYears, (_, index) => {
                return <DenseCellSm></DenseCellSm>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Variable costs also go down by
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.variableCostGoDown)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Contribution</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.contribution)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Fixed Costs</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.fixedCosts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Breakeven Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.bep)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>% to Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.whenSalesGoDown.percentOfSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>When RM cost goes up by</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                % can be passed on to customer
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell
                    key={
                      index
                    }></NumberDenseCell> /*{ {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.growth} )}*/
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales will go up to</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.salesWillGoUp)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Variable costs up by</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.variableCostUpBy)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Contribution</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.contribution)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Fixed costs</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.fixedCosts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Breakeven Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.bep)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>% to Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.rmSalesWillGoUp.percentOfSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>

            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>When Other Variable costs up by</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return <DenseCellSm></DenseCellSm>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                % can be passed on to customer
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}></NumberDenseCell> /* {
                  data[index]?.workingCapitalManagement.cashConversionCycle
                    .cashConversionCycle
                } */
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales will go up to</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.salesWillGoUp)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Other Variable Cost up by
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.othVarCostUpBy)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Contribution</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.contribution)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Fixed Costs</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.fixedCosts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Breakeven Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.bep)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>% to Sales</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToBep.othVarSalesWillGoUp.percentOfSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
             <TableRow>
              <HeadingDenseCell colSpan={totalYears?.length + 1}>&nbsp;</HeadingDenseCell>
            </TableRow>
            <TableRow>
              <HeadingDenseCell colSpan={totalYears?.length + 1}>Sensitivity to DSCR</HeadingDenseCell>
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>When Sales go down</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.salesGoDown)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales (when down by)</SubCell>
              {_.map(totalYears, (_, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Variable costs also go down by
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.variableCostGoDown)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>SGA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.sga)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total cost</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.totalCost)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Operating Profits</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.operatingProfit)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBITDA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.ebitda)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Interest Payments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.interestPayments)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Repayments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.repayment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Gross DSCR</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenSalesGoDown.grossDscr)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>When RM cost goes up by</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                % can be passed on to customer
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <DenseCellSm></DenseCellSm> /*{formatNumber(data[index]?.turnoverRatio.inventoryTurnover)}*/
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales goes up</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.salesGoUp)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Variable costs sales go up to
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.variableCostUpBy)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>SGA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.sga)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total cost</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.totalCost)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBITDA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.ebitda)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Interest payments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.interestPayments)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Repayments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.repayment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Gross DSCR</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrRmWhenSalesGoUp.grossDscr)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>When Variable costs go up by</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                % can be passed on to customer
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <DenseCellSm></DenseCellSm> /*
                {formatNumber(data[index]?.turnoverRatio.inventoryTurnover)}
              */
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sales goes up</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.othVarSalesGoUp)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Variable costs sales go up to
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.othVarCostSalesGoUp)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>SGA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.sga)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total cost</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.totalCost)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBITDA</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.ebitda)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Interest payments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.interestPayments)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Repayments</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.repayment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Gross DSCR</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.sensitivityToDscr.dscrWhenOthVarGoUp.grossDscr)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
