import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoanProposalState {
  isViewOnly: boolean;
}

const initialState = {
  isViewOnly: false,
};

const RegisterCompany = createSlice({
  name: 'loanProposal',
  initialState,
  reducers: {
    updateCompanyViewType: (state: ILoanProposalState, action: PayloadAction<any>) => {
      return (state = { ...state, isViewOnly: action.payload.viewOnly });
    },
  },
});

const { reducer: RegisterCompanyReducer, actions } = RegisterCompany;

export const { updateCompanyViewType } = actions;
export default RegisterCompanyReducer;
