import { Box, Card, CardHeader } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DataTable from 'components/common/data-table';
import { grey } from '@mui/material/colors';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import { useTheme } from '@mui/styles';
import getIcon from 'icons/icons';
import { ADD, DELETE, GO_BACK } from 'icons/icon-names';

const cardHeader = {
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
  },
};

const YearlyPlan = ({ setViewType, watchAllFields, setValue, getValues }: any) => {
  const theme = useTheme();
  const nonCreditExposureRow: any[] = [
    {
      field: 'sno',
      headerName: '',
      editable: true,
    },
    {
      field: 'month',
      headerName: 'Month',
      editable: true,
    },
    {
      field: 'activitiesPlanned',
      headerName: 'Activities Planned',
      editable: true,
    },
    {
      field: '',
      headerName: 'Actions',
      pinned: 'right',
      suppressSizeToFit: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <TooltipIconButton
              onClick={() => {
                setValue('yearlyPlan', [...watchAllFields?.yearlyPlan, {}]);
              }}
              icon={getIcon(ADD, theme.palette.primary.main)}
              title="Add"
            />
            {params.rowIndex > 0 && (
              <TooltipIconButton
                onClick={(e: any) => {
                  let updatedFields = [...getValues('yearlyPlan')];
                  if (updatedFields.length > 0) {
                    updatedFields.splice(params.node.rowIndex, 1);
                    setValue('yearlyPlan', updatedFields);
                    params.api.setRowData(updatedFields);
                  }
                }}
                icon={getIcon(DELETE, theme.palette.error.main)}
                title="Delete"
              />
            )}
          </>
        );
      },
    },
  ];

  const { state } = useLocation();

  return (
    <>
      <Card style={{ borderRadius: 10 }}>
        <CardHeader
          sx={{
            p: 1,
            ...cardHeader,
            pl: 2,
            background: grey[50],
            '& .MuiCardHeader-action': {
              alignSelf: 'center !important',
            },
          }}
          title="Yearly Plan"
          action={
            <TooltipIconButton
              title="Go Back"
              icon={getIcon(GO_BACK, theme.palette.primary.main)}
              onClick={() => {
                setViewType();
              }}
            />
          }
        />
      </Card>
      <Box sx={{ p: 1, height: 'calc(100vh - 220px)' }}>
        <Box sx={{ height: '100%', mt: 1 }}>
          <DataTable
            columnDefs={nonCreditExposureRow}
            autoSize={true}
            rowData={watchAllFields?.yearlyPlan}
            sizeToFit={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default YearlyPlan;
