import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const approveReject = async (data: any) => {
  const response = await httpService.post('/workflow/bizSupApproval', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const riskTeam = async () => {
  const response = await httpService.get('/workflow/riskTeamDetails', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


export const getRiskDocument = async (xrefId:any) => {
  const response = await httpService.get(`/docs/memos/${xrefId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


export const assignUser = async (loanReqId: any, userId: string) => {
  const response = await httpService.get(`/workflow/assignRiskTask/${loanReqId}/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const getAllApprovalRequests = async () => {
  const resposne = await httpService.get('/workflow/getApprovalAuthorityTasks', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return resposne.data;
};

export const approveApprovalAuthorityTask = async (loanRequestDetail: any) => {
  const response = await httpService.post(`/workflow/approveApprovalAuthorityTask`, loanRequestDetail, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


export const getOpenRequests = async () => {
  const resposne = await httpService.get('/workflow/getApprovalAuthorityTasks/open', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return resposne.data;
};



export const getClosedRequests = async () => {
  const resposne = await httpService.get('/workflow/getApprovalAuthorityTasks/closed', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return resposne.data;
};
