import Slider from '@mui/material/Slider';
import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function SimpleSlider({ name, control, label, ...input }: any) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined" >
          {/* <SimpleFormLabel label={label} /> */}
          <Slider sx={{ width: '12rem',...input }} defaultValue={0.00000005} step={1} marks min={0} max={10} />
        </FormControl>
      )}
    />
  );
}
