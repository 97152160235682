export interface InputFormRowType extends DataSheet.Cell<InputFormRowType> {
  key?: string;
  value?: string | null;
  expr?: string | null;
  readonly?: boolean;
  width?: string;
  type?: string | null;
  form?: string | null;
  group?: string | null;
}

export interface InputFormDataType {
  [col: string]: InputFormRowType;
}

export interface InputFormProps {
  cells: InputFormDataType;
  type?: typeof FormType[keyof typeof FormType];
}

export interface FinanceInputFormProps {
  cells: InputFormDataType;
  type?: typeof FinanceFormType[keyof typeof FinanceFormType];
}

export type InputCellType = {
  value: string;
  onChange: (value: string) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export enum FormType {
  FORMPROFITLOSS,
  FORMSALES,
  FORMADDITIONALINFORMATION,
  FORMCOMMONSIZEPROFITLOSS,
  FORMCONTINGENTLIABILITIES,
  FORMDEBTDETAILS,
  FORMBALANCESHEET,
  FORMCOMMONSIZEBALANCESHEET,
  FORMCASHFLOWSTATEMENT,
  FORMFUNDFLOWSTATEMENT,
  FORMSUMMARYPROJECTIONS,
  FORMKEYPERFORMANCE
}

export enum FinanceFormType {
  FORMPROFITLOSS,
  FORMLOANS,
  FORMDEPOSITS,
  FORMDEBTDETAILS,
  FORMCAPITALINJECTION,
  FORMKEYPLASSUMPTIONS,
  FORMOTHERASSUMPTIONS,
  FORMBALANCESHEET,
  FORMKEYBSASSUMPTIONS,
  FORMCAPITALADEQUEACYRATIO,
  FORMCASHFLOWSTATEMENT,
  FORMFINANCIALRATIOS,
}

export enum FormGroup {
  BASIC,
  ACTUALS,
  PROJECTION
}

export type FormGroupType = typeof FormGroup[keyof typeof FormGroup];

export interface IInputTable {
  inputType: FormGroupType
}

export interface FormGroupI {
  group: FormGroupType;
}
