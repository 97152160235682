import _ from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Paper } from '@mui/material';
import ProposalSideMenu from './proposal-side-menu';
import ProjectionBasicDetails from './projection-basic-details';
import ManufacturingTable from '../../credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable';
import { FormGroup } from '../../../../types/inputform.d';
import {
  PerformanceSummary,
  FormII,
  FormIII,
  FormIV,
  FormV,
  WCAnalysis,
  FormVI,
  ProjectionSummary,
} from './manufacturing-components';
import { FormBS, FormPL, CashFlow, FormALM, FeeIncome } from './finance-components';
import { styled } from '@mui/styles';
import useRefData from 'hooks/ref-data';
import { RootState } from '../../../../state/store';
import FinanceTable from 'components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Finance/FinanceTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number | null;
  style?: any;
}

const StyledCard = styled(Card)(() => ({
  height: 'calc(100vh - 240px)',
  overflowY: 'auto',
  // borderTopRightRadius: 40,
  // borderBottomRightRadius: 40,
  width: 200,
  pt: 2,
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: 'calc(100vh - 100px)', width: 'calc(100vw - 200px)' }}
      {...other}>
      {value === index && <Box sx={{ pl: 2, pr: 2, pt: 0, height: '100%', pb: 20 }}>{children}</Box>}
    </div>
  );
}

function BasicFormPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: 'calc(100vh - 100px)', width: 'calc(100vw - 200px)', overflow: 'hidden' }}
      {...other}>
      {value === index && (
        <Box sx={{ pl: 2, pr: 2, pt: 0, overflow: 'hidden', height: '100%', mb: 100 }}>{children}</Box>
      )}
    </div>
  );
}

const ProjectionForm = () => {
  const [value, setValue] = useState<null | number>(0);
  const {  fn } = useRefData();
  const { mapProjectionTemplate } = fn;
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const cells = useSelector((state: RootState) => state?.inputscreen?.cells);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  let tabPanelItems:any =[]
  
  if(mapProjectionTemplate){
    const projectionTemplateName = mapProjectionTemplate!(tabs![selectedTab]?.projectionTemplate)
    tabPanelItems =
    !_.isEmpty(tabs) && projectionTemplateName === 'Manufacturing'
      ? [
          {
            key: 'actuals',
            component: <ManufacturingTable key={FormGroup.ACTUALS} inputType={FormGroup.ACTUALS} />,
          },
          {
            key: 'projections',
            component: <ManufacturingTable key={FormGroup.PROJECTION} inputType={FormGroup.PROJECTION} />,
          },          
          { key: 'performance-summary', component: <PerformanceSummary /> },
          { key: 'projection-summary', component: <ProjectionSummary /> },
          { key: 'form-II', component: <FormII /> },
          { key: 'form-III', component: <FormIII /> },
          { key: 'form-IV', component: <FormIV /> },
          { key: 'form-V', component: <FormV /> },
          { key: 'form-VI', component: <FormVI /> },
          { key: 'wc-analysis', component: <WCAnalysis /> },
        ]
      : [
          { key: 'actuals', component: <FinanceTable key={FormGroup.ACTUALS} inputType={FormGroup.ACTUALS} /> },
          {
            key: 'projections',
            component: <FinanceTable key={FormGroup.PROJECTION} inputType={FormGroup.PROJECTION} />,
          },
          { key: 'form-bs', component: <FormBS /> },
          { key: 'form-pl', component: <FormPL /> },
          { key: 'cash-flow', component: <CashFlow /> },
          { key: 'form-alm', component: <FormALM /> },
          { key: 'fee-income', component: <FeeIncome /> },
        ];

  }
  
  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <StyledCard elevation={4}>
          <ProposalSideMenu value={value} handleChange={handleChange} />
        </StyledCard>
      </Box>
      <Box>
        <BasicFormPanel value={value} index={0}>
          <ProjectionBasicDetails />
        </BasicFormPanel>
        {!_.isEmpty(cells?.formbalancesheet) && <Paper style={{top: 0,
            left: 0,
            zIndex: 2,
            position: "sticky",
            backgroundColor: "#fafafa",
            padding: "20px"
        }}>
        <table style={{border: "1px solid rgba(145, 158, 171, 0.24)",
        padding: "2px",
          borderCollapse: "collapse"
        }}>
        <thead>
        <tr>

        <th style={{width:"40%"}}>{value && FormGroup![value]?.toUpperCase()}</th>
        
        {!_.isEmpty(cells?.formbalancesheet) && Object.assign(
          [],
          ...Object.values(cells).map((row:any) =>
            Object.entries(row)
              .filter(([k]:any) => k?.endsWith('.0'))
              .map(([k, v]:any) => `${v?.value} ${v?.group?.toUpperCase()}`)
        )).map((c:any, i: number) => (<th key={i} style={{width:"150px", border: "1px solid rgba(145, 158, 171, 0.24)",
                padding: "2px",
          borderCollapse: "collapse"
        }}>{c}</th>))}
        </tr>
        </thead>
        <tbody>
        <tr>
        {!_.isEmpty(cells?.formbalancesheet) && Object.values(cells?.formbalancesheet)
        .filter((a:any) => a?.key?.endsWith("totalAssetsA"))
        .map((v:any, i: number) => <td key={i} style={{width:"150px", border: "1px solid rgba(145, 158, 171, 0.24)",
                padding: "2px",
          borderCollapse: "collapse"
        }}>{Number(v?.value) ? Number(v?.value).toFixed(1):v?.value}</td>)}
        </tr>
        <tr>
        {!_.isEmpty(cells?.formbalancesheet) && Object.values(cells?.formbalancesheet)
        .filter((a:any) => a?.key?.endsWith("totalLiabilitiesB"))
        .map((v:any, i: number) => <td key={i} style={{width:"150px", border: "1px solid rgba(145, 158, 171, 0.24)",
                padding: "2px",
          borderCollapse: "collapse"
        }}>{Number(v?.value) ? Number(v?.value).toFixed(1):v?.value}</td>)}
        </tr>
        <tr>
        {!_.isEmpty(cells?.formbalancesheet) && Object.values(cells?.formbalancesheet)
        .filter((a:any) => a?.key?.endsWith("differenceAB"))
        .map((v:any, i: number) => <td key={i} style={{width:"150px", border: "1px solid rgba(145, 158, 171, 0.24)",
                padding: "2px",
          borderCollapse: "collapse"
        }}>{Number(v?.value) ? Number(v?.value).toFixed(1):v?.value}</td>)}
        </tr>
        </tbody>
        </table>
        </Paper>}

        {tabPanelItems.map((tabPanelItem: any, index: number) => (
          <TabPanel key={tabPanelItem.key} value={value} index={index + 1}>
            {tabPanelItem.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectionForm;
