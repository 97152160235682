import { Box, Typography, Divider } from '@mui/material';
import { INPUT } from 'constants/form-components';
import { getFormFields } from 'utils/form-utils';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { MULTI_LINE_INPUT, RADIO_BUTTONS } from 'constants/form-components';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

function DerivativeAssessment({ control, watchAllFields }: any) {
  const { state } = useLocation();
  // const [showDerivativeToggle, setDerivativeToggle] = useState(false);

  // useEffect(() => {
  //   if (watchAllFields?.isDerivativeAssessment) {
  //     setDerivativeToggle(watchAllFields?.isDerivativeAssessment == 'no' ? false : true);
  //   }
  // }, [watchAllFields?.isDerivativeAssessment]);
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, display: 'flex' }}>
          <Typography variant="caption" sx={{ pt: 1.25, width: '25%' }}>
            <b>Deriviative Assessment</b>
          </Typography>
          {getFormFields(
            {
              name: 'isDerivativeAssessment',
              component: RADIO_BUTTONS,
              values: [
                {
                  label: 'Applicable',
                  value: 'true',
                },
                {
                  label: 'Non-Applicable',
                  value: 'false',
                },
              ],
            },
            control,
            state.viewOnly
          )}
        </Box>
        <Divider />
      </Box>
      {(watchAllFields?.isDerivativeAssessment === 'true' || watchAllFields?.isDerivativeAssessment === true) && (
        <>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
              <Typography variant="caption">
                <b>Classification of the Forex/Derivative Limits into the following based on end-use:</b>
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'End Use'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.forexClassification.endUse',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'Limits'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.forexClassification.limits',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'Tenor'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.forexClassification.tenor',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'Validity of limits'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.forexClassification.validityOfLimits',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
              <Typography variant="caption">
                <b>Sanctions details</b>
              </Typography>
              <Divider />
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'Breaking Clause'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.sanctionsDetails.breakClause',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <SimpleFormLabel label={'Margining'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.sanctionsDetails.margining',
                    component: INPUT,
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
              <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
              <Box sx={{ width: '75%' }}>
                {getFormFields(
                  {
                    name: 'assessmentLimits.commentsDeriviativeAssessment',
                    component: MULTI_LINE_INPUT,
                    label: 'Comment',
                  },
                  control,
                  state.viewOnly
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default DerivativeAssessment;
