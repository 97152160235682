import { Button, Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import ActionBar from 'components/styled-components/ActionBar';
import TableHeading from 'components/common/table-header';
import { roles } from 'constants/strings';
import useLocalStore from 'hooks/user-local-store';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import CamButton from 'components/common/review-cam-btn/CamButton';

export default function FormII() {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formII'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form2, (e) => {
    return e.year;
  });

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form2[idx].actuals) {
      return data?.form2[idx].actuals;
    }
    return data?.form2[idx]?.projection;
  });

  return (
    <MainCard>
      <Table size="small">
        <TableHeading title="Profit & Loss Statement" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Assessment of working capital requirement</b>
              </Typography>
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>Domestic Sales</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].domesticSales.domesticSalesValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Export Sales</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].domesticSales.exportSales)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Total Gross Sales</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].totalGrossSales.totalGrossSalesValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Less Duty (tax)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].totalGrossSales.lessDutyTax)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Net Sales (1 - 2)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].netSales.netSalesValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          {/*<TableRow>
            <DenseCellSm>
              % age rise (+) or fall (-) in net sales as compared to previous year (annualised)
            </DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].netSales.percentageChangeInNetSales)}</NumberDenseCell>
              );
            })}
          </TableRow>*/}
          <TableRow>
            <DenseCellSm>Net Other Operating Income/Expense</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].netSales.netOtherOperatingIncomeExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Total Operating Income</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].totalOperatingIncome)}
                </NumberDenseCell>
              );
            })}
          </TableRow>

          <TableRow>
            <HeadingDenseCell>Raw materials</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].rawMaterial.rawMaterialValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Domestic</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].rawMaterial.domestic)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Imported</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].rawMaterial.imported)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Other Spares</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].otherSpares.otherSparesValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Domestic</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].otherSpares.domestic)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Imported</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].otherSpares.imported)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Allowance for Inventory (-)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].allowanceForInventory)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Power and Fuel</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].powerAndFuel)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Direct Labour (Factory wages & salaries)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].directLabour)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Other manufacturing expenses</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.otherManufacturingExpenseValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Repairs & Maintenance</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.repairAndMaintenance)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Finished stock handling</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.finishedStockHandling)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other factory expense</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.otherFactoryExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Excise and Other Duties</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.exciseAndOtherDuties)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Others</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].otherManufacturingExpense.others)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Cost of production</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].costOfProduction.costOfProductionValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Add: Opening Stock-in-process</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].costOfProduction.openingStockInProgress)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Deduct: Closing Stock-in-process</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].costOfProduction.closingStockInProgress)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Total Cost of Production</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].totalCostOfProduction)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Add: Purchase of traded goods</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].purchaseOfTradedGoods)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Add: Opening Stock finished goods</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].openingStockFinishedGoods)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Deduct: Closing Stock finished goods</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].closingStockFinishedGoods)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Total Cost of Goods Sold (COGS)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].totalCostOfGoodsSold)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>Fixed/Indirect Costs</HeadingDenseCell>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Selling & General Admin Expenses</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.sellingGeneralAdminValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Carriage Outward handling charges</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.carriageOutward)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Export and Other Selling expenses</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.exportAndOtherSelling)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Securities services charges</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.securitiesServiceCharges)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Rent & Insurance</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.rentAndInsurance)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Rates & Taxes</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.ratesAndTaxes)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Travelling expenses</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.travellingExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Legal & professional charges</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.legalAndProfessionalCharges)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Directors fee</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.directorsFee)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Auditors' remuneration</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.auditorsRenumeration)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Excise duty</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.exciseDuty)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>CSR expenses</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.csrExpenses)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Donations</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.donations)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Misc expenses</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.miscExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>MTM translations</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.mtmTransactions)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Others</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].sellingGeneralAdminExpense.others)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Depreciation & Amortization</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].depreciationAndAmortization.value)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Plant, property and equipment</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].depreciationAndAmortization.plantPropertyEquipment)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other Intagible Assets</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].depreciationAndAmortization.otherIntangibleAssets)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          {/*<TableRow>
            <DenseCellSm>Depreciation</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].depreciationAndAmortization.depreciation)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Amortization</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].depreciationAndAmortization.amortization)}</NumberDenseCell>
              );
            })}
          </TableRow>*/}
          <TableRow>
            <DenseCellSm>Total Costs</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].depreciationAndAmortization.totalCosts)}
                </NumberDenseCell>
              );
            })}
          </TableRow>

          <TableRow>
            <HeadingDenseCell>Earning Before Interest & Tax (EBIT)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].ebit)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Interest</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].interest.interestValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Interest on Fixed Loans / Term loans</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].interest.interestOnFixedLoan)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Adjustment in borrowing cost due to Exchange fluctuations</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].interest.adjustmentInBorrowing)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Interest on Working capital loans</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].interest.interestOnWorkingCapitalLoans)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Lease liabilities</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].interest.leaseLiabilities)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Bank Charges & Other Interests</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].interest.bankCharges)}</NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Earning After Interest</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].earningAfterInterest)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Net Non-Operating Income / Expenses</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Non-Operating Income/Expenses (Cash Items)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpCash.netNonOperatingIncExpCashValue
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Interest Income received</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpCash.interestIncomeCompanies
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>

          <TableRow>
            <DenseCellSm>Dividend received from companies</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpCash.dividendFromCompanies
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other non-operating income/(expense)-Investing</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpCash.otrNonOperatingIncomeInvesting
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other non-operating income/(expense)-financing</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.netNonOperatingIncExpCash.otrNonOperatingIncomeFinancing
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Non-Operating Income/Expenses (Non-Cash Items)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash
                      .newNonOperatingIncExpNonCashValue
                  }
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net gain/(loss) on sale of current investments FVTPL</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.netGainsSale)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net gain/(loss) on fair valuation of current investments FVTPL</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.netGainsFairValuation
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net gain/(loss) on disposal of property, plant and equipment</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.netGainsDisposal)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Impairment on Property, Plant and Equipment (-ve)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.impairmentOnProperty
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Bad Debt</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.badDebt)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Impairment loss recognised on trade receivables (-ve)t</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.impairmentOnProperty
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Impairment loss recognised on other assets -(ve)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.impairmentLossOnTrade
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Goodwill impairment (-ve)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.goodwillImpairment
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Intangibles written off (-ve)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.intangibleWrittenOff
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Unrealised Forex Gain / (loss)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.unrealisedForexGains
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Liabilities no longer required written back</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx].netNonOperatingIncExp.newNonOperatingIncExpNonCash.liabilitiesWrittenOff
                  )}
                </DenseCellSm>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Exceptional Items - (Income)/Expense (Net)</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].exceptionalItems.exceptionalItemsValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Exceptional Income</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].exceptionalItems.exceptionalIncome)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Exceptional Expense</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].exceptionalItems.exceptionalExpense)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Profit before Tax</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].profitBeforeTax)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Provision for taxes</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].provisionForTax.provisionForTaxValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Current tax</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].provisionForTax.currentTax)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Deferred tax</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].provisionForTax.deferredTax)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other Adjustments</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].provisionForTax.otherAdjustments)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm colSpan={numberOfYears?.length + 1}>&nbsp;</DenseCellSm>
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Net Profit / Loss</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].netProfitLoss.netProfitLossValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other Comprehensive income</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return <NumberDenseCell key={idx}>{formatNumber(dataArr[idx].otherCompIncLoss)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>
              <b>Total Comprehensive income</b>
            </HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].netProfitLoss.netProfitLossValue)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Dividend + Dividend Tax</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].totalCompInc.dividendAndDividendTax)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Dividend Rate (% age)</DenseCellSm>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx].netProfitLoss.dividendRange)}
                </NumberDenseCell>
              );
            })}
          </TableRow>

          <TableRow>
            <HeadingDenseCell>Transferred to Retained Earnings</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx].transferredToRetainedEarning)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
      <ActionBar>
        <CamButton
          sx={{ p: 1, mr: 2 }}
          onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
          camState={state}
        />
      </ActionBar>
    </MainCard>
  );
}
