import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SalesDetails from './sales-details';
import Arrangements from './arrangements/arrangements';
import KeyCompititor from './arrangements/key-compititor';
import SpecificAdvantage from './advantage';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT, TABLE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';

const ProductionPerformance = ({ productionPerformanceData, ...props }: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const labelStyle = {
    width: '15%',
    height: '0%',
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <SalesDetails {...props} data={productionPerformanceData} />
      </Grid>
      <Grid item lg={12}>
        <Arrangements
          {...props}
          rawMaterialData={productionPerformanceData?.rawMaterialAndProcurementArrangement}
        />
      </Grid>
      <Grid item lg={12}>
        <KeyCompititor {...props} t={t} />
      </Grid>
      <Grid item lg={12}>
        <SpecificAdvantage {...props} />
      </Grid>
      <Grid item lg={12}>
        <Box>
          <FormTitle formNumbering="3.5" title="Swot Analysis"></FormTitle>
          <Grid lg={12}>
            <Grid item lg={12} md={12} pt={2}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label="Strengths" sx={labelStyle} />
                {getFormFields(
                  {
                    name: 'productionPerformanceAnalysis.swotAnalysis.strengths',
                    component: MULTI_LINE_INPUT,
                    label: 'Comment',
                  },
                  props.control,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item lg={12} md={12} pt={2}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label="Weakness" sx={labelStyle} />
                {getFormFields(
                  {
                    name: 'productionPerformanceAnalysis.swotAnalysis.weakness',
                    component: MULTI_LINE_INPUT,
                    label: 'Comment',
                  },
                  props.control,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item lg={12} md={12} pt={2}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label="Opportunities" sx={labelStyle} />
                {getFormFields(
                  {
                    name: 'productionPerformanceAnalysis.swotAnalysis.opportunities',
                    component: MULTI_LINE_INPUT,
                    label: 'Comment',
                  },
                  props.control,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
            <Grid item lg={12} md={12} pt={2}>
              <Box sx={{ display: 'flex' }}>
                <SimpleFormLabel label="Threats" sx={labelStyle} />
                {getFormFields(
                  {
                    name: 'productionPerformanceAnalysis.swotAnalysis.threats',
                    component: MULTI_LINE_INPUT,
                    label: 'Comment',
                  },
                  props.control,
                  !state?.isEdit
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <CustomTableContainer>
          <Table size="small" sx={{ border: tableStyles.tableBorders }}>
            <TableBody>
              <Row
                title="Strengths"
                data={
                  <DenseCellSm>
                    {getFormFields(
                      {
                        name: 'swotAnalysis.strengths',
                        component: MULTI_LINE_INPUT,
                      },
                      control,
                      !state?.isEdit
                    )}
                  </DenseCellSm>
                }
              />
              <Row
                title="Weakness"
                data={
                  <DenseCellSm>
                    {getFormFields(
                      {
                        name: 'swotAnalysis.weakness',
                        component: MULTI_LINE_INPUT,
                      },
                      control,
                      !state?.isEdit
                    )}
                  </DenseCellSm>
                }
              />
              <Row
                title="Opportunities"
                data={
                  <DenseCellSm>
                    {getFormFields(
                      {
                        name: 'swotAnalysis.opportunities',
                        component: MULTI_LINE_INPUT,
                      },
                      control,
                      !state?.isEdit
                    )}
                  </DenseCellSm>
                }
              />
              <Row
                title="Threats"
                data={
                  <DenseCellSm>
                    {getFormFields(
                      {
                        name: 'swotAnalysis.threats',
                        component: MULTI_LINE_INPUT,
                      },
                      control,
                      !state?.isEdit
                    )}
                  </DenseCellSm>
                }
              />
            </TableBody>
          </Table>
        </CustomTableContainer> */}
      </Grid>
    </Grid>
  );
};

export default ProductionPerformance;
