import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

const refDataGroups = Object.freeze({
  INDUSTRY_TYPES: 'IndustryTypes',
  LOAN_TYPES: 'LoanTypes',
  PROJECTION_TYPE: 'LoanTypes',
  SUB_INDUSTRY_MANUFACTURING: 'SubIndustryType_Manufacturing',
  SUB_INDUSTRY_FINANCE: 'SubIndustryType_Finance',
  RISK_TYPES: 'RiskTypes',
  COMPANY_TYPES: 'CompanyTypes',
  FISCAL_YEAR_TYPES: '',
});

const getIndustryType = async () => {
  const res = await httpService.get(`/refData/byGroup/IndustryTypes`, {
    headers: { Authorization: `Bearer ${getToken()}` || '', 'Content-Type': 'text/plain' },
  });
  return res.data;
};

const getRefDataByGroupName = async (groupName: string) => {
  const res = await httpService.get(`/refData/byGroup/${groupName}`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const getRefDataByParentRef = async (id: number | undefined) => {
  const res = await httpService.get(`/refData/byParentRef/${id}`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const getRefDataByGroups = async (groups: string) => {
  const res = await httpService.post('/refData/byGroups', groups, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'text/plain',
    },
  });
  return res.data;
};

const getRiskRationale = async (id: number | undefined) => {
  const result = await httpService.get(`/riskRatingMaster/questions/${id}`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return result.data;
};
const getRiskRationaleData = async (id: number | undefined) => {
  const result = await httpService.get(`/riskRatingMaster/getRiskRating/${id}`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return result.data;
};
const getPortfolioData = async () => {
  const result = await httpService.get(`/portfolio/view`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return result.data;
};
const getDealPipeLine = async () => {
  const result = await httpService.get(`/portfolio/dealPipeline/view`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return result.data;
};
const getDrawDownForecast = async () => {
  const result = await httpService.get(`/portfolio/drawDownForecast/view`, {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return result.data;
};

const generateRiskRating = async (id: number, result: any) => {
  const res = await httpService.post(`/riskRatingMaster/calculateRating/${id}`, result, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};
const savePortfolio = async (data: any) => {
  const res = await httpService.post(`/portfolio/save`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};
const saveDealPipeLine = async (data: any) => {
  const res = await httpService.post(`/portfolio/dealPipeline/save`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};
const saveDrawDownData = async (data: any) => {
  const res = await httpService.post(`/portfolio/drawDownForecast/save`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};
const saveRiskRational = async (id: number, result: any) => {
  const saveRisk = await httpService.post(`/riskRatingMaster/riskRating/${id}`, result, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return saveRisk.data;
};

const getSystemNotification = async () => {
  const res = await httpService.get(`/sys/notification/allActive`, {
    headers: { Authorization: `Bearer ${getToken()}` || '', 'Content-Type': 'text/plain' },
  });
  return res.data;
};

const getResourceCenterDocs = async () => {
  const res = await httpService.get(`/sys/resourceCenterDocs`, {
    headers: { Authorization: `Bearer ${getToken()}` || '', 'Content-Type': 'text/plain' },
  });
  return res.data;
};

const getRarocRating = async (data: any) => {
  const res = await httpService.post(`/riskRatingMaster/calculateRAROC`, data, {
    headers: { Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const viewCallReport = async (id: number) => {
  const res = await httpService.get(`/portfolio/callReport/view/${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const saveCallReport = async (id: number, data: any) => {
  const res = await httpService.post(`/portfolio/callReport/save/${id}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const getPublicConfig = async () => {
  const res = await httpService.get(`/sys/public/config`, {
    headers: { Authorization: '', 'Content-Type': 'text/plain' },
  });
  return res.data;
};

export {
  getIndustryType,
  getRefDataByParentRef,
  refDataGroups,
  getRefDataByGroupName,
  getRefDataByGroups,
  getRiskRationale,
  generateRiskRating,
  saveRiskRational,
  getRiskRationaleData,
  getSystemNotification,
  getResourceCenterDocs,
  getPortfolioData,
  savePortfolio,
  getDealPipeLine,
  saveDealPipeLine,
  getDrawDownForecast,
  saveDrawDownData,
  getRarocRating,
  viewCallReport,
  saveCallReport,
  getPublicConfig,
};
