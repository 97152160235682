import { FC } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  styled,
  TableCell,
} from '@mui/material';
import { useFieldArray, useWatch } from 'react-hook-form';
import { MULTI_LINE_INPUT } from '../../../../../constants/form-components';
import { getFormFields } from '../../../../../utils/form-utils';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import TableDenseCell from 'components/common/table/TableDenseCell';
import { useLocation } from 'react-router-dom';
interface IProps {
  control: any;
  watchAllFields: any;
  viewMode: any;
}

export const RatingForm: FC<IProps> = ({ control, watchAllFields, viewMode }) => {
  const { state } = useLocation();
  const theme = useTheme();
  const { fields } = useFieldArray({ name: 'ratings', control });
  const values = useWatch({ name: 'finalRatingDetails', control });

  const transformOptionsData = (options: []) => {
    let transformedArray = options.map((option) => {
      return { id: option, displayText: option };
    });
    return transformedArray || [];
  };

  const CustomizeTableCell = styled(TableCell)(({ theme }) => ({
    minWidth: 50,
    background: theme.palette.grey[50],
    maxWidth: '200 !important',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  }));

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            padding: 1.2,
            border: '2px dashed rgba(145, 158, 171, 0.24)',
            display: 'flex',
            background: theme.palette.primary.light,
            justifyContent: 'space-evenly',
            height:"50px"
          }}>
          <Typography variant="subtitle1">Final Score: <span style={{color:'rgb(232, 59, 70)'}}><b>{(values?.finalScore && Number(values?.finalScore)?.toLocaleString()) || 0}</b></span></Typography>
          <Typography variant="subtitle1">Final Risk Rating: <span style={{color:'rgb(232, 59, 70)'}}><b>{values?.finalRiskRating || ''}</b></span></Typography>
          <Typography variant="subtitle1">
            Industry: <span style={{color:'rgb(232, 59, 70)'}}><b>{watchAllFields?.financialAssessment?.borrowerSummary?.industryOutlook}</b></span>
          </Typography>
        </Box>
        <TableContainer sx={{ mt: 7, overflowX: 'hidden', height: 'calc(100vh - 280px)!important' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <CustomizeTableCell width={'80px'}>
                  <Typography variant="subtitle2">S.No</Typography>
                </CustomizeTableCell>
                <CustomizeTableCell width={'150px'}>
                  <Typography variant="subtitle2">Risk Category</Typography>
                </CustomizeTableCell>
                <CustomizeTableCell width={'150px'}>
                  <Typography variant="subtitle2">Risk Parameter</Typography>
                </CustomizeTableCell>
                <CustomizeTableCell width={'400px'}>
                  <Typography variant="subtitle2">Guidance/DataSource</Typography>
                </CustomizeTableCell>
                <CustomizeTableCell width={'100px'}>
                  <Typography variant="subtitle2">Values Selected</Typography>
                </CustomizeTableCell>
                <CustomizeTableCell width={'420px'}>
                  <Typography variant="subtitle2">Comments, If any</Typography>
                </CustomizeTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item: any, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableDenseCell>{item.sNo}</TableDenseCell>
                    <TableDenseCell>{item.riskCategory}</TableDenseCell>
                    <TableDenseCell>{item.riskParam}</TableDenseCell>
                    <TableDenseCell>{item.dataSource}</TableDenseCell>
                    <TableDenseCell>
                      {getFormFields(
                        {
                          name: `ratings[${idx}].value`,
                          component: 'dropdown',
                          options: transformOptionsData(item.options) || [],
                        },
                        control,
                        viewMode
                      )}
                    </TableDenseCell>
                    <TableDenseCell sx={{ paddingLeft: 1.5, paddingRight: 2 }}>
                      {getFormFields(
                        { name: `ratings[${idx}].comment`, component: MULTI_LINE_INPUT },
                        control,
                        viewMode
                      )}
                    </TableDenseCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
