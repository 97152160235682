import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MarketingArrangement from './marketing-arrangement';
import TopCustomers from './top-customers';
import TopProducts from './top-products';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';

export default function BusinessDescription(props: any) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const onBusinessModelChange = (newValue: any) => {
    props.setValue('businessDescription.businessModel', newValue);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12}>
          <Box sx={{ pl: 0.5, pr: 0.5 }}>
            <FormTitle
              formNumbering="2.1"
              title={t('loanProjection.businessBackgroud.businessDescription.businessModel')}
            />
            <Box sx={{ mt: 1 }}>
              <RichTextEditor
                value={props?.watchAllFields?.businessDescription?.businessModel}
                onEditorChangeHandler={onBusinessModelChange}
                disabled={!state.isEdit}
              />
            </Box>
            {/* {getFormFields(
              { component: MULTI_LINE_INPUT, name: 'businessDescription.companyBusinessDetails', maxLength: 5000 },
              control,
              state.viewOnly
            )} */}
          </Box>
          {/* <Table size="small">
            <TableHeading title={t('loanProjection.businessBackgroud.businessDescription.businessModel')} />
          </Table> */}
        </Grid>
        <Grid item lg={12}>
          {/**
           * TODO:
           * need details about this one
           */}
          <TopProducts {...props} />
        </Grid>
        <Grid item lg={12}>
          <TopCustomers {...props} />
        </Grid>
        <Grid item lg={12}>
          <MarketingArrangement {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}
