import { useState } from 'react';
import { Box, Divider, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import ProjectionLayout from '../../layout/projection-layout';
import { toggleLoader } from 'state/reducers/common-actions';
import { approveApprovalAuthorityTask } from 'services/approval-service';
import ApprovalMainMenu from './approvalMainMenu';
import { BreadCrumbs } from 'components/common/pages/BreadCrumbs';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import ActionBar from 'components/styled-components/ActionBar';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';

const ApprovalScreen = (props: any) => {
  const { getRequestStatusCode, getHomePage } = useLoanTable();
  const [open, setOpen] = useState(false);
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log('ApprovalScreen', state);
  const { enqueueSnackbar } = useSnackbar();
  const { statusCode, viewOnly } = state;

  const approveReturnMutation = useMutation((approvalData: any) => approveApprovalAuthorityTask(approvalData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(
        `Loan Request ID : ${state?.loanRequestId} has been ${
          variables?.approvalStatus ? 'Approved' : 'Returned'
        }`,
        {
          variant: 'success',
        }
      );
      navigate(getHomePage());
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const onClickApproveReturnHandler = (comment: string) => {
    if (state?.loanRequestId && approveReturn != null && state?.flowableTaskId) {
      approveReturnMutation.mutate({
        approvalStatus: approveReturn,
        loanId: state?.loanRequestId,
        approvalComment: comment,
        approvalTaskId: state?.flowableTaskId,
      });
    }
  };

  const handleClickOpen = (approveReturn: boolean | null) => {
    setMessage('');

    if (approveReturn != null) {
      setApproveReturn(approveReturn);
      setMessage('Confirmation');
      setOpen(true);
    }
  };

  return (
    <ProjectionLayout>
      <Box>
        <BreadCrumbs
          title={t('screenHeaders.approvalAuthority.heading')}
          loanRequestId={state?.loanRequestId}
          company={state?.company}
          amount={state?.amount}
          route={routes.APPROVAL_AUTHORITY_ROUTE}
        />
        <ApprovalMainMenu />
        <Divider />
        {/**children will be the routes */}
        {props.children}
        <ActionBar>
          {!state.viewOnly && (
            <>
              <Button variant="contained" onClick={() => handleClickOpen(false)} sx={{ p: 1, right: 20 }}>
                Return
              </Button>
              <Button
                type="submit"
                onClick={() => handleClickOpen(true)}
                variant="contained"
                sx={{ p: 1, right: 10 }}>
                Approve
              </Button>
            </>
          )}
        </ActionBar>
        <DialogWithComment
          open={open}
          setOpen={setOpen}
          title={message}
          description={`Do you want to ${approveReturn ? 'Approve' : 'Return'} for Loan Request ID: ${
            state?.loanRequestId
          } ?`}
          type={'info'}
          onClickHandler={onClickApproveReturnHandler}
        />
      </Box>
    </ProjectionLayout>
  );
};

export default ApprovalScreen;
