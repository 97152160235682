import Box from '@mui/material/Box';
import { MainBox } from './styles';

export default function ProjectionLayout({ children }: any) {
  return (
    <Box sx={{ display: 'flex', overflow: 'hidden', backgroundColor: '#fff' }}>
      <MainBox
        component="main"
        sx={{ overflowX: 'hidden', height: '100%', width: '100vw', backgroundColor: '#fff' }}>
        <Box sx={{ height: '100%' }}>{children}</Box>
      </MainBox>
    </Box>
  );
}
