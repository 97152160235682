import { useSelector } from 'react-redux';
import _ from 'lodash';
import InputForm from '../InputForm';
import { RootState } from '../../../../../../../state/store';
import formSalesTemplate from './formSalesTemplate.json';
import {
  getFormDataFromState,
  getForm,
  updateJson,
  getRowTemplate,
  getItemsAndAppend,
  addCustomFormula,
  getItems,
} from '../../common/FormUtil';
import { FormGroup, FormType, FormGroupType, FormGroupI } from '../../../../../../../types/inputform.d';

export const generateFormSales = (group: FormGroupType, salesCells: any, dbJson: any, loanDetails: any) => {
  const formCells = _.cloneDeep(salesCells);

  let allRows: any[] = [];
  let formDataCells: any;

  if (!_.isEmpty(formCells)) {
    formDataCells = getFormDataFromState(formCells, group);
  } else if (_.isEmpty(formCells)) {
    const {
      form: formSales,
      productsWithCapacity,
      productsWithSales,
      productsWithDomestic,
      rawMaterialsRate,
      rawMaterialsCost,
      productDomesticSales,
      productExportSales,
      lessInterUnitTrnsfrChromOre,
    } = _.cloneDeep(formSalesTemplate) as any;

    const { products, rawMaterials } = loanDetails;

    updateJson(
      'type',
      'productsWithCapacity',
      getItemsAndAppend(products, productsWithCapacity, 'product'),
      formSales
    );

    updateJson('type', 'productsWithSales', getItemsAndAppend(products, productsWithSales, 'product'), formSales);

    updateJson(
      'type',
      'productsWithDomestic',
      getItemsAndAppend(products, productsWithDomestic, 'product'),
      formSales
    );

    updateJson('type', 'rawMaterials', getItems(rawMaterials, 'rawMaterial'), formSales);

    updateJson(
      'type',
      'rawMaterialsRate',
      getItemsAndAppend(rawMaterials, rawMaterialsRate, 'rawMaterial'),
      formSales
    );

    const rawMaterialWithUnit = getItemsAndAppend(rawMaterials, rawMaterialsCost, 'rawMaterial');
    rawMaterialWithUnit.push({ lessInterUnitTrnsfrChromOre: lessInterUnitTrnsfrChromOre });

    updateJson('type', 'rawMaterialsCost', rawMaterialWithUnit, formSales);

    // Total Rows //
    allRows = getRowTemplate('label', formSales);

    formDataCells = getForm(
      formCells,
      FormType[FormType.FORMSALES].toLowerCase(),
      group,
      allRows,
      dbJson,
      loanDetails
    );
  }

  // if (group === FormGroup.PROJECTION) {
  //   Object.entries(formJson).map(([, cell]) => cell?.expr && cellUpdate(copyCells, cell, cell?.expr));
  // }
  return formDataCells;
};

export const FormSales = ({ group }: FormGroupI) => {
  const cells = useSelector(
    (state: RootState) => state?.inputscreen?.cells![FormType[FormType.FORMSALES].toLowerCase()]
  );

  if (!group || !cells) return <></>;

  const cellData: any = {};

  const actualsCells = Object.fromEntries(
    Object.entries(cells).filter(([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase())
  );
  _.merge(cellData, actualsCells);

  if (group === FormGroup.PROJECTION) {
    const projectionCells = Object.fromEntries(
      Object.entries(cells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()
      )
    );
    _.merge(cellData, projectionCells);
  }

  return <InputForm cells={cellData} />;
};
