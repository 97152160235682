import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const approveReject = async (data: any) => {
  const response = await httpService.post('/workflow/creditAdminApproval', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const creditTeam = async () => {
  const response = await httpService.get('/workflow/creditAdminTeamDetails', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const assignUser = async (loanReqId: any, userId: string) => {
  const response = await httpService.get(`/workflow/assignCreditAdminTask/${loanReqId}/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const approveAfterVerification = async (data: any) => {
  const response = await httpService.post('/workflow/creditAdminVerification', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const generateSanctionLetter = async (data: any) => {
  const response = await httpService.post('/loanProposal/generateSanctionLetter', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const getSanctionDetail = async (loanReqId: any) => {
  const response = await httpService.get(`/loanProposal/getSanctionDetail/${loanReqId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};
