import React, { useMemo } from 'react';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

interface IDataTableProps {
  ref?: any;
  columnDefs: (ColDef | ColGroupDef)[];
  rowData: any;
  overrideDefaultColDef?: ColDef;
  onRowValueChanged?: any;
  gridOptions?: GridOptions;
  groupIncludeTotalFooter?: boolean;
  autoSize?: boolean;
  height?: any;
  rowHeight?: any;
  pagination?: any;
  domLayout?: any;
  sizeToFit?: boolean;
  style?: any;
  pinnedBottomRowData?: any;
  onCellEditingStopped?: any;
  enableCellChangeFlash?: any;
  getRowNodeId?: any;
  editType?: string;
  onGridReady?: any;
  onCellValueChanged?: any;
  suppressColumnVirtualisation?: any;
  paginationPageSize?: any;
  onRowEditingStopped?: any;
  getRowStyle?: any;
}

const ReactDataTable = React.forwardRef(
  (
    {
      columnDefs,
      rowData,
      overrideDefaultColDef,
      onRowValueChanged,
      gridOptions,
      groupIncludeTotalFooter,
      autoSize,
      height,
      rowHeight = 36,
      pagination = true,
      domLayout,
      sizeToFit,
      style = { height: 'calc(100% - 25px)' },
      pinnedBottomRowData,
      onCellEditingStopped,
      enableCellChangeFlash = false,
      getRowNodeId,
      editType = 'fullRow',
      onGridReady = (event: any) => {
        autoSize && event.columnApi.autoSizeAllColumns();
        sizeToFit && event.api.sizeColumnsToFit();
      },
      onCellValueChanged,
      suppressColumnVirtualisation = false,
      paginationPageSize = 10,
      onRowEditingStopped,
      getRowStyle,
    }: IDataTableProps,
    ref: any
  ) => {
    const defaultColDef: ColDef = useMemo(
      () => ({
        resizable: true,
        sortable: true,
        filter: true,
        minWidth: 100,
        flex: 1,
        singleClickEdit: true,
      }),
      []
    );
    return (
      <div style={style} className="ag-theme-alpine">
        <div
          style={{
            height: '100%',
            width: '100%',
          }}>
          <AgGridReact
            ref={ref}
            columnDefs={columnDefs}
            rowData={rowData}
            animateRows={true}
            reactUi={true}
            onGridReady={onGridReady}
            enableRangeSelection={true}
            domLayout={domLayout || 'domLayout'}
            defaultColDef={overrideDefaultColDef || defaultColDef}
            paginationPageSize={paginationPageSize}
            pagination={pagination}
            editType={editType}
            gridOptions={gridOptions}
            groupIncludeTotalFooter={groupIncludeTotalFooter}
            onRowValueChanged={onRowValueChanged}
            stopEditingWhenCellsLoseFocus={true}
            pinnedBottomRowData={pinnedBottomRowData}
            onCellEditingStopped={onCellEditingStopped}
            enableCellChangeFlash={enableCellChangeFlash}
            getRowNodeId={getRowNodeId}
            onCellValueChanged={onCellValueChanged}
            suppressColumnVirtualisation={suppressColumnVirtualisation}
            onRowEditingStopped={onRowEditingStopped}
            getRowStyle={getRowStyle}
          />
        </div>
      </div>
    );
  }
);

export default ReactDataTable;
