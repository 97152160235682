import { FC, useMemo, useCallback } from 'react';
import { Grid, Box } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';

const OtherInformations: FC<any> = ({ control, getValues, setValue, watchAllFields }) => {
  const { t } = useTranslation();
  const { isViewOnly } = useSelector((state: RootState) => state.registerCompany);
  const theme = useTheme();

  const bankDetailsColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'bankName',
        headerName: 'Bank Name',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'accountNumber',
        headerName: 'Account Number',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'ifscCode',
        headerName: 'IFSC Code',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'branchAddress',
        headerName: 'Branch Address',
        editable: isViewOnly ? false : true,
        width: 150,
      },
    ];

    if (!isViewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('bankDetails').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('bankDetails'), {}];
                      setValue('bankDetails', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('bankDetails').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('bankDetails')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('bankDetails', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const organizationColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'state',
        headerName: 'State',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'city',
        headerName: 'City',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'zipCode',
        headerName: 'Zip Code',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'country',
        headerName: 'Country',
        editable: isViewOnly ? false : true,
        width: 150,
      },
    ];
    if (!isViewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('orgAddress').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('orgAddress'), {}];
                      setValue('orgAddress', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('orgAddress').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('orgAddress')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('orgAddress', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const contactColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'contactPerson',
        headerName: 'Contact Person',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'officePhone',
        headerName: 'Office Phone',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'mobilePhone',
        headerName: 'Mobile Phone',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'officeEmail',
        headerName: 'Office Email',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'officeAddress',
        headerName: 'Office Address',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'designation',
        headerName: 'Designation',
        editable: isViewOnly ? false : true,
        width: 150,
      },
    ];
    if (!isViewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('orgContact').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('orgContact'), {}];
                      setValue('orgContact', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('orgContact').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('orgContact')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('orgContact', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onBankDetailsCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('bankDetails', updatedRowData);
  }, []);

  const onOrgCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('orgAddress', updatedRowData);
  }, []);

  const onContactCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('orgContact', updatedRowData);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12}>
        <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <FormTitle title={t('companyMaster.otherInfo.bankDetails')} />
          <Box sx={{ mt: 1 }}>
            <DataTable
              columnDefs={bankDetailsColDefs}
              autoSize={true}
              rowData={watchAllFields?.bankDetails}
              pagination={false}
              domLayout={'autoHeight'}
              sizeToFit={true}
              style={{ height: '100%' }}
              onCellValueChanged={onBankDetailsCellValueChanged}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <FormTitle title="Organisation Address" />
          <Box sx={{ mt: 1 }}>
            <DataTable
              columnDefs={organizationColDefs}
              autoSize={true}
              rowData={watchAllFields?.orgAddress}
              pagination={false}
              domLayout={'autoHeight'}
              sizeToFit={true}
              style={{ height: '100%' }}
              onCellValueChanged={onOrgCellValueChanged}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Box sx={{ pl: 0.5, pr: 0.5 }}>
          <FormTitle title="Contact Details" />
          <Box sx={{ mt: 1 }}>
            <DataTable
              columnDefs={contactColDefs}
              autoSize={true}
              rowData={watchAllFields?.orgContact}
              pagination={false}
              domLayout={'autoHeight'}
              sizeToFit={true}
              style={{ height: '100%' }}
              onCellValueChanged={onContactCellValueChanged}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default OtherInformations;
