import { FC } from 'react';
import { InputBaseComponentProps, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';

const inputStyle = {
  '& .MuiInputBase-input': {
    padding: '7px 11px',
    width: '12rem',
    fontSize: '12px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
  },
};

interface IInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  control: Control<any>;
  type?: 'number' | 'text';
  style?: any;
  inputProps?: InputBaseComponentProps;
  multiLineInput?: boolean;
  maxRows?: number;
  fullWidth?: boolean;
  rows?: number;
  disabled?: boolean;
  rules?: any;
}

const Input: FC<IInputProps> = ({
  label,
  placeholder,
  name,
  control,
  type,
  style,
  inputProps,
  multiLineInput = false,
  maxRows,
  fullWidth = false,
  rows = 2,
  disabled,
  rules,
  ...input
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          value={field.value}
          type={type}
          fullWidth={fullWidth}
          multiline={multiLineInput}
          maxRows={maxRows}
          rows={rows}
          disabled={disabled}
          FormHelperTextProps={{
            margin: 'dense',
            sx: { textAlign: 'right' },
          }}
          // helperText={multiLineInput && `${field?.value?.length | 0}/${inputProps?.maxLength}`}
          placeholder={placeholder || label}
          sx={!multiLineInput ? { ...inputStyle, ...style, ...input } : { ...style, ...input }}
          inputProps={{ ...inputProps }}
          variant="outlined"
          onChange={(e: any) => field.onChange(e)}
          InputProps={
            multiLineInput
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ position: 'absolute', bottom: '0%', right: '1%' }}>
                        {field?.value?.length | 0} / {inputProps?.maxLength}
                      </div>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      )}
    />
  );
};

export default Input;
