import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITabSchema } from 'models/common';
import { RootState } from 'state/reducers';
import { changeMenuTab } from 'state/reducers/tabReducer';
import useLoanTable from 'hooks/useLoanTable';

function a11yProps(id: any) {
  return {
    itemNumber: id,
    'aria-controls': `simple-tabpanel-${id}`,
  };
}

let MENU_SCHEMA: any = [];

const CreditMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const theme = useTheme();
  const { selectedIndex }: any = useSelector((state: RootState) => state.mainMenu);
  const { getRequestStatusCode } = useLoanTable();

  useEffect(() => {
    if (state?.statusCode === getRequestStatusCode('PENDING_WITH_CREDIT_ADMIN')) {
      MENU_SCHEMA = [
        {
          id: 1,
          name: 'Sanction Letter',
          pathName: 'sanction-letter',
          value: '/dashboard/credit-admin/sanction-letter',
        },
      ];
    } else if (state?.statusCode === getRequestStatusCode('PENDING_WITH_CREDIT_ADMIN_VERIFICATION')) {
      MENU_SCHEMA = [
        {
          id: 2,
          name: 'Legal Review Document',
          pathName: 'document-submission',
          value: '/dashboard/credit-admin/document-submission',
        },
      ];
    }

    for (let i = 0; i < MENU_SCHEMA.length; i++) {
      if (pathname === MENU_SCHEMA[i].value) {
        dispatch(changeMenuTab(MENU_SCHEMA[i].id));
        break;
      }
    }
  }, [pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const route = MENU_SCHEMA?.find((item: any) => item?.id === newValue);
    navigate(route?.value || '', {
      state: { ...state },
    });
    dispatch(changeMenuTab(newValue));
  };
  const _renderTabs = () => {
    return _.map(MENU_SCHEMA, (item: ITabSchema) => {
      return <Tab color="#ffffff" label={item.name} value={item.id} {...a11yProps(item.id)} key={item.id} />;
    });
  };

  return (
    <Box>
      <Tabs value={selectedIndex} onChange={handleChange}>
        {_renderTabs()}
      </Tabs>
    </Box>
  );
};

export default CreditMenu;
