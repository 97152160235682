import { Box, Divider, Grid } from '@mui/material';
// import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Control } from 'react-hook-form';
import _ from 'lodash';
import { RootState } from 'state/reducers';
// import useRefData from 'hooks/ref-data';
import { getFormFields } from 'utils/form-utils';
import { useLocation } from 'react-router-dom';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { FormTitle } from 'components/common/form-components/FormTitle';

const companyDetails = [
  { label: 'Company Name', name: 'companyName', disabled: true },
  { label: 'Group', name: 'grpName', disabled: true },
  { label: 'Established Year', name: 'establishedYear', disabled: true },
  { label: 'Industry', name: 'industryType', disabled: true },
  { label: 'Sub-Industry', name: 'industry', disabled: true },
  { label: 'Relationship with Bank (Since)', name: 'relationshipWithBankSince', disabled: false },
  { label: 'Client Classification for Derivatives', name: 'clientClassificationForDerivatives', disabled: false },
  { label: 'Priority Sector Advance', name: 'prioritySectorAdvance', disabled: false },
  { label: 'Other Compliance Items', name: 'otherComplianceItems', disabled: false },
  { label: 'Special Mention Acccount', name: 'specialMentionAccount', disabled: false },
  { label: 'Own Bank', name: 'ownBank', disabled: false },
  { label: 'Other Banks', name: 'otherBank', disabled: false },
];

const CompanyDetailsTable = ({ control }: { control: Control<any> }) => {
  const { companyId } = useSelector((state: RootState) => state.loanProposal);
  const { state } = useLocation();

  const _renderCompanyDetailInputs = () => {
    return _.map(companyDetails, ({ label, name, disabled }, idx: number) => {
      if (name === 'specialMentionAccount') {
        return (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <SimpleFormLabel
                label={label}
                sx={{
                  width: '20%',
                  position: 'relative',
                }}
                labelStyle={{
                  display: 'inline',
                  fontWeight: 600,
                }}
              />
            </Box>
            <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
          </Grid>
        );
      }
      if (name === 'ownBank' || name === 'otherBank') {
        return (
          <Grid item xs={6}>
            <Box>
              <SimpleFormLabel
                label={label}
                sx={{
                  width: '40%',
                }}
              />
              {getFormFields(
                {
                  component: 'textField',
                  name: `companyAndGroupDetails.companyDetails[${name}]`,
                },
                control,
                !state?.isEdit || disabled ? true : false
              )}
            </Box>
          </Grid>
        );
      }

      if (name === 'establishedYear') {
        return (
          <Grid item xs={6}>
            <Box>
              <SimpleFormLabel label={label} sx={{ width: '40%' }} />
              {getFormFields(
                {
                  component: 'datepicker',
                  name: `companyAndGroupDetails.companyDetails[${name}]`,
                },
                control,
                !state?.isEdit || disabled ? true : false
              )}
            </Box>
          </Grid>
        );
      }
      return (
        <Grid item xs={6}>
          <Box>
            <SimpleFormLabel label={label} sx={{ width: '40%' }} />
            {getFormFields(
              {
                component: 'textField',
                name: `companyAndGroupDetails.companyDetails[${name}]`,
              },
              control,
              !state?.isEdit || disabled ? true : false
            )}
          </Box>
        </Grid>
      );
    });
  };

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle formNumbering="1.1" title="Company Details" />
      <Box>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
          {_renderCompanyDetailInputs()}
        </Grid>
      </Box>
    </Box>

    // <TableContainer sx={{ overflowX: 'hidden' }}>
    //   <Table size="small" sx={{ minWidth: 400, border: '0.5px solid #f2f2f2' }}>
    //     <TableHeading title="1.1 Company Details" />
    //     <TableBody>{_renderTableBody()}</TableBody>
    //   </Table>
    // </TableContainer>
  );
};

export default CompanyDetailsTable;
