import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IRadioValues, ISimpleRadioProps } from '../../../models/formComponents';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export default function SimpleRadio({
  name,
  defaultValue,
  control,
  values,
  disabled,
  rules,
  ...input
}: ISimpleRadioProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => {
        const value = name === 'fiscalYearTypeId' ? `${field?.value?.id || field.value}` : `${field?.value}`;
        return (
          <RadioGroup
            aria-label="radio"
            value={value}
            sx={{ display: 'flex', flexDirection: 'row', ...input }}
            onChange={field.onChange}
            name={name}>
            {_.map(values, ({ label, value }: IRadioValues, idx: number) => (
              <FormControlLabel
                sx={{ height: '34px' }}
                value={value}
                control={<Radio size="small" />}
                label={label}
                key={idx}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        );
      }}
    />
  );
}
