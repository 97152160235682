import { Box } from '@mui/material';
import _ from 'lodash';
import { Control } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import { useMemo, useCallback } from 'react';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const RatingSummaryTable = ({
  control,
  watchAllFields,
  setValue,
}: {
  control: Control;
  watchAllFields: any;
  setValue: any;
}) => {
  const { state } = useLocation();

  const ratingSummaryColDefs = useMemo(
    () => [
      {
        field: 'ratingSummary',
        headerName: 'Externally Rating Summary',
        editable: false,
        pinned: 'left',
        minWidth: 270,
        suppressSizeToFit: true,
        cellStyle: (params: any) => {
          if (params.value === 'Fund-Based Limits' || params.value === 'Non-Fund Limits') {
            return { paddingLeft: '30px' };
          }
          return null;
        },
      },
      { field: 'type', headerName: 'Type', editable: state?.isEdit, width: 150 },
      {
        headerName: 'Borrower',
        children: [
          {
            field: 'borrower_amt_rated',
            headerName: 'Amount Rated',
            editable: state?.isEdit,
            width: 150,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          { field: 'borrower_prev_year', headerName: 'Previous Rating', editable: state?.isEdit, width: 150 },
          { field: 'borrower_curr_year', headerName: 'Current Rating', editable: state?.isEdit, width: 150 },
          {
            field: 'borrower_rating_outlook',
            headerName: 'Rating Outlook',
            editable: state?.isEdit,
            width: 150,
          },
        ],
      },
      {
        headerName: 'Guarantor',
        children: [
          {
            field: 'guarantor_amt_rated',
            headerName: 'Amount Rated',
            editable: state?.isEdit,
            width: 150,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          { field: 'guarantor_prev_year', headerName: 'Previous Rating', editable: state?.isEdit, width: 150 },
          { field: 'guarantor_curr_year', headerName: 'Current Rating', editable: state?.isEdit, width: 150 },
          {
            field: 'guarantor_rating_outlook',
            headerName: 'Rating Outlook',
            editable: state?.isEdit,
            width: 150,
          },
        ],
      },
    ],
    []
  );

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('companyAndGroupDetails.externalRatingSummary', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle formNumbering="1.2" title="Externally Rating Summary"></FormTitle>
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={ratingSummaryColDefs}
          autoSize={true}
          rowData={watchAllFields?.companyAndGroupDetails?.externalRatingSummary}
          pagination={false}
          domLayout={'autoHeight'}
          style={{ height: '100%' }}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
  );
};

export default RatingSummaryTable;
