import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { MainCard } from 'components/layout/styles';
import { getPerformanceSummary, updatePerformanceSummary } from 'services/loan-projection-services';
import DenseCell from 'components/common/table/dense-cell';
import { RootState } from 'state/reducers';
import fontSize from 'constants/fonts';
import { IPerformanceSummary } from 'models/loan-projection/performance-summary';
import { toggleLoader } from 'state/reducers/common-actions';
import FuturePlans from './future-plans';
import BankingArrangementAndAssesmentLimits from './proposal-and-assesment-limits/banking-arrangement-assesment-limits';
import ProfitabilityAnalysis from './profitability-analysis';
import ActivityEfficiency from './activity-efficiency';
import LiquidityRatioAndCapitalStructure from './liquidity-ratio';
import AnalysisOfOffBalanceSheet from './analysis-of-balance-sheet';
import LeverageDetails from './leverage-detail';
import AssesmentOfLimits from './assesment-of-limits';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import ActionBar from 'components/styled-components/ActionBar';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import SimpleAccordion from 'components/common/simple-accordian';
import SummaryOfProjection from '../projection-summary/summary-of-projection';
import { ProfitabilityAssumptionsGuarantor } from '../projection-summary/profitability-assumption';
import { filterGridEmptyData } from 'utils/common-utils';

export const StyledDenseCell = styled(DenseCell)(() => ({
  fontSize: fontSize?.tableCellFontSm,
}));

export interface IPerformanceSummaryControl {
  capitalStructureComment: string;
  debtDetailComment: string;
  debtServiceabilityComment: string;
  ebitdaAnalysisComment: string;
  foreignCurrencyExposureComment: string;
  keyNumbersComment: string;
  liquidityRatioComment: string;
  workingCapitalManagementComments: string;
  turnoverRatioComments: string;
  accReceivablesComments: string;
  analysisOfBalance: any;
}

const PerformanceSummary = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tabs, selectedTab } = useSelector((state: RootState) => state?.projections);
  const { status }: any = useSelector((state: RootState) => state?.loanProposal);
  const { enqueueSnackbar } = useSnackbar();
  const { getRequestStatusCode } = useLoanTable();

  const { control, reset, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: {
      profatabilityAnalysis: {
        comments: '',
      },
      futurePlans: '',
      leverageDetails: {
        comments: '',
      },
      assessmentLimits: {
        commentsNonFundBased: '',
        commentsFundBased: '',
        commentsDeriviativeAssessment: '',
        lcLimit: '',
        bgLimit: '',
        pcfcLimit: '',
        sanctionsDetails: {
          margining: '',
          breakClause: '',
        },
        forexClassification: {
          tenor: '',
          endUse: '',
          limits: '',
          validityOfLimits: '',
        },
      },
      activityEfficiency: {
        comments: '',
      },
      analysisOffBalanceSheet: {
        comments: '',
        liabilityItems: [{}],
      },
      liquidityCapitalStructure: {
        comments: '',
      },
      populateDomesticExportRealisation: {},
      workingCapital: [{}],
      termLoansBanking: [{}],
      sourceFunds: [{}],
      useOfFunds: [{}],
      keyAssumptionsGuarantor: '',
      detailsOfProject: '',
      isDerivativeAssessment: 'false',
      isProjectReturns: 'false',
      projectPurpose: '',
      projectCost: '',
    },
  });

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  const projectionId = tabs[selectedTab].id;

  const watchAllFields = watch();

  const { data, isLoading, isError, refetch } = useQuery(
    'getPerformanceSummary',
    () => getPerformanceSummary(state?.loanRequestId, projectionId),
    {
      onSuccess: (data: any) => {
        if (data?.performanceSummaryInputData) {
          const copyData: any = { ...data.performanceSummaryInputData };
          copyData.analysisOffBalanceSheet.liabilityItems =
            copyData?.analysisOffBalanceSheet?.liabilityItems.length > 0
              ? copyData?.analysisOffBalanceSheet?.liabilityItems
              : [{}];
          copyData.workingCapital = copyData?.workingCapital.length > 0 ? copyData?.workingCapital : [{}];
          copyData.termLoansBanking = copyData?.termLoansBanking.length > 0 ? copyData?.termLoansBanking : [{}];
          copyData.sourceFunds = copyData?.sourceFunds.length > 0 ? copyData?.sourceFunds : [{}];
          copyData.useOfFunds = copyData?.useOfFunds.length > 0 ? copyData?.useOfFunds : [{}];
          reset({ ...copyData });
        }
      },
    }
  );

  const mutation = useMutation((data: any) => updatePerformanceSummary(state?.loanRequestId, projectionId, data), {
    onSuccess: (data) => {
      enqueueSnackbar(`Performance Summary Updated`, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error while saving Performance Summary', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(toggleLoader(false));
    },
  });

  const years: string[] = _.map(data?.guarantorProjectionSummary, (el: any) => el.year);

  const filteredYears = _.filter(years, (year: string) => Boolean(year));

  const summaryOfProjectionGuarantorData = data?.guarantorProjectionSummary
    ? _.map(data.guarantorProjectionSummary, (projectionSummary) => {
        if (_.has(projectionSummary, 'actuals')) {
          return projectionSummary.actuals.summaryOfProjection;
        }
        return projectionSummary.projection.summaryOfProjection;
      })
    : [];

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  if (!isLoading && !isError) dispatch(toggleLoader(false));

  const performanceSummaryData: IPerformanceSummary = data;
  const {
    keyNumbers,
    ebitdaAnalysis,
    activityEfficiency,
    liquidityCapitalStructure,
    leverageDetails,
    assessmentLimits,
    npvIrr,
  } = performanceSummaryData?.performanceSummary || {
    keyNumbers: {},
    ebitdaAnalysis: {},
    activityEfficiency: {},
    liquidityCapitalStructure: {},
    leverageDetails: {},
    assessmentLimits: {},
    npvIrr: {},
  };

  const totalYears = _.map(keyNumbers?.array, (key: any) => key?.year);

  const totalYearsAssessment = _.map(assessmentLimits?.bgAssessment?.array, (key: any) => key?.year);

  const _onUpdatePerformanceSummary = (data: any) => {
    console.log('_onUpdatePerformanceSummary', data);
    const filteredData = filterGridEmptyData(data, [
      'workingCapital',
      'termLoansBanking',
      'sourceFunds',
      'useOfFunds',
    ]);

    filteredData.analysisOffBalanceSheet = filterGridEmptyData(filteredData?.analysisOffBalanceSheet, [
      'liabilityItems',
    ]);

    mutation?.mutate(
      { performanceSummaryInputData: data },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const basicProps = {
    t,
    control,
    totalYears,
    getValues,
    setValue,
    watchAllFields,
  };

  return (
    <form onSubmit={handleSubmit(_onUpdatePerformanceSummary)}>
      {(state?.statusCode === getRequestStatusCode('DRAFT') ||
        state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
        state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
        !state?.statusCode) && (
        <ActionBar>
          <Button type="submit" variant="contained" sx={{ mr: 2, p: 1 }}>
            Save Draft
          </Button>
          <CamButton
            sx={{ p: 1, mr: 2 }}
            onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
            camState={state}
          />
        </ActionBar>
      )}
      <MainCard>
        <Grid container>
          <Grid item lg={12}>
            <SimpleAccordion
              title={'4.1 Financial Performance: Applicant'}
              expand={true}
              children={
                <Grid container spacing={2}>
                  <Grid item md={12} lg={12}>
                    <ProfitabilityAnalysis
                      {...basicProps}
                      ebidtaData={ebitdaAnalysis?.array}
                      keyNumberData={keyNumbers?.array}
                    />
                  </Grid>

                  <Grid item md={12} lg={12}>
                    <ActivityEfficiency
                      {...basicProps}
                      workingCapitalData={activityEfficiency?.workingCapitalManagement?.array}
                      turnoverData={activityEfficiency?.turnoverRatio?.array}
                      accReceivableData={activityEfficiency?.accountReceivablesQuality?.array}
                    />
                  </Grid>

                  <Grid item md={12} lg={12}>
                    <LiquidityRatioAndCapitalStructure
                      {...basicProps}
                      liquidityData={liquidityCapitalStructure?.liquidityRatio?.array}
                      capitalStructureData={liquidityCapitalStructure?.capitalStructure?.array}
                    />
                  </Grid>

                  <Grid item md={12} lg={12}>
                    <LeverageDetails
                      {...basicProps}
                      debtDetailsData={leverageDetails?.debtDetails?.array}
                      debtServiceabilityData={leverageDetails?.debtServiceability?.array}
                      foreignCurrencyExposureData={leverageDetails?.foreignCurrencyExposure?.array}
                    />
                  </Grid>

                  <Grid item md={12} lg={12}>
                    <AnalysisOfOffBalanceSheet {...basicProps} />
                  </Grid>

                  {/* <Grid item md={12} lg={12}>
            <AuditorComments {...basicProps} />
          </Grid> */}

                  <Grid item md={12} lg={12}>
                    <FuturePlans {...basicProps} />
                  </Grid>

                  {/* <Grid item md={12} lg={12}>
            <ProposalAndAssesmentLimits {...basicProps} />
          </Grid> */}

                  {/* <Grid item md={12} lg={12}>
                    <Box sx={{ pl: 0.5, pr: 0.5 }}>
                      <FormTitle title={t('loanProjection.performanceSummary.bankingArrangementAndAssesment')} />
                      <Box sx={{ mt: 0.5 }}></Box>
                    </Box>
                  </Grid>

                  <Grid item md={12} lg={12}></Grid> */}
                </Grid>
              }
            />
          </Grid>
          {data?.guarantorProjectionSummary && (
            <Grid item lg={12}>
              <SimpleAccordion
                title={'4.2 Financial Performance: Guarantor'}
                children={
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <ProfitabilityAssumptionsGuarantor
                        title="4.2.a Commentary on Financial Performance"
                        {...basicProps}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <SummaryOfProjection
                        totalYears={filteredYears}
                        data={summaryOfProjectionGuarantorData}
                        yearEnded="March 31"
                        title="4.2.b Summary Performance"
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          )}
          <Grid item lg={12}>
            <SimpleAccordion
              title={t('loanProjection.performanceSummary.bankingArrangementAndAssesment')}
              children={
                <Grid container spacing={2}>
                  <Grid item md={12} lg={12}>
                    <BankingArrangementAndAssesmentLimits {...basicProps} />
                    <AssesmentOfLimits
                      {...basicProps}
                      totalYears={totalYearsAssessment}
                      workingCapitalData={assessmentLimits?.workingCapitalLimits?.assessedBankFinance?.array}
                      netWorkingCapitalAnalysisData={assessmentLimits?.netWorkingCapital?.array}
                      pcfcLimitData={assessmentLimits?.pcfcAssessment?.array}
                      lcAssessmentData={assessmentLimits?.lcAssessment?.array}
                      bgAssessmentData={assessmentLimits?.bgAssessment?.array}
                      npvIrr={npvIrr}
                      updateBusinessBgData={_onUpdatePerformanceSummary}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </MainCard>
    </form>
  );
};

export default PerformanceSummary;
