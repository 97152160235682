import { Box, Stack, Typography, Switch } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect, useCallback } from 'react';
import DataTable from 'components/common/data-table';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { useLocation } from 'react-router-dom';
import { Controller } from 'react-hook-form';

const PrevRestructing = ({ getValues, setValue, watchAllFields, control }: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const prevRestructingColDef = useMemo(() => {
    var colDef: any = [
      {
        field: 'particulars',
        headerName: 'Particulars',
        sortable: false,
        suppressMenu: true,
      },
      {
        field: 'yesNo',
        headerName: 'Yes/No',
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: (params: any) => {
          const name = `routingThroughUs[${params.rowIndex}].yesNo`;
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      params.api.stopEditing();
                      field.onChange(e.target.checked);
                      setTimeout(() => {
                        let fields = [...getValues('routingThroughUs')];
                        setValue('routingThroughUs', fields);
                        params.api.setRowData(fields);
                      }, 100);
                    }}
                    disabled={!state?.isEdit}
                  />
                )}
              />
              <Typography>Yes</Typography>
            </Stack>
          );
        },
        width: 10,
      },
      {
        field: 'details',
        headerName: 'If yes, please provide details',
        sortable: false,
        suppressMenu: true,
        editable: state?.isEdit,
      },
    ];
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('routingThroughUs', updatedRowData);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <FormTitle title={'Restructuring, If any'} />
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={prevRestructingColDef}
          autoSize={true}
          rowData={watchAllFields?.routingThroughUs}
          domLayout={'autoHeight'}
          sizeToFit={true}
          pagination={false}
          onCellValueChanged={onCellValueChanged}
          editType="single"
        />
      </Box>
    </Box>
  );
};

export default PrevRestructing;
