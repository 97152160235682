import { Box, Button } from '@mui/material';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { getFormFields } from 'utils/form-utils';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import AddIcon from '@mui/icons-material/Add';
import { useMemo } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import { useLocation } from 'react-router-dom';
import useLoanTable from 'hooks/useLoanTable';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { useCallback } from 'react';

const PeerAnalysis = ({ control, getValues, setValue, watchAllFields, viewMode }: any) => {
  const { state } = useLocation();
  const { getRequestStatusCode } = useLoanTable();
  const theme = useTheme();

  const colDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'companyName',
        headerName: 'Company Name',
        editable: !viewMode,
      },
      {
        headerName: 'Particulars',
        children: [
          {
            field: 'operatingIncome',
            headerName: 'Total Operating Income',
            editable: !viewMode,
            width: 150,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'EBIDTA',
            headerName: 'EBIDTA%',
            editable: !viewMode,
            width: 150,
          },
          {
            field: 'PAT',
            headerName: 'PAT%',
            editable: !viewMode,
            width: 150,
          },
          {
            field: 'totalDebtEBIDTA',
            headerName: 'Total Debt/EBIDTA',
            editable: !viewMode,
            width: 150,
          },
          {
            field: 'totalDebtNCA',
            headerName: 'Total Debt/NCA',
            editable: !viewMode,
            width: 150,
          },
          ,
        ],
      },
    ];
    if (!viewMode) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('peerAnalysis').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('peerAnalysis'), {}];
                      setValue('peerAnalysis', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('peerAnalysis').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('peerAnalysis')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('peerAnalysis', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('peerAnalysis', updatedRowData);
  }, []);

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          title="PortfolioReport"
          width="1024"
          height="612"
          src="https://app.powerbi.com/view?r=eyJrIjoiMWFhNWQ3NWUtNjZkZC00MTQyLWIwMDgtNzg3ZDlkMjQ5YmYxIiwidCI6IjRiNTRiOTlkLTc4YjUtNDJiYi1hY2M1LWJmYjdkMGNkNzU4NiJ9"
          frameBorder="0"
          allowFullScreen={true}></iframe>
      </Box>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title="Peer Performance Analysis" />
        <Box>
          <Box sx={{ mt: 1 }}>
            <DataTable
              columnDefs={colDefs}
              autoSize={true}
              rowData={watchAllFields?.peerAnalysis || []}
              pagination={false}
              domLayout={'autoHeight'}
              sizeToFit={true}
              style={{ height: '100%' }}
              onCellValueChanged={onCellValueChanged}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 7 }}>
        <FormTitle title="Comments By Risk Team" />
        <Box sx={{ mt: 1 }}>
          {getFormFields(
            {
              name: 'commentByRiskTeam',
              component: MULTI_LINE_INPUT,
            },
            control,
            viewMode
          )}
        </Box>
      </Box>
    </>
  );
};

export default PeerAnalysis;
