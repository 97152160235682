import { useMemo, useCallback } from 'react';
import { Box, Divider } from '@mui/material';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import DataTable from 'components/common/data-table';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import { formatGridNumber } from 'utils/common-utils';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import SimpleFormLabel from 'components/common/form-components/form-label';

const ManagementDetails = ({
  watchAllFields,
  setValue,
  getValues,
}: {
  control: any;
  watchAllFields: any;
  setValue: any;
  getValues: any;
}) => {
  const { state } = useLocation();
  const theme = useTheme();

  const managementColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'name',
        headerName: 'Name',
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'position',
        headerName: 'Position',
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'shareholding',
        headerName: 'Shareholding (%)',
        editable: state?.isEdit,
        width: 150,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'experienceOutsideCompany',
        headerName: 'Experience Outside Company',
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'experienceWithCompany',
        headerName: 'Experience with Company',
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'remarks',
        headerName: 'Remarks',
        editable: state?.isEdit,
        width: 150,
      },
    ];
    if (state?.isEdit) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('companyAndGroupDetails.managementDetails').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('companyAndGroupDetails.managementDetails'), {}];
                      setValue('companyAndGroupDetails.managementDetails', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('companyAndGroupDetails.managementDetails').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('companyAndGroupDetails.managementDetails')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('companyAndGroupDetails.managementDetails', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const ShareholdingsColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'name',
        headerName: 'Shareholder Name',
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'numberOfShares',
        headerName: 'Number of Shares',
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        editable: state?.isEdit,
        width: 150,
      },
      {
        field: 'stake',
        headerName: '% Stake',
        editable: state?.isEdit,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
        width: 150,
      },
    ];
    if (state?.isEdit) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('companyAndGroupDetails.shareHoldings').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('companyAndGroupDetails.shareHoldings'), {}];
                      setValue('companyAndGroupDetails.shareHoldings', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}

              {getValues('companyAndGroupDetails.shareHoldings').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('companyAndGroupDetails.shareHoldings')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('companyAndGroupDetails.shareHoldings', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('companyAndGroupDetails.managementDetails', updatedRowData);
  }, []);

  const onCellValueShareHoldingsChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('companyAndGroupDetails.shareHoldings', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle formNumbering="1.4" title="Shareholding & Key Management Details" />
      <Box sx={{ marginLeft: '20px', marginRight: '20px' }}>
        <Box sx={{ display: 'flex' }}>
          <SimpleFormLabel
            label="1.4a Key Management"
            sx={{
              width: '20%',
              position: 'relative',
            }}
            labelStyle={{
              display: 'inline',
              fontWeight: 600,
            }}
          />
        </Box>
        <Divider />
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={managementColDefs}
            autoSize={true}
            rowData={watchAllFields?.companyAndGroupDetails?.managementDetails}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>

      <Box sx={{ marginLeft: '20px', marginRight: '20px' }}>
        <Box sx={{ display: 'flex' }}>
          <SimpleFormLabel
            label="1.4b Shareholding Details"
            sx={{
              width: '20%',
              position: 'relative',
            }}
            labelStyle={{
              display: 'inline',
              fontWeight: 600,
            }}
          />
        </Box>
        <Divider />
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={ShareholdingsColDefs}
            autoSize={true}
            rowData={watchAllFields?.companyAndGroupDetails?.shareHoldings}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueShareHoldingsChanged}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementDetails;
