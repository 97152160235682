import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const getLoanProposalData = async (loanRequestId: string, screenName: string) => {
  try {
    const res = await httpService.get(`/loanProposal/${screenName}/${loanRequestId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log(`getLoanProposalData: ${screenName}-${loanRequestId}: ERROR`, error);
  }
};
export const postLoanProposalData = async (loanRequestId: string, screenName: string, data: any) => {
  try {
    const res = await httpService.post(`/loanProposal/${screenName}/${loanRequestId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log(`getLoanProposalData: ${screenName}-${loanRequestId}: ERROR`, error);
  }
};

export const getCompliance = async (loanId: string | number) => {
  try {
    const res = await httpService.get(`/loanProposal/compliance/${loanId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {}
};

export const updateCompliance = async (loanId: string | number, data: any) => {
  try {
    const res = await httpService.post(`/loanProposal/compliance/${loanId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res;
  } catch (error) {}
};

export const getProposalNTc = async (loanId: string | number) => {
  try {
    const res = await httpService.get(`/loanProposal/proposalNTC/${loanId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res.data;
  } catch (error) {
    console.log(error, 'getProposalNTc');
  }
};

export const updateProposalNtc = async (loanId: string | number, data: any) => {
  try {
    const res = await httpService.post(`/loanProposal/proposalNTC/${loanId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return res;
  } catch (error) {
    console.log(error, 'updateProposalNtc');
  }
};

export const triggerCAM = async (loanReqId: number) => {
  try {
    const response = await httpService.get(`/loanProposal/triggerCam/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('triggerCAM: ERROR', error);
  }
};

export const rejectLoanProposal = async (loanReqId: number) => {
  try {
    const response = await httpService.get(`/workflow/rejectLoanProposal/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('rejectLoanProposal: ERROR', error);
  }
};
