import { Alert, TextareaAutosize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Typography from '@mui/material/Typography';

const DialogWithComment = ({ open, setOpen, title, description, type, onClickHandler }: any) => {
  const [comment, setComment] = useState('');
  const handleClose = () => {
    setOpen(false);
    setComment('');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs">
      <Alert severity={type}>
        <Typography variant="subtitle1">{title}</Typography>
      </Alert>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2">{description}</Typography>
          <br />
          <TextareaAutosize
            aria-label="comment"
            minRows={3}
            placeholder=" Comment"
            style={{ width: '90%', float: 'left' }}
            value={comment}
            required={true}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={() => onClickHandler(comment)} disabled={!comment} autoFocus>
          <Typography>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogWithComment;
