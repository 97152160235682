import { formatGridNumber } from 'utils/common-utils';

const LOAN_STATUS = {
  DRAFT: 'Draft',
  PENDING_WITH_RM: 'Pending With RM',
  PROPOSAL_PENDING: 'Proposal Pending',
  PENDING_FOR_RISK_APPROVAL: 'Risk Team',
  PENDING_FOR_BUS_SUP_APPROVAL: 'Business Supervisor',
  PENDING_FOR_RISK_ASSIGNMENT: 'Risk Assignment',
  PENDING_WITH_APPROVAL_AUTHORITY: 'Approval Authority',
  PENDING_WITH_CREDIT_ADMIN: 'Credit Admin',
  PENDING_WITH_CREDIT_ADMIN_TL: 'Credit Team Assignment',
  PENDING_WITH_LEGAL_DOC_PREP: 'Legal Team Document Preparation',
  PENDING_WITH_RM_DOC_PREP: 'RM Document Preparation',
  PENDING_WITH_LEGAL_DOC_UPLOAD: 'Legal Team Document Upload',
  PENDING_WITH_CREDIT_ADMIN_VERIFICATION: 'Credit Admin Document Verification',
  PENDING_WITH_REVIEWER: 'Pending with Support Group',
  COMPLETED: 'Completed',
  REWORK_WITH_RM: 'Rework with RM',
  PENDING_APPROVAL: 'Pending Approval',
  CLOSED: 'Closed',
  REJECT: 'Reject',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
  REJECTED: 'Rejected',
};

const MEMO_TYPE = {
  CREDIT_ADMIN: 'Credit Admin',
  LEGAL_ADMIN: 'Legal Admin',
};

const LOAN_ACCESS = {
  EDIT: 'EDIT',
  READ_ONLY: 'READ_ONLY',
  CREATE_REQUEST: 'CREATE_REQUEST',
  CLAIM: 'CLAIM',
  CLAIMED: 'CLAIMED',
  REJECT: 'REJECT',
  REWORK: 'REWORK',
  APPROVE: 'APPROVE',
};

const LOAN_ROLE = {
  BUSINESS: 'BUSINESS',
  RISK: 'RISK',
};

const LOAN_TABLE = [
  {
    field: 'loanRequestId',
    pinned: 'left',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
  },
  {
    field: 'loanRequestDate',
    headerName: 'Request Date',
  },
  {
    field: 'CIF',
    headerName: 'UID',
    hide: true,
  },
  {
    field: 'company',
  },
  {
    field: 'companyId',
    hide: true,
  },
  {
    field: 'zone',
    hide: true,
  },
  {
    field: 'loanType',
    hide: true,
  },
  {
    field: 'requestType',
  },
  {
    field: 'loanCurrency',
  },
  {
    field: 'amount',
    headerName: 'Amount (in MN)',
    valueFormatter: formatGridNumber,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: 'tenor',
    hide: true,
  },
  {
    field: 'status',
  },
  {
    field: 'statusCode',
    hide: true,
  },
  {
    field: 'flowableTaskId',
    hide: true,
  },
  {
    field: 'requestor',
  },
  {
    field: 'rmTeamLead',
    headerName: 'RM Team Lead',
  },
];

const LOAN_MEMO_TABLE = [
  {
    field: 'memoId',
    pinned: 'left',
    width: 200,
    minWidth: 200,
    maxWidth: 250,
    resizable: false,
  },
  {
    field: 'reviewType',
    headerName: 'Review Type',
  },
  {
    field: 'loanId',
  },
  {
    field: 'company',
  },
  {
    field: 'companyId',
    hide: true,
  },
  {
    field: 'loanCurrency',
  },
  {
    field: 'amount',
    valueFormatter: formatGridNumber,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: 'status',
    width: 200,
    minWidth: 200,
    maxWidth: 250,
    resizable: false,
  },
  {
    field: 'statusCode',
    hide: true,
  },
  {
    field: 'flowableTaskId',
    hide: true,
  },
  {
    field: 'slaTimestamp',
    headerName: 'SLA',
    width: 200,
    minWidth: 200,
    maxWidth: 250,
    resizable: false,
  },
];

const MEMO_TABLE = [
  {
    field: 'memoId',
    pinned: 'left',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
  },
  {
    field: 'typeOfMemo',
    headerName: 'Subtype',
  },
  {
    field: 'from',
    headerName: 'Raised By',
  },
  {
    field: 'subject',
    headerName: 'Subject',
  },
  {
    field: 'approverType',
    headerName: 'Assigned to',
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  {
    field: 'approvalStatus',
    headerName: 'Status',
    width: 150,
    minWidth: 150,
    maxWidth: 200,
  },
  {
    field: 'date',
    headerName: 'Memo Date',
    width: 200,
    minWidth: 200,
    maxWidth: 250,
    resizable: false,
  },
  {
    field: 'memoDocId',
    hide: true,
  },
];

export { LOAN_STATUS, LOAN_ACCESS, LOAN_TABLE, LOAN_ROLE, MEMO_TABLE, LOAN_MEMO_TABLE, MEMO_TYPE };
