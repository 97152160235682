import { Box, Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BreadCrumbs } from 'components/common/pages/BreadCrumbs';
import ProjectionLayout from '../../layout/projection-layout';
import LegalMenu from './LegalMenu';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';

const LegalLayout = (props: any) => {
  const { getRequestStatusCode, getHomePage } = useLoanTable();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  return (
    <ProjectionLayout>
      <Box>
        <BreadCrumbs
          title={t('screenHeaders.legalAdmin.heading')}
          loanRequestId={state?.loanRequestId}
          company={state?.company}
          amount={state?.amount}
          route={routes.LEGAL_ADMIN_ROUTE}
        />
        <LegalMenu />
        <Divider />
        {props.children}
      </Box>
    </ProjectionLayout>
  );
};

export default LegalLayout;
