import { FC } from 'react';
import { MenuItem, FormControl, Select, Box, Chip } from '@mui/material';
import _ from 'lodash';
import { IDropdownProps } from '../../../models/formComponents';
import { Controller } from 'react-hook-form';
import fontSize from '../../../constants/fonts';

const MultiSelectDropdown: FC<IDropdownProps> = ({
  values,
  label,
  options,
  name,
  control,
  disabled,
  rules,
  ...input
}) => {
  const select = {
    '& .MuiSelect-select': {
      padding: '7px 11px',
      width: '10.6rem',
      fontSize: '11px',
      ...input,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl margin="dense" sx={{ padding: 0, margin: 0, ...input }} disabled={disabled}>
          <Select
            value={field?.value || []}
            defaultValue={[]}
            sx={select}
            multiple
            margin="dense"
            name={name}
            onChange={(e) => field.onChange(e)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip
                    key={value?.displayText}
                    label={options?.find((option: any) => option?.id == value?.id)?.displayText}
                    sx={{ fontSize: '11px' }}
                  />
                ))}
              </Box>
            )}>
            {_.map(options, (option: any, index) => {
              return (
                <MenuItem key={`${option}-${index}`} value={option}>
                  {option.displayText}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default MultiSelectDropdown;
