import { Alert, TextareaAutosize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import queryClient from 'services/react-query-service';
import { uploadDocument, saveDocumentMeta, downloadDocument, getDocumentsByCodeAndId, deleteDocument } from 'services/document-services';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleLoader } from 'state/reducers/common-actions';
import { useSnackbar } from 'notistack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const DialogWithUpload = ({ open, setOpen, title, description, type, onClickHandler }: any) => {

  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<any|null>(null);
  const [doc, setDoc] = useState<any|null>(null);
  const { state } = useLocation();
  const { data: docData, isSuccess: docSuccess } = useQuery(['getDocumentsByCodeAndId',state?.loanRequestId], 
    ()=> getDocumentsByCodeAndId("RISK_MEMO", state?.loanRequestId),{
      enabled: !!state?.loanRequestId
    }
  );
  
  const deleteDocumentMutation = useMutation((data: any) => deleteDocument(data?.id), {
    onSuccess: async (data: any) => {
     enqueueSnackbar(`Document ${data?.docFileName} deleted`, {
        variant: 'success',
      });
     setDoc(null); 
     setFile(null)
    },
    onError: async (data: any) => {
      enqueueSnackbar(`Error while deleting ${data?.docFileName}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(toggleLoader(false));
    },
  });


  const handleClose = (clearDoc:any) => {
    setOpen(false);
    setComment('');
    clearDoc && setDoc(null);
  };

  const downloadDocuments = async (docDetails: any) => {
    await downloadDocument(docDetails);
    return false;
  }

  const handleUpload = () =>{
  	setDoc(null);
  	
  	if(file){
	  	saveDocumentMetaMutation.mutate({
	        docDesc: state?.reviewType,
	        docFileName: file?.name,
	        docXrefId: state?.loanRequestId,
	        docXrefType: 'RISK_MEMO',
	      });

	    if (saveDocumentMetaMutation?.isLoading) {
	        dispatch(toggleLoader(true));
	    }    	
  	} else {
  		enqueueSnackbar(`Please select Document`, {
          variant: 'error',
        });
  	}
  	
  }

  const saveDocumentMetaMutation = useMutation((data: any) => saveDocumentMeta(data), {
    onSuccess: async (data: any, result: any) => {
      let upload = await uploadDocument(data?.id, file);
      if (upload) {
        enqueueSnackbar(`Document ${file?.name} Saved`, {
          variant: 'success',
        });
        setFile(null);
        setDoc(data)
        
      } else {
        enqueueSnackbar(`Error while saving Document ${file?.name}`, {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar(`Error while uploading ${file?.name}`, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("getDocumentsByCodeAndId")
      dispatch(toggleLoader(false));
    },
  });


  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs">
      <Alert severity={type}>
        <Typography variant="subtitle1">{title}</Typography>
      </Alert>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2">{description}</Typography>
          <br />
          <TextareaAutosize
            aria-label="comment"
            minRows={3}
            placeholder=" Comment"
            style={{ width: '90%', float: 'left' }}
            value={comment}
            required={true}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
          />          
        </DialogContentText>
      </DialogContent>
      {!doc && <DialogContent dividers>
      	<label htmlFor={`upload_file`}>
            <Button variant="contained" component="span"  sx={{ "marginRight" : "10px"}}>
                  Attach
                   <input
                    hidden
                    id={`upload_file`}
                    type="file"
                    onChange={(e:any) => setFile(e?.target.files[0])}
                  />
            </Button>                
        </label> 

        {file && <div>
          <div>
            <br /><span style={{ cursor: 'pointer', float:'left' }}><b>{file?.name}</b> &nbsp;&nbsp;&nbsp;</span> <span
            style={{ cursor: 'pointer', float:'left' }}
            onClick={() => {setDoc(null); setFile(null)}}>
              <DeleteForeverIcon fontSize="small" />
            </span>
          </div>
        </div> } 
        </DialogContent> }
        {(doc || file) && (<DialogContent dividers>
        {doc && <><div style={{float:'left' }}><b><a href="#" onClick={()=> downloadDocuments(doc)}>{doc?.docFileName}</a></b> uploaded</div> 

        <div
          style={{ cursor: 'pointer', textAlign: 'center', float:'left' }}
          onClick={() => deleteDocumentMutation.mutate(doc)}>
            <DeleteForeverIcon fontSize="small" />
          </div>
          </>
      }
        {file && 
          	<Button variant="contained" component="span" onClick={handleUpload}>
                    Upload
            </Button>
        } 
      </DialogContent>)}
      <DialogActions>
        <Button onClick={() => handleClose(false)}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={() => onClickHandler(comment, doc?.id)} disabled={!comment} autoFocus>
          <Typography>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogWithUpload;
