import { TableCell, Typography } from '@mui/material';

const TableDenseCell = ({ children }: any) => {
  return (
    <TableCell sx={{ verticalAlign: 'top', pt: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

export default TableDenseCell;
