import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMainMenu {
  loader: boolean;
}

const initialState: IMainMenu = {
  loader: false,
};

const tabSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    toggleLoader: (state: IMainMenu, action: PayloadAction<boolean>) => {
      return {
        loader: action.payload,
      };
    },
  },
});

const { actions, reducer } = tabSlice;

export const { toggleLoader } = actions;
export default reducer;
