import { Alert, Paper, AlertTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';

const AlertDialog = ({ open, setOpen, title, description, type }: any) => {
  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs">
        <Alert severity={type}>
          <Typography variant="subtitle1">{title}</Typography>
        </Alert>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle2">{description}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            <Typography>Ok</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialog;
