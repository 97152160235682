import { useState, useCallback } from 'react';
import { Box, Button, Grid, Card, CardHeader } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { useForm } from 'react-hook-form';
import DataTable from 'components/common/data-table';
import { grey } from '@mui/material/colors';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE, GO_BACK } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { viewCallReport, saveCallReport } from 'services/common-services';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import ActionBar from 'components/styled-components/ActionBar';
import YearlyPlan from '../YearlyPlan';
import AccountPlan from '../AccountPlan';
import useRefData from 'hooks/ref-data';
import { getDocuments, downloadDocument, uploadDocument, saveDocumentMeta } from 'services/document-services';

interface IRow {
  name: string;
  label: string;
  component: string;
}

const cardHeader = {
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
  },
};

const CallReport = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [view, setView] = useState('CallReport');
  const { state } = useLocation();
  const { fn } = useRefData();
  const [doc, setDoc] = useState<any | null>(null);
  const [gridApi, setGridApi] = useState<any | undefined>();
  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm({
    defaultValues: {
      yearlyPlan: [{}],
      accountPlan: [{}],
      companyDetails: {
        cin: '',
        industry: '',
        groupName: '',
        subIndustry: '',
        customerName: '',
        incorporationDate: '',
        numberOfEmployees: '',
        relationshipSince: '',
        businessActivities: '',
      },
      callReportDetails: [{}],
    },
  });

  const updateFileName = (id: any, filename: any) => {
    var rowNode = gridApi?.api?.getRowNode(id);
    rowNode?.setDataValue('file', filename);
    gridApi?.api?.refreshCells({
      force: true,
      suppressFlash: false,
      columns: ['visitReport'],
    });
    gridApi?.api?.sizeColumnsToFit();
  };

  const handleUpload = (refId: any, file: any) => {
    if (file) {
      uploadDocumentMetaMutation.mutate({
        refId: refId,
        fileDetails: {
          docFileName: file?.name,
          docXrefId: state?.loanRequestId,
          docXrefType: 'PORTFOLIO',
        },
        file: file,
      });

      if (uploadDocumentMetaMutation?.isLoading) {
        dispatch(toggleLoader(true));
      }
    } else {
      enqueueSnackbar(`Please select Document`, {
        variant: 'error',
      });
    }
  };

  const uploadDocumentMetaMutation = useMutation(
    ({ refId, fileDetails, file }: any) => saveDocumentMeta(fileDetails),
    {
      onSuccess: async (data: any, variables: any) => {
        let upload = await uploadDocument(data?.id, variables?.file);
        if (upload) {
          enqueueSnackbar(`Document ${variables?.file?.name} Saved`, {
            variant: 'success',
          });
          if (doc) {
            setDoc({
              ...doc,
              [variables?.refId]: {
                credAdminUploadedDocId: data?.id,
                credAdminUploadedDocName: variables?.file?.name,
              },
            });
          } else {
            setDoc({
              [variables?.refId]: {
                credAdminUploadedDocId: data?.id,
                credAdminUploadedDocName: variables?.file?.name,
              },
            });
          }
          gridApi?.api?.refreshCells({
            force: true,
            suppressFlash: false,
            columns: ['visitReport'],
          });
        } else {
          enqueueSnackbar(`Error while saving Document ${variables?.file?.name}`, {
            variant: 'error',
          });
        }
      },
      onError: (variables: any) => {
        enqueueSnackbar(`Error while uploading ${variables?.file?.name}`, {
          variant: 'error',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['getLegalDocDetails', state?.loanRequestId]);
        dispatch(toggleLoader(false));
      },
    }
  );

  const downloadDocuments = async (docDetails: any) => {
    await downloadDocument(docDetails);
    return false;
  };

  const watchAllFields = watch();

  const callReportRow: any[] = [
    {
      label: 'Customer Name',
      name: 'companyDetails.customerName',
      component: 'textField',
    },
    {
      label: 'Group Name',
      name: 'companyDetails.groupName',
      component: 'textField',
    },
    {
      label: 'Incorporation Date',
      type: 'date',
      name: 'companyDetails.incorporationDate',
      component: 'datepicker',
    },
    {
      label: 'CIN',
      name: 'companyDetails.cin',
      component: 'textField',
    },
    {
      label: 'No of Employees',
      name: 'companyDetails.numberOfEmployees',
      component: 'textField',
    },
    {
      label: 'Industry',
      name: 'companyDetails.industry',
      component: 'textField',
    },
    {
      label: 'Sub-Industry',
      name: 'companyDetails.subIndustry',
      component: 'textField',
    },
    {
      label: 'Relationship since',
      name: 'companyDetails.relationshipSince',
      component: 'textField',
    },
    {
      label: 'Business Activities',
      name: 'companyDetails.businessActivities',
      component: 'textField',
    },
    {
      label: 'Yearly Plan',
      name: 'yearlyPlan',
      component: 'textField',
    },
    {
      label: 'Account Plan',
      name: 'accountPlan',
      component: 'textField',
    },
  ];

  const { data: callReportData, refetch } = useQuery('viewCallReport', () => viewCallReport(state.cid), {
    onSuccess: (data: any) => {
      if (data) {
        if (data.yearlyPlan.length == 0) {
          data.yearlyPlan = [{}];
        }
        if (data.accountPlan.length == 0) {
          data.accountPlan = [{}];
        }

        data.companyDetails.industry = fn.mapIndustryType(data?.companyDetails?.industry);
        data.companyDetails.subIndustry = fn.mapSubIndustryType(data?.companyDetails?.subIndustry);
        reset({ ...data });
      }
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const saveMutation = useMutation((data: any) => saveCallReport(state.cid, data), {
    onSuccess: async (data: any) => {
      enqueueSnackbar('Successfully Updated', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(toggleLoader(false));
      queryClient.invalidateQueries('viewCallReport');
    },
  });

  const removeEmptyRows = (data: any) => {
    const callReportData = data.callReportDetails.filter((row: any) => {
      return Object.keys(row).length > 0 ? !Object.values(row).every((x) => x === null || x === '') : false;
    });

    const yearlyPlanData = data.yearlyPlan.filter((row: any) => {
      return Object.keys(row).length > 0 ? !Object.values(row).every((x) => x === null || x === '') : false;
    });

    const accountPlanData = data.accountPlan.filter((row: any) => {
      return Object.keys(row).length > 0 ? !Object.values(row).every((x) => x === null || x === '') : false;
    });

    return {
      ...data,
      callReportDetails: callReportData,
      yearlyPlan: yearlyPlanData,
      accountPlan: accountPlanData,
    };
  };

  const onSubmit = (data: any) => {
    let filteredData = removeEmptyRows(data);
    saveMutation.mutate(filteredData);
  };

  const creditExposureRow: any[] = [
    {
      headerName: 'Credit Exposure',
      children: [
        {
          field: 'sno',
          headerName: '',
          editable: true,
        },
        {
          field: 'limitCredit',
          headerName: 'Limit',
          editable: true,
        },
        {
          field: 'outstandingCredit',
          headerName: 'Outstanding',
          editable: true,
        },
      ],
    },
  ];

  const nonCreditExposureRow: any[] = [
    {
      headerName: 'Non-Credit Exposure',
      children: [
        {
          field: 'sno',
          headerName: '',
          editable: true,
        },
        {
          field: 'limitNonCredit',
          headerName: 'Limit',
          editable: true,
        },
        {
          field: 'outstandingNonCredit',
          headerName: 'Outstanding',
          editable: true,
        },
      ],
    },
  ];

  const productRow: any[] = [
    {
      field: 'date',
      headerName: 'Date',
      editable: true,
    },
    {
      field: 'companyContact',
      headerName: 'Company Contact',
      editable: true,
    },
    {
      field: 'nextMeeting',
      headerName: 'Next Meeting',
      editable: true,
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      editable: true,
    },
    {
      field: 'visitReport',
      headerName: 'Visit Report',
      sortable: false,
      minWidth: 200,
      resizable: true,
      cellRendererFramework: (params: any) => {
        return (
          <div style={{ cursor: 'pointer', textAlign: 'center', position: 'absolute', top: '-2.5px' }}>
            {(!doc || (doc && !Object.keys(doc)?.includes(params?.data?.id?.toString()))) && (
              <label htmlFor={`attach_${params?.data?.id}`}>
                <Button variant="contained" component="span">
                  Attach
                  <input
                    hidden
                    id={`attach_${params?.data?.id}`}
                    type="file"
                    onChange={(e: any) => updateFileName(params?.data?.id, e?.target.files[0])}
                  />
                </Button>
              </label>
            )}
            {params?.data?.file?.name &&
              (!doc || (doc && !Object.keys(doc)?.includes(params?.data?.id?.toString()))) && (
                <>
                  <span>
                    &nbsp;&nbsp;&nbsp;{params?.data?.file?.name} <b>selected</b>&nbsp;&nbsp;&nbsp;
                  </span>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={() => handleUpload(params?.data?.id, params?.data?.file)}>
                    Upload
                  </Button>
                </>
              )}
            {params?.data?.credAdminUploadedDocId && (
              <div style={{ cursor: 'pointer', padding: '8px', textAlign: 'center' }}>
                <a
                  href="#"
                  onClick={() =>
                    downloadDocuments({
                      id: params?.data?.credAdminUploadedDocId,
                      docFileName: params?.data?.credAdminUploadedDocName,
                    })
                  }>
                  {params?.data?.credAdminUploadedDocName}
                </a>
              </div>
            )}
            {!params?.data?.credAdminUploadedDocId && doc && doc![params?.data?.id] && (
              <span>
                &nbsp;&nbsp;&nbsp;
                <b>
                  <a
                    href="#"
                    onClick={() =>
                      downloadDocuments({
                        id: doc[params?.data?.id]?.credAdminUploadedDocId,
                        docFileName: params?.data?.file?.name,
                      })
                    }>
                    {params?.data?.file?.name}
                  </a>
                </b>{' '}
                &nbsp;&nbsp;&nbsp;<b>uploaded</b>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: '',
      headerName: 'Actions',
      width: 100,
      pinned: 'right',
      suppressSizeToFit: true,
      cellRendererFramework: (params: any) => {
        return (
          <>
            <TooltipIconButton
              onClick={() => {
                return setValue('callReportDetails', [
                  ...watchAllFields?.callReportDetails,
                  {
                    date: '',
                    remarks: '',
                    nextMeeting: '',
                    visitReport: '',
                    companyContact: '',
                  },
                ]);
              }}
              icon={getIcon(ADD, theme.palette.primary.main)}
              title="Add"
            />
            {params.rowIndex > 0 && (
              <TooltipIconButton
                onClick={(e: any) => {
                  let updatedFields = [...getValues('callReportDetails')];
                  if (updatedFields.length > 0) {
                    updatedFields.splice(params.node.rowIndex, 1);
                    setValue('callReportDetails', updatedFields);
                    params.api.setRowData(updatedFields);
                  }
                }}
                icon={getIcon(DELETE, theme.palette.error.main)}
                title="Delete"
              />
            )}
          </>
        );
      },
    },
  ];

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('callReportDetails', updatedRowData);
  }, []);

  const setViewType = () => {
    setView('CallReport');
  };

  const addFields = () => {
    return (
      <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        {_.map(callReportRow, (row: IRow, index: number) => {
          if (row.name === 'yearlyPlan' || row.name === 'accountPlan') {
            return (
              <Grid key={index} item xs={12}>
                <Box>
                  <SimpleFormLabel label={row.label} sx={{ width: '40%' }} />
                  <Button
                    variant="text"
                    onClick={() => {
                      if (row.name === 'yearlyPlan') {
                        setView('YearlyPlan');
                      } else {
                        setView('AccountPlan');
                      }
                    }}>
                    {row.label}
                  </Button>
                </Box>
              </Grid>
            );
          } else {
            return (
              <Grid key={index} item xs={12}>
                <Box>
                  <SimpleFormLabel label={row.label} sx={{ width: '40%' }} />
                  {getFormFields(
                    {
                      ...row,
                    },
                    control,
                    true
                  )}
                </Box>
              </Grid>
            );
          }
        })}
      </Grid>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 1, height: 'calc(100vh - 180px)' }}>
        {view === 'CallReport' && (
          <>
            <Card style={{ borderRadius: 10 }}>
              <CardHeader
                sx={{
                  p: 1,
                  ...cardHeader,
                  pl: 2,
                  background: grey[50],
                  '& .MuiCardHeader-action': {
                    alignSelf: 'center !important',
                  },
                }}
                title="Call Report"
                action={
                  <TooltipIconButton
                    title="Go Back"
                    icon={getIcon(GO_BACK, theme.palette.primary.main)}
                    onClick={() => {
                      navigate('/dashboard/portfolio-management/status-tracker');
                    }}
                  />
                }
              />
            </Card>
            <Box sx={{ height: 'calc(100vh - 200px)', overflowY: 'scroll', pb: 6 }}>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Box sx={{ pl: 0.5, pr: 0.5 }}>
                    <Box>{addFields()}</Box>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={{ height: '50%', mt: 1 }}>
                    <DataTable
                      columnDefs={creditExposureRow}
                      autoSize={true}
                      rowData={[]}
                      domLayout={'autoHeight'}
                      sizeToFit={true}
                      pagination={false}
                    />
                  </Box>
                  <Box sx={{ height: '50%', mt: 1 }}>
                    <DataTable
                      columnDefs={nonCreditExposureRow}
                      autoSize={true}
                      rowData={[]}
                      domLayout={'autoHeight'}
                      sizeToFit={true}
                      pagination={false}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box sx={{ height: 400, mt: 1 }}>
                    <DataTable
                      columnDefs={productRow}
                      autoSize={true}
                      rowData={watchAllFields?.callReportDetails}
                      onGridReady={(event: any) => {
                        setGridApi(event);
                        event?.api?.sizeColumnsToFit();
                      }}
                      // domLayout={'autoHeight'}
                      sizeToFit={true}
                      pagination={false}
                      onCellValueChanged={onCellValueChanged}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {view === 'YearlyPlan' && (
          <YearlyPlan
            setViewType={setViewType}
            watchAllFields={watchAllFields}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        {view === 'AccountPlan' && (
          <AccountPlan
            setViewType={setViewType}
            watchAllFields={watchAllFields}
            setValue={setValue}
            getValues={getValues}
          />
        )}
      </Box>

      <ActionBar>
        <Button variant="contained" sx={{ mr: 2, p: 1 }} type="submit">
          Save Draft
        </Button>
      </ActionBar>
    </form>
  );
};

export default CallReport;
