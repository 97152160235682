import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useMutation, useQueries, useQueryClient, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateFinanceInputScreen } from '../../../credit-analysis/loan-proposal/FinancialProjections/Finance/FinanceTable/inputScreenSlice';
import { updateFinanceFormula } from '../../../credit-analysis/loan-proposal/FinancialProjections/Finance/FinanceTable/formulaSlice';
import { updateInputScreen } from '../../../credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable/inputScreenSlice';
import { updateFormula } from '../../../credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable/formulaSlice';
import { useBasicDetails } from './basic-details-fields';
import { IFormSchema } from 'models/forms-schema-types';
import {
  createOrUpdateProjection,
  getExistingBasicDetails,
  saveFormulaPartByProjectionId,
} from 'services/loan-projection-services';
import { BasicDetails } from 'models/loan-projection/loan-projection';
import { RootState } from 'state/reducers';
import Autocomplete from '@mui/material/Autocomplete';
import AutocompleteWithCreate from 'components/common/form-components/AutocompleteWithCreate';
import MultiSelectAutocomplete from 'components/common/form-components/MultiSelectAutocomplete';
import BackdropLoaderLabel from 'components/common/backdrop-loader/BackdropLoaderLabel';
import {
  getAllProducts,
  getAllRawMaterials,
  getAllConsumer,
  getAllNonConsumer,
} from 'services/rawMaterials-products-services';
import { updateTabs } from '../financial-projection-reducer';
import ActionBar from 'components/styled-components/ActionBar';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import useLoanTable from 'hooks/useLoanTable';
import useRefData from 'hooks/ref-data';
import { FormGroup } from 'types/inputform.d';
import {
  generateInputFormJson,
  generateFormulaJson,
} from 'components/dashboard/credit-analysis/loan-proposal/FinancialProjections/Manufacturing/ManufacturingTable/ManufacturingCommon';
import { searchCompany } from 'services/company-services';

export const chunkString = (str: string, length: number) => {
  return str.match(new RegExp('(.|[\r\n]){1,' + length + '}', 'g'));
};

const ProjectionBasicDetails = ({ onCreateProjection, onCloseModal, open }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [parentData, setParentData] = useState({});
  const [products, setProducts] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [annualYears, setAnnualYears] = useState([]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { getRequestStatusCode } = useLoanTable();
  const { refData, fn } = useRefData();
  const { typeOfMoratorium } = refData;
  const { enqueueSnackbar } = useSnackbar();
  const { mapProjectionTemplate, mapLoanType } = fn;
  //router state
  const { state } = useLocation();
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const [companyLoading, setCompanyLoading] = useState(false);
  const mutation = useMutation((data) => searchCompany(data), {
    onSettled: (data: any) => {
      setCompanyLoading(false);
    },
  });

  //redux state
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const years = _.range(new Date().getFullYear() - 4, new Date().getFullYear() + 1).map(
    (year) => new Object({ label: year + '', value: year + '', displayText: year + '' })
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      companyName: null,
      projectionName: _.isEmpty(tabs) || (tabs?.length === 1 && !open) ? 'Applicant' : 'Guarantor',
      fiscalYearTypeId: 46,
      isApplicant: _.isEmpty(tabs) || (tabs?.length === 1 && !open) ? true : false,
      startYear: '',
      endYear: '',
      projectionTemplate: !_.isEmpty(tabs) ? tabs[0].projectionTemplate : '',
      moratoriumType: '',
      moratoriumPeriod: '',
    },
  });

  const { isLoading: existLoading, data: existData } = useQuery(
    ['getExistingBasicDetails', state?.loanRequestId, tabs],
    () => getExistingBasicDetails(state?.loanRequestId),
    {
      enabled: open,
      onSuccess: (data: any) => {
        console.log('getExistingBasicDetails', data);
        setParentData(data);
      },
    }
  );

  const projectionName = useWatch({ control, name: 'projectionName' });
  const projectionTemplate = useWatch({ control, name: 'projectionTemplate' });
  const moratoriumType = useWatch({ control, name: 'moratoriumType' });
  const formStartYear = useWatch({ control, name: 'startYear' });
  const formEndYear = useWatch({ control, name: 'endYear' });
  const formFields = useBasicDetails(open, projectionTemplate);
  const watchMoratoriumType = watch('moratoriumType');

  const productRawQueries =
    mapProjectionTemplate(projectionTemplate) === 'Manufacturing'
      ? [
          { queryKey: ['getAllProducts'], queryFn: getAllProducts },
          {
            queryKey: ['getAllRawMaterials'],
            queryFn: getAllRawMaterials,
          },
        ]
      : mapProjectionTemplate(projectionTemplate) === 'Finance'
      ? [
          {
            queryKey: ['getAllNonConsumerItems'],
            queryFn: getAllNonConsumer,
          },
          {
            queryKey: ['getAllConsumerItems'],
            queryFn: getAllConsumer,
          },
        ]
      : [];

  const result: any = useQueries(productRawQueries);

  useEffect(() => {
    const moratoriumTypeValue: any = typeOfMoratorium?.find((moratorium: any) => moratorium?.id == moratoriumType);
    if (moratoriumTypeValue?.displayText === 'No Moratorium') {
      setValue('moratoriumPeriod', '0');
    } else {
      setValue('moratoriumPeriod', '');
    }
  }, [moratoriumType]);

  useEffect(() => {
    if (projectionTemplate !== tabs![selectedTab]?.projectionTemplate && !open) {
      setProducts([]);
      setRawMaterials([]);
    } else if (tabs[selectedTab]) {
      const { products, rawMaterials } = tabs[selectedTab];
      setProducts(products);
      setRawMaterials(rawMaterials);
    }
  }, [projectionTemplate, tabs![selectedTab]?.projectionTemplate]);

  useEffect(() => {
    let dataCopy: any = {};
    if (tabs[selectedTab] && !open) {
      dataCopy = { ...tabs[selectedTab] };
    } else if (parentData && open) {
      dataCopy = { ...parentData };
    }

    if (!_.isEmpty(dataCopy)) {
      const { products, rawMaterials, annualYears } = dataCopy;
      setProducts(products);
      setRawMaterials(rawMaterials);
      if (!_.isEmpty(annualYears)) {
        setAnnualYears(annualYears);
      } else {
        setAnnualYears([]);
      }
      if (dataCopy?.companyDetails) {
        setSelectedCompany(dataCopy.companyDetails);
      }

      reset({
        ...dataCopy,
      });
    }
  }, [parentData, tabs[selectedTab]]);

  useEffect(() => {
    const sYear: any = _.max(annualYears?.map((year: any) => year?.value));
    const nYear = new Date().getFullYear() + '';
    const startYear = sYear ? Number(sYear) + 1 + '' : nYear;
    if (!_.isEqual(annualYears, tabs[selectedTab]?.annualYears)) {
      setValue('startYear', startYear);
    }
    let endYear = startYear;
    if (state?.loanType === 'Working Capital') {
      endYear = Number(startYear) + 3 + '';
    }
    if (!_.isEqual(annualYears, tabs[selectedTab]?.annualYears)) {
      setValue('endYear', endYear);
    }
  }, [annualYears, tabs[selectedTab]?.annualYears]);

  useEffect(() => {
    if (
      watchMoratoriumType ===
      typeOfMoratorium?.find((moratorium: any) => moratorium?.displayText === 'Not Applicable')?.id?.toString()
    ) {
      setValue('moratoriumPeriod', '0');
    }
  }, [watchMoratoriumType]);

  const saveFormulaPartMutation = useMutation((formulaData: any) => saveFormulaPartByProjectionId(formulaData), {
    onSuccess: (response) => {
      if (response?.status === 200) {
        enqueueSnackbar(`Projection ${projectionName} Created`, {
          variant: 'success',
        });
        dispatch(updateInputScreen({}));
        dispatch(updateFormula({}));
        dispatch(updateFinanceInputScreen({}));
        dispatch(updateFinanceFormula({}));
      }
    },
    onError: (response) => {
      enqueueSnackbar('Error while saving Projection', {
        variant: 'error',
      });
    },
    onSettled: (response) => {
      if (response?.status !== 202) {
        setLoading(false);
      }
    },
  });

  const basicDetailsMutation = useMutation((data: BasicDetails) => createOrUpdateProjection(data), {
    onSuccess: async (data) => {
      const newTabs = _.cloneDeep(tabs);
      newTabs[selectedTab] = _basicMutationAction({
        ...data,
      });

      const inputFormJson = generateInputFormJson(FormGroup.PROJECTION, {}, {}, newTabs[selectedTab]);
      const formulaJson = generateFormulaJson(inputFormJson, newTabs[selectedTab]);
      // const formulaJson = generateFormulaJson({}, newTabs[selectedTab]);
      console.dir(Object.assign({}, formulaJson?.actuals));
      console.dir(Object.assign({}, formulaJson?.projection));
      const formulaString = JSON.stringify({
        actuals: Object.assign({}, formulaJson?.actuals),
        projection: Object.assign({}, formulaJson?.projection),
      });
      const formulaPart = chunkString(formulaString, 900000);
      let i = 1;
      if (formulaPart) {
        for (const part of formulaPart) {
          await saveFormulaPartMutation.mutateAsync({
            projectionId: newTabs[selectedTab]?.id,
            formulaString: part,
            partNo: i++,
            totalParts: formulaPart?.length,
          });
        }
      }
      dispatch(
        updateTabs({
          tabs: [...newTabs],
        })
      );
    },
    onError: () => {
      enqueueSnackbar('Error while saving Projection', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getProjectionsByLoanId', state?.loanRequestId, tabs]);
      open && onCloseModal(selectedTab);
      setLoading(false);
    },
  });

  const companySearch = _.debounce((value: string) => {
    setCompanyLoading(true);
    const data = {
      empty: true,
      idExp: value,
      nameExp: value,
      panExp: value,
    };
    //@ts-ignore
    mutation.mutate(data);
  }, 100);

  const renderFormFields = () => {
    return _.map(formFields, (input: IFormSchema, idx: number) => {
      if (input.name === 'projectionName') {
        return (
          <Grid item xs={6} key={idx}>
            <Box sx={{ display: 'flex' }}>
              <SimpleFormLabel label={input.label} sx={{ width: '42%' }} />
              <Box>
                <Typography>{projectionName}</Typography>
              </Box>
            </Box>
          </Grid>
        );
      }
      if (input.name === 'products') {
        return (
          <Grid item xs={12} key={idx}>
            <Box sx={{ display: 'flex' }}>
              <SimpleFormLabel label={input.label} sx={{ width: '21%', alignSelf: 'start' }} />
              <Box>
                <AutocompleteWithCreate
                  value={products}
                  label={input.label}
                  name="products"
                  options={result[0]?.data || []}
                  setValue={(d: any) => {
                    setProducts(d);
                  }}
                  disabled={!state?.isEdit}
                />
              </Box>
            </Box>
          </Grid>
        );
      }
      if (input.name === 'rawMaterials') {
        return (
          <Grid item xs={12} key={idx}>
            <Box sx={{ display: 'flex' }}>
              <SimpleFormLabel label={input.label} sx={{ width: '21%', alignSelf: 'start' }} />
              <AutocompleteWithCreate
                value={rawMaterials}
                label={input.label}
                name="rawMaterials"
                setValue={(d: any) => setRawMaterials(d)}
                options={result[1]?.data || []}
                disabled={!state?.isEdit}
              />
            </Box>
          </Grid>
        );
      }

      if (input.name === 'annualYears') {
        return (
          <Grid item xs={12} key={idx}>
            <Box className="required" sx={{ display: 'flex' }}>
              <SimpleFormLabel label={input.label} sx={{ width: '21%', alignSelf: 'start' }} />
              <MultiSelectAutocomplete
                value={annualYears}
                label={input.label}
                name="annualYears"
                setValue={(d: any) => setAnnualYears(d)}
                options={years || []}
                disabled={!state?.isEdit}
              />
            </Box>
          </Grid>
        );
      }

      return (
        <Grid item xs={6} key={idx}>
          <Box className="required" sx={{ display: 'flex' }}>
            <SimpleFormLabel label={input.label} sx={{ width: '42%' }} />
            {getFormFields(
              input,
              control,
              input.name === 'isApplicant' ||
                !state?.isEdit ||
                (input.name === 'projectionTemplate' && tabs.length === 1 && selectedTab === 1) ||
                (input.name === 'moratoriumPeriod' &&
                  moratoriumType ==
                    typeOfMoratorium
                      ?.find((moratorium: any) => moratorium?.displayText === 'Not Applicable')
                      ?.id?.toString()),
              { required: input.required ? true : false }
            )}
          </Box>
        </Grid>
      );
    });
  };

  const _renderBasicDetailsForm = () => {
    return <>{renderFormFields()}</>;
  };

  const _basicMutationAction = (data: any) => {
    data.startYear = moment(data.startYear).format('YYYY');
    data.endYear = moment(data.endYear).format('YYYY');
    data.products = products;
    data.rawMaterials = rawMaterials;
    data.loanRequestId = state.loanRequestId;
    data.annualYears = annualYears;
    data.actualsYears = annualYears ? annualYears?.map((year: any) => year?.value).sort() : [];

    return data;
  };

  const _onCreateProjection = (data: any) => {
    const yearArray = annualYears
      ?.map((y: any) => Number(y?.value))
      ?.sort((a, b): any => {
        return a - b;
      });

    const isSeq = yearArray?.every((num, i) => i === yearArray?.length - 1 || num === yearArray[i + 1] - 1);
    if (!isSeq) {
      enqueueSnackbar('There should not be any gap in annual year', {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }
    const _payload = _basicMutationAction({
      ...data,
      projectionName: _.isEmpty(tabs) || (tabs?.length === 1 && !open) ? 'Applicant' : 'Guarantor',
      companyDetails: selectedCompany,
    });

    if (_payload.projectionName === 'Guarantor' && !selectedCompany) {
      showErrors();
    } else if (annualYears.length === 0) {
      showErrors();
    } else {
      setLoading(true);
      basicDetailsMutation.mutate(_payload);
    }
  };

  const _saveUpdate = (data: any) => {
    const yearArray = annualYears
      ?.map((y: any) => Number(y?.value))
      ?.sort((a, b): any => {
        return a - b;
      });
    const isSeq = yearArray?.every((num, i) => i === yearArray?.length - 1 || num === yearArray[i + 1] - 1);
    if (!isSeq) {
      enqueueSnackbar('There should not be any gap in annual year', {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }
    const _payload = _basicMutationAction({
      ...data,
      projectionName: tabs![selectedTab]?.projectionName,
      companyDetails: selectedCompany,
    });
    if (_payload.projectionName === 'Guarantor' && !selectedCompany) {
      showErrors();
    } else if (annualYears.length === 0) {
      showErrors();
    } else {
      setLoading(true);
      basicDetailsMutation.mutate(_payload);
    }
  };

  const showErrors = () => {
    console.log('errors');
    enqueueSnackbar('Please fill all required fields', {
      variant: 'error',
      autoHideDuration: 5000,
    });
  };

  return (
    <>
      <form
        onSubmit={open ? handleSubmit(_onCreateProjection, showErrors) : handleSubmit(_saveUpdate, showErrors)}>
        <Card sx={{ height: 'calc(100vh - 240px)', overflowY: 'scroll' }}>
          <CardContent>
            {existData?.loanRequestId && (
              <Grid container sx={{ mt: 0 }}>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="subtitle2">
                      Below value pre-populated from parent Loan Request ID: {existData?.loanRequestId}
                    </Typography>
                    <br />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid container sx={{ mt: 0, borderBottom: '1px #babfc7 solid' }}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h6">Loan Request</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} sx={{ mt: 0 }}>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Loan Type" sx={{ width: '42%' }} />
                  <Box>
                    <Typography>{state?.loanType}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Loan Sub Type" sx={{ width: '42%' }} />
                  <Box sx={{ width: '300px' }}>
                    <Typography>{state?.loanSubType}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Request Type" sx={{ width: '42%' }} />
                  <Box>
                    <Typography>{state?.requestType}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Purpose of Facility" sx={{ width: '42%' }} />
                  <Box>
                    <Typography>{state?.purposeOfFacility}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Loan Amount (Million)" sx={{ width: '42%' }} />
                  <Box>
                    <Typography>
                      {state?.loanCurrency} {state?.amount}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex' }}>
                  <SimpleFormLabel label="Source of Repayment" sx={{ width: '42%' }} />
                  <Box>
                    <Typography>{state?.sourceRepayment}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: '24px', borderBottom: '1px #babfc7 solid' }}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h6">Projection</Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1} sx={{ mt: 0 }}>
              <Grid item xs={6}>
                {projectionName === 'Guarantor' ? (
                  <Box sx={{ display: 'flex' }} className="required">
                    <SimpleFormLabel label="Company" sx={{ width: '40%' }} />
                    <Box>
                      <Controller
                        render={() => (
                          <Autocomplete
                            disablePortal
                            freeSolo
                            id="company-search-box"
                            value={selectedCompany}
                            options={mutation?.data || []}
                            filterOptions={(options, state) => options}
                            getOptionLabel={(option: any) => option?.name + ' (' + option?.id + ')'}
                            onChange={(event, newValue) => {
                              setSelectedCompany(newValue);
                            }}
                            onClose={() => {
                              setLoading(false);
                            }}
                            sx={{
                              padding: 0,
                              '& .MuiAutocomplete-inputRoot': {
                                padding: '3px 11px',
                              },
                              '& .MuiChip-label': {
                                fontSize: '12px',
                              },
                              width: '13.3rem',
                            }}
                            renderInput={(params) => (
                              <TextField
                                margin="dense"
                                placeholder="Search Company"
                                {...params}
                                sx={{
                                  margin: 0,
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {companyLoading ? <CircularProgress color="inherit" size={18} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {state?.isEdit && <SearchIcon />}
                                    </InputAdornment>
                                  ),
                                }}
                                variant="standard"
                                onChange={(e) => {
                                  companySearch(e.target.value);
                                }}
                              />
                            )}
                            disabled={!state?.isEdit}
                          />
                        )}
                        name="companyName"
                        control={control}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex' }}>
                    <SimpleFormLabel label="Company Name" sx={{ width: '42%' }} />
                    <Box>
                      <Typography>{state?.company}</Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              {_renderBasicDetailsForm()}
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-end',
            }}>
            {open && (
              <ActionBar>
                <Button variant="contained" onClick={() => onCloseModal(selectedTab - 1)} sx={{ p: 1, right: 20 }}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{ p: 1, right: 10 }}>
                  Submit
                </Button>
              </ActionBar>
            )}
            {!open &&
              (state?.statusCode === getRequestStatusCode('DRAFT') ||
                state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
                state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
                !state?.statusCode) && (
                <ActionBar>
                  {!state.viewOnly && (
                    <Button type="submit" variant="contained" sx={{ p: 1, right: 10 }}>
                      Save Draft
                    </Button>
                  )}
                </ActionBar>
              )}
          </CardActions>
        </Card>
      </form>
      <BackdropLoaderLabel isLoading={isLoading} label="Please wait while we generate forms for you" />
    </>
  );
};

export default ProjectionBasicDetails;
