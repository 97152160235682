import { FC } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import DateRangeIcon from '@mui/icons-material/DateRange';

const inputStyle = {
  '& .MuiInputBase-input': {
    padding: '7px 11px',
    width: '8.930rem',
    fontSize: '11px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(145, 158, 171, 0.32) !important',
  },
};

const CustomDateRangeIcon = () => {
  return <DateRangeIcon fontSize="small" />;
};

const DatePicker: FC<any> = ({ control, name, disabled, rules, minDate, maxDate, ...input }) => {
  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ margin: 0 }}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => {
            return (
              <DesktopDatePicker
                components={{
                  OpenPickerIcon: CustomDateRangeIcon,
                }}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
                renderInput={(params) => <TextField sx={{ ...inputStyle }} {...params} />}
              />
            );
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DatePicker;
