import _ from 'lodash';
import DataSheet from 'react-datasheet';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../../../state/store';
import { updateInputScreen } from './ManufacturingTable/inputScreenSlice';
import { InputFormProps, InputFormRowType } from '../../../../../../types/inputform.d';
import { cellUpdate, generateGrid, getNumberFormatted, getInputScope, isNumeric } from '../common/FormUtil';
import { formatNumber } from 'utils/common-utils';

const InputForm = ({ cells }: any) => {
  const inputscreencells = useSelector((state: RootState) => state?.inputscreen?.cells);
  const formula = useSelector((state: RootState) => state?.formula?.rows);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { isEdit } = state;

  const onCellsChanged = (changes: DataSheet.CellsChangedArgs<InputFormRowType>) => {
    const stateCells = _.cloneDeep(inputscreencells);
    const formulaScope = Object.assign({}, formula);

    const inputScope = getInputScope(stateCells);

    changes.forEach(({ cell, value }) => {
      cellUpdate(stateCells, cell, value?.toString()?.replace(/,/g, ''), inputScope, formulaScope);
    });

    Object.entries(stateCells as Object).map(([formname, value]) => {
      value &&
        Object.entries(value as Object).map(([key, cell]) => {
          if ('dataEditor' in cell) {
            return (stateCells[formname][key] = Object.assign({}, { ...cell, dataEditor: null }));
          }
        });
    });

    dispatch(updateInputScreen(stateCells));
  };

  const valueRenderer = (cell:any) =>{
    return (isNumeric(cell?.value) && !cell?.key?.endsWith(".0")) ? formatNumber(getNumberFormatted(cell?.value)) : cell?.value
  }

  const dataRenderer = (cell:any) =>{
    return (isNumeric(cell?.expr) && !cell?.key?.endsWith(".0")) ? formatNumber(getNumberFormatted(cell?.expr)) : cell?.expr
  }

  return (
    <DataSheet
      data={generateGrid(cells, !isEdit)}
      valueRenderer={valueRenderer}
      dataRenderer={dataRenderer}
      onCellsChanged={onCellsChanged}
    />
  );
};

export default InputForm;
