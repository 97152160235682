import { Box, Stepper, Step, StepButton, Typography } from '@mui/material';

export default function LinearStepper({
  steps,
  activeStep,
  stepperStyle,
  setStepByIndex,
}: {
  steps: any;
  activeStep: number;
  stepperStyle: any;
  setStepByIndex: any;
}) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stepper nonLinear activeStep={activeStep} sx={stepperStyle}>
        {steps.map((step: any, index: number) => (
          <Step key={step.index}>
            <StepButton color="inherit" onClick={() => setStepByIndex(index)}>
              <Typography variant='subtitle2'>{step.label}</Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
