import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import _ from 'lodash';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import { getFormFields } from 'utils/form-utils';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function LCAssessment({ t, totalYears, data, control }: IProps) {
  const { state } = useLocation();
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>{t('loanProjection.performanceSummary.nonFundBasedLimits')}</b>
          </Typography>
          <Divider />
        </Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>{t('loanProjection.performanceSummary.lcAssessment')}</b>
          </Typography>
          <Divider />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#F8F8F8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Particulars</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  key={year}
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <DenseCellSm>
                <b>Domestic LC</b>
              </DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <DenseCellSm key={i}>&nbsp;</DenseCellSm>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Annual raw material purchase</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.domestic?.annualRawMaterialPurchase)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>% Purchase under LC</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.purchaseUnderLc)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Estimated purchase under LC</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.domestic?.estimatedPurchaseUnderLc)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Monthly RM purchases</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.monthlyRmPurchase)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Usance (days)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.usance)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Lead time (days)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.leadTime)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Total Time</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.totalTime)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>LC limit required</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.lcLimitRequired)}</NumberDenseCell>
                );
              })}
            </TableRow>
            {/* <TableRow>
              <DenseCellSm>Recommended LC limit</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.domestic?.recommendedLcLimit)}</NumberDenseCell>
                );
              })}
            </TableRow> */}
            <TableRow>
              <DenseCellSm>
                <b>Export LC</b>
              </DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <DenseCellSm key={i}>&nbsp;</DenseCellSm>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Annual raw material purchase</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.exportLcAssessment?.annualRawMaterialPurchase)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>% Purchase under LC</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.exportLcAssessment?.purchaseUnderLc)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Estimated purchase under LC</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.exportLcAssessment?.estimatedPurchaseUnderLc)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Monthly RM purchases</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.exportLcAssessment?.monthlyRmPurchase)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Usance (days)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.exportLcAssessment?.usance)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Lead time (days)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.exportLcAssessment?.leadTime)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Total Time</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>{formatNumber(data[i]?.exportLcAssessment?.totalTime)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>LC limit required</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i]?.exportLcAssessment?.lcLimitRequired)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', mt: 1, mb: 2 }}>
          <SimpleFormLabel label={'Limit Proposed'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
          <Box sx={{ width: '75%' }}>
            {getFormFields(
              {
                name: 'assessmentLimits.lcLimit',
                component: 'textField',
                type: 'number',
              },
              control,
              state.viewOnly
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
