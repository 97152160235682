import { Grid, Table, TableBody, TableHead, TableRow, TableCell, Typography, TableContainer, Stack } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// import TableDenseCell from 'components/common/table/TableDenseCell';
import { IFormSchema } from '../../../../../models/forms-schema-types';
import { getFormFields } from '../../../../../utils/form-utils';
import { useLocation } from 'react-router-dom';
import { RADIO_BUTTONS } from 'constants/form-components';
import { SWITCH } from '../../../../../constants/form-components';

const headers = ['Declaration', 'Yes/No'];

const isCompanyEngage: IFormSchema[] = [
  {
    name: 'regulatorsRequirement.declaration.realStateExposure',
    label: 'The exposure is classified as Real Estate exposure',
  },
  {
    name: 'regulatorsRequirement.declaration.capitalMarketExposure',
    label: 'The exposure is classified as Capital Market exposure',
  },
  { name: 'regulatorsRequirement.declaration.infraExposure', label: 'Infrastructure Exposure' },
  // { name: 'regulatorsRequirement.declaration.others', label: 'Others' },
  {
    name: 'regulatorsRequirement.declaration.prioritySector',
    label: 'The following facility(ies) would qualify as Priority sector',
  },
  {
    name: 'regulatorsRequirement.declaration.borrowerLimit',
    label: 'The limits to the borrower are proposed to/have been taken over from other bank(s)',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.acqFinance',
    label: 'Whether it is Acquisition Financing?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isNBFC',
    label: 'Whether borrower is Non Bank Finance Company (NBFC)?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.isIFC',
    label: 'a)Is it an Infrastructure finance company ?',
  },
  {
    name: 'regulatorsRequirement.declaration.riskExposure',
    label: 'Country/Bank risk exposure at May 08, 2014 (applicable only if country risk domicile is not India):',
  },
  {
    name: 'regulatorsRequirement.declaration.intangibleAccess',
    label: 'Advances backed only by intangible assets',
  },
  { name: 'regulatorsRequirement.declaration.ndu', label: 'Advances backed by NDU/POA' },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.trans',
    label: 'Whether it is related party transaction?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.provision',
    label:
      'Whether Have the provisions of Section 19(2) (whether Bank is not permitted to hold more than 30% of shares company) of the Banking Regulation Act 1949 been complied with?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.unhedged',
    label: 'Whether borrower has unhedged foreign currency exposure?',
  },
  {
    name: 'regulatorsRequirement.exposureDetails.classification.projectType',
    label: 'Whether this is a Project Loan/Finance?',
  },
];

const details: IFormSchema[] = _.map(isCompanyEngage, (el: IFormSchema, i: number): IFormSchema => {
  return {
    defaultValue: 'no',
    label: el?.label,
    type: 'none',
    name: el?.name,
    component: RADIO_BUTTONS,
    radioGroup: `${i}`,
    values: [
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ],
  };
});

const Declaration = ({ control }: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Grid item lg={12}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {_.map(headers, (header: string, idx: number) => {
                return (
                  <TableCell
                    align="center"
                    width={'80%'}
                    key={idx}
                    sx={{
                      background: '#F8F8F8',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}>
                    <Typography>
                      <b>{header}</b>
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((detail: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ padding:0, paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}><Typography>{detail.label}</Typography></TableCell>
                  <TableCell sx={{ padding:0,  paddingLeft:1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      {getFormFields(
                        {
                          name: detail.name,
                          component: SWITCH,
                        },
                        control,
                        !state?.isEdit
                      )}
                      <Typography>Yes</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Declaration;
