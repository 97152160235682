import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getFormFields } from '../../../../../utils/form-utils';
import { MULTI_LINE_INPUT } from '../../../../../constants/form-components';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OurBankOverdue from './our-bank-overdue';
import ConductOfAccounts from './conduct-of-account';
import { FormTitle } from 'components/common/form-components/FormTitle';
import PrevRestructing from './PrevRestructing';
import SimpleFormLabel from 'components/common/form-components/form-label';

const InternalCompliance = (props: any) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12}>
        <FormTitle title={'Key Observations And Comments'} />
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel sx={{ alignSelf: 'start' }} label="Auditor Comments" />
          {getFormFields(
            {
              name: 'auditorComments',
              component: MULTI_LINE_INPUT,
              label: 'Comment',
              maxLength: 5000,
              minRows: 5,
            },
            props.control,
            !state?.isEdit
          )}
        </Box>
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel sx={{ alignSelf: 'start' }} label="Stock Audit" />
          {getFormFields(
            {
              name: 'stockAudit',
              component: MULTI_LINE_INPUT,
              label: 'Comment',
              maxLength: 5000,
              minRows: 5,
            },
            props.control,
            !state?.isEdit
          )}
        </Box>
        <Box sx={{ mt: 1, display: 'flex' }}>
          <SimpleFormLabel sx={{ alignSelf: 'start' }} label="Unit Visit" />
          {getFormFields(
            {
              name: 'unitVisit',
              component: MULTI_LINE_INPUT,
              label: 'Comment',
              maxLength: 5000,
              minRows: 5,
            },
            props.control,
            !state?.isEdit
          )}
        </Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1 }}></Box>
        <Grid item lg={12}>
          <FormTitle title={'Conduct Of Accounts'} />
          <OurBankOverdue {...props} />
        </Grid>
        <Grid item lg={12}>
          <ConductOfAccounts {...props} />
        </Grid>
        <Grid item lg={12}>
          <PrevRestructing {...props} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InternalCompliance;
