import { Box, Typography, Divider, MenuItem, Stack, IconButton, Tooltip, Popover } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react';
import { sum } from 'mathjs';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';

export default function SanctionDetails({ t, watchAllFields, setValue, getValues }: any) {
  const fundGridRef = useRef<any>(null);
  const securityCoverGridRef = useRef<any>(null);
  const { state } = useLocation();
  const theme = useTheme();

  const [fundSubTotal, setFundSubTotal] = useState({
    facility: 'Fund-Based (A)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'FBTOTAL',
  });

  const [nonfundSubTotal, setNonFundSubTotal] = useState({
    facility: 'Non-Fund Based (B)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'NFBTOTAL',
  });

  const [fundNonFundTotal, setFundNonFundTotal] = useState({
    facility: 'Total (A+B)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'TOTAL',
  });

  const total = (field: string) => {
    if (watchAllFields?.sanctionDetails) {
      const fundRows = [...(getValues('sanctionDetails')||[])]?.filter((e) => e?.facilityType === 'Fund Based') || [];
      return fundRows.reduce((prevValue: any, currentValue: any) => {
        if (isNaN(currentValue[field])) {
          return sum(prevValue, 0);
        } else {
          return sum(prevValue, currentValue[field]);
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const nonFundTotal = (field: string) => {
    if (watchAllFields?.sanctionDetails) {
      const nonFundRows =
        [...(getValues('sanctionDetails')||[])].filter((e) => e?.facilityType === 'Non-Fund Based') || [];
      return nonFundRows.reduce((prevValue: any, currentValue: any) => {
        if (isNaN(currentValue[field])) {
          return sum(prevValue, 0);
        } else {
          return sum(prevValue, currentValue[field]);
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const abTotal = () => {
    const fundBased: any = {
      existingLimit: total('existingLimit'),
      renewal: total('renewal'),
      additional: total('additional'),
      proposedTotal: total('proposedTotal'),
    };

    const nonFundBasedData: any = {
      existingLimit: nonFundTotal('existingLimit'),
      renewal: nonFundTotal('renewal'),
      additional: nonFundTotal('additional'),
      proposedTotal: nonFundTotal('proposedTotal'),
    };

    function sumObjectsByKey(...objs: any) {
      return objs.reduce((a: any, b: any) => {
        for (let k in b) {
          if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
        }
        return a;
      }, {});
    }
    return sumObjectsByKey(fundBased, nonFundBasedData);
  };

  const deleteRowHandler = (params: any) => {
    let updatedFields = [...getValues('sanctionDetails')];
    if (updatedFields.length > 0) {
      if (params?.data?.isChild) {
        const rowIndex = updatedFields.findIndex((e) => e.id === params.node.id);
        updatedFields.splice(rowIndex, 1);
        setValue('sanctionDetails', updatedFields);
      } else {
        const filteredRows = updatedFields?.filter((e: any) => e?.parentId !== params.node.id);
        setValue('sanctionDetails', filteredRows);
      }

      params.api.setRowData(updatedFields);
    }
  };

  const checkColumnEditable = (params: any) => {
    if (
      !state?.isEdit ||
      params?.data?.uid === 'TOTAL' ||
      params?.data?.uid === 'FBTOTAL' ||
      params?.data?.uid === 'NFBTOTAL' ||
      (params.colDef.field === 'securityApp' && params.data.isChild)
    ) {
      return false;
    }

    return true;
  };

  // const childValueFormatter = (params: any) => {
  //   if (params?.data?.isChild && params?.value) {
  //     const stringVal = params.value.toString();
  //     var commaSeparatedVal = formatNumber(stringVal.trim().replace(/[()]/g, ''));
  //     return '(' + commaSeparatedVal + ')';
  //   } else {
  //     return formatGridNumber(params);
  //   }
  // };

  const defaultColDefs = useMemo(() => {
    let colDef: any = [
      {
        field: 'facility',
        headerName: 'Facility',
        width: 150,
        editable: checkColumnEditable,
        cellStyle: (params: any) => {
          if (params.data.isChild) {
            return { paddingLeft: '25px' };
          }
        },
      },
      {
        field: 'facilityType',
        headerName: 'Facility Type',
        width: 150,
        editable: checkColumnEditable,
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Fund Based', 'Non-Fund Based'],
        },
      },
      {
        field: 'productType',
        headerName: 'Product Type',
        width: 150,
        editable: checkColumnEditable,
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params: any) => {
          if (params.data.facilityType == 'Fund Based') {
            return {
              values: [
                'Term Loan (TL)',
                'Cash Credit',
                'Working Capital Demand Loan (WCDL)',
                'Export Packing Credit (EPC)',
                'Post-Shipment Credit in Foreign Currency (PCFC)',
                'Bill Discounting (BD)',
              ],
            };
          } else if (params.data.facilityType == 'Non-Fund Based') {
            return {
              values: ['Bank Guarantee (BG)', 'Letter of Credit (LC)', 'Derivative', 'Capex LC'],
            };
          }
        },
      },
      {
        field: 'currency',
        headerName: 'Currency',
        width: 150,
        editable: checkColumnEditable,
      },
      {
        field: 'existingLimit',
        headerName: 'Existing Limit',
        width: 100,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'renewal',
        headerName: 'Renewal (A)',
        width: 100,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'additional',
        headerName: 'Additional (B)',
        width: 100,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'proposedTotal',
        headerName: 'Proposed Total (A+B)',
        width: 100,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'pricing',
        headerName: 'Pricing',
        width: 180,
        editable: checkColumnEditable,
      },
      {
        field: 'securityApp',
        headerName: 'Security Applicability',
        width: 180,
        editable: checkColumnEditable,
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params: any) => {
          return {
            values: ['WC Secured', 'WC Unsecured', 'TL Secured', 'TL Unsecured'],
          };
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          if (
            !state?.isEdit ||
            params?.data?.uid === 'FBTOTAL' ||
            params.data.uid === 'NFBTOTAL' ||
            params?.data?.uid === 'TOTAL'
          ) {
            return null;
          } else {
            return (
              <>
                {!params?.data?.isChild && RowPopup(params)}
                {params?.data?.isChild && (
                  <TooltipIconButton
                    onClick={() => {}}
                    sx={{ visibility: 'hidden' }}
                    icon={getIcon(ADD, theme.palette.error.main)}
                    title="Add"
                  />
                )}
                {getValues('sanctionDetails').length > 1 && (
                  <TooltipIconButton
                    onClick={() => deleteRowHandler(params)}
                    icon={getIcon(DELETE, theme.palette.error.main)}
                    title="Delete"
                  />
                )}
              </>
            );
          }
        },
      },
    ];
    return colDef;
  }, []);

  const securityCoverColDefs = useMemo(() => {
    let colDef: any = [
      {
        field: 'facility',
        headerName: 'Type of Facility',
        width: 100,
        editable: checkColumnEditable,
      },
      {
        field: 'facilityAmount',
        headerName: 'Facility Amount (A)',
        width: 120,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
      },
      {
        field: 'security',
        headerName: 'Security',
        width: 180,
        editable: checkColumnEditable,
      },
      {
        field: 'share',
        headerName: 'Share of Security',
        width: 120,
        editable: checkColumnEditable,
      },
      {
        field: 'amount',
        headerName: 'Amount (B)',
        width: 100,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'securityCover',
        headerName: 'Security Cover (B/A)',
        width: 120,
        editable: checkColumnEditable,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          if (!state?.isEdit) return null;
          return (
            <>
              {params.rowIndex === getValues('securityCoverCalculations').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('securityCoverCalculations'), {}];
                      setValue('securityCoverCalculations', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('securityCoverCalculations').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('securityCoverCalculations')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('securityCoverCalculations', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      },
    ];
    return colDef;
  }, []);

  useEffect(() => {
    setFundSubTotal({
      facility: 'Fund-Based (A)',
      existingLimit: total('existingLimit'),
      renewal: total('renewal'),
      additional: total('additional'),
      proposedTotal: total('proposedTotal'),
      uid: 'FBTOTAL',
    });
    setNonFundSubTotal({
      facility: 'Non-Fund Based (B)',
      existingLimit: nonFundTotal('existingLimit'),
      renewal: nonFundTotal('renewal'),
      additional: nonFundTotal('additional'),
      proposedTotal: nonFundTotal('proposedTotal'),
      uid: 'NFBTOTAL',
    });
    setFundNonFundTotal({
      facility: 'Total (A+B)',
      uid: 'TOTAL',
      ...abTotal(),
    });
  }, [watchAllFields]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });

    setValue('sanctionDetails', updatedRowData);

    if (event.colDef.field != 'facility') {
      if (event?.data?.facilityType === 'Fund Based') {
        const fundSubTotal = {
          facility: 'Fund-Based (A)',
          existingLimit: total('existingLimit'),
          renewal: total('renewal'),
          additional: total('additional'),
          proposedTotal: total('proposedTotal'),
          os: total('os'),
          uid: 'FBTOTAL',
        };
        setFundSubTotal(fundSubTotal);
        setValue('fundSubTotal', fundSubTotal);
      }
      if (event?.data?.facilityType === 'Non-Fund Based') {
        const nonFundSubTotal = {
          facility: 'Non-Fund Based (B)',
          existingLimit: nonFundTotal('existingLimit'),
          renewal: nonFundTotal('renewal'),
          additional: nonFundTotal('additional'),
          proposedTotal: nonFundTotal('proposedTotal'),
          os: nonFundTotal('os'),
          uid: 'NFBTOTAL',
        };
        setNonFundSubTotal(nonFundSubTotal);
        setValue('nonfundSubTotal', nonFundSubTotal);
      }
      const fundNonFundTotal = {
        facility: 'Total',
        ...abTotal(),
        uid: 'TOTAL',
      };
      setFundNonFundTotal(fundNonFundTotal);
      setValue('fundNonFundTotal', fundNonFundTotal);

      if (event?.colDef?.field == 'facilityType') {
        const rowNode = fundGridRef.current!.api.getRowNode(event?.node?.id)!;
        if (event?.data?.facilityType === 'Fund Based') rowNode.setDataValue('productType', 'Term Loan (TL)');
        if (event?.data?.facilityType === 'Non-Fund Based')
          rowNode.setDataValue('productType', 'Bank Guarantee (BG)');
      }

      if (event?.colDef?.field == 'securityApp') {
        fundGridRef.current.api.forEachNode((e: any) => {
          if (event?.data?.id === e?.data?.parentId && e?.data?.isChild) {
            e?.setDataValue('securityApp', event?.data?.securityApp);
          }
        });
      }
    }
  }, []);

  const onCellSecurityValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('securityCoverCalculations', updatedRowData);
  }, []);

  const RowPopup = (params: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const onClickParentRowHandler = () => {
      params.api.stopEditing();
      setTimeout(() => {
        const id = uuidv4();
        let fields = [
          ...getValues('sanctionDetails'),
          {
            id: id,
            isChild: false,
            parentId: id,
            facilityType: 'Fund Based',
            securityApp: 'WC Secured',
            productType: 'Term Loan (TL)',
          },
        ];
        setValue('sanctionDetails', fields);
        params.api.setRowData(fields);
      }, 100);
    };

    const onClickChildRowHandler = () => {
      params.api.stopEditing();
      setTimeout(() => {
        const fields = [...getValues('sanctionDetails')];
        const parentObj: any = {};
        fields?.forEach((field: any) => {
          if (parentObj[field?.parentId]) {
            parentObj[field?.parentId].push(field);
          } else {
            parentObj[field?.parentId] = [field];
          }
        });

        if (params?.data?.parentId in parentObj)
          parentObj[params?.data?.parentId].push({
            id: uuidv4(),
            isChild: true,
            parentId: params?.data?.parentId,
            facilityType: 'Fund Based',
            securityApp: params?.data?.securityApp,
            productType: 'Term Loan (TL)',
          });

        const fieldsArr = [];
        for (const key in parentObj) {
          if (params?.data?.parentId in parentObj) {
            fieldsArr.push(...parentObj[key]);
          }
        }

        setValue('sanctionDetails', [...fieldsArr]);
        params.api.setRowData([...fieldsArr]);
      }, 100);
    };

    return (
      <>
        <Tooltip title="Add">
          <IconButton onClick={handleClick}>{getIcon(ADD, theme.palette.primary.main)}</IconButton>
        </Tooltip>
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Stack spacing={0.75}>
            <MenuItem onClick={onClickParentRowHandler}>
              <Typography>Add Parent Row</Typography>
            </MenuItem>
            <MenuItem onClick={onClickChildRowHandler}>
              <Typography>Add Child Row</Typography>
            </MenuItem>
          </Stack>
        </Popover>
      </>
    );
  };

  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1 }}>
        <FormTitle title={t('proposalTnc.heading')} />
        <Box sx={{ mt: 1 }}>
          <RichTextEditor
            value={watchAllFields?.proposalComment}
            onEditorChangeHandler={(newValue: any) => {
              setValue('proposalComment', newValue);
            }}
            disabled={!state?.isEdit}
          />
        </Box>
      </Box>

      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1 }}>
        <FormTitle title={t('proposalTnc.sanctionDetails')} />
        <Box sx={{ mt: 1 }}>
          <DataTable
            ref={fundGridRef}
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={watchAllFields?.sanctionDetails}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            pinnedBottomRowData={[fundSubTotal, nonfundSubTotal, fundNonFundTotal]}
            onCellValueChanged={(params: any) => onCellValueChanged(params)}
            getRowNodeId={(params: any) => {
              return params.id;
            }}
            enableCellChangeFlash={true}
            editType="single"
            getRowStyle={(params: any) => {
              if (
                !params.data.isChild &&
                (params.data?.uid !== 'FBTOTAL' || params.data?.uid !== 'NFBTOTAL' || params.data?.uid !== 'TOTAL')
              ) {
                return {
                  color: '#2F80ED',
                };
              }
              return null;
            }}
          />
        </Box>
      </Box>

      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1 }}>
        <FormTitle title="7.1.c Security Cover Calculations" />
        <Box sx={{ mt: 1 }}>
          <DataTable
            ref={securityCoverGridRef}
            columnDefs={securityCoverColDefs}
            autoSize={false}
            rowData={watchAllFields?.securityCoverCalculations}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={(params: any) => onCellSecurityValueChanged(params)}
          />
        </Box>
      </Box>
    </>
  );
}
