import { Card, CardContent, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useQueries } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import InternalCompliance from './internal-compliance';
import RegulatoryRequirement from './regulatory-requirement';
import KeyRiskCompliance from './KeyRiskCompliance';
import SimpleAccordion from '../../../common/simple-accordian';
import { RootState } from '../../../../state/store';
import { getCompliance, updateCompliance } from 'services/loan-proposal-services';
import { StatusCodes } from 'constants/status-codes';
import ActionBar from 'components/styled-components/ActionBar';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { routes } from 'constants/strings';
import { MENU_ITEMS } from '../projection-menu-schema';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { toggleLoader } from 'state/reducers/common-actions';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import ExternalCompliance from './external-compliance/external-compliance';
import { useEffect } from 'react';

export interface IComplianceControl {
  auditorComment: string;
  borrower: string;
  routingTurnover: string;
  stockAudit: string;
  unitVisit: string;
  otherBankOverdue: any[];
}

const ComplainceDetails = () => {
  const { status }: any = useSelector((state: RootState) => state.loanProposal);
  const { t } = useTranslation();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { getRequestStatusCode } = useLoanTable();
  const navigate = useNavigate();

  const updateJson = (data: any) => {
    let obj = { ...data };

    obj?.keyRiskTrigger.forEach((value: any) => {
      value.observed =
        value.observed === 'Yes' ? true : value.observed === 'No' ? false : value.observed ? 'Yes' : 'No';
    });

    obj?.routingThroughUs.forEach((value: any) => {
      value.yesNo = value.yesNo === 'Yes' ? true : value.yesNo === 'No' ? false : value.yesNo ? 'Yes' : 'No';
    });

    Object.keys(obj?.regulatorsRequirement?.declaration).forEach((key) => {
      obj.regulatorsRequirement.declaration[key] =
        obj?.regulatorsRequirement?.declaration[key] === 'Yes'
          ? true
          : obj?.regulatorsRequirement?.declaration[key] === 'No'
          ? false
          : obj?.regulatorsRequirement?.declaration[key]
          ? 'Yes'
          : 'No';
    });

    Object.keys(obj?.regulatorsRequirement?.exposureDetails).forEach((key) => {
      Object.keys(obj?.regulatorsRequirement?.exposureDetails[key]).forEach((childKey: any) => {
        obj.regulatorsRequirement.exposureDetails[key][childKey] =
          obj?.regulatorsRequirement?.exposureDetails[key][childKey] === 'Yes'
            ? true
            : obj?.regulatorsRequirement?.exposureDetails[key][childKey] === 'No'
            ? false
            : obj?.regulatorsRequirement?.exposureDetails[key][childKey]
            ? 'Yes'
            : 'No';
      });
    });

    obj?.defaultersList.forEach((value: any) => {
      value.yesNo = value.yesNo === 'Yes' ? true : value.yesNo === 'No' ? false : value.yesNo ? 'Yes' : 'No';
    });

    return obj;
  };

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  const { control, handleSubmit, reset, watch, getValues, setValue } = useForm({
    defaultValues: {
      keyRiskTrigger: [
        {
          item: 'Exposure as % of Capital Funds',
          details: 'Single exposure as % of capital funds: > 20%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Group exposure as % of capital funds: > 25%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: 'Related Party Credit as % of Capital Funds',
          details: 'Credit to related parties  as % of  capital funds : > 10%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Deposit from related parties as % of  capital funds : > 10%	',
          crticical: 'Non-Critical',
          observed: false,
          explanation: '',
        },
        {
          item: 'Movement of Debtors including with related parties',
          details: 'Average receivables increased by 20%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Receivables > 6 months increased by 20%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Related party debtors increased by 20%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Provision for doubtful debt as % debtors increased by 15%',
          crticical: 'Critical',
          observed: '',
          explanation: '',
        },
        {
          item: 'Movement of Creditors including from related parties',
          details: 'Average creditors increased by 20%',
          crticical: 'Non-Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Related party creditors increased by 20%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Related party creditors as percentage of total creditors > 30%',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: 'Inventory Movement',
          details: 'Inventory increased by 30%',
          crticical: 'Non-Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Inventory turnover position > 2 times',
          crticical: 'Non-Critical',
          observed: false,
          explanation: '',
        },
        {
          item: 'Cash Flow position',
          details: 'Operating Cash Flow is negative',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
        {
          item: '',
          details: 'Cash profit is decreasing',
          crticical: 'Critical',
          observed: false,
          explanation: '',
        },
      ],
      auditorComments: '',
      conductOfAccount: [
        {
          particulars: 'Arrears in Cash Credit or Term loan',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Any BG invocation',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Any LC devolvement',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Overdue domestic bills in the year',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Delay in regularization of EPC availed',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Overdue export bills in the year',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Inward cheque returns due to insufficiency of funds',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
        {
          particulars: 'Temporary Overdrafts availed',
          numberOfTimes: '',
          amount: 0,
          observmaxDelayed: 0,
        },
      ],
      routingThroughUs: [
        {
          particulars: 'Other Bank',
          yesNo: false,
          details: '',
        },
        {
          particulars: 'Own Bank',
          yesNo: false,
          details: '',
        },
      ],
      regulatorsRequirement: {
        declaration: {
          realStateExposure: false,
          capitalMarketExposure: false,
          infraExposure: false,
          others: false,
          regularTransaction: false,
          borrowerLimit: false,
          prioritySector: false,
          riskExposure: false,
          intangibleAccess: false,
          ndu: false,
        },
        exposureDetails: {
          classification: {
            isCIC: false,
            isNBFC: false,
            isIFC: false,
            trans: false,
            provision: false,
            unhedged: false,
            takeOver: false,
            projectType: false,
            isClient: false,
            lendingIden: false,
          },
          companyEngagedIn: {
            norm1: false,
            norm2: false,
            norm3: false,
          },
          microAndSme: {
            sme1: false,
            sme2: false,
          },
          capitalMarketExposure: {
            cme1: false,
            cme2: false,
            cme3: false,
            cme4: false,
            cme5: false,
            cme6: false,
          },
          ofac: {
            oafc1: false,
            oafc2: false,
            oafc3: false,
            oafc4: false,
            oafc5: false,
            oafc6: false,
          },
        },
        corpGovtPractices: [
          {
            facets: 'The procedure adopted for selection, orientation and succession of Directors',
            comments: '',
          },
          {
            facets: 'The Directors and their core competence',
            comments: '',
          },
          {
            facets: 'Accounting standard followed',
            comments: '',
          },
          {
            facets: 'Existence of independent audit committee and its functioning ',
            comments: '',
          },
          {
            facets: 'Transparency in disclosure of financial information, executive compensation etc. ',
            comments: '',
          },
        ],
      },
      defaultersList: [
        {
          particulars:
            'The name of the Company or its Directors appears in RBI/CIBIL list of defaulters/RBI’s list of wilful defaulters/ credit information bureau list of defaulters..',
          yesNo: '',
          details: '',
        },
        {
          particulars: 'The Company’s name figures in ECGC’s caution list',
          yesNo: '',
          details: '',
        },
        {
          particulars:
            'If ‘Yes’ to the above, please provide details of the steps taken by the company for removal and justification for proposing assistance',
          yesNo: '',
          details: '',
        },
        {
          particulars:
            'The borrower or their partners /directors etc. are not facing any litigations which have been initiated by other lenders as on May 08, 2014',
          yesNo: '',
          details: '',
        },
        {
          particulars: 'If Yes to the above, provide rationale for recommending credit facility.',
          yesNo: '',
          details: '',
        },
        {
          particulars:
            'Is the borrower’s name appearing in the SFIO lists of “Total Cases Filed” and “Cases in CLB” ?',
          yesNo: '',
          details: '',
        },
      ],
      summaryOfTransaction: {},
    },
  });

  const watchAllFields = watch();

  const basicProps = {
    control,
    getValues,
    setValue,
    watchAllFields,
  };

  useEffect(() => {
    dispatch(toggleLoader(true));
  }, []);

  const queryData = useQueries([
    {
      queryKey: 'getComplianceData',
      queryFn: () => getCompliance(state.loanRequestId),
      onSuccess: (data: any) => {
        if (data) {
          const updatedData = updateJson(data);
          reset({ ...updatedData });
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    },
  ]);

  const mutation = useMutation((data: any) => updateCompliance(state.loanRequestId, data));

  const _renderSaveButton = () => {
    if (state.viewOnly)
      return (
        <ReviewCamButton
          onClick={() => {
            navigate(routes.CAM_ROUTE, {
              state: { ...state, loanRequestId: state.loanRequestId },
            });
            const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
            camId && camId?.id && dispatch(changeMenuTab(camId?.id));
          }}
        />
      );
    if (
      state?.statusCode === getRequestStatusCode('DRAFT') ||
      state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
      state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
      !state?.statusCode
    ) {
      return (
        <ActionBar>
          <Button type="submit" variant="contained" size="medium" sx={{ p: 1, mr: 2 }}>
            Save Draft
          </Button>
          <CamButton
            sx={{ p: 1, mr: 2 }}
            onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
            camState={state}
          />
        </ActionBar>
      );
    }
  };

  const onSubmit = (data: any) => {
    const dataObj = updateJson(data);
    dispatch(toggleLoader(true));
    dataObj.loanRequestId = state.loanRequestId;
    mutation.mutate(dataObj, {
      //@ts-ignore
      onSuccess: (res: AxiosResponse) => {
        dispatch(toggleLoader(false));
        if (res.status === StatusCodes.OK) {
          enqueueSnackbar('Successfully Updated', {
            variant: 'success',
          });
          queryData[0].refetch();
          return;
        }
        return enqueueSnackbar('Somthing went wrong', {
          variant: 'error',
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ height: 'calc(100vh - 130px)', overflowY: 'scroll', pb: 6 }}>
        <CardContent>
          <Grid container>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'8.1 Key Risk Trigger'}
                expand={true}
                children={<KeyRiskCompliance {...basicProps} />}
              />
            </Grid>
            <Grid item lg={12}>
              <SimpleAccordion
                title={'8.2 Internal Compliance Requirement'}
                children={<InternalCompliance {...basicProps} />}
              />
            </Grid>
            <Grid item lg={12}>
              <SimpleAccordion
                title={t('8.3 External Compliance')}
                children={<ExternalCompliance {...basicProps} />}
              />
            </Grid>
            <Grid item lg={12}>
              <SimpleAccordion
                title={t('regulatoryRequirement.heading')}
                children={<RegulatoryRequirement {...basicProps} />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {_renderSaveButton()}
    </form>
  );
};

export default ComplainceDetails;
