import { Box, Typography, Divider } from '@mui/material';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import NetWorkingCapitalAnalysis from './working-capital-limits/fund-based-limits/net-working-capital-analysis';
import PCFCLimitAssessment from './working-capital-limits/fund-based-limits/pcfc-limit-assesment';
import AssessedBankFinances from './working-capital-limits/fund-based-limits/assessed-bank-finances';
import LCAssessment from './working-capital-limits/non-fund-based/lc-assessment';
import BGAssesment from './working-capital-limits/non-fund-based/bg-assesment';
import DerivativeAssessment from './working-capital-limits/non-fund-based/derivative-assessment';
import TermLoans from './term-loans/term-loan';
import SourceAndUseOfFunds from './term-loans/source-and-use-of-funds';
import DetailsOfProject from './term-loans/details-of-project';
import { FormTitle } from 'components/common/form-components/FormTitle';
import ProjectIrr from './term-loans/project-irr';
import { RADIO_BUTTONS } from 'constants/form-components';
import { useLocation } from 'react-router-dom';
import { getFormFields } from 'utils/form-utils';

interface IProps extends IPerformanceSummaryTableProps {
  workingCapitalData: any;
  netWorkingCapitalAnalysisData: any;
  pcfcLimitData: any;
  lcAssessmentData: any;
  bgAssessmentData: any;
}
function AssesmentOfLimits(props: any) {
  const {
    t,
    bgAssessmentData,
    workingCapitalData,
    lcAssessmentData,
    netWorkingCapitalAnalysisData,
    pcfcLimitData,
    control,
    watchAllFields,
  } = props;
  const { state } = useLocation();
  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={t('loanProjection.performanceSummary.assesmentOfLimits')} />
        <Box sx={{ mt: 0.5 }}>
          <AssessedBankFinances {...props} data={workingCapitalData} />
          <NetWorkingCapitalAnalysis {...props} data={netWorkingCapitalAnalysisData} />
          <PCFCLimitAssessment {...props} data={pcfcLimitData} />
          {/** Non fund limits */}
          <LCAssessment {...props} data={lcAssessmentData} />
          <BGAssesment data={bgAssessmentData} {...props} />
          <DerivativeAssessment control={control} watchAllFields={watchAllFields} />
        </Box>
      </Box>

      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title={t('loanProjection.performanceSummary.termLoans')} />
        <Box sx={{ mt: 0.5 }}>
          <TermLoans {...props} />
        </Box>
      </Box>

      <Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>{t('loanProjection.performanceSummary.sourceAndUseOfFunds')}</b>
          </Typography>
          <Divider />
        </Box>
        <SourceAndUseOfFunds {...props} />
      </Box>

      <Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" sx={{ width: '26%' }}>
            <b>Project Metrices</b>
          </Typography>
          {getFormFields(
            {
              name: 'isProjectReturns',
              component: RADIO_BUTTONS,
              values: [
                {
                  label: 'Applicable',
                  value: 'true',
                },
                {
                  label: 'Non-Applicable',
                  value: 'false',
                },
              ],
            },
            control,
            state.viewOnly
          )}
        </Box>
        <Divider />
        {(watchAllFields?.isProjectReturns === 'true' || watchAllFields?.isProjectReturns === true) && (
          <ProjectIrr {...props} />
        )}
      </Box>

      <Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>{t('loanProjection.performanceSummary.detailsOfProject')}</b>
          </Typography>
          <Divider />
        </Box>
        <DetailsOfProject {...props} />
      </Box>
    </>
  );
}

export default AssesmentOfLimits;
