import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const getOverallAnalysis = async (loanId:any) => {
  const response = await httpService.get(`/visualization/view/${loanId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

export const getWCAnalysis = async (loanId:any) => {
  const response = await httpService.get(`/visualization/view/wc/${loanId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};
