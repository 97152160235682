import { Box } from '@mui/material';
import _ from 'lodash';
import { FC } from 'react';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
interface IRiskMitigation {
  setValue: any;
  getValues: any;
  watchAllFields: any;
  viewMode: any;
}

const RiskMitigation: FC<IRiskMitigation> = ({ setValue, watchAllFields, viewMode }) => {
  const onRiskMitigationChange = (newValue: any) => {
    setValue('financialAssessment.riskMitigation', newValue);
  };

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="4. Risk & Mitigation" />
      <Box sx={{ mt: 1 }}>
        <RichTextEditor
          value={watchAllFields?.financialAssessment?.riskMitigation}
          onEditorChangeHandler={onRiskMitigationChange}
          disabled={viewMode}
        />
      </Box>
    </Box>
  );
};

export default RiskMitigation;
