import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

async function registerCompany(companyData: RootObject) {
  return await httpService.post('/org', companyData, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
      'Content-Type': 'text/plain',
    },
  });
}

const getRegisteredOrgList = async () => {
  const res = await httpService.get('/org', {
    headers: { 'Content-Type': 'text/plain', Authorization: `Bearer ${getToken()}` || '' },
  });
  return res.data;
};

const getCompanyDataById = async (id: number | null) => {
  if (id === null) return [];
  const res = await httpService.get(`/org/${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` || '', 'Content-Type': 'text/plain' },
  });
  return res.data;
};

const searchCompany = async (data: any) => {
  const res = await httpService.post('/org/search', data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

export { getCompanyDataById, registerCompany, getRegisteredOrgList, searchCompany };

export interface OrgContact {
  id?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  contactEmail: string;
  designation: string;
}

export interface OrgAddress {
  id?: number;
  state: string;
  city: string;
  zipCode: string;
  country: string;
  updatedBy?: any;
}

export interface OrgDocument {
  id?: number;
  docFileName: string;
  docLocation: string;
  docTypeId: number;
  docFormatTypeId: number;
  docXrefTypeId: number;
}

export interface RootObject {
  orgName: string;
  orgEmail: string;
  orgTypeId: number;
  industryTypeId: number;
  subIndustryTypeId: number;
  panNumber: string;
  isin: string;
  description: string;
  updatedBy: string;
  orgContact: OrgContact[];
  orgAddress: OrgAddress[];
  orgDocuments: OrgDocument[];
  id?: number;
}
