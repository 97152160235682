import { useEffect } from 'react';
import { Container, Grid, Card, Typography, Box, Divider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactECharts from 'echarts-for-react'; 
import { getWCAnalysis } from 'services/visualization-services';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toggleLoader } from 'state/reducers/common-actions';
import { useDispatch } from 'react-redux';
import { arTurnOverOption, arReceivableOption, dioOption, dsoOption, dpoOption, cashConversionCycleOption, breakupData  } from "./wcData";

const WCVisualization = (theme: any) => {

  const dispatch = useDispatch();
  const { state } = useLocation();  
  const { data, isLoading, isSuccess } = useQuery(['getWCAnalysis',state?.loanRequestId], () => getWCAnalysis(state?.loanRequestId));

  const columnDefs =  [
  {
    headerName: 'Breakup of Current Assets & Liabilities',
    field: "header",
    children: [
      {
        field: "breakup",     
        headerName: "",
        cellStyle: { "textAlign": "left" },
        cellRendererFramework: (params: any) => {
            return ['Current Assets', 'Total Current Assets', 'Current Liabilities', 'Total Current Liabilities'].includes(params.data.breakup) ?              
                (<div><b>{params?.data?.breakup}</b></div>)
               : 
                (<div>{params?.data?.breakup}</div>)
              
        }
      },
      { 
          field: "data",
          cellStyle: { "textAlign": "right" },
          headerName: '', 
      }
    ]
  }
];


  useEffect(()=>{
    if(isLoading){
       dispatch(toggleLoader(true))
    }else {
      dispatch(toggleLoader(false))
    }
    
  },[isLoading])

  return (
        <>
          {isSuccess && !data && (
              <Alert severity="warning" sx={{ height: '100vh' }}>
                <AlertTitle>
                  <Typography variant="h6">Warning</Typography>
                </AlertTitle>
                <Typography variant="subtitle1">No data available.</Typography>
              </Alert>
            )}
          {isSuccess && data && (
      <Container sx={{ p: 2, width: "80%", "height": "100vh" }}>
        <Grid container spacing={3} sx={{ "justifyContent": "center" }}>
          <Grid item xs={2}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.light,
             
              }}             
            >            
              <Typography variant="h3">{data?.workingCapitalStructure?.inventoryTurnover}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Inventory Turnover
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={2}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.light,
             
              }}             
            >
            
              <Typography variant="h3">{data?.workingCapitalStructure?.receivablesTurnover}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Receivables Turnover
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={2}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.light,
             
              }}             
            >
            
              <Typography variant="h3">{data?.workingCapitalStructure?.accountPayableTurnover}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Accounts Payable Turnover
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={2}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.light,
             
              }}             
            >
            
              <Typography variant="h3">{data?.workingCapitalStructure?.netSalesToTotalAssets}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Net Sales to Total Assets
              </Typography>
            </Card>
          </Grid>  

          <Grid item xs={4}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.light,
             
              }}             
            >
            
              <Typography variant="h3">{data?.workingCapitalStructure?.netSalesToNetWorth}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                Net Sales to Net Worth
              </Typography>
            </Card>

          </Grid>

          <Grid item xs={7}>
            <div style={{ "width": "100%", "height": "650px", }} className="ag-theme-alpine">
              <AgGridReact columnDefs={columnDefs} rowData={breakupData(data?.breakupData)} onGridReady={(event: any) => event.api.sizeColumnsToFit()}></AgGridReact>
            </div>
          </Grid>
           <Grid item xs={5}>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                <Box sx={{m:2}}>
                  <ReactECharts
                    option={arTurnOverOption(data?.arTurnOverOption)}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{m:2}}>
                  <ReactECharts
                    option={arReceivableOption(data?.arReceivableOption)}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                </Box>
               </Grid>
               </Grid>
            </Grid>
           <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs>
            <Box sx={{textAlign:"center"}}>
              <Typography gutterBottom variant="h4" component="div" >
                Working Capital Management
              </Typography>            
              </Box>
            </Grid>
            <Divider variant="middle" />
         </Grid>
         <Grid item xs={12}>
         <Grid container spacing={2}>
           <Grid item xs={3}>
            <Box sx={{m:1}}>
              <ReactECharts
                option={dioOption(data?.dioOption)}
                notMerge={true}
                lazyUpdate={true}
              />
              </Box>
            </Grid>
            <Grid item xs={3}>
            <Box sx={{m:1}}>
              <ReactECharts
                option={dsoOption(data?.dsoOption)}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
           </Grid>
           <Grid item xs={3}>
            <Box sx={{m:1}}>
            <ReactECharts
              option={dpoOption(data?.dpoOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
            <Grid item xs={3}>
            <Box sx={{m:1}}>
            <ReactECharts
              option={cashConversionCycleOption(data?.cashConversionCycleOption)}
              notMerge={true}
              lazyUpdate={true}
            />
            </Box>
           </Grid>
           </Grid>
         </Grid>          
       </Grid>          
        </Grid>
         </Container>
      )}
    </>
  );
};

export default WCVisualization;
