import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import _ from 'lodash';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import TableCommentRow from 'components/common/table/table-comment-row';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import CustomTableContainer from '../../../../../../common/table/CustomTableContainer';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function EbidtaAnalysis({ t, totalYears, data, control }: IProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.ebidtaAnalysis')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Breakup of cost heads</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>Raw Materials (including consumables)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlRawMaterial)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Allowance for Inventory (-)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlAllowanceForInventory)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Power and Fuel</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlPowerAndFuel)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Direct Labour (Factory wages & salaries)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlDirectLabour)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Other manufacturing expenses</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlOtherManuExpense)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Change in Inventory</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlChangeInInventory)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>Cost of Goods Sold</HeadingDenseCell>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell heading key={i}>
                  {formatNumber(data[i].costOfGoodsSold)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Selling, General & Admin expense</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].pnlSellingGeneralAdminExp)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <HeadingDenseCell>EBIDTA%</HeadingDenseCell>
            {_.map(totalYears, (_, i: number) => {
              return (
                <NumberDenseCell heading key={i}>
                  {formatNumber(data[i].pnlEbitda)}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          {/* <TableCommentRow title="Comments" fieldName="ebitdaAnalysis.comments" control={control} /> */}
        </TableBody>
      </Table>
    </Box>
  );
}
