import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import { COLOR_WHITE, PRIMARY_COLOR } from 'constants/color';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { SubRow, SubSubRow } from '../../../../../common/table/table-data-row/rows';

const FormBS = () => {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();
  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formV'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );
  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form5, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form5[idx].actuals) {
      return data?.form5[idx].actuals;
    }
    return data?.form5[idx]?.projection;
  });

  return (
    <Table size="small" sx={{ width: '100%' }}>
      <TableHead>
        <TableRow sx={{ backgroundColor: PRIMARY_COLOR }}>
          <HeadingDenseCell
            sx={{
              color: COLOR_WHITE,
              '&.MuiTableCell-root': {
                width: 500,
              },
            }}>
            As of March 31,
          </HeadingDenseCell>
          {_.map(numberOfYears, (year: string, idx: number) => {
            return (
              <HeadingDenseCell sx={{ color: COLOR_WHITE }} align="right" key={`$${year}-${idx}`}>
                {year}
              </HeadingDenseCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <HeadingDenseCell colSpan={10}>ASSETS</HeadingDenseCell>
        </TableRow>
        <TableRow>
          <HeadingDenseCell colSpan={10}>Financial assets </HeadingDenseCell>
        </TableRow>
        <SubRow
          title="Cash & Cash Equivalents"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Bank balances other than cash & cash equivalents"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.otherCurrentLiabilities}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Derivative financial instruments"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.workingCapitalGap}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Trade recievables"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.minStipulatedNetWorkingCapital}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Loans"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.actualNetWorkingCapital}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Investments"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.item3MinusItem4}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Other financial assets"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.item3MinusItem5}
            </DenseCellSm>
          ))}
        />

        <TableRow>
          <HeadingDenseCell colSpan={10}>Non-financial assets</HeadingDenseCell>
        </TableRow>
        <SubRow
          title="Current tax assets (net)"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Deferred tax assets (net)"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.otherCurrentLiabilities}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Property plant and equipment"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.workingCapitalGap}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Capital work-in process"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.minStipulatedNetWorkingCapital}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Intangible assets under development"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.actualNetWorkingCapital}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Other non-financial assets"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].secondMethodOfLending.item3MinusItem4}
            </DenseCellSm>
          ))}
        />
        <TableRow>
          <HeadingDenseCell>Total assets</HeadingDenseCell>
          {_.map(numberOfYears, (_, idx: number) => {
            return (
              <DenseCellSm key={idx} align="right">
                {dataArr[idx].secondMethodOfLending.item3MinusItem4}
              </DenseCellSm>
            );
          })}
        </TableRow>
        <TableRow>
          <HeadingDenseCell colSpan={10}>LIABILITIES & EQUITY</HeadingDenseCell>
        </TableRow>
        <TableRow>
          <HeadingDenseCell colSpan={10}>Liabilities </HeadingDenseCell>
        </TableRow>
        <SubRow
          title="Financial liabilities"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Derivative financial instruments"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Payables"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubSubRow
          title="Trade payables"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.item3MinusItem5}
            </DenseCellSm>
          ))}
        />
        <SubSubRow
          title="Other payables"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.item3MinusItem5}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Debt securities"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Borrowings (other than debt securities)"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Deposits"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Subordinated debts"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
        <SubRow
          title="Other financial liabilities"
          data={_.map(numberOfYears, (_, idx: number) => (
            <DenseCellSm key={`${idx}`} align="right">
              {dataArr[idx].firstMethodOfLending.totalCurrentAsset}
            </DenseCellSm>
          ))}
        />
      </TableBody>
    </Table>
  );
};

export default FormBS;
