import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/strings';

const CamButton = ({ sx, onClick, camState }: any) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    onClick();
    navigate(routes.CAM_ROUTE, {
      state: { ...camState },
    });
  };

  return (
    <Button variant="contained" sx={sx} onClick={onClickHandler}>
      Review CAM
    </Button>
  );
};

export default CamButton;
