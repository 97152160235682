import * as React from 'react';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import fontSize from 'constants/fonts';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import {
  createProduct,
  createRawMaterial,
  createNonConsumerItem,
  createConsumerItem,
} from 'services/rawMaterials-products-services';
import useRefData from 'hooks/ref-data';

const filter = createFilterOptions<any>();

export default function AutocompleteWithCreate({
  label,
  options,
  name,
  value,
  setValue,
  disabled = false,
  rules,
  ...input
}: {
  label: string;
  options: any;
  name: string;
  value: any;
  setValue: any;
  disabled?: boolean;
  rules?: any;
}) {
  const { fn } = useRefData();
  const { mapProjectionTemplate } = fn;
  const [open, toggleOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const mutationFn =
    label === 'Products'
      ? createProduct
      : label === 'Raw Materials'
      ? createRawMaterial
      : label === 'Retail: Non-consumer'
      ? createNonConsumerItem
      : createConsumerItem;

  //@ts-ignore
  const mutation = useMutation(mutationFn);

  const handleClose = () => {
    setDialogValue({
      displayText: '',
      description: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    displayText: '',
    description: '',
  });

  const handleSubmit = () => {
    mutation.mutate(
      { ...dialogValue },
      {
        onSuccess: (result) => {
          setValue((prevVal: any) => [
            ...prevVal,
            {
              ...result?.data,
            },
          ]);
          options.push(result?.data);
          queryClient.invalidateQueries([
            'getAllProducts',
            'getAllRawMaterials',
            'getAllNonConsumerItems',
            'getAllConsumerItems',
          ]);
        },
      }
    );

    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        value={value}
        disabled={disabled}
        onChange={(event, newValue: any) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                displayText: newValue,
                description: '',
              });
            });
          } else if (newValue && newValue.length && newValue[newValue.length - 1].inputValue) {
            toggleOpen(true);
            setDialogValue({
              displayText: newValue[newValue.length - 1].inputValue,
              description: '',
            });
          } else {
            setValue(newValue);
          }
        }}
        isOptionEqualToValue={(option, value) => option?.displayText === value?.displayText}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              displayText: `Add ${params.inputValue}`,
              inputValue: `${params.inputValue}`,
            });
          }
          return filtered;
        }}
        options={options || []}
        getOptionLabel={(option) => {
          return option.displayText;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>
            <Typography>{option.displayText}</Typography>
          </li>
        )}
        sx={{
          padding: 0,
          '& .MuiAutocomplete-inputRoot': {
            padding: '7px 11px',
          },
          '& .MuiChip-label': {
            fontSize: '12px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
          },
          width: '33rem',
        }}
        // freeSolo
        renderInput={(params) => <TextField sx={input} {...params} />}
      />

      {/** Dialog-- for adding option */}
      <Dialog open={open}>
        <DialogTitle>{`Add a new ${label}`}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Did you miss any film in our list? Please, add it!</DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.displayText}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                displayText: event.target.value,
              })
            }
            label="Display Text"
            type="text"
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.description}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                description: event.target.value,
              })
            }
            label="Description"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

interface OptionType {
  id?: string;
  displayText: string;
  description?: string;
  title?: string;
  inputValue?: string;
}
