import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import _ from 'lodash';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function AssessedBankFinances({ t, totalYears, data, control }: IProps) {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>Working Capital: Fund based Limits</b>
          </Typography>
          <Divider />
        </Box>
        <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
          <Typography variant="caption">
            <b>Assessed Bank Finance (ABF)</b>
          </Typography>
          <Divider />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#F8F8F8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Particulars</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  key={year}
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <DenseCellSm>Sales (Net)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].netSales)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Total Current Assets (TCA)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].totalCurrentAsset)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Current Liabilities (other than bank borrowings)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return (
                  <NumberDenseCell key={i}>
                    {formatNumber(data[i].currentLiabilitiesOthThnBankBorrowing)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Working Capital Gap</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].workingCapitalGap)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Net working capital</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].netWorkingCapital)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Assessed Bank Finance (ABF)</DenseCellSm>
              {_.map(totalYears, (_, i: number) => {
                return <NumberDenseCell key={i}>{formatNumber(data[i].assessedBankFinance)}</NumberDenseCell>;
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
