import { Box } from '@mui/material';
// import TooltipIconButton from 'components/common/tooltip-icon-button';
// import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
// import DataTable from 'components/common/data-table';
// import { useMemo } from 'react';
// import { Delete } from '@mui/icons-material';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';

const ProposedDeviations = ({ watchAllFields, setValue }: any) => {
  const { state } = useLocation();

  // const defaultColDefs = useMemo(() => {
  //   let colDef: any = [
  //     {
  //       field: 'item',
  //       headerName: 'Item',
  //       width: 200,
  //       editable: state?.isEdit,
  //     },
  //     {
  //       field: 'standard',
  //       headerName: 'Standard',
  //       width: 350,
  //       editable: state?.isEdit,
  //     },
  //     {
  //       field: 'revised',
  //       headerName: 'Revised',
  //       width: 350,
  //       editable: state?.isEdit,
  //     },
  //   ];
  //   if (state?.isEdit) {
  //     colDef.push({
  //       field: '',
  //       headerName: 'Actions',
  //       width: 100,
  //       pinned: 'right',
  //       suppressSizeToFit: true,
  //       cellRendererFramework: (params: any) => {
  //         return (
  //           <TooltipIconButton
  //             onClick={(e: any) => {
  //               let updatedFields = [...getValues('proposedDeviationsCurrentSanction')];
  //               if (updatedFields.length > 0) {
  //                 updatedFields.splice(params.node.rowIndex, 1);
  //                 setValue('proposedDeviationsCurrentSanction', updatedFields);
  //                 params.api.setRowData(updatedFields);
  //               }
  //             }}
  //             icon={<Delete fontSize="small" />}
  //             title="Delete"
  //           />
  //         );
  //       },
  //     });
  //   }
  //   return colDef;
  // }, []);

  // const defaultPostSanctionColDefs = useMemo(() => {
  //   let colDef: any = [
  //     {
  //       field: 'item',
  //       headerName: 'Item',
  //       width: 200,
  //       editable: state?.isEdit,
  //     },
  //     {
  //       field: 'standard',
  //       headerName: 'Standard',
  //       width: 350,
  //       editable: state?.isEdit,
  //     },
  //     {
  //       field: 'revised',
  //       headerName: 'Revised',
  //       width: 350,
  //       editable: state?.isEdit,
  //     },
  //   ];
  //   if (state?.isEdit) {
  //     colDef.push({
  //       field: '',
  //       headerName: 'Actions',
  //       width: 100,
  //       pinned: 'right',
  //       suppressSizeToFit: true,
  //       cellRendererFramework: (params: any) => {
  //         return (
  //           <TooltipIconButton
  //             onClick={(e: any) => {
  //               let updatedFields = [...getValues('proposedDeviationsPostSanction')];
  //               if (updatedFields.length > 0) {
  //                 updatedFields.splice(params.node.rowIndex, 1);
  //                 setValue('proposedDeviationsPostSanction', updatedFields);
  //                 params.api.setRowData(updatedFields);
  //               }
  //             }}
  //             icon={<Delete fontSize="small" />}
  //             title="Delete"
  //           />
  //         );
  //       },
  //     });
  //   }
  //   return colDef;
  // }, []);

  // const _renderAddButton = (buttonType: string) => {
  //   if (!state?.isEdit) return;
  //   return (
  //     <Box sx={{ width: '100%' }}>
  //       <Button
  //         sx={{ p: 0, pl: 1, pr: 1, mt: 1, mb: 1, float: 'right' }}
  //         onClick={() => {
  //           return setValue(buttonType, [
  //             ...watchAllFields[buttonType],
  //             {
  //               item: '',
  //               standard: '',
  //               revised: '',
  //             },
  //           ]);
  //         }}
  //         variant="outlined"
  //         startIcon={<AddIcon fontSize="small" />}>
  //         Add new row
  //       </Button>
  //     </Box>
  //   );
  // };

  return (
    <>
      <RichTextEditor
        value={watchAllFields?.proposedDeviations}
        onEditorChangeHandler={(newValue: any) => {
          setValue('proposedDeviations', newValue);
        }}
        disabled={!state?.isEdit}
      />
      {/* <Box sx={{ pl: 0.5, pr: 0.5, mt: 1 }}>
        <FormTitle title="A. Current Sanction" />
        <Box sx={{ height: 400, mt: 1 }}>
          
          <DataTable
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={watchAllFields?.proposedDeviationsCurrentSanction}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
          />
        </Box>
        {_renderAddButton('proposedDeviationsCurrentSanction')}
      </Box> */}
      {/* <Box sx={{ pl: 0.5, pr: 0.5, mt: 7 }}>
        <FormTitle title="B. Post-Sanction Matters" />
        <Box sx={{ height: 400, mt: 1 }}>
          <DataTable
            columnDefs={defaultPostSanctionColDefs}
            autoSize={true}
            rowData={watchAllFields?.proposedDeviationsPostSanction}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
          />
        </Box>
        {_renderAddButton('proposedDeviationsPostSanction')}
      </Box> */}
    </>
  );
};

export default ProposedDeviations;
