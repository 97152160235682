export const COMPANY = 'COMPANY';
export const REQUEST = 'CallMadeOutlinedIcon';
export const STATUS = 'TrendingUpOutlinedIcon';
export const EXPOSURE = 'ExposureOutlinedIcon';
export const RIGHT_ARROW = 'ChevronLeftIcon';
export const LEFT_ARROW = 'ChevronRightIcon';
export const PENDING = 'PENDING';
export const MEMO = 'MEMO';
export const LOAN_PROPOSAL = 'LOAN_PROPOSAL';
export const DASHBOARD = 'DASHBOARD';
export const COMMUNICATION = 'COMMUNICATION';
export const DISBURSMENT = 'DISBURSMENT';
export const COMPLIANCES = 'COMPLIANCES';
export const STATUS_UPDATE = 'STATUS_UPDATE';
export const PORTFOLIO = 'PORTFOLIO';
export const RiSK_RATIONALE = 'RiSK_RATIONALE';
export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';
export const APPROVAL = 'APPROVAL';
export const LOGOUT = 'LOGOUT';
export const CHECKED = 'CHECKED';
export const UNCHECKED = 'UNCHECKED';
export const NEW_LOAN = 'NEW_LOAN';
export const GO_BACK = 'GO_BACK';
export const MY_REQUEST = 'MY_REQUEST';
export const CLOSED = 'CLOSED';

