import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMainMenu {
  tabs: any[];
  selectedTab: number;
  applicantBasicDetails: any;
}

const initialState: IMainMenu = {
  tabs: [], //{ id: '1', projectionName: 'projection 1' }
  selectedTab: 0,
  applicantBasicDetails: {},
};

const projections = createSlice({
  name: 'projections',
  initialState,
  reducers: {
    createProjection: (state: IMainMenu, action: PayloadAction<any>) => {
      return {
        ...state,
        tabs: [...state.tabs, action.payload],
      };
    },
    updateTabs: (state: IMainMenu, action: PayloadAction<any>) => {
      // const formattedData = _.map(action.payload.tabs, (d) => JSON.parse(d));
      return {
        ...state,
        tabs: action.payload.tabs,
      };
    },
    updateSelectedTab: (state: IMainMenu, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    storeApplicantBasicDetail: (state: IMainMenu, action: PayloadAction<any>) => {
      state.applicantBasicDetails = action.payload;
    },
  },
});

const { actions, reducer } = projections;

export const { createProjection, updateTabs, updateSelectedTab, storeApplicantBasicDetail } = actions;
export default reducer;
