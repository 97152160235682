import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const getDocuments = async (loanReqId: number) => {
  try {
    const response = await httpService.get(`/docs/metaByXref/LOAN_REQUEST/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getDocuments: ERROR', error);
  }
};

export const getRiskDocuments = async (loanReqId: number) => {
  try {
    const response = await httpService.get(`/docs/metaByXref/risk/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getDocuments: ERROR', error);
  }
};

export const viewDocument = async (docId: string) => {
  try {
    const response = await httpService.get(`/docs/byId/${docId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('viewDocument: ERROR', error);
  }
};

export const downloadDocument = async (docDetails: any) => {
  try {
    const response = await httpService.get(`/docs/byId/${docDetails?.id}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    let responseType = response.headers['content-type']    
    
    let url = URL.createObjectURL(new Blob([response?.data],{
      type: responseType
    }));
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute("download", docDetails?.docFileName);
    a.click();
    a.remove();    
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('downloadDocument: ERROR', error);
  }
};

export const uploadDocument = async (docId: string, file: any): Promise<Boolean> => {
  let upload: Boolean = false;
  try {
    const formData = new FormData();
    formData.append('file', file);

    await httpService.post(`/docs/content/${docId}`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
        'Content-Type': 'multipart/form-data',
      },
    });
    upload = true;
  } catch (error) {
    upload = false;
    console.error('uploadDocument: ERROR', error);
  }
  return upload;
};

export const saveDocumentMeta = async (data: any) => {
  const res = await httpService.post('/docs/saveMetaData', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

export const getMeta = async (loanReqId: number, docType: string, type: string) => {
  try {
    const response = await httpService.get(`/docs/byDocType/${docType}/${type}/${loanReqId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getCAMSPDF: ERROR', error);
  }
};

export const getDocumentList = async (type: string) => {
  try {
    const response = await httpService.get(`/docs/metaByXref/${type}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getDocuments: ERROR', error);
  }
};


export const getDocumentsByCodeAndId = async (code: string, typeId: number) => {
  try {
    const response = await httpService.get(`/docs/metaByXref/${code}/${typeId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('getDocumentsByCodeAndId: ERROR', error);
  }
};


export const deleteDocument = async (docId: number) => {
  try {
    const response = await httpService.get(`/docs/delete/${docId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}` || '',
      },
    });
    return response.data;
  } catch (error) {
    console.error('deleteDocument: ERROR', error);
  }
};

