import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMainMenu {
  selectedIndex: number;
}

const initialState: IMainMenu = {
  selectedIndex: 1,
};

const tabSlice = createSlice({
  name: 'mainMenu',
  initialState,
  reducers: {
    changeMenuTab: (state: IMainMenu, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
  },
});

const { actions, reducer } = tabSlice;

export const { changeMenuTab } = actions;
export default reducer;
