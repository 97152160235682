import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box } from '@mui/material';
import TableHeading from 'components/common/table-header';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import SubCell from 'components/common/table/subcell';
import _ from 'lodash';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import { FormTitle } from 'components/common/form-components/FormTitle';
interface IProps {
  yearEnded: string;
  data: any;
  totalYears: string[];
  title: string;
}

export default function ({ yearEnded, data, totalYears, title }: IProps) {
  console.log('yearEnded', yearEnded, 'data', data, 'totalYears', totalYears);
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={title} />
      <Box sx={{ mt: 0.5 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#F8F8F8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Year Ended {yearEnded}</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  align="center"
                  key={year}
                  sx={{
                    minWidth: '20px',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    background: '#F8F8F8',
                  }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <HeadingDenseCell>Manufacturing Sales</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.manufacturingSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Trading Sales</HeadingDenseCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.tradingSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Service Sales</DenseCellSm>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.serviceSales.serviceSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Of Which Exports</DenseCellSm>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.serviceSales.OfWhichExports)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Gross Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.grossSales)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Net Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.netSales)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Other Net Operating Income</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.otherNetOperatingIncome)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Total Operating Income</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.totalOperatingIncome)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Manufacturing as % Gross Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.manufacturingAsGrossSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Trading Sales as % of Gross Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.tradingSalesAsGrossSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Service Sales as % of Gross Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.serviceSalesAsGrossSales.serviceSalesAsGrossSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Exports as % of Gross Sales</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.serviceSalesAsGrossSales.exportAsGrossSales)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Cost of Production</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.costOfProduction)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Cost of Goods Sold</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.costOfGoodsSold)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Cost of Goods Sold</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.costOfGoodsSold)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Gross Margin</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.grossMargin)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>EBITDA</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.ebitda)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Depreciation & Amortisation</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.depreciationAndAmortization)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>EBIT</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.ebit)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Interest</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.interest)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Net Non Operating Income/Expense</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.netNonOperatingIncExp)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Profit Before Tax (PBT)</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.profitBeforeTax)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Profit After tax (PAT)</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.profitAfterTax)}</NumberDenseCell>;
              })}
            </TableRow>

            <TableRow>
              <DenseCellSm>Cash Profit</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.cashProfit)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Net Cash Accruals (NCA)</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.netCashAccruals)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Networth</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.totalNetWorth)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Tangible Networth (TNW)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.tangibleNetWorth.value)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Of which Quasi Equity</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.tangibleNetWorth.ofWhichQuasiEquity)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Exposure in subsidiaries/Group Cos.</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.exposureInSubsidiaries.value)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Investment</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.exposureInSubsidiaries.investment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Loans and advances</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.exposureInSubsidiaries.loanAndAdvances)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Adjusted TNW (ATNW)</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.adjustedTNW)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Long term debt (LTD) (A)</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.longTermDebts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Short term debt (STD) (B)</HeadingDenseCell>

              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.shortTermDebts.shortTermDebts)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Current portion of LTD-CPLTD (B1)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.shortTermDebts.currentPortion)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Other working capital STD (B2)
              </SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.shortTermDebts.projOtherWorkingCapital)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>WC bank finance (C)</HeadingDenseCell>

              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.wcBankFinance)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Debt (w/o Gtee)</SubCell>
              {_.map(totalYears, (_, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.totalDebtWithoutGtee)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Guarantee* (D)</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.guarantee)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <DenseCellSm>Total Debt</DenseCellSm>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.totalDebt)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Total Assets</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.totalAssetsInSummary.totalAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Total current assets (excl. Cash & Investment)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalAssetsInSummary.totalCurrentAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Cash & Investment</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalAssetsInSummary.cashAndInvestment)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net fixed assets</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalAssetsInSummary.netFixedAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <HeadingDenseCell>Total Liabilities</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index} heading>
                    {formatNumber(data[index]?.totalLiabilities.value)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Total Current Liabilities
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalLiabilities.totalCurrentLiabilities)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Total Outside Term Liabilities (TOL)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalLiabilities.totalOutsideTermLiabilities)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Networth</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.totalLiabilities.totalNetWorthInLiabilities)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
