import { FC, useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Typography } from '@mui/material';

// components
import { useTranslation } from 'react-i18next';
import MenuPopover from 'components/common/pages/MenuPopover';
import enIcon from '../../../assets/icons/ic_flag_en.svg';
import deIcon from '../../../assets/icons/ic_flag_de.svg';
import language from '../../../assets/icons/language.png';

interface IProps {}

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: enIcon,
  },
  {
    value: 'de',
    label: 'German',
    icon: deIcon,
  },
];

const LanguagePopover: FC<IProps> = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ ...LANGS[0] });
  const { i18n } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const languageHandler = (option: any) => {
    setSelectedLanguage({ ...option });
    i18n.changeLanguage(option.value);
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 32,
          height: 32,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}>
        <img src={language} alt="Language" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.0,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}>
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedLanguage.value}
              onClick={() => languageHandler(option)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default LanguagePopover;
