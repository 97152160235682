import _ from 'lodash';
import {
  DROPDOWN,
  MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
  MULTI_SELECT_AUTOCOMPLETE,
  RADIO_BUTTONS,
  INPUT,
  YEAR_PICKER,
} from '../../../../../constants/form-components';
import useRefData from 'hooks/ref-data';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';

const useBasicDetails = (isPopup: boolean, projectionTemplate: string) => {
  const { refData, fn } = useRefData();
  const { mapProjectionTemplate } = fn;
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const tab = isPopup ? selectedTab - 1 : selectedTab;
  const projectionType = projectionTemplate || tabs![tab]?.projectionTemplate;
  const annualYears = _.range(new Date().getFullYear() - 4, new Date().getFullYear() + 1).map(
    (year) => new Object({ label: year + '', value: year + '', displayText: year + '' })
  );

  const fy = refData.financialYearTypes;
  const fiscalYear: any = _.map(fy, (fy) => {
    return { label: fy.displayText, value: fy.id };
  });

  let fields = [
    {
      label: 'Is Applicant',
      name: 'isApplicant',
      component: RADIO_BUTTONS,
      radioGroup: 'isApplicant',
      values: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      disbaled: true,
    },
    { label: 'Projection Name', name: 'projectionName', component: INPUT },
    {
      label: 'Projection Template',
      name: 'projectionTemplate',
      component: DROPDOWN,
      options: refData.projectionTemplate || [],
      required: true,
    },
    {
      label: 'Fiscal Year',
      name: 'fiscalYearTypeId',
      component: RADIO_BUTTONS,
      radioGroup: 'fiscalYear',
      values: fiscalYear,
    },
    {
      label: 'Moratorium for Term Loan',
      name: 'moratoriumType',
      component: DROPDOWN,
      options: refData.typeOfMoratorium || [],
      required: true,
    },
    { label: 'Moratorium Period (Years)', name: 'moratoriumPeriod', component: INPUT, required: true },
    {
      label: 'Payment Type',
      name: 'paymentType',
      component: DROPDOWN,
      options: refData.paymentType || [],
      required: true,
    },

    {
      label: 'Interest Calculation',
      name: 'interestCalculation',
      component: DROPDOWN,
      options: refData.interestCalcTypes || [],
      required: true,
    },
    {
      label: 'Depreciation Method',
      name: 'depricationTypeId',
      component: DROPDOWN,
      options: refData.depreciationMethods,
      required: true,
    },
    {
      label: 'Audited Annual Report available for',
      name: 'annualYears',
      component: MULTI_SELECT_AUTOCOMPLETE,
      options: annualYears || [],
      required: true,
    },
    {
      label: 'Starting Year for Projections',
      name: 'startYear',
      component: YEAR_PICKER,
      required: true,
    },
    { label: 'Ending Year', name: 'endYear', component: YEAR_PICKER, required: true },
  ];

  if (mapProjectionTemplate(projectionType) == 'Manufacturing') {
    fields = [
      ...fields,
      {
        label: 'Products',
        name: 'products',
        component: MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
        options: [],
        required: false,
      },
      {
        label: 'Raw Materials',
        options: [],
        name: 'rawMaterials',
        component: MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
        required: false,
      },
    ];
  } else if (mapProjectionTemplate(projectionType) === 'Finance') {
    fields = [
      ...fields,
      {
        label: 'Retail: Non-consumer',
        name: 'products',
        component: MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
        options: [],
        required: false,
      },
      {
        label: 'Retail: Consumer',
        options: [],
        name: 'rawMaterials',
        component: MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
        required: false,
      },
    ];
  }
  return fields;
};

export { useBasicDetails };
