import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import _ from 'lodash';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import { formatNumber } from 'utils/common-utils';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import { getFormFields } from 'utils/form-utils';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function BGAssesment({ t, totalYears, data, control }: IProps) {
  const { state } = useLocation();
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.bgAssesment')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Particulars</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>
              <b>Import requirement</b>
            </DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <DenseCellSm key={i}>&nbsp;</DenseCellSm>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Net Sales</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].netSales)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Estimated Sales for which BG would be issued (5% of aggregate turnover)-A</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].estimatedSaleForBg)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Estimated purchase under LC</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].estimatedPurchaseUnderLc)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Aggregate guarantee @ 10% (as % of net sales) - B</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].aggregatedGuarantee)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Add: Existing guarantees- C</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].addExistingGuarantee)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Less: Guarantees maturing during year (D)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].lessMaturingGuarantee)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Limit assessed ( B+C-D)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].limitAssessed)}</NumberDenseCell>;
            })}
          </TableRow>
          {/* <TableRow>
            <DenseCellSm>Limit proposed</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].limitProposed)}</NumberDenseCell>;
            })}
          </TableRow> */}
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <SimpleFormLabel label={'Limit Proposed'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
        <Box sx={{ width: '75%' }}>
          {getFormFields(
            {
              name: 'assessmentLimits.bgLimit',
              component: 'textField',
              type: 'number',
            },
            control,
            state.viewOnly
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 1, mb: 2 }}>
        <SimpleFormLabel label={'Comment'} sx={{ width: '25%', placeSelf: 'flex-start' }} />
        <Box sx={{ width: '75%' }}>
          {getFormFields(
            {
              name: 'assessmentLimits.commentsNonFundBased',
              component: MULTI_LINE_INPUT,
              label: 'Comment',
            },
            control,
            state.viewOnly
          )}
        </Box>
      </Box>
    </Box>
  );
}
