import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropLoader = ({ isLoading }: { isLoading: boolean }) => {

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoader;
