import { FormLabel, Grid, styled, Typography, Box } from '@mui/material';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { Control } from 'react-hook-form';
import fontSize from '../../../../constants/fonts';
import { MULTI_LINE_INPUT } from '../../../../constants/form-components';
import { IFormSchema } from '../../../../models/forms-schema-types';
import { getFormFields } from '../../../../utils/form-utils';

interface IGridFormProps {
  input: IFormSchema;
  control: Control<any>;
  disabled?: boolean;
  required?: boolean;
}

export const StyledGridItem = styled(Grid)(() => ({
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
}));

const _renderForm = (input: IFormSchema, control: Control<any>, disabled: boolean) => {
  if (input.component === MULTI_LINE_INPUT) {
    return (
      <StyledGridItem item xs={10} md={10}>
        {getFormFields(input, control, disabled)}
      </StyledGridItem>
    );
  }

  return (
    <StyledGridItem item xs={4} md={4}>
      {getFormFields(input, control, disabled)}
    </StyledGridItem>
  );
};

export default function GridForm({ input, control, disabled = false, required }: IGridFormProps) {
  return (
    <>
      <Grid item xs={6}>
        <Box className={required ? 'required' : ''} sx={{ display: 'flex' }}>
          <SimpleFormLabel label={input.label} sx={{ width: '40%' }} />
          {getFormFields(input, control)}
        </Box>
      </Grid>
    </>
  );
}

export function GridSingleForm({ input, control, disabled = false }: IGridFormProps) {
  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <SimpleFormLabel label={input.label} sx={{ minWidth: '20%' }} />
          {getFormFields(input, control, disabled)}
        </Box>
      </Grid>
    </>
  );
}
