import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITabSchema } from 'models/common';
import { RootState } from 'state/reducers';
import { changeMenuTab } from 'state/reducers/tabReducer';

function a11yProps(id: any) {
  return {
    itemNumber: id,
    'aria-controls': `simple-tabpanel-${id}`,
  };
}

const MENU_SCHEMA: any = [
  {
    id: 1,
    name: 'Risk Rationale',
    pathName: 'risk-rationale',
    value: '/dashboard/approval-authority/approval/risk-rationale',
  },
  { id: 2, name: 'CAM', pathName: 'approval', value: '/dashboard/approval-authority/approval' },
  {
    id: 3,
    name: 'Visualisation',
    pathName: 'visualisation',
    value: '/dashboard/approval-authority/approval/authority-visualisation',
  },
   {
    id: 4,
    name: 'Documents',
    pathName: 'authority-documents',
    value: '/dashboard/approval-authority/approval/authority-documents',
  },
];

const ApprovalMainMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const theme = useTheme();
  const { selectedIndex }: any = useSelector((state: RootState) => state.mainMenu);

  useEffect(() => {
    for (let i = 0; i < MENU_SCHEMA.length; i++) {
      if (pathname === MENU_SCHEMA[i].value) {
        dispatch(changeMenuTab(MENU_SCHEMA[i].id));
        break;
      }
    }
  }, [pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const route = MENU_SCHEMA?.find((item: any) => item?.id === newValue);
    navigate(route?.value || '', {
      state: { ...state },
    });
    dispatch(changeMenuTab(newValue));
  };

  const _renderTabs = () => {
    return _.map(MENU_SCHEMA, (item: ITabSchema) => {
      return <Tab color="#ffffff" label={item.name} value={item.id} {...a11yProps(item.id)} key={item.id} />;
    });
  };

  return (
    <Box>
      <Tabs value={selectedIndex} onChange={handleChange}>
        {_renderTabs()}
      </Tabs>
    </Box>
  );
};

export default ApprovalMainMenu;
