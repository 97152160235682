import { Box } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  {
    field: 'col1',
    headerName: 'SR No',
    width: 120,
    resizable: true,
    disableColumnMenu: true,
  },
  { field: 'col2', headerName: 'App Login date', width: 120, disableColumnMenu: true },
  { field: 'col3', headerName: 'Customer Name', width: 120, disableColumnMenu: true },
  { field: 'col4', headerName: 'CIF', width: 100, disableColumnMenu: true },
  { field: 'col5', headerName: 'Approval Mode', width: 120, disableColumnMenu: true },
  { field: 'col6', headerName: 'Request Type', width: 120, disableColumnMenu: true },
  { field: 'col7', headerName: 'Facility Type', width: 120, disableColumnMenu: true },
  { field: 'col8', headerName: 'Facility', width: 120, disableColumnMenu: true },
  { field: 'col9', headerName: 'Facility Currency', width: 120, disableColumnMenu: true },
  { field: 'col10', headerName: 'Facility Amount', width: 120, disableColumnMenu: true },
  { field: 'col11', headerName: 'Pending Department', width: 120, disableColumnMenu: true },
  { field: 'col12', headerName: 'Remarks', width: 120, disableColumnMenu: true },
  { field: 'col13', headerName: 'Committee Date', width: 120, disableColumnMenu: true },
];

const PendingApproval = () => {
  return (
    <Box sx={{ padding: 2, height: '580px' }}>
      <DataGrid columns={columns} rows={[]} />
    </Box>
  );
};

export default PendingApproval;
