import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { riskReviewTasks } from 'services/loan-projection-services';
import Chat from 'components/common/chat';
import _ from 'lodash';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import ActionBar from 'components/styled-components/ActionBar';

const ReviewComment = () => {
  const { state } = useLocation();

  const { data: allComments, isLoading: allCommentLoading } = useQuery(
    ['riskReviewTasks', state?.loanRequestId],
    () => riskReviewTasks(state?.loanRequestId),
    { enabled: !!state?.loanRequestId }
  );

  return (
    <Grid container sx={{ pl: 1 }}>
      <Grid item md={12} sx={{ height: 'calc(100vh - 185px)', display: 'flex' }}>
        {!_.isEmpty(allComments) &&
          allComments?.map((comment: any) => (
            <Grid item xs={3} sx={{ mt: 1 }}>
              <Chat
                key={comment?.departmentType}
                heading={_.startCase(_.camelCase(comment?.departmentType)) + ' Conversation'}
                status={_.startCase(_.camelCase(comment?.status))}
                isLoading={allCommentLoading}
                comments={comment?.commentList}
              />{' '}
            </Grid>
          ))}
        {_.isEmpty(allComments) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ height: '100vh' }}>
              <AlertTitle>
                <Typography variant="h6">No Conversation found</Typography>
              </AlertTitle>
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        <ActionBar></ActionBar>
      </Grid>
    </Grid>
  );
};

export default ReviewComment;
