import { Grid, Box } from '@mui/material';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import GroupSupport from './group-support';
import RawMaterialsBreakup from './raw-materials-breakup';
import TopSuppliers from './top-suppliers';

const Arrangements = ({ rawMaterialData, ...props }: any) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle
        formNumbering="3.2"
        title={t('loanProjection.businessBackgroud.productionPerformance.heading2')}
      />
      <Grid>
        <Grid item lg={12}>
          <RawMaterialsBreakup {...props} t={t} data={rawMaterialData} />
        </Grid>
        <Grid item lg={12}>
          <TopSuppliers t={t} {...props} />
        </Grid>
        <Grid item lg={12}>
          <GroupSupport t={t} {...props} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Arrangements;
