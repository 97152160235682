import { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useMutation, useQuery, useQueries } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadCrumbs } from 'components/common/pages/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CAM from '../loan-projection-and-proposal/cam/CAM';
import ProjectionLayout from '../../layout/projection-layout';
import { useSnackbar } from 'notistack';
import Chat from 'components/common/chat';
import SendIcon from '@mui/icons-material/Send';
import { storeLoanRequestData } from '../loan-projection-and-proposal/loan-proposal-reducer';
import { completeReviewTask, viewTaskComments, addReviewComment } from 'services/loan-projection-services';
import { getLoanRequestByLoanRequestId } from 'services/loan-request-services';
import { toggleLoader } from 'state/reducers/common-actions';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import DialogWithUpload from 'components/common/modals/DialogWithUpload';
import ActionBar from 'components/styled-components/ActionBar';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';

const MemosRequest = (props: any) => {
  const { isRM } = useLoanTable();
  const [open, setOpen] = useState(false);
  const [chatOrComplete, setChatOrComplete] = useState(false);
  const [message, setMessage] = useState('');
  const [send, setSend] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { statusCode, viewOnly } = state;

  useQueries([
    {
      queryKey: 'getLoanRequestByLoanRequestId',
      queryFn: () => getLoanRequestByLoanRequestId(state?.loanRequestId),
      onSuccess: (res: any) => {
        if (res.status === 200) {
          dispatch(storeLoanRequestData(res.data));
        }
      },
    },
  ]);

  const completeReviewTaskMutation = useMutation((reviewTask: any) => completeReviewTask(reviewTask), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`Review has been completed for Loan Request ID: ${state?.loanRequestId}`, {
        variant: 'success',
      });
      handleClose();
      navigate(routes.MEMOS_ROUTE);
    },
    onError: (err, variables) => {
      enqueueSnackbar(`Failed to complete review for Loan Request ID: ${state?.loanRequestId}`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const handleCompleteTask = () => {
    if (state?.loanRequestId && state?.flowableTaskId) {
      dispatch(toggleLoader(true));
      completeReviewTaskMutation?.mutate(`${state?.flowableTaskId}`);
    } else {
      enqueueSnackbar(`Failed to complete review comment`, {
        variant: 'error',
      });
    }
  };

  const { data, isLoading } = useQuery(
    ['viewComment', state?.flowableTaskId, send],
    () => viewTaskComments(state?.flowableTaskId),
    { enabled: !!state?.flowableTaskId }
  );

  const addReviewCommentMutation = useMutation((commentDetails: any) => addReviewComment(commentDetails), {
    onSuccess: (data, variables) => {
      setSend(variables);

      if (chatOrComplete) {
        handleCompleteTask();
      } else {
        handleClose();
        enqueueSnackbar(`Review comment has been added for Loan Request ID: ${state?.loanRequestId}`, {
          variant: 'success',
        });
        navigate(routes.MEMOS_ROUTE);
      }
    },
    onError: (err, variables) => {
      enqueueSnackbar(`Error while adding review comment for Loan Request ID: ${state?.loanRequestId}`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const handleAddReviewComment = (comment: any,docId: any) => {
    if (state?.loanRequestId && state?.flowableTaskId && comment && state?.reviewType) {
      const commentDetails = {
        comment: comment,
        reviewType: state?.reviewType,
        taskId: `${state?.flowableTaskId}`,
        docId: docId
      };
      dispatch(toggleLoader(true));
      addReviewCommentMutation?.mutate(commentDetails);
    } else {
      enqueueSnackbar(`Failed to send comment`, {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setMessage('');
  };

  const onClickHandler = (comment: any, docId: any) => {
    if (chatOrComplete != null) {
      handleAddReviewComment(comment, docId);
    }
  };

  const handleClickOpen = (chat: boolean) => {
    setMessage('');
    setChatOrComplete(chat);

    if (chat != null) {
      if (chat) {
        setMessage('Confirmation');
        setOpen(true);
      } else {
        setMessage('Query');
        setOpen(true);
      }
    }
  };

  return (
    <ProjectionLayout>
      <Box>
        <BreadCrumbs
          title={t('screenHeaders.memos.heading')}
          loanRequestId={state?.loanRequestId}
          company={state?.company}
          amount={state?.amount}
          route={routes.MEMOS_ROUTE}
        />

        <Grid container>
          <Grid item xs={9}>
            <CAM />
          </Grid>
          <Grid item xs={3} sx={{ pl: 2 }}>
            <Chat
              key={state?.reviewType}
              heading={_.startCase(_.camelCase(state?.reviewType)) + ' Conversation'}
              isLoading={isLoading}
              comments={data}
            />
            <ActionBar>
              {!viewOnly && !isRM() && (
                <>
                  <Button sx={{ p: 1, right: 20 }} onClick={() => handleClickOpen(true)} variant="contained">
                    Mark Review Completed
                  </Button>
                </>
              )}
              {!viewOnly && (
                <Button
                  variant="contained"
                  sx={{ p: 1, right: 10 }}
                  endIcon={<SendIcon fontSize="small" />}
                  onClick={() => handleClickOpen(false)}>
                  {isRM() ? 'Respond' : 'Raise Query'}
                </Button>
              )}
            </ActionBar>
          </Grid>

          <DialogWithUpload
            open={open}
            setOpen={setOpen}
            title={message}
            description={`${
              chatOrComplete ? 'Do you want to complete review' : 'Raise a query'
            } for Loan Request ID: ${state?.loanRequestId} ?`}
            type={chatOrComplete ? 'info' : 'warning'}
            onClickHandler={onClickHandler}
          />
        </Grid>
      </Box>
    </ProjectionLayout>
  );
};

export default MemosRequest;
