import { useEffect   } from 'react';
import { Button, Table, TableBody, TableHead, TableRow, TableCell, Typography, Grid, Box } from '@mui/material';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import ActionBar from 'components/styled-components/ActionBar';
import TableHeading from 'components/common/table-header';
import { roles } from 'constants/strings';
import useLocalStore from 'hooks/user-local-store';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormsData, generateWCAnalysys } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { Row, SubRow } from '../../../../../common/table/table-data-row/rows';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import CamButton from 'components/common/review-cam-btn/CamButton';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { useTheme } from '@mui/material/styles';
import { useForm, useWatch, Controller } from 'react-hook-form';
import Input from 'components/common/form-components/Input';
import queryClient from 'services/react-query-service';

function WCAnalysis() {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pcfcLeadTime: 0,
      lcPurchaseUnderLc: 0,
      lcUsance: 0,
      lcleadTime: 0,
      lcExportPurchaseUnderLc: 0,
      lcExportUsance: 0,
      lcExportLeadTime: 0,
      bgEstSalesPercentage: 0,
      bgAggGuaranteePercentage: 0,
    },
  });

  const lcUsance = useWatch({ control, name: 'lcUsance' });
  const lcleadTime = useWatch({ control, name: 'lcleadTime' });
  const lcExportUsance = useWatch({ control, name: 'lcExportUsance' });
  const lcExportLeadTime = useWatch({ control, name: 'lcExportLeadTime' });

  const generateWCAnalysysMutation = useMutation((data: any) => generateWCAnalysys(data), {
    onSuccess: (data) => {
      enqueueSnackbar('WCAnalysis form has been configured', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error while configuring WCAnalysis form', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getFormIIIData']);
      dispatch(toggleLoader(false));
    },
  });


  const navigate = useNavigate();

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'wcAnalysis'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );

  useEffect(()=>{

    if(data?.inputValues){
      reset({...data?.inputValues})  
    }   

  },[data])

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }

  const numberOfYears: string[] = _.map(data?.wcAnalysis, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.wcAnalysis[idx].actuals) {
      return data?.wcAnalysis[idx].actuals;
    }
    return data?.wcAnalysis[idx]?.projection;
  });

  const onSubmit = (data:any) =>{
    if (Object.values(data).some((x:any) => x === null || x === '' || isNaN(parseFloat(x)) || !isFinite(x))) {
      enqueueSnackbar('Please fill all details in number', {
        variant: 'error',
      });
    } else {
      dispatch(toggleLoader(true));
      generateWCAnalysysMutation.mutate({loanId:state.loanRequestId, projectionId, ...data, lcTotalTime: (Number(lcUsance)+Number(lcleadTime)), lcExportTotalTime:(Number(lcExportUsance)+Number(lcExportLeadTime))});
    }
    
  };

  
  return (
    <>
    <MainCard>
           <Box sx={{ pt: 0.5, pb: 0.5, background: theme.palette.primary.main, color: '#ffffff'}} >
       <Typography  variant="subtitle2" sx={{ pt: 0.5, pb: 0.5, paddingLeft:"12px" }}>
            Configuration
        </Typography>
      </Box>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6"  sx={{ paddingLeft: "12px"}}>
              PCFC Assessment
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Lead Time (Days)" sx={{ width: '42%' }} />
              <Box>
                <Input name="pcfcLeadTime" control={control} type="number" />
              </Box>
            </Box>
          </Grid>          
      </Grid>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" sx={{ paddingLeft: "12px"}}>
              LC Assessment (Domestic)
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% Purchase under LC" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcPurchaseUnderLc" control={control} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Usance (Days)" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcUsance" control={control} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Lead Time (Days)" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcleadTime" control={control} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Total Time" sx={{ width: '42%' }} />
              <Box sx={{padding:"8px"}}>
                 <Typography>{(lcUsance && lcleadTime)?(Number(lcUsance)+Number(lcleadTime)):''}</Typography>
              </Box>
            </Box>
          </Grid>   
      </Grid>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" sx={{ paddingLeft: "12px"}}>
              LC Assessment (Export)
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="% Purchase under LC" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcExportPurchaseUnderLc" control={control} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Usance (Days)" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcExportUsance" control={control} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Lead Time (Days)" sx={{ width: '42%' }} />
              <Box>
                <Input name="lcExportLeadTime" control={control} type="number" />
              </Box>
            </Box>
          </Grid>   
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Total Time" sx={{ width: '42%' }} />
              <Box sx={{padding:"8px"}}>
                 <Typography>{(lcExportUsance && lcExportLeadTime)?(Number(lcExportUsance)+Number(lcExportLeadTime)):''}</Typography>
              </Box>
            </Box>
          </Grid>   
      </Grid>
      <Grid container sx={{ mt: 2, borderBottom: '1px #babfc7 solid'}}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" sx={{ paddingLeft: "12px"}}>
              BG Assessment
            </Typography>
          </Box>
        </Grid>
      </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Estimated Sales for BG" sx={{ width: '42%' }} />
              <Box>
                <Input name="bgEstSalesPercentage" control={control} type="number" />
              </Box>
            </Box>
          </Grid>          
          <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <SimpleFormLabel label="Aggregate guarantee (as % of net sales)" sx={{ width: '42%' }} />
              <Box>
                <Input name="bgAggGuaranteePercentage" control={control} type="number" />
              </Box>
            </Box>
          </Grid>          
      </Grid>
      
      <Table size="small">
        <TableHeading title="Working Capital Analysis" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              Year ended / ending March 31
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>Assessed Bank Finance (ABF)</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Sales (Net)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.netSales)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Total Current Assets (TCA)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.totalCurrentAsset)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Current Liabilities (other than bank borrowings)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.currentLiabilitiesOthThnBankBorrowing)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Working Capital Gap"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.workingCapitalGap)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Net working capital"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.netWorkingCapital)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Assessed Bank Finance (ABF)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.assessedBankFinance.assessedBankFinance)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>&nbsp;</HeadingDenseCell>
          </TableRow>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>Net Working Capital Analysis</HeadingDenseCell>
          </TableRow>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>Year ended / ending March 31,</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="NWC / TCA"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.netWorkingCapital.nwcByTca)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="ABF / TCA"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.netWorkingCapital.abfByTca)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Sundry Creditors & Other Current Liabilities / TCA"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.netWorkingCapital.sundryCreditorsByTca)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>&nbsp;</HeadingDenseCell>
          </TableRow>          
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>PCFC Assessment</HeadingDenseCell>
          </TableRow>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>Year ended / ending March 31,</HeadingDenseCell>
          </TableRow>
          <SubRow
            title="Exports"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}> {formatNumber(dataArr[idx]?.pcfcAssessment.exports)}</NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Net Sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}> {formatNumber(dataArr[idx]?.pcfcAssessment.netSales)}</NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Months cost of Sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.pcfcAssessment.monthlyCostOfSales)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Lead time @ ${data?.inputValues?.pcfcLeadTime} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.pcfcAssessment.leadTime45Days)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>&nbsp;</HeadingDenseCell>
          </TableRow>          
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>LC Assessment(Domestic)</HeadingDenseCell>
          </TableRow>
          <Row
            heading
            title="Particulars"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell key={idx}>{}</HeadingDenseCell>;
            })}
          />
          <Row
            heading
            title="Export LC"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell key={idx}>{}</HeadingDenseCell>;
            })}
          />
          <SubRow
            title="Annual raw material purchase"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.annualRawMaterialPurchase)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`% Purchase under LC @ ${data?.inputValues?.lcExportPurchaseUnderLc}`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.purchaseUnderLc)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Estimated purchase under LC"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.estimatedPurchaseUnderLc)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Monthly RM purchases"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.monthlyRmPurchase)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Usance @ ${data?.inputValues?.lcExportUsance} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.usance)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Lead time @ ${data?.inputValues?.lcExportLeadTime} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.leadTime)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Total time @ ${data?.inputValues?.lcExportTotalTime} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.totalTime)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="LC limit required"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.exportLcAssessment?.lcLimitRequired)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>&nbsp;</HeadingDenseCell>
          </TableRow>          
          <Row
            heading
            title="Domestic LC"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell key={idx}>{}</HeadingDenseCell>;
            })}
          />
          <SubRow
            title="Annual raw material purchase"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.annualRawMaterialPurchase)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`% Purchase under LC @ ${data?.inputValues?.lcPurchaseUnderLc}`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.purchaseUnderLc)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Estimated purchase under LC"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.estimatedPurchaseUnderLc)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Monthly RM purchases"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.monthlyRmPurchase)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Usance @ ${data?.inputValues?.lcUsance} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.usance)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Lead time @ ${data?.inputValues?.lcleadTime} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.leadTime)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Total time @ ${data?.inputValues?.lcTotalTime} days`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.totalTime)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="LC limit required"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.lcAssessment?.domesticLcAssessment?.lcLimitRequired)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>&nbsp;</HeadingDenseCell>
          </TableRow>          
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears.length + 1}>BG Assessment</HeadingDenseCell>
          </TableRow>
          {/* <Row
            heading
            title="BG Assessment"
            data={_.map(numberOfYears, (_, idx: number) => {
              return <HeadingDenseCell key={idx}>{}</HeadingDenseCell>;
            })}
          /> */}
          <SubRow
            title="Net Sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}> {formatNumber(dataArr[idx]?.bgAssessment?.netSales)}</NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Estimated Sales for which BG would be issued (${data?.inputValues?.bgEstSalesPercentage}% of aggregate turnover)-A`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.bgAssessment?.estimatedSaleForBg)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title={`Aggregate guarantee (${data?.inputValues?.bgAggGuaranteePercentage}% of A):B`}
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.bgAssessment?.aggregatedGuarantee)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Add: Existing guarantees- C"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.bgAssessment?.addExistingGuarantee)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Less: Guarantees maturing during year (D)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.bgAssessment?.lessMaturingGuarantee)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Limit assessed ( B+C-D)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.bgAssessment?.limitAssessed)}
                </NumberDenseCell>
              );
            })}
          />
        </TableBody>
      </Table>
      <ActionBar>
        {!state.viewOnly && (
          <>
          <Button sx={{ p: 1, mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            Generate
          </Button>
          </>
        )}
        <CamButton
          sx={{ p: 1, mr: 1 }}
          onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
          camState={state}
        />
      </ActionBar>
    </MainCard>
    </>
  );
}

export default WCAnalysis;
