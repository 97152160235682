import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FileUploadProps } from 'models/fileupload';

const useStyle = makeStyles({
  root: {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    border: '1px solid #babfc7',
    '&:hover p,&:hover svg,& img': {
      opacity: 0.7,
    },
    '& p, svg': {
      opacity: 1,
    },
    '&:hover img': {
      opacity: 0.3,
    },
  },
  noMouseEvent: {
    pointerEvents: 'none',
  },
  iconText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  onDragOver: {
    '& img': {
      opacity: 0.3,
    },
    '& p, svg': {
      opacity: 1,
    },
  },
});

export const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  fileButton = false,
  hoverLabel = 'Click or drag to upload file',
  dropLabel = 'Drop file here',
  width = '400px',
  height = '165px',
  backgroundColor = '#fff',
  onChange,
  onDrop,
}) => {
  const classes = useStyle();
  const [labelText, setLabelText] = useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: async (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);

      if (fileButton && e?.dataTransfer?.files[0]) {
        setLabelText(e?.dataTransfer?.files![0]?.name);
      }

      let upload = false;
      try {
        await onDrop(e);
        upload = true;
      } catch (exp) {
        console.log(`Issue while uploading file: ${e?.dataTransfer?.files![0]?.name}`);
      }

      if (upload && fileButton && e?.dataTransfer?.files![0]) {
        setLabelText(e?.dataTransfer?.files![0]?.name);
      }
    },
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelText('');
    let upload = false;
    try {
      await onChange(event);
      upload = true;
    } catch (exp) {
      console.log(`Issue while uploading file: ${event?.target?.files![0]?.name}`);
    }

    if (upload && fileButton && event?.target?.files![0]) {
      setLabelText(event?.target?.files![0]?.name);
    }
  };

  return (
    <>
      <input onChange={handleChange} accept={accept} className={classes.hidden} id="file-upload" type="file" />

      <label
        htmlFor="file-upload"
        {...dragEvents}
        className={`${classes.root} ${isDragOver && classes.onDragOver}`}>
        <Box width={width} height={height} bgcolor={backgroundColor} className={classes.noMouseEvent}>
          {(fileButton || isDragOver || isMouseOver) && (
            <>
              <Box height={height} width={width} className={classes.iconText} sx={{ p: 2 }}>
                <CloudUploadIcon fontSize="small" />
                <Typography>{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </label>
    </>
  );
};
