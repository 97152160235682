import { TableRow } from '@mui/material';
import { SxProps } from '@mui/system';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';

interface IRowProps {
  title: string;
  data: any;
  heading?: boolean;
  sx?: SxProps;
}

export const Row = ({ title, data, heading = false, sx }: IRowProps) => {
  return (
    <TableRow>
      {heading ? (
        <HeadingDenseCell sx={{ paddingLeft: 5, borderRight: '1px solid rgba(224, 224, 224, 1)', ...sx }}>
          {title}
        </HeadingDenseCell>
      ) : (
        <DenseCellSm sx={{ paddingLeft: 4, ...sx }} borderRight>
          {title}
        </DenseCellSm>
      )}
      {data}
    </TableRow>
  );
};

export const SubRow = ({ title, data }: IRowProps) => {
  return (
    <TableRow>
      <DenseCellSm sx={{ paddingLeft: 6, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{title}</DenseCellSm>
      {data}
    </TableRow>
  );
};

export const SubSubRow = ({ title, data }: IRowProps) => {
  return (
    <TableRow>
      <DenseCellSm sx={{ paddingLeft: 8, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{title}</DenseCellSm>
      {data}
    </TableRow>
  );
};
