import Layout from 'components/layout/layout';
import { Outlet } from 'react-router';

function MemosLayout() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default MemosLayout;
