import _ from 'lodash';
import { useEffect } from 'react';
import { Button, CardActions, CardContent, Card, Box } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IInputTable } from 'types/inputform.d';
import { updateInputScreen } from './inputScreenSlice';
import { updateFormula } from './formulaSlice';
import { RootState } from 'state/store';
import { getInputValue } from '../../common/FormUtil';
import { createInputScreen, getInputScreen, getFormulaJsonByProjectionId } from 'services/loan-projection-services';
import useLocalStore from 'hooks/user-local-store';
import { useSnackbar } from 'notistack';
import { toggleLoader } from 'state/reducers/common-actions';
import { useLocation } from 'react-router-dom';
import { roles } from 'constants/strings';
import { MENU_ITEMS } from 'components/dashboard/loan-projection-and-proposal/projection-menu-schema';
import ActionBar from 'components/styled-components/ActionBar';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import useRefData from 'hooks/ref-data';
import { renderTable, generateInputJsonforDB, generateInputFormJson, generateFormulaJson } from './ManufacturingCommon'

const ManufacturingTable = ({ inputType }: IInputTable) => {
  const dispatch = useDispatch();
  const { state: camState } = useLocation();
  const queryClient = useQueryClient();
  const { fn } = useRefData();
  const { mapFiscalYear } = fn;
  const { getRequestStatusCode } = useLoanTable();
  const { loanRequestId, status, viewOnly }: any = useSelector((state: RootState) => state.loanProposal);
  const { tabs, selectedTab }: any = useSelector((state: RootState) => state.projections);
  const { data, isSuccess } = useQuery(['getManufacturingScreen', selectedTab, loanRequestId], () =>
    getInputScreen({ loanId: tabs![selectedTab]?.loanRequestId, projectionId: tabs![selectedTab]?.id })
  );
  const { data:formulaJsonData, isSuccess: formulaSuccess } = useQuery(['getFormulaJsonByProjectionId', tabs![selectedTab]?.id], () =>
    getFormulaJsonByProjectionId(tabs![selectedTab]?.id)
  );
  const cells = useSelector((state: RootState) => state?.inputscreen?.cells, shallowEqual);
  const formula = useSelector((state: RootState) => state?.formula?.rows);
  const { enqueueSnackbar } = useSnackbar();
  const inputScreenMutation = useMutation((data: any) => createInputScreen(data?.projection, data?.finalData), {
    onSuccess: (data) => {
      enqueueSnackbar(
        `Data saved for Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'success',
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(
        `Error while saving Loan Request ID : ${tabs![selectedTab]?.loanRequestId} / Projection: ${
          tabs![selectedTab]?.id
        }`,
        {
          variant: 'error',
        }
      );
    },
    onSettled: (data) => {
      queryClient.invalidateQueries(['getManufacturingScreen', selectedTab, loanRequestId]);
      dispatch(toggleLoader(false));
    },
  });

  useEffect(() => {
    dispatch(toggleLoader(true));
  }, []);

  
  const onClickHandler = () => {
    const data = generateInputJsonforDB(cells,tabs![selectedTab]?.loanRequestId, tabs![selectedTab]?.id);
    dispatch(toggleLoader(true));
    data && inputScreenMutation.mutate(data);
  };

  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  useEffect(() => {
    
    if (isSuccess && formulaSuccess) {
      const dbJson: any = data && { ...getInputValue(data) };
      const loanDetails = tabs && { ...tabs[selectedTab], fiscalYear: mapFiscalYear(tabs[selectedTab]?.fiscalYearTypeId)};

      const inputFormJson = generateInputFormJson(inputType, {...cells}, dbJson, loanDetails)

      const formulaJson = {};

      if (!_.isEmpty(Object.keys(formula))) {
         _.merge(formulaJson, formula);        
      }else if (formulaJsonData && !_.isEmpty(Object.keys(formulaJsonData))){
        _.merge(formulaJson, formulaJsonData);
      }else{
        _.merge(formulaJson, generateFormulaJson(inputFormJson, loanDetails))
      }

      
      dispatch(updateInputScreen(inputFormJson));
      dispatch(updateFormula(formulaJson));

      // console.dir(inputFormJson);
      // console.dir(Object.assign({},formulaJson.actuals));
      // console.dir(Object.assign({},formulaJson.projection));
      
      dispatch(toggleLoader(false));
    }
  }, [isSuccess, formulaSuccess, tabs[selectedTab]]);

  useEffect(() => {
    return () => {
      dispatch(updateInputScreen({}));
      dispatch(updateFormula({}));
    };
  }, [tabs[selectedTab]]);

  return (
    <>
      <Card sx={{ height: 'calc(100vh - 240px)', width: 'calc(100vw - 200px)' }}>
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ width: 'calc(100vw - 220px)', height: 'calc(100vh - 400px)', overflowY: 'scroll' }}>
            {renderTable(inputType)}
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          {!viewOnly &&
            (camState?.statusCode === getRequestStatusCode('DRAFT') ||
              camState?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
              camState?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
              !camState?.statusCode) && (
              <ActionBar>
                <Button onClick={onClickHandler} variant="contained" sx={{ p: 1, right: 12 }}>
                  Save and Update
                </Button>
                <CamButton
                  sx={{ p: 1, right: 8 }}
                  onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
                  camState={camState}
                />
              </ActionBar>
            )}
        </CardActions>
      </Card>
    </>
  );
};
export default ManufacturingTable;
