import {
  DATE_PICKER,
  INPUT,
  DROPDOWN,
  MULTI_SELECT_DROPDOWN,
  MULTI_LINE_INPUT,
  RADIO_BUTTONS,
} from 'constants/form-components';
import { IFormSchema } from 'models/forms-schema-types';
import useRefData from 'hooks/ref-data';

const useMemoDetails = () => {
  
  const { refData, fn } = useRefData();
  const {
      memoTypes,
      approvalTypes,
      approverTypes,
      recommendedTypes,
      copyTypes
    } = refData
  const { mapProjectionTemplate } = fn;
  
  const memoSchema: IFormSchema[] = [
    {
      label: 'Type Of Memo',
      name: 'typeOfMemo',
      component: DROPDOWN,
      options:  memoTypes || [],
      width: "30rem" 
    },
    { label: 'Date', name: 'date', component: DATE_PICKER, width: "30rem" },
    { label: 'From', name: 'from', component: INPUT, width: "30rem"  },
    { label: 'To', name: 'to', component: MULTI_SELECT_DROPDOWN, options:  approverTypes || [], width: "30rem"  },
    { label: 'CC', name: 'cc', component: MULTI_SELECT_DROPDOWN, options:  copyTypes || [], width: "30rem"   },
    { label: 'Subject', name: 'subject', component: INPUT, width: "30rem"  },
    { label: 'Company Details', name: 'companyDetails', component: MULTI_LINE_INPUT,  maxLength: 5000, minRows: 5 },
    { label: 'Proposal in brief', name: 'proposal', component: MULTI_LINE_INPUT, maxLength: 5000, minRows: 5 }, 
  ];


  return memoSchema;

};

export { useMemoDetails };