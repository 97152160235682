import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Input, IconButton, Badge } from '@mui/material';
import ProfilePopover from './ProfilePopover';
import { useNavigate } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import notification from '../../../assets/icons/notification.png';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { storeUserData } from 'state/reducers/user-reducer';
import { useTranslation } from 'react-i18next';
import LanguagePopover from './LanguagePopover';
import { getMyRequestsCount } from 'services/loan-request-services';
import { getUser } from 'utils/common-utils';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import bank from '../../../assets/icons/bank.png';
import product from '../../../assets/images/product.png';
import logo from '../../../assets/images/logo.svg';
import { routes } from 'constants/strings';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/styles';
import { getPublicConfig } from 'services/common-services';

interface IProps {}

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  backgroundColor: theme.palette.primary.main,
  width: '100%',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  '&.MuiToolbar-root': {
    minHeight: '50px',
    height: '50px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

const AppLogo = styled('div')(({ theme }) => ({
  width: '50px',
  height: '35px',
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}));

const Left = styled(Box)(({ theme }) => ({
  width: '35%',
  display: 'flex',
}));

const Center = styled(Box)(({ theme }) => ({
  width: '30%',
  display: 'flex',
  justifyContent: 'center',
}));

const Right = styled(Box)(({ theme }) => ({
  width: '35%',
  height: '100%',
  display: 'flex',
  justifyContent: 'end',
}));

const NavBar: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [loginBy, setLoginBy] = useState<string>();
  const [userSwitch, setUserSwitch] = useState<string>();
  const { username }: any = useSelector((state: RootState) => state.user?.userDetails);
  const { data, isSuccess } = useQuery(['getMyRequestsCount', username], getMyRequestsCount, {
    onSuccess: (data: any) => {
      navigate(routes.DASHBOARD_ROUTE, { replace: true });
    },
    onError: async (error: any) => {
      if (error?.response?.status === 401) {
        const user = await getUser();
        if (user) {
          dispatch(storeUserData(user));
          localStorage.setItem('user', JSON.stringify(user));
          navigate(routes.DASHBOARD_ROUTE, { replace: true });
        }
      }
    },
  });

  useQuery(['getPublicConfig'], getPublicConfig, {
    onSuccess: (data: any) => {
      data.forEach((e: any) => {
        if (e.configKey === 'SHOW_USER_SWITCH') {
          setUserSwitch(e.configValue);
        }
      });
    },
    onError: async (error: any) => {
      console.log('error', error);
    },
  });

  const handleLogin = async () => {
    try {
      const data = {
        username: loginBy,
        password: `${loginBy?.replace('_', '')}@1235`,
      };

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/authenticate`, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.data.jwtToken) {
        navigate('/temp');
        dispatch(storeUserData(res?.data));
        setLoginBy('');
        localStorage.setItem('user', JSON.stringify(res?.data));
        navigate(routes.DASHBOARD_ROUTE, { replace: true });
      } else {
      }
      return console.log('Something wrong while login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <RootStyle>
        <ToolbarStyle>
          <Left>
            <Box
              sx={{ height: 32, mr: 2 }}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(routes.DASHBOARD_ROUTE);
              }}>
              <img src={bank} alt="Logo" />
            </Box>
            <Box
              sx={{ height: 32, width: 100, mr: 2 }}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(routes.DASHBOARD_ROUTE);
              }}>
              <img src={product} alt="Logo" />
            </Box>
            {userSwitch === 'true' && (
              <Box sx={{ ml: 2 }}>
                <Input
                  sx={{ background: 'white', height: '100%' }}
                  placeholder="  Login By"
                  onChange={(event) => setLoginBy(event.target.value)}
                />
                <IconButton color="secondary" aria-label="loginby" component="span" onClick={handleLogin}>
                  <SendOutlinedIcon fontSize="small" sx={{ color: 'red' }} />
                </IconButton>
              </Box>
            )}
          </Left>
          <Center>
            <Typography variant="h5">Loan Origination System (Large & Mid Corporates)</Typography>
          </Center>
          <Right>
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <Typography noWrap component="span" sx={{ fontSize: '13px' }}>
                Login as <b>{username}</b>
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ borderColor: `${theme.palette.secondary.light}` }} />
              <Typography noWrap component="span" sx={{ fontSize: '13px' }}>
                {t('app.date', { date: new Date() })}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ borderColor: `${theme.palette.secondary.light}` }} />
              <LanguagePopover />
              <IconButton
                size="small"
                aria-label="show notifications"
                color="inherit"
                sx={{ marginRight: '8px !important' }}>
                <Badge color="error" badgeContent=" " variant="dot">
                  <img src={notification} alt="Notification" />
                </Badge>
              </IconButton>
              <ProfilePopover />
            </Stack>
          </Right>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
};

export default NavBar;
