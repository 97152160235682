import { useSelector } from 'react-redux';
import _ from 'lodash';
import InputForm from '../InputForm';
import { RootState } from '../../../../../../../state/store';
import formLoansTemplate from './formLoansTemplate.json';
import {
  getFormDataFromState,
  getForm,
  getRowTemplate,
  getItemsAndAppend,
  updateJson,
} from '../../common/FormUtil';
import { FormGroup, FinanceFormType, FormGroupType, FormGroupI } from '../../../../../../../types/inputform.d';

export const generateFormLoans = (group: FormGroupType, loansCells: any, dbJson: any, loanDetails: any) => {
  const formCells = _.cloneDeep(loansCells);

  let allRows: any[] = [];
  let formDataCells: any;

  function getYieldTotal(items: any, newFormula: any, label: string) {
    const updatedItem = _.cloneDeep(newFormula);

    let prefixValArray: any = [];
    items.map((item: any) => {
      if (updatedItem.hasOwnProperty('prefix') && updatedItem['prefix'].includes('{item}')) {
        prefixValArray.push(updatedItem['prefix'].replaceAll('{item}', `${label}_${item?.id}`));
      }
    });
    updatedItem['prefix'] = prefixValArray.join('+');
    updatedItem['actuals'] = updatedItem['actuals']
      .replace('prefix', updatedItem['prefix'])
      .replace('suffix', updatedItem['suffix']);
    updatedItem['projection'] = updatedItem['projection']
      .replace('prefix', updatedItem['prefix'])
      .replace('suffix', updatedItem['suffix']);
    delete updatedItem['prefix'];
    delete updatedItem['suffix'];

    return updatedItem;
  }

  if (!_.isEmpty(formCells)) {
    formDataCells = getFormDataFromState(formCells, group);
  } else if (_.isEmpty(formCells)) {
    const {
      form: formLoans,
      totalRetailNonConsumer,
      totalRetailConsumer,
      growthRetailNonConsumer,
      growthRetailConsumer,
      compositionRetailNonConsumer,
      compositionRetailConsumer,
      breakdownRetailNonConsumer,
      breakdownRetailConsumer,
      yieldRetailNonConsumer,
      yieldRetailConsumer,
      interestIncomeRetailNonConsumer,
      interestIncomeRetailConsumer,
      yieldNonConsumerTotal,
      yieldConsumerTotal,
    } = _.cloneDeep(formLoansTemplate) as any;

    const { products, rawMaterials } = loanDetails;

    updateJson(
      'type',
      'totalRetailNonConsumer',
      getItemsAndAppend(products, totalRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'totalRetailConsumer',
      getItemsAndAppend(rawMaterials, totalRetailConsumer, 'rawMaterials'),
      formLoans
    );
    updateJson(
      'type',
      'growthRetailNonConsumer',
      getItemsAndAppend(products, growthRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'growthRetailConsumer',
      getItemsAndAppend(rawMaterials, growthRetailConsumer, 'rawMaterials'),
      formLoans
    );
    updateJson(
      'type',
      'compositionRetailNonConsumer',
      getItemsAndAppend(products, compositionRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'compositionRetailConsumer',
      getItemsAndAppend(rawMaterials, compositionRetailConsumer, 'rawMaterials'),
      formLoans
    );
    updateJson(
      'type',
      'breakdownRetailNonConsumer',
      getItemsAndAppend(products, breakdownRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'breakdownRetailConsumer',
      getItemsAndAppend(rawMaterials, breakdownRetailConsumer, 'rawMaterials'),
      formLoans
    );
    updateJson(
      'type',
      'yieldRetailNonConsumer',
      getItemsAndAppend(products, yieldRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'yieldRetailConsumer',
      getItemsAndAppend(rawMaterials, yieldRetailConsumer, 'rawMaterials'),
      formLoans
    );
    updateJson(
      'type',
      'interestIncomeRetailNonConsumer',
      getItemsAndAppend(products, interestIncomeRetailNonConsumer, 'product'),
      formLoans
    );
    updateJson(
      'type',
      'interestIncomeRetailConsumer',
      getItemsAndAppend(rawMaterials, interestIncomeRetailConsumer, 'rawMaterials'),
      formLoans
    );

    updateJson(
      'projectionFormula',
      'yieldNonConsumerTotal',
      getYieldTotal(products, yieldNonConsumerTotal, 'product'),
      formLoans
    );

    updateJson(
      'projectionFormula',
      'yieldConsumerTotal',
      getYieldTotal(rawMaterials, yieldConsumerTotal, 'rawMaterials'),
      formLoans
    );

    console.log('formLoans', formLoans);

    // Total Rows //
    allRows = getRowTemplate('label', formLoans);

    formDataCells = getForm(
      formCells,
      FinanceFormType[FinanceFormType.FORMLOANS].toLowerCase(),
      group,
      allRows,
      dbJson,
      loanDetails
    );
  }

  // if (group === FormGroup.PROJECTION) {
  //   Object.entries(formJson).map(([, cell]) => cell?.expr && cellUpdate(copyCells, cell, cell?.expr));
  // }

  return formDataCells;
};

export const FormLoans = ({ group }: FormGroupI) => {
  const cells = useSelector(
    (state: RootState) =>
      state?.financeInputscreen?.cells![FinanceFormType[FinanceFormType.FORMLOANS].toLowerCase()]
  );
  // const cells = useSelector((state: RootState) => state?.financeInputscreen?.cells![FinanceFormType[FinanceFormType.FORMLONES].toLowerCase()]);

  if (!group || !cells) return <></>;

  const cellData: any = {};

  const actualsCells = Object.fromEntries(
    Object.entries(cells).filter(([, value]: any) => value?.group === FormGroup[FormGroup.ACTUALS].toLowerCase())
  );
  _.merge(cellData, actualsCells);

  if (group === FormGroup.PROJECTION) {
    const projectionCells = Object.fromEntries(
      Object.entries(cells).filter(
        ([, value]: any) => value?.group === FormGroup[FormGroup.PROJECTION].toLowerCase()
      )
    );
    _.merge(cellData, projectionCells);
  }

  return <InputForm cells={cellData} />;
};
