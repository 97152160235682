import { FC, useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SyntheticEvent } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import fontSize from 'constants/fonts';
import { RootState } from 'state/store';
import { updateTabs, updateSelectedTab } from './financial-projection-reducer';
import { useLocation } from 'react-router-dom';
import useLoanTable from 'hooks/useLoanTable';

interface ProjectionList {
  onCreateNewProjection: () => void;
  listItems: any;
}

const ProjectionsList: FC<ProjectionList> = ({ onCreateNewProjection, listItems }) => {
  const { getRequestStatusCode } = useLoanTable();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();
  listItems = _.map(listItems, (listItem) => JSON.parse(listItem));
  const { state } = useLocation();

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={selectedTab}
      onChange={(event: SyntheticEvent, newValue: number) => dispatch(updateSelectedTab(newValue))}
      sx={{ borderBottom: 1, borderColor: 'divider', p: 0 }}>
      {_.map(listItems || tabs, (tab: any, idx: number) => {
        return (
          <Tab
            key={idx}
            sx={{ ...tabStyle, textTransform: 'capitalize', fontSize: 13 }}
            label={tab.projectionName}
          />
        );
      })}
      {!state?.viewOnly && (state?.statusCode === getRequestStatusCode('DRAFT') ||
                  state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
                  state?.statusCode === getRequestStatusCode('REWORK_WITH_RM')) && (tabs.length >= 2 ? null : (
        <Tab sx={tabStyle} icon={<AddIcon fontSize="small" />} onClick={onCreateNewProjection} />
      ))}
    </Tabs>
  );
};

export default ProjectionsList;

const tabStyle = {
  p: 0,
  fontSize: fontSize.formLabel,
  pl: 1,
};
