import { Alert, Box, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getFormFields } from 'utils/form-utils';
import SimpleFormLabel from '../form-components/form-label';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { generateSanctionLetter, getSanctionDetail } from 'services/credit-service';
import { toggleLoader } from 'state/reducers/common-actions';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import { retriggerSanction } from 'state/reducers/sanctionReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import queryClient from 'services/react-query-service';

const companyDetails = [
  { label: 'Company Address', name: 'companyAddress', component: 'textField' },
  { label: 'Contact Person', name: 'companyContactPerson', component: 'textField' },
  { label: 'Bank Contact Person', name: 'bankContactPerson', component: 'textField' },
  { label: 'Bank Branch Address', name: 'bankAddress', component: 'textField' },
  { label: 'Last date for submission', name: 'lastDateSubmission', component: 'datepicker', type: 'data' },
];

interface IFormInput {
  companyAddress: string;
  companyContactPerson: string;
  bankContactPerson: string;
  bankAddress: string;
  lastDateSubmission: string;
  id: string;
}

const DialogWithSanctionForm = ({ open, setOpen, title, description, type }: any) => {
  const trigger = useSelector((state: RootState) => state?.sanction?.trigger);
  const { state } = useLocation();
  const { data: sanctionDetail } = useQuery(['getSanctionDetail', state?.loanRequestId], () =>
    getSanctionDetail(state?.loanRequestId)
  );

  const { control, handleSubmit, reset } = useForm<IFormInput>({
    defaultValues: {
      companyAddress: '',
      companyContactPerson: '',
      bankContactPerson: '',
      bankAddress: '',
      lastDateSubmission: moment().format('dd/mm/YYYY'),
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const generateSanctionLetterMutation = useMutation((sanctionData: any) => generateSanctionLetter(sanctionData), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`Sanction Letter has been generated for Loan Request Id: ${state?.loanRequestId}`, {
        variant: 'success',
      });
      queryClient.invalidateQueries(['getMemo', state?.loanRequestId]);
    },
    onError: (data, variables) => {
      enqueueSnackbar(`Failed to generate Sanction Letter for Loan Request Id: ${state?.loanRequestId}`, {
        variant: 'error',
      });
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
      dispatch(retriggerSanction(!trigger));
      setOpen(false);
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { companyAddress, companyContactPerson, bankContactPerson, bankAddress, lastDateSubmission } = data;
    if (
      !_.some({ companyAddress, companyContactPerson, bankContactPerson, bankAddress }, _.isEmpty) &&
      lastDateSubmission
    ) {
      generateSanctionLetterMutation.mutate({
        ...data,
        loanId: state?.loanRequestId,
        refId: state?.companyId,
        id: sanctionDetail?.id,
      });
    } else {
      enqueueSnackbar(`Please fill all fields`, {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const _renderSanctionInputs = () => {
    return _.map(companyDetails, ({ label, ...company }, idx: number) => {
      return (
        <Grid key={idx} item xs={12}>
          <Box>
            <SimpleFormLabel label={label} sx={{ width: '35%' }} />
            {getFormFields(
              {
                ...company,
              },
              control
            )}
          </Box>
        </Grid>
      );
    });
  };

  useEffect(() => {
    sanctionDetail && reset({ ...sanctionDetail });
  }, [sanctionDetail]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '75%', maxHeight: 450 } }}
      maxWidth="sm">
      <Alert severity={type}>
        <Typography variant="subtitle1">{title}</Typography>
      </Alert>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2">{description}</Typography>
          <br />
          <form>
            <Grid container rowSpacing={1} sx={{ justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Box>
                  <SimpleFormLabel label={'Company Name'} sx={{ width: '35%', float: 'left' }} />
                  <Typography sx={{ lineHeight: '2.5' }}>&nbsp;{state?.company}</Typography>
                </Box>
              </Grid>
              {_renderSanctionInputs()}
            </Grid>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={handleSubmit(onSubmit)} autoFocus>
          <Typography>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogWithSanctionForm;
