import AutocompleteWithCreate from 'components/common/form-components/AutocompleteWithCreate';
import TableInput from 'components/common/table/table-input';
import { Control } from 'react-hook-form';
import DatePicker from '../components/common/form-components/DatePicker';
import Dropdown from '../components/common/form-components/Dropdown';
import MultiSelectDropdown from '../components/common/form-components/MultiSelectDropdown';
import Input from '../components/common/form-components/Input';
import MultiSelectAutocomplete from '../components/common/form-components/MultiSelectAutocomplete';
import SimpleRadio from '../components/common/form-components/SimpleRadio';
import SolidButton from '../components/common/form-components/SolidButton';
import SimpleSlider from '../components/common/form-components/SimpleSlider';
import YearPicker from '../components/common/form-components/YearPicker';
import {
  INPUT,
  MULTI_LINE_INPUT,
  MULTI_SELECT_DROPDOWN,
  MULTI_SELECT_AUTOCOMPLETE,
  MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION,
  TABLE_INPUT,
  RADIO_BUTTONS,
  YEAR_PICKER,
  TABLE_BUTTON,
  DROPDOWN,
  DATE_PICKER,
  SLIDER,
  SWITCH,
} from '../constants/form-components';
import MuiSwitch from 'components/common/form-components/MuiSwitch';

const getFormFields = (
  input: any,
  control: Control<any>,
  disabled: boolean = false,
  rules: any = {},
) => {
  switch (input.component) {
    case DROPDOWN:
      return <Dropdown {...input} control={control} disabled={disabled} rules={rules} />;
    case MULTI_SELECT_DROPDOWN:
      return <MultiSelectDropdown {...input} control={control} disabled={disabled} rules={rules} />;
    case SLIDER:
      return <SimpleSlider control={control} {...input} rules={rules} />;
    case DATE_PICKER:
      return <DatePicker control={control} {...input} disabled={disabled} rules={rules} />;
    case RADIO_BUTTONS:
      return <SimpleRadio control={control} {...input} disabled={disabled} rules={rules} />;
    case YEAR_PICKER:
      return <YearPicker control={control} {...input} disabled={disabled} rules={rules} />;
    case MULTI_LINE_INPUT:
      return (
        <Input
          {...input}
          style={{ width: '100% !important' }}
          control={control}
          multiLineInput={true}
          fullWidth={true}
          inputProps={{ maxLength: input.maxLength || 1000 }}
          disabled={disabled}
          rules={rules}
        />
      );
    case MULTI_SELECT_AUTOCOMPLETE:
      return <MultiSelectAutocomplete {...input} control={control} disabled={disabled} rules={rules} />;
    case INPUT:
      return <Input {...input} control={control} disabled={disabled} rules={rules} />;
    case TABLE_INPUT:
      return <TableInput {...input} control={control} disabled={disabled} rules={rules} />;
    case TABLE_BUTTON:
      return <SolidButton {...input} />;
    case MULTI_SELECT_AUTOCOMPLETE_WITH_CREATE_OPTION:
      return <AutocompleteWithCreate {...input} control={control} disabled={disabled} rules={rules} />;
    case SWITCH:
      return <MuiSwitch {...input} control={control} disabled={disabled} rules={rules} />;
    default:
      return;
  }
};

export { getFormFields };
