import { useEffect, useMemo, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { sum } from 'mathjs';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const ExposureSummary = ({ getValues, watchAllFields }: any) => {
  const fundGridRef = useRef<any>(null);

  const [fundSubTotal, setFundSubTotal] = useState({
    facility: 'Fund-Based (A)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'FBTOTAL',
  });

  const [nonfundSubTotal, setNonFundSubTotal] = useState({
    facility: 'Non-Fund Based (B)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'NFBTOTAL',
  });

  const [fundNonFundTotal, setFundNonFundTotal] = useState({
    facility: 'Total (A+B)',
    existingLimit: 0,
    renewal: 0,
    additional: 0,
    proposedTotal: 0,
    uid: 'TOTAL',
  });
  const defaultColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'facility',
        headerName: 'Facility',
        minWidth: 270,
        pinned: 'left',
        cellStyle: (params: any) => {
          if (params.data.isChild) {
            return { paddingLeft: '25px' };
          }
        },
      },
      {
        field: 'currency',
        headerName: 'Currency',
        width: 150,
      },
      {
        field: 'existingLimit',
        headerName: 'Existing Limit',
        width: 100,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'renewal',
        headerName: 'Renewal (A)',
        width: 100,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'additional',
        headerName: 'Additional (B)',
        width: 100,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'proposedTotal',
        headerName: 'Proposed Total (A+B)',
        width: 100,
        valueFormatter: formatGridNumber,
        cellEditor: NumericEditor,
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'pricing',
        headerName: 'Pricing',
        width: 180,
      },
      {
        field: 'securityApp',
        headerName: 'Security Applicability',
        width: 180,
      },
    ];
    return colDef;
  }, []);

  const total = (field: string) => {
    if (watchAllFields?.financialAssessment?.exposureSummary?.sanctionDetails) {
      const fundRows =
        [...getValues('financialAssessment.exposureSummary.sanctionDetails')].filter(
          (e) => e?.facilityType === 'Fund Based'
        ) || [];
      return fundRows.reduce((prevValue: any, currentValue: any) => {
        if (isNaN(currentValue[field])) {
          return sum(prevValue, 0);
        } else {
          return sum(prevValue, currentValue[field]);
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const nonFundTotal = (field: string) => {
    if (watchAllFields?.financialAssessment?.exposureSummary?.sanctionDetails) {
      const nonFundRows =
        [...getValues('financialAssessment.exposureSummary.sanctionDetails')].filter(
          (e) => e?.facilityType === 'Non-Fund Based'
        ) || [];
      return nonFundRows.reduce((prevValue: any, currentValue: any) => {
        if (isNaN(currentValue[field])) {
          return sum(prevValue, 0);
        } else {
          return sum(prevValue, currentValue[field]);
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const abTotal = () => {
    const fundBased: any = {
      existingLimit: total('existingLimit'),
      renewal: total('renewal'),
      additional: total('additional'),
      proposedTotal: total('proposedTotal'),
    };

    const nonFundBasedData: any = {
      existingLimit: nonFundTotal('existingLimit'),
      renewal: nonFundTotal('renewal'),
      additional: nonFundTotal('additional'),
      proposedTotal: nonFundTotal('proposedTotal'),
    };

    function sumObjectsByKey(...objs: any) {
      return objs.reduce((a: any, b: any) => {
        for (let k in b) {
          if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
        }
        return a;
      }, {});
    }
    return sumObjectsByKey(fundBased, nonFundBasedData);
  };

  const onFundGridReady = (params: any) => {
    params.columnApi.autoSizeAllColumns();
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    setFundSubTotal({
      facility: 'Fund-Based (A)',
      existingLimit: total('existingLimit'),
      renewal: total('renewal'),
      additional: total('additional'),
      proposedTotal: total('proposedTotal'),
      uid: 'FBTOTAL',
    });
    setNonFundSubTotal({
      facility: 'Non-Fund Based (B)',
      existingLimit: nonFundTotal('existingLimit'),
      renewal: nonFundTotal('renewal'),
      additional: nonFundTotal('additional'),
      proposedTotal: nonFundTotal('proposedTotal'),
      uid: 'NFBTOTAL',
    });
    setFundNonFundTotal({
      facility: 'Total (A+B)',
      uid: 'TOTAL',
      ...abTotal(),
    });
  }, [watchAllFields]);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="2. Sanction Details (IDR Equivalent)" />
      <Box sx={{ mt: 1 }}>
        <DataTable
          ref={fundGridRef}
          columnDefs={defaultColDefs}
          autoSize={true}
          rowData={watchAllFields?.financialAssessment?.exposureSummary?.sanctionDetails || []}
          pagination={false}
          domLayout={'autoHeight'}
          sizeToFit={true}
          style={{ height: '100%' }}
          onGridReady={onFundGridReady}
          pinnedBottomRowData={[fundSubTotal, nonfundSubTotal, fundNonFundTotal]}
          getRowNodeId={(params: any) => {
            return params.id;
          }}
          getRowStyle={(params: any) => {
            if (
              !params.data.isChild &&
              (params.data?.uid !== 'FBTOTAL' || params.data?.uid !== 'NFBTOTAL' || params.data?.uid !== 'TOTAL')
            ) {
              return {
                color: '#2F80ED',
              };
            }
            return null;
          }}
        />
      </Box>
    </Box>
  );
};

export default ExposureSummary;
