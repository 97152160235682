import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

export const getAllArchiveRequests = async () => {
  const resposne = await httpService.get('/loanProjections/archivedRequests', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return resposne.data;
};
