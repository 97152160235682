import { Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';

const DialogWithCheckBox = ({ open, setOpen, title, description, type, onClickHandler }: any) => {
  const [checked, setChecked] = useState<number[]>([]);

  const handleClose = () => {
    setOpen(false);
    setChecked([]);
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs">
      <Alert severity={type}>
        <Typography variant="subtitle1">{title}</Typography>
      </Alert>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2">{description}</Typography>
          <br />
          <Typography variant="subtitle2">
            By clicking confirm, you are agreeing that you have verified all the forms.
          </Typography>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem key={0} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(0)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(0) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-0' }}
                  />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-0'} primary={`Form Actual`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={1} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(1)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(1) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-1' }}
                  />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-1'} primary={`Form Projection`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={2} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(2)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(2) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-2' }}
                  />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-2'} primary={`Form Performance Summary`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={3} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(3)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(3) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-3' }}
                  />
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-3'} primary={`Form Projection Summary`} />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={onClickHandler} disabled={checked?.length !== 4} autoFocus>
          <Typography>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogWithCheckBox;
