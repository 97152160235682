import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = { "rows": {} };

const financeFormula = createSlice({
	name: 'formula',
	initialState,
	reducers: {
		updateFinanceFormula: (state, action: PayloadAction<any>) => {
			state.rows = action.payload
		}
	},
});

const { actions, reducer } = financeFormula;

export const { updateFinanceFormula } = actions;

export default reducer;
