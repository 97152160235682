import { Box } from '@mui/material';
import _ from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';
import DataTable from 'components/common/data-table';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import { useLocation } from 'react-router-dom';

const OurBankOverdue = ({ getValues, setValue, watchAllFields }: any) => {
  const [rowData, setRowData] = useState<any>([]);
  const { state } = useLocation();

  useEffect(() => {
    setRowData(() => getValues('conductOfAccount'));
  }, [watchAllFields]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setRowData(updatedRowData);
    setValue('conductOfAccount', updatedRowData);
  }, []);

  const ourBankColDef = useMemo(() => {
    var colDef: any = [
      {
        headerName: 'Details of Arrears/overdue/irregularity history of Our Bank',
        children: [
          {
            field: 'particulars',
            headerName: 'Particulars',
            sortable: false,
            suppressMenu: true,
          },
          {
            field: 'numberOfTimes',
            headerName: 'No of times',
            sortable: false,
            suppressMenu: true,
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'amount',
            headerName: 'Amount',
            sortable: false,
            suppressMenu: true,
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: 'maxDelay',
            headerName: 'Maximum delay days',
            sortable: false,
            suppressMenu: true,
            editable: state?.isEdit,
          },
        ],
      },
    ];
    return colDef;
  }, []);

  return (
    <>
      <Box sx={{ pt: 1 }}>
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={ourBankColDef}
            autoSize={true}
            rowData={rowData}
            domLayout={'autoHeight'}
            sizeToFit={true}
            pagination={false}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>
    </>
  );
};

export default OurBankOverdue;
