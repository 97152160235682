import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ApprovalIcon from '@mui/icons-material/Approval';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Delete from '@mui/icons-material/Delete';

import {
  COMPANY,
  LOAN_PROPOSAL,
  STATUS,
  EXPOSURE,
  RIGHT_ARROW,
  LEFT_ARROW,
  PENDING,
  DASHBOARD,
  COMMUNICATION,
  DISBURSMENT,
  MEMO,
  COMPLIANCES,
  STATUS_UPDATE,
  PORTFOLIO,
  RiSK_RATIONALE,
  ADD,
  EDIT,
  DELETE,
  APPROVAL,
  LOGOUT,
  CHECKED,
  UNCHECKED,
  NEW_LOAN,
  GO_BACK,
  MY_REQUEST,
  CLOSED,
} from './icon-names';

const getIcon = (iconName: string, color?: string, width: number = 25, height: number = 25) => {
  switch (iconName) {
    case COMPANY:
      return <BusinessOutlinedIcon fontSize="small" />;
    case PENDING:
      return <PendingActionsOutlinedIcon fontSize="small" />;
    case LOAN_PROPOSAL:
      return <AccountBalanceOutlinedIcon fontSize="small" />;
    case MY_REQUEST:
      return <ReceiptOutlinedIcon fontSize="small" />;
    case CLOSED:
      return <EventAvailableOutlinedIcon fontSize="small" />;
    case COMMUNICATION:
      return <SupportAgentOutlinedIcon fontSize="small" />;
    case DASHBOARD:
      return null;
    case DISBURSMENT:
      return <PendingActionsOutlinedIcon fontSize="small" />;
    case MEMO:
      return <NoteOutlinedIcon fontSize="small" />;
    case COMPLIANCES:
      return <PendingActionsOutlinedIcon fontSize="small" />;
    case STATUS:
      return <AvTimerOutlinedIcon fontSize="small" />;
    case EXPOSURE:
      return <ExposureOutlinedIcon fontSize="small" />;
    case RIGHT_ARROW:
      return <ChevronRightIcon fontSize="small" />;
    case LEFT_ARROW:
      return <ChevronLeftIcon fontSize="small" />;
    case STATUS_UPDATE:
      return <UpdateOutlinedIcon fontSize="small" />;
    case PORTFOLIO:
      return <WorkOutlineOutlinedIcon fontSize="small" />;
    case RiSK_RATIONALE:
      return <BugReportOutlinedIcon fontSize="small" />;
    case ADD:
      return <AddCircleIcon fontSize="small" style={{ color: color }} />;
    case EDIT:
      return <EditIcon fontSize="small" style={{ color: color }} />;
    case DELETE:
      return <Delete fontSize="small" style={{ color: color }} />;
    case APPROVAL:
      return <ApprovalIcon fontSize="small" />;
    case LOGOUT:
      return <LogoutOutlinedIcon fontSize="small" />;
    case CHECKED:
      return <CheckCircleIcon fontSize="small" />;
    case UNCHECKED:
      return <CheckCircleOutlineIcon fontSize="small" />;
    case NEW_LOAN:
      return <NoteAddIcon fontSize="small" />;
    case GO_BACK:
      return <ArrowBackIcon fontSize="small" />;
    default:
      return;
  }
};

export default getIcon;
