import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TableHeading from 'components/common/table-header';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import SubCell from 'components/common/table/subcell';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';
import { FormTitle } from 'components/common/form-components/FormTitle';

interface IProps {
  yearEnded: string;
  data: any;
  totalYears: string[];
}

export default function KeyPerformance({ yearEnded, data, totalYears }: IProps) {
  const { t } = useTranslation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={t('loanProjection.projectionSummary.keyPerformance')} />
      <Box sx={{ mt: 0.5 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                width={'25%'}
                sx={{
                  background: '#f8f8f8',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}>
                <Typography>
                  <b>Year Ended {yearEnded}</b>
                </Typography>
              </TableCell>
              {_.map(totalYears, (year: string) => (
                <TableCell
                  key={year}
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#f5f5f5' }}>
                  <Typography>
                    <b>{year}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Profitability</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Gross Margin (%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.grossMargin)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBITDA (%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.profitability.ebitda)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBIT (%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.profitability.ebit)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>PBT (%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.profitability.pbt)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Effective Tax Rate</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.effectiveTaxRate)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Profit After tax (PAT%)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.profitAfterTax)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Dividend Payout Rate</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.dividendPayoutRate)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Retention Rate</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.retentionRate)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Return of Assets(ROA)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.returnOfAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Return of Equity (ROE)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.profitability.returnOfEquity)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>ROCE %</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.profitability.roce)}</NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Growth (%)</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Assets Growth</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.growth)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Networth Growth</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.growth.totalNetWorthGrowth)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Total Operating Income (%)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.growth.totalOperatingIncome)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net Sales Growth(%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.netSalesGrowth)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBIDTA Growth (%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.ebitdaGrowth)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EBIT Growth(%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.ebitGrowth)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>PBT Growth(%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.pbtGrowth)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>PAT Growth(%)</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.patGrowth)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Sustainable Growth</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.growth.sustainableGrowth)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Altman Z Score</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.growth.altmanZScore)}</NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Working Capital Management</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Cash Conversion Cycle</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.workingCapitalManagement.cashConversionCycle.cashConversionCycle)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Days Inventory Outstanding (Cost of Production)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(
                      data[index]?.workingCapitalManagement.cashConversionCycle.daysInventoryOutstanding
                    )}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Days Sales Oustanding (Gross Sales)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.workingCapitalManagement.cashConversionCycle.daysSalesOutstanding)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Days Payable Outstanding (Raw Materials)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(
                      data[index]?.workingCapitalManagement.cashConversionCycle.daysPayableOutstanding
                    )}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Working Capital Turnover
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.workingCapitalManagement.workingCapitalTurnover)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Accounts Receivables Quality</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total A/R</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.accountsReceivablesQuality.totalAr)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Allowance for Doubtful Account
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.accountsReceivablesQuality.allowanceForDoubtfulAccount)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net A/R</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.accountsReceivablesQuality.netAr)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell
                style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{`Ageing Ratio > 90days`}</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.accountsReceivablesQuality.ageingRatioGr180days)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Turnover Ratio's</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Inventory Turnover</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.inventoryTurnover)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Receivables Turnover</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.receivableTurnover)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Accounts Payable Turnover
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.accPayableTurnover)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Net Sales to Total Assets
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.netSalesToTotalAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net Sales to Net Worth</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.netSalesToNetWorth)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Net Sales to Net Fixed Assets
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.turnoverRatio.netSalesToNetFixedAssets)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Coverage</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Interest Cover</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.coverage.interestCover)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Debt Service Coverage Ratio
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.coverage.debtServiceCoverageRatio)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>NCA to CPLTD</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.coverage.ncaToCpltd)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Debt / EBITDA</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.coverage.totalDebtByEbitda)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Debt/NCA</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.coverage.totalDebitByNca)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Liquidity</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net working capital</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.liquidity.netWorkingCapital)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Current Ratio</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.liquidity.currentRatio)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Quick Ratio</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.liquidity.quickRatio)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Net Sales / NWC</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.liquidity.netSalesByNwc)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Operating cash flow</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.liquidity.operatingCashFlow)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Free cash flow</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.liquidity.freeCashFlow)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>FCFE</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.liquidity.fcfe)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>FCFF</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.liquidity.fcff)}</NumberDenseCell>;
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Balance Sheet Structure/Gearing</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Debt/TNW</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.balanceSheetStructure.totalDebtByTnw)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Total Debt/ATNW</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.balanceSheetStructure.totalDebtByAtnw)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Total Leverage (TOL/TNW)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.balanceSheetStructure.totalLeverage)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                Adjusted Leverage (TOL /Adjusted TNW)
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.balanceSheetStructure.adjustedLeverage)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            {/**-------------------------------------------------------------------------------- */}
            <TableRow>
              <HeadingDenseCell>Per Share</HeadingDenseCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}></NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                No of Outstanding shares
              </SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.perShare.noOfOutstandingShares)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Par Value of Share</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.perShare.perValueOfShare)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>EPS</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.perShare.eps)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Share Price</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>{formatNumber(data[index]?.perShare.sharePrice)}</NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Book Value per share</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.perShare.bookValuePerShare)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>P/E</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.perShare.pByE)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>PBV</SubCell>
              {_.map(totalYears, (year, index) => {
                return <NumberDenseCell key={index}>{formatNumber(data[index]?.perShare.pBV)}</NumberDenseCell>;
              })}
            </TableRow>
            <TableRow>
              <SubCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Dividend Yield</SubCell>
              {_.map(totalYears, (year, index) => {
                return (
                  <NumberDenseCell key={index}>
                    {formatNumber(data[index]?.perShare.dividendYield)}
                  </NumberDenseCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
