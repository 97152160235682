import { Grid } from '@mui/material';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import DataTable from 'components/common/data-table';
import { ActionCell } from 'components/common/data-table/table-action-cell';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getAllArchiveRequests } from 'services/archive-service';
import { RootState } from 'state/reducers';
import ContentLayout from 'components/common/containers/content-layout';
import { LOAN_TABLE } from '../credit-analysis/loan-proposal/LoanProposalConsts';
import useLoanTable from 'hooks/useLoanTable';
import { routes } from 'constants/strings';
import { viewDocument, getMeta } from 'services/document-services';
import Layout from 'components/layout/layout';
import ActionBar from 'components/styled-components/ActionBar';

const ArchiveList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLoanRequestState, _requestData, getRequestStatusCode } = useLoanTable();
  const { username }: any = useSelector((state: RootState) => state?.user?.userDetails);
  const { data: archiveRequests } = useQuery(['getAllArchiveRequests', username], getAllArchiveRequests);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const getPath = (loanData: any) => {
    return routes.LOAN_PROPOSAL_ROUTE;
  };

  const downloadDocuments = async (type: string, docId: number) => {
    const docData = await getMeta(docId, type, 'LOAN_REQUEST');

    if (docData?.id) {
      const docFile = await viewDocument(docData?.id);
      window.open(URL.createObjectURL(docFile));
      return false;
    } else {
      enqueueSnackbar(`Unable to download ${type} for Loan Request Id: ${docId}`, {
        variant: 'error',
      });
    }
  };

  const gotoLoanRequest = (loanData: any, viewOnly = true, isEdit = false) => {
    navigate(getPath(loanData), {
      state: {
        ...getLoanRequestState(loanData, viewOnly, isEdit),
        from: pathname,
      },
    });
  };

  const isViewEnabled = (loanData: any) => {
    return true
  }

  const isMOMEnabled = (loanData: any) => {
    return loanData?.momFlag
  }

  const isCAMSEnabled = (loanData: any) => {
    return loanData?.camsFlag
  }


  const ACTION = {
    field: 'action',
    pinned: 'right',
    width: 170,
    minWidth: 170,
    maxWidth: 170,
    resizable: false,
    sortable: false,
    filter: false,
    cellRendererFramework: (params: any) => {
      return ActionCell({
        view: {
          viewBtnTitle: 'View',
          onView: () => gotoLoanRequest(params?.data, true, false),
          enabled: isViewEnabled(params?.data),
        },
        mom: {
          momBtnTitle: 'MOM',
          onMom: () => downloadDocuments('MOM', params?.data?.loanRequestId),
          enabled: isMOMEnabled(params?.data),
        },
        cams: {
          camsBtnTitle: 'CAM',
          onCams: () => downloadDocuments('CAM', params?.data?.loanRequestId),
          enabled: isCAMSEnabled(params?.data),
        },
      });
    },
  };

  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [...LOAN_TABLE, { field: 'currentAssignee' }, ACTION],
    []
  );

  return (
    <Layout>
      <ContentLayout title="Archive">
        <Grid container spacing={1}>
          <Grid item md={12} sx={{ height: 'calc(100vh - 175px)' }}>
            <DataTable columnDefs={columnDefs} autoSize={true} rowData={_requestData(archiveRequests)} />
          </Grid>
          <Grid item md={12}>
            <ActionBar></ActionBar>
          </Grid>
        </Grid>
      </ContentLayout>
    </Layout>
  );
};

export default ArchiveList;
