import httpService from './http-service';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

const getMemos = async () => {
  const response = await httpService.get('/workflow/memos', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getMemoById = async (memoId:any) => {
  const response = await httpService.get(`/memo/view/${memoId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


const getMemosDocument = async () => {
  const response = await httpService.get('/refData/memoPreDealDocType', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


const saveMemo = async (data: any) => {
  const res = await httpService.post('/memo/save', data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};


const updateMemo = async (memoId: any, data: any) => {
  const res = await httpService.post(`/memo/update/${memoId}`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

const approveMemo = async (data: any) => {
  const res = await httpService.post('/memo/approve', data, {
    headers: {
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return res.data;
};

const getOpenMemos = async () => {
  const response = await httpService.get('/memo/view/open', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


const getClosedMemos = async () => {
  const response = await httpService.get('/memo/view/closed', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};

const getLoanProposalOpenMemos = async () => {
  const response = await httpService.get('/memo/proposalReview/open', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};


const getLoanProposalClosedMemos = async () => {
  const response = await httpService.get('/memo/proposalReview/closed', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}` || '',
    },
  });
  return response.data;
};




export { getMemos, getMemosDocument, saveMemo, getMemoById, getOpenMemos, getClosedMemos, approveMemo, updateMemo, getLoanProposalOpenMemos, getLoanProposalClosedMemos };
