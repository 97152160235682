import { useEffect } from 'react';
import { Card, CardContent, Grid, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ProposedDeviations from './proposed-deviations';
import ProposalTNC from './sanction-details';
import TC from './t-and-c';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useMutation, useQueries } from 'react-query';
import { getProposalNTc, updateProposalNtc } from 'services/loan-proposal-services';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { StatusCodes } from 'constants/status-codes';
import ActionBar from 'components/styled-components/ActionBar';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import { routes } from 'constants/strings';
import { MENU_ITEMS } from '../projection-menu-schema';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { toggleLoader } from 'state/reducers/common-actions';
import SimpleAccordion from '../../../common/simple-accordian';
import CamButton from 'components/common/review-cam-btn/CamButton';
import useLoanTable from 'hooks/useLoanTable';
import { Raroc } from './raroc';
import { filterGridEmptyData } from 'utils/common-utils';
import { v4 as uuidv4 } from 'uuid';
import GroupExposure from './group-exposure';


export default function ProposalAndTC() {
  const { status }: any = useSelector((state: RootState) => state.loanProposal);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { getRequestStatusCode } = useLoanTable();
  const uniqueId = uuidv4();
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      groupExposure: {
        nonProjectLoan:{},
        capitalFunds:{},
        fields: [
          {
            particulars: 'Amount',
            limitA: '',
            outstandingA: '',
            limitB: '',
            outstandingB: '',
          },
          {
            particulars: 'Project Loan',
            limitA: '',
            outstandingA: '',
            limitB: '',
            outstandingB: '',
          }
        ],
      },
      sanctionDetails: [
        {
          facilityType: 'Fund Based',
          securityApp: 'WC Secured',
          productType: 'Term Loan (TL)',
          isChild: false,
          parentId: uniqueId,
          id: uniqueId,
        },
      ],
      securityCoverCalculations: [{}],
      proposedDeviationsCurrentSanction: [],
      proposedDeviationsPostSanction: [],
      proposedDeviations: '',
      tc: '',
      covenants: [
        {
          type: 'DSCR',
          approvedValue: '',
          testingFrequency: '',
        },
        {
          type: 'Asset Cover',
          approvedValue: '',
          testingFrequency: '',
        },
        {
          type: 'DE Ratio',
          approvedValue: '',
          testingFrequency: '',
        },
      ],
      proposalComment: '',
      fundSubTotal: {
        facility: 'Fund-Based (A)',
        existingLimit: 0,
        renewal: 0,
        additional: 0,
        proposedTotal: 0,
        uid: 'FBTOTAL',
      },
      nonfundSubTotal: {
        facility: 'Non-Fund Based (B)',
        existingLimit: 0,
        renewal: 0,
        additional: 0,
        proposedTotal: 0,
        uid: 'NFBTOTAL',
      },
      fundNonFundTotal: {
        facility: 'Total (A+B)',
        existingLimit: 0,
        renewal: 0,
        additional: 0,
        proposedTotal: 0,
        uid: 'TOTAL',
      },
      comments: {},
    },
  });

  const { t } = useTranslation();
  const watchAllFields = watch();
  const navigate = useNavigate();
  const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');

  useEffect(() => {
    dispatch(toggleLoader(true));
  }, []);

  const queryData = useQueries([
    {
      queryKey: 'getProposalNTc',
      queryFn: () => getProposalNTc(state.loanRequestId),
      onSuccess: (data: any) => {
        if (data) {
          reset({...data });
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    },
  ]);

  const mutation = useMutation((data: any) => updateProposalNtc(state.loanRequestId, data));

  const _onFormSubmit = (data: any) => {
    dispatch(toggleLoader(true));
    data.loanRequestId = state.loanRequestId;
    //@ts-ignore
    const filteredData = filterGridEmptyData(data, ['sanctionDetails']);
    mutation.mutate(filteredData, {
      //@ts-ignore
      onSuccess: (res: AxiosResponse<any, any>) => {
        if (res.status === StatusCodes.OK) {
          enqueueSnackbar('Updated Successfully', {
            variant: 'success',
          });
          queryData[0].refetch();
          return;
        }
      },
      onSettled: () => {
        dispatch(toggleLoader(false));
      },
    });
  };

  const basicProps = {
    t,
    control,
    setValue,
    getValues,
    watchAllFields,
  };

  const _renderSubmitBtn = () => {
    if (state.viewOnly)
      return (
        <ReviewCamButton
          onClick={() => {
            navigate(routes.CAM_ROUTE, {
              state: { ...state },
            });
            const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
            camId && camId?.id && dispatch(changeMenuTab(camId?.id));
          }}
        />
      );

    if (
      state?.statusCode === getRequestStatusCode('DRAFT') ||
      state?.statusCode === getRequestStatusCode('PROPOSAL_PENDING') ||
      state?.statusCode === getRequestStatusCode('REWORK_WITH_RM') ||
      !state?.statusCode
    ) {
      return (
        <ActionBar>
          <Button type="submit" variant="contained" sx={{ p: 1, mr: 2 }}>
            Save Draft
          </Button>
          <CamButton
            sx={{ p: 1, mr: 2 }}
            onClick={() => camId && camId?.id && dispatch(changeMenuTab(camId?.id))}
            camState={state}
          />
        </ActionBar>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(_onFormSubmit)}>
      <Card sx={{ height: 'calc(100vh - 130px)', overflowY: 'scroll', pb: 6 }}>
        <CardContent>
          <Grid container>
            <Grid item md={12} lg={12} sm={12}>
              <SimpleAccordion
                title={'7.1 Proposal & Sanction Details'}
                expand={true}
                children={<ProposalTNC {...basicProps} />}
              />
            </Grid>
             <Grid item md={12} lg={12} sm={12}>
              <SimpleAccordion
                title={'7.2 Single & Group Lending Limit'}
                children={<GroupExposure {...basicProps}/>}
              />
            </Grid>
            <Grid item md={12} lg={12} sm={12}>
              <SimpleAccordion
                title={'7.3 Risk Adjusted Return on Capital (RAROC)'}
                children={<Raroc {...basicProps} />}
              />
            </Grid>
            <Grid item md={12} lg={12} sm={12}>
              <SimpleAccordion
                title={'7.4 Proposed Deviations'}
                children={<ProposedDeviations {...basicProps} />}
              />
            </Grid>
            <Grid item md={12} lg={12} sm={12}>
              <SimpleAccordion title={'7.5 Terms and Conditions'} children={<TC {...basicProps} />} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {_renderSubmitBtn()}
    </form>
  );
}
