import Layout from '../../../layout/layout';
import { Outlet } from 'react-router-dom';

const CompanyMaster = () => (
  <Layout sx={{ width: '100vw' }}>
    <Outlet />
  </Layout>
);

export default CompanyMaster;
