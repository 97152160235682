import { useCallback } from 'react';
import { Grid, Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { useMemo } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';

const TopProducts = ({
  watchAllFields,
  setValue,
  getValues,
}: {
  control: any;
  watchAllFields: any;
  setValue: any;
  getValues: any;
}) => {
  const { state } = useLocation();
  const theme = useTheme();

  const { applicantBasicDetails } = useSelector((state: RootState) => state.projections);

  const { actualsYears } = applicantBasicDetails;
  const actuals = actualsYears && [...actualsYears].sort();

  const defaultColDefs = useMemo(() => {
    let colDef: any = [
      {
        field: 'totalSales',
        headerName: 'Product',
        editable: state?.isEdit,
      },
      {
        field: 'applications',
        headerName: 'Applications',
        editable: state?.isEdit,
      },
    ];
    actuals?.forEach((year: string, idx: number) => {
      let obj = {
        headerName: `${year}`,
        children: [
          {
            field: `amount_${year}`,
            headerName: 'Amount',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: `totalSales_${year}`,
            headerName: 'Total Sales (%)',
            editable: state?.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      };

      colDef.push(obj);
    });

    if (state?.isEdit) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('businessDescription.topProducts').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('businessDescription.topProducts'), {}];
                      setValue('businessDescription.topProducts', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('businessDescription.topProducts').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('businessDescription.topProducts')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('businessDescription.topProducts', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, [actuals]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('businessDescription.topProducts', updatedRowData);
  }, []);

  return (
    <Grid item lg={12}>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle formNumbering="2.2" title="Top Products"></FormTitle>
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={watchAllFields?.businessDescription?.topProducts}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TopProducts;
