import { Box, Button, styled, Typography } from '@mui/material';

const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  height: 'calc(100vh - 140px)',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100px',
  justifyContent: 'space-between',
}));

const EmptyProjectionList = ({ createNewProjection, viewOnly }: any) => {
  return (
    <Container>
      <ContentContainer>
        {viewOnly && <Typography variant="subtitle1">No Projections Yet.</Typography>}
        {!viewOnly && (
          <Button onClick={createNewProjection} variant="contained">
            Create New Projection
          </Button>
        )}
      </ContentContainer>
    </Container>
  );
};

export default EmptyProjectionList;
