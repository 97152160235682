import { styled } from '@mui/material/styles';
import { Typography, Box, Link } from '@mui/material';
import LoginForm from './LoginForm';
import logo from '../../assets/images/logo.svg';
import login1 from '../../assets/images/login1.png';
import login2 from '../../assets/images/login2.svg';
import { useQuery } from 'react-query';
import { getUser } from 'utils/common-utils';
import { getMyRequestsCount } from 'services/loan-request-services';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleLoader } from '../../state/reducers/common-actions';
import { storeUserData } from '../../state/reducers/user-reducer';
import { routes } from 'constants/strings';
import { getPublicConfig } from 'services/common-services';
import company from '../../assets/images/company.png';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  backgroundColor: '#F7F9FD',
}));

const LeftBox = styled('div')(({ theme }) => ({
  width: '70%',
  backgroundColor: theme.palette.primary.main,
}));

const AppLogo = styled('div')(({ theme }) => ({
  width: '50px',
  height: '48px',
  backgroundImage: `url(${logo})`,
  margin: '20px 0px 20px 0px',
}));

const ImageOne = styled('div')(({ theme }) => ({
  width: '793px',
  height: '474px',
  position: 'absolute',
  backgroundImage: `url(${login1})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  top: '50%',
  left: '40%',
  transform: 'translate(-50%, -50%)',
}));

const ImageTwo = styled('div')(({ theme }) => ({
  width: '666px',
  height: '630px',
  position: 'absolute',
  backgroundImage: `url(${login2})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  top: '50%',
  left: '70%',
  transform: 'translate(-50%, -50%)',
}));

const MainBox = styled('div')(({ theme }) => ({
  width: '400px',
  height: '630px',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '25%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
}));

const Footer = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '95%',
  left: '1%',
  color: '#f7f9fd',
}));

const userValue = localStorage.getItem('user');
let userDetails: any = null;
if (typeof userValue === 'string') {
  userDetails = JSON.parse(userValue);
}

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [footerText, setFooterText] = useState('');

  const { isLoading } = useQuery(['getMyRequestsCount', userDetails?.username], getMyRequestsCount, {
    onSuccess: (data: any) => {
      navigate(routes.DASHBOARD_ROUTE, { replace: true });
    },
    onError: async (error: any) => {
      if (error?.response?.status === 401) {
        const user = await getUser();
        if (user) {
          dispatch(storeUserData(user));
          localStorage.setItem('user', JSON.stringify(user));
          navigate(routes.DASHBOARD_ROUTE, { replace: true });
        }
      }
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  useQuery(['getPublicConfig'], getPublicConfig, {
    onSuccess: (data: any) => {
      data.forEach((e: any) => {
        if (e.configKey === 'COPY_RIGHT_MSG') {
          const value = e?.configValue.replace(
            'GlobnX',
            `<img style="height: 20px; width: 50px" src="${company}" />`
          );
          setFooterText(value);
        }
      });
    },
    onError: async (error: any) => {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(toggleLoader(true));
    }
  }, [isLoading, dispatch]);

  return (
    <Box sx={{ height: '100%' }}>
      {!isLoading && (
        <>
          <RootStyle>
            <LeftBox />
            <MainBox>
              <Box sx={{ height: '20%', display: 'flex' }}>
                <AppLogo />
              </Box>
              <Box sx={{ height: '80%' }}>
                <Typography variant="h1" sx={{ mb: 5, color: '#fff' }}>
                  WELCOME TO KREDITACCEL
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: 400, color: '#fff' }}>
                  Login
                </Typography>
                <LoginForm />
              </Box>
            </MainBox>
            <ImageOne />
            <ImageTwo />
            <Footer>
              <div style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: footerText }} />
            </Footer>
          </RootStyle>
        </>
      )}
    </Box>
  );
}
