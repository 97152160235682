import { Grid } from '@mui/material';
import Declaration from './declaration';
import ExposureDetail from './exposure-detail';
import CorporateGovPractices from './corp-gov-practices';
import { FormTitle } from 'components/common/form-components/FormTitle';


const RegulatoryRequirement = (props: any) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <FormTitle title="8.4.a Exposure Declaration" />
        <Declaration {...props} />
      </Grid>
      <Grid item lg={12}>
        <FormTitle title="8.4.b Priority Sector Opportunity" />
        <ExposureDetail {...props} />
      </Grid>
      <Grid item lg={12}>
        <FormTitle title="8.4.c Corporate Governance practices followed" />
        <CorporateGovPractices {...props} />
      </Grid>
    </Grid>
  );
};

export default RegulatoryRequirement;
