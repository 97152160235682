import { ITabSchema } from 'models/common';

export const MENU_ITEMS: ITabSchema[] = [
  { id: 1, name: 'Dashboard', pathName: '', value: `/dashboard` },
  { id: 2, name: 'Company Master', pathName: 'company-master', value: `/dashboard/company-master` },
  { id: 3, name: 'Loan Officer', pathName: 'credit-analysis', value: `/dashboard/credit-analysis` },
  { id: 4, name: 'Risk Unit', pathName: 'risk-unit', value: `/dashboard/risk-unit` },
  {
    id: 5,
    name: 'Approval Authority',
    pathName: 'approval-authority',
    value: `/dashboard/approval-authority`,
  },
  { id: 6, name: 'Credit Admin', pathName: 'credit-admin', value: `/dashboard/credit-admin-list` },
  { id: 7, name: 'Legal Admin', pathName: 'legal-admin', value: `/dashboard/legal-admin-list` },
  { id: 8, name: 'Resource Center', pathName: 'resource-center', value: `/dashboard/resource-center` },
  { id: 9, name: 'Memos', pathName: 'memos', value: `/dashboard/memos` },

  {
    id: 10,
    name: 'Portfolio Management',
    pathName: 'portfolio-management',
    value: `/dashboard/portfolio-management`,
  },
  {
    id: 11,
    name: 'Archive',
    pathName: 'archive',
    value: `/dashboard/archive`,
  },
];
