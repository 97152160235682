import { Provider } from 'react-redux';
import Router from './routes/router';
import store from './state/store';
import './styles/datasheet.css';
import { Suspense, useEffect } from 'react';
import BackdropLoader from 'components/common/backdrop-loader';
import ThemeProvider from 'theme';
import { QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import queryClient from './services/react-query-service';


function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={2000}>
        <Suspense fallback={<BackdropLoader isLoading />}>
          <Provider store={store}>
            <ThemeProvider>          
                <Router />
            </ThemeProvider>
          </Provider>
        </Suspense>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
