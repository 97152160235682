import { Container, Stack, Typography, Box } from '@mui/material';
import Layout from '../../layout/layout';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import ItemCard from './dashboard-card-item';
import SystemNotifications from './SystemNotifications';
import { getMyRequestsCount } from 'services/loan-request-services';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import useLoanTable from 'hooks/useLoanTable';
import ActionBar from 'components/styled-components/ActionBar';
import { routes } from 'constants/strings';
import dashboard from '../../../assets/images/dashboard.svg';
import clock from '../../../assets/icons/clock.png';
import check from '../../../assets/icons/check.png';
import menu from '../../../assets/icons/menu.png';

export default function MyDashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { username }: any = useSelector((state: RootState) => state.user?.userDetails);
  const { data, isSuccess } = useQuery(['getMyRequestsCount', username], getMyRequestsCount);
  const { getHomePage } = useLoanTable();

  return (
    <Layout>
      <Container sx={{ pt: 2, width: '80%' }}>
        <Stack direction="row" spacing={2} sx={{ height: 200, justifyContent: 'center' }}>
          <ItemCard
            onClick={() =>
              navigate(getHomePage(), {
                state: {
                  tabId: 'PENDING_ACTIONS',
                },
              })
            }>
            <Box
              style={{
                backgroundColor: 'rgba(246, 169, 15, 0.12)',
                padding: '14px',
                borderRadius: '10px',
                width: '53px',
                height: '53px',
              }}>
              <img src={clock} alt="Clock" />
            </Box>
            <Typography variant="subtitle1" sx={{ marginTop: '5px', color: '#b7b7b7' }}>
              Pending Actions
            </Typography>
            <Typography variant="h1" sx={{ color: '#676767' }}>
              {isSuccess && data?.PENDING}
            </Typography>
          </ItemCard>
          {getHomePage() !== routes.APPROVAL_AUTHORITY_ROUTE && (
            <ItemCard
              onClick={() =>
                navigate(getHomePage(), {
                  state: {
                    tabId: 'IN_PROGRESS',
                  },
                })
              }>
              <Box
                style={{
                  backgroundColor: 'rgba(98, 128, 255, 0.12)',
                  padding: '14px',
                  borderRadius: '10px',
                  width: '53px',
                  height: '53px',
                }}>
                <img src={menu} alt="Menu" />
              </Box>
              <Typography variant="subtitle1" sx={{ marginTop: '5px', color: '#b7b7b7' }}>
                In Progress
              </Typography>
              <Typography variant="h1" sx={{ color: '#676767' }}>
                {isSuccess && data?.IN_PROGRESS}
              </Typography>
            </ItemCard>
          )}
          <ItemCard
            onClick={() =>
              navigate(getHomePage(), {
                state: {
                  tabId: 'COMPLETED',
                },
              })
            }>
            <Box
              style={{
                backgroundColor: 'rgba(40, 216, 146, 0.12)',
                padding: '14px',
                borderRadius: '10px',
                width: '53px',
                height: '53px',
              }}>
              <img src={check} alt="Check" />
            </Box>
            <Typography variant="subtitle1" sx={{ marginTop: '5px', color: '#b7b7b7' }}>
              Completed Request
            </Typography>
            <Typography variant="h1" sx={{ color: '#676767' }}>
              {isSuccess && data?.CLOSED}
            </Typography>
          </ItemCard>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'center', padding: 2 }}>
          <SystemNotifications />
        </Stack>
      </Container>
      <img
        style={{ width: '438px', height: '361px', position: 'absolute', bottom: '48px', zIndex: -1 }}
        src={dashboard}
        alt="Dashboard"
      />
      <ActionBar></ActionBar>
    </Layout>
  );
}
