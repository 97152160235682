import { FC } from 'react';
import { Box } from '@mui/material';
import { FormTitle } from 'components/common/form-components/FormTitle';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';

interface ICurrentProposal {
  control: any;
  setValue: any;
  getValues: any;
  watchAllFields: any;
  viewMode: any;
}

const CurrentProposal: FC<ICurrentProposal> = ({ setValue, watchAllFields, viewMode }) => {
  const onCurrentProposalChange = (newValue: any) => {
    setValue('financialAssessment.currentProposal', newValue);
  };

  return (
    <>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle title="3. Current Proposal" />
        <Box sx={{ mt: 1 }}>
          <RichTextEditor
            value={watchAllFields?.financialAssessment?.currentProposal}
            onEditorChangeHandler={onCurrentProposalChange}
            disabled={viewMode}
          />
        </Box>
      </Box>
    </>
  );
};

export default CurrentProposal;
