import { IconButton, Tooltip } from '@mui/material';

interface ITooltipIconButton {
  title: string;
  onClick: any;
  icon: React.ReactElement | any;
  sx?: any;
}

export default function TooltipIconButton({ title, onClick, icon, sx }: ITooltipIconButton) {
  return (
    <Tooltip title={title}>
      <IconButton sx={sx} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}
