import { Button, TextField, Stack, Alert } from '@mui/material';
import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleLoader } from '../../state/reducers/common-actions';
import { storeUserData } from '../../state/reducers/user-reducer';
import { routes } from 'constants/strings';
import { useTheme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<any>('');
  const theme = useTheme();

  const onLogin = async (e: any) => {
    e.preventDefault();
    dispatch(toggleLoader(true));
    setError('');
    try {
      const data = {
        username,
        password,
      };

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/authenticate`, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      dispatch(toggleLoader(false));

      if (res.data.jwtToken) {
        dispatch(storeUserData(res?.data));
        localStorage.setItem('user', JSON.stringify(res?.data));
        return navigate(routes.DASHBOARD_ROUTE);
      } else {
        setError('Wrong username or password. Try again');
      }
      return console.log('Something wrong while login');
    } catch (error: any) {
      dispatch(toggleLoader(false));
      if (error?.response?.data?.status >= 401 && error?.response?.data?.status < 500) {
        setError('Wrong username or password. Try again');
      } else {
        setError('Something went wrong');
      }
    }
  };

  return (
    <>
      <Stack sx={{ mb: 2 }} direction="row" alignItems="center" justifyContent="center">
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>

      <form onSubmit={onLogin}>
        <Stack spacing={3}>
          <OutlinedInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
            id="username"
            placeholder="Username"
            value={username}
            sx={{ backgroundColor: '#F7F9FD' }}
          />
          <OutlinedInput
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            id="password"
            type="password"
            value={password}
            placeholder="Password"
            sx={{ backgroundColor: '#F7F9FD' }}
          />
        </Stack>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          sx={{ mt: 2, backgroundColor: '#F7F9FD', color: theme.palette.primary.main }}>
          Login
        </Button>
      </form>
    </>
  );
}

export default LoginForm;
