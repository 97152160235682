import { Container, Stack, Typography, Link, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ItemCard from 'components/dashboard/my-dashboard/dashboard-card-item';
import ActionBar from 'components/styled-components/ActionBar';

export const PortfolioReport = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ pt: 2, width: '80%' }}>
        <Stack direction="row" spacing={2} sx={{ height: 350, justifyContent: 'center' }}>
          {/* <ItemCard
            onClick={() =>
              navigate('/dashboard/portfolio-management/call-report', {
                state: {
                  tabId: '1',
                },
              })
            }>
            <div style={{ backgroundColor: '#f2f2f2', padding: 8, borderRadius: 100 }}>
              <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main, height: 50, width: 50 }} />
            </div>
            <Typography variant="subtitle2">Total Portfolio Report (Credit, Non-Credit, CA)</Typography>
          </ItemCard>
          <ItemCard
            onClick={() =>
              navigate('/dashboard/portfolio-management/yearly-plan', {
                state: {
                  tabId: '1',
                },
              })
            }>
            <div style={{ backgroundColor: '#f2f2f2', padding: 8, borderRadius: 100 }}>
              <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main, height: 50, width: 50 }} />
            </div>
            <Typography variant="subtitle2">Status Wise Report</Typography>
          </ItemCard>
          <ItemCard
            onClick={() =>
              navigate('/dashboard/portfolio-management/account-plan', {
                state: {
                  tabId: '1',
                },
              })
            }>
            <div style={{ backgroundColor: '#f2f2f2', padding: 8, borderRadius: 100 }}>
              <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main, height: 50, width: 50 }} />
            </div>
            <Typography variant="subtitle2">Weekly Drawdown And Repayment</Typography>
          </ItemCard> */}
          <ItemCard
            onClick={() =>
              navigate('/dashboard/portfolio-management/power-bi', {
                state: {
                  tabId: '1',
                },
              })
            }>
            <div style={{ backgroundColor: '#f2f2f2', padding: 8, borderRadius: 100 }}>
              <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main, height: 50, width: 50 }} />
            </div>
            <Typography variant="subtitle2">Portfolio Report</Typography>
          </ItemCard>
          <ItemCard>
            <div style={{ backgroundColor: '#f2f2f2', padding: 8, borderRadius: 100 }}>
              <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main, height: 50, width: 50 }} />
            </div>
            <Typography variant="subtitle2">Income Details</Typography>
          </ItemCard>
        </Stack>
      </Container>
      <ActionBar></ActionBar>
    </>
  );
};

export default PortfolioReport;
