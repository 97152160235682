import { Table, Box } from '@mui/material';
import TableHeading from 'components/common/table-header';
import { RichTextEditor } from 'components/common/richTextEditor/RichTextEditor';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';

const ProfitabilityAssumptions = ({ control, setValue, getValues, watchAllFields }: any) => {
  const { state } = useLocation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="6.1.a Assumptions of Profitability" />
      <Box sx={{ mt: 0.5 }}>
        <RichTextEditor
          value={watchAllFields?.keyAssumptions || ''}
          onEditorChangeHandler={(newValue: any) => {
            setValue('keyAssumptions', newValue);
          }}
          disabled={state.viewOnly}
        />
      </Box>
    </Box>
  );
};

export const ProfitabilityAssumptionsGuarantor = ({ control, setValue, getValues, watchAllFields, title }: any) => {
  const { state } = useLocation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={title} />
      <Box sx={{ mt: 0.5 }}>
        <RichTextEditor
          value={watchAllFields?.keyAssumptionsGuarantor || ''}
          onEditorChangeHandler={(newValue: any) => {
            setValue('keyAssumptionsGuarantor', newValue);
          }}
          disabled={state.viewOnly}
        />
      </Box>
    </Box>
  );
};

export default ProfitabilityAssumptions;
