// import { CHANGE_SIDEBAR_ITEM } from '../actions/actionTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISidebarState {
  selectedIndex: number;
  isOpen: boolean;
}

const initialState: ISidebarState = {
  selectedIndex: 0,
  isOpen: false,
};

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    changeMenu: (state: ISidebarState, action: PayloadAction<any>) => {
      return { ...state, selectedIndex: action.payload };
    },
    toggleDrawer: (state: ISidebarState, action: PayloadAction<boolean>) => {
      return { ...state, isOpen: action.payload };
    },
  },
});

const { actions, reducer } = sideBarSlice;

export const { changeMenu, toggleDrawer } = actions;

export default reducer;

// const sideBarReducer = (state = initialState, action: any) => {
//   const { type, payload } = action;

//   switch (type) {
//     case CHANGE_SIDEBAR_ITEM:
//       return {
//         ...state,
//         selectedIndex: payload === undefined ? 0 : payload,
//       };
//     default:
//       return { ...state };
//   }
// };

// export default sideBarReducer;
