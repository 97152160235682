import { Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Divider } from '@mui/material';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import TableCommentRow from 'components/common/table/table-comment-row';
import _ from 'lodash';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { formatNumber } from 'utils/common-utils';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

interface IProps extends IPerformanceSummaryTableProps {
  data: any;
}

export default function DebtServiceability({ t, totalYears, data, control }: IProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ pl: 0.5, pr: 0.5, mt: 1, mb: 1 }}>
        <Typography variant="caption">
          <b>{t('loanProjection.performanceSummary.debtServiceability')}</b>
        </Typography>
        <Divider />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Year ended March 31</b>
              </Typography>
            </TableCell>
            {_.map(totalYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseCellSm>Total debt/EBIDTA (times)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].totalDebtByEbitda)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Total debt/NCA (times)</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].totalDebitByNca)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Interest Coverage Ratio</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].interestCover)}</NumberDenseCell>;
            })}
          </TableRow>
          <TableRow>
            <DenseCellSm>Debt Service Coverage Ratio</DenseCellSm>
            {_.map(totalYears, (_, i: number) => {
              return <NumberDenseCell key={i}>{formatNumber(data[i].debtServiceCoverageRatio)}</NumberDenseCell>;
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
