import { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';
import { NumericEditor } from 'components/common/data-table/numericEditor';

const DirectorsList = ({ control, getValues, setValue, watchAllFields }: any) => {
  const { isViewOnly } = useSelector((state: RootState) => state.registerCompany);
  const theme = useTheme();

  const directorListColDefs = useMemo(() => {
    var colDef: any = [
      {
        field: 'position',
        headerName: 'Position',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'middleName',
        headerName: 'Middle Name',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'pan',
        headerName: 'PAN #',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'din',
        headerName: 'DIN # (if applicable)',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'email',
        headerName: 'Email',
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'mobile',
        headerName: 'Mobile Phone #',
        cellEditor: NumericEditor,
        editable: isViewOnly ? false : true,
        width: 150,
      },
      {
        field: 'officePhone',
        headerName: 'Office Phone #',
        cellEditor: NumericEditor,
        editable: isViewOnly ? false : true,
        width: 150,
      },
    ];
    if (!isViewOnly) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              {params.rowIndex === getValues('directors').length - 1 && (
                <TooltipIconButton
                  onClick={() => {
                    params.api.stopEditing();
                    setTimeout(() => {
                      let fields = [...getValues('directors'), {}];
                      setValue('directors', fields);
                      params.api.setRowData(fields);
                    }, 100);
                  }}
                  icon={getIcon(ADD, theme.palette.primary.main)}
                  title="Add"
                />
              )}
              {getValues('directors').length > 1 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('directors')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('directors', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, []);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('directors', updatedRowData);
  }, []);

  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title="Key Management (List of Directors)" />
      <Box sx={{ mt: 1 }}>
        <DataTable
          columnDefs={directorListColDefs}
          autoSize={true}
          rowData={watchAllFields?.directors}
          pagination={false}
          domLayout={'autoHeight'}
          sizeToFit={true}
          style={{ height: '100%' }}
          onCellValueChanged={onCellValueChanged}
        />
      </Box>
    </Box>
  );
};

export default DirectorsList;
