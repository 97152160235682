import { Outlet } from 'react-router-dom';
import CreditLayout from './CreditLayout';

const Credit = () => {
  return (
    <CreditLayout>
      <Outlet />
    </CreditLayout>
  );
};

export default Credit;
