import { useQuery } from 'react-query';
import DataTable from 'components/common/data-table';
import { downloadDocument } from 'services/document-services';
import { getResourceCenterDocs } from 'services/common-services';
import { Box, Grid } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ArticleIcon from '@mui/icons-material/Article';
import { ColGroupDef } from 'ag-grid-community';
import Layout from 'components/layout/layout';
import ContentLayout from 'components/common/containers/content-layout';
import ActionBar from 'components/styled-components/ActionBar';

const columns = [
  {
    field: 'srNo',
    headerName: 'Sr. No.',
    width: 90,
    maxWidth: 120,
    sortable: true,
    resizable: false,
    pinned: 'left',
  },
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'docTypeCode',
    headerName: 'Type',
    sortable: true,
  },
  {
    field: 'docFileName',
    headerName: 'File Name',
    sortable: true,
  },
  {
    field: 'docDesc',
    headerName: 'Description',
    sortable: true,
  },

  {
    field: 'action',
    headerName: 'Action',
    pinned: 'right',
    width: 50,
    sortable: false,
    cellRendererFramework: (params: any) => {
      return (
        <div
          style={{ cursor: 'pointer', padding: '8px', textAlign: 'center' }}
          onClick={() => downloadDocuments(params?.data)}>
          <DownloadForOfflineIcon fontSize="small" />
        </div>
      );
    },
  },
];

const downloadDocuments = async (docDetails: any) => {
  await downloadDocument(docDetails);
  return false;
};

const getRows = (data: any): ColGroupDef[] => {
  let rows = null;
  if (data) {
    rows = data?.map((doc: any, i: number) => {
      return { ...doc, srNo: i + 1 };
    });
  }
  return rows;
};

const ResourceCenter = () => {
  const { data } = useQuery(['getResourceCenterDocs'], getResourceCenterDocs);

  return (
    <Layout>
      <ContentLayout title="Resource Center">
        <Grid container spacing={1}>
          <Grid item md={12} sx={{ height: 'calc(100vh - 175px)' }}>
            <DataTable columnDefs={columns} autoSize={false} rowData={getRows(data)} />
          </Grid>
          <Grid item md={12}>
            <ActionBar></ActionBar>
          </Grid>
        </Grid>
      </ContentLayout>
    </Layout>
  );
};

export default ResourceCenter;
