import { Box } from '@mui/material';
import { IPerformanceSummaryTableProps } from 'models/loan-projection/performance-summary';
import { FormTitle } from 'components/common/form-components/FormTitle';
import SimpleFormLabel from 'components/common/form-components/form-label';
import { getFormFields } from 'utils/form-utils';
import { MULTI_LINE_INPUT } from 'constants/form-components';
import { useLocation } from 'react-router-dom';

export default function FuturePlans({ t, control }: IPerformanceSummaryTableProps) {
  const { state } = useLocation();
  return (
    <Box sx={{ pl: 0.5, pr: 0.5 }}>
      <FormTitle title={t('loanProjection.performanceSummary.futurePlans')} />
      <Box sx={{ mt: 1 }}>
        {getFormFields(
          {
            name: 'futurePlans',
            component: MULTI_LINE_INPUT,
            label: 'Comment',
          },
          control,
          state.viewOnly
        )}
      </Box>
    </Box>
  );
}
