import { Alert, Paper, AlertTitle } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { getSystemNotification } from 'services/common-services';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlertDialog from 'components/common/modals/AlertDialog';
import { makeStyles } from '@mui/styles';

const SystemNotifications = () => {
  const { data, isSuccess } = useQuery(['getSystemNotification'], getSystemNotification);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');

  const useStyles = makeStyles({
    fullWidth: {
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
  });

  const classes = useStyles();
  const showDialog = ({ open, title, description, type }: any) => {
    setTitle(title);
    setDescription(description);
    setType(type === 'WARN' ? 'warning' : 'info');
    setOpen(open);
  };

  return (
    <Paper
      sx={{
        width: '630px',
        height: '100%',
        backgroundColor:
          isSuccess && data?.some((n: any) => n?.type?.toUpperCase() === 'WARN')
            ? 'rgb(255, 252, 240)'
            : 'rgb(241, 249, 255)',
      }}
      square>
      {open && <AlertDialog open={open} setOpen={setOpen} title={title} description={description} type={type} />}
      <Alert
        className={classes.fullWidth}
        sx={{ pt: 2, width: '100%' }}
        variant="outlined"
        severity={isSuccess && data?.some((n: any) => n?.type?.toUpperCase() === 'WARN') ? 'warning' : 'info'}>
        <AlertTitle>
          <Typography variant="subtitle1">System Notifications</Typography>
        </AlertTitle>
        <div style={{ padding: '2px', width: '100%' }}>
          {isSuccess &&
            data?.map((n: any) => (
              <div key={n?.id} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="caption">{n?.title}</Typography>
                </div>
                <div
                  onClick={() =>
                    showDialog({ open: true, title: n?.title, description: n?.sysDetails, type: n?.type })
                  }>
                  <VisibilityIcon fontSize="small" />
                </div>
              </div>
            ))}

          {isSuccess && _.isEmpty(data) && (
            <div>
              <Typography variant="caption">No Notification</Typography>
            </div>
          )}
        </div>
      </Alert>
    </Paper>
  );
};

export default SystemNotifications;
