import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import BackdropLoader from '../common/backdrop-loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import NavBar from 'components/common/navbar';
import { CssBaseline } from '@mui/material';

const APP_BAR = 50;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: 'calc(100vh - ' + APP_BAR + ')',
  marginTop: APP_BAR,
}));

export default function Dashboard() {
  const { loader } = useSelector((state: RootState) => state.common);
  return (
    <RootStyle>
      <CssBaseline />
      <BackdropLoader isLoading={loader} />
      <NavBar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
