import { Grid, Box, Button } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducers';
import useRefData from 'hooks/ref-data';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FormTitle } from 'components/common/form-components/FormTitle';
import DataTable from 'components/common/data-table';
import { useMemo } from 'react';
import { formatGridNumber } from 'utils/common-utils';
import { NumericEditor } from 'components/common/data-table/numericEditor';
import TooltipIconButton from 'components/common/tooltip-icon-button';
import getIcon from 'icons/icons';
import { ADD, DELETE } from 'icons/icon-names';
import { useTheme } from '@mui/styles';


const TopCustomers = ({
  watchAllFields,
  setValue,
  getValues,
}: {
  control: any;
  watchAllFields: any;
  setValue: any;
  getValues: any;
}) => {
  const { t } = useTranslation();
  const { fn } = useRefData();
  const { state } = useLocation();
  const theme = useTheme();
  const { applicantBasicDetails } = useSelector((state: RootState) => state.projections);

  const _getFiscalYear = useCallback(() => {
    return fn.mapFiscalYear(applicantBasicDetails?.fiscalYearTypeId?.id);
  }, [applicantBasicDetails]);

  const { actualsYears } = applicantBasicDetails;
  const actuals = actualsYears && [...actualsYears].sort();

  const defaultColDefs = useMemo(() => {
    let colDef: any = [
      {
        field: 'totalSales',
        headerName: 'Company Name',
        editable: state.isEdit,
      },
    ];
    actuals?.forEach((year: string, idx: number) => {
      let obj = {
        headerName: `${year}`,
        children: [
          {
            field: `amount_${year}`,
            headerName: 'Amount',
            editable: state.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
          {
            field: `totalSales_${year}`,
            headerName: 'Total Sales (%)',
            editable: state.isEdit,
            valueFormatter: formatGridNumber,
            cellEditor: NumericEditor,
            cellStyle: { textAlign: 'right' },
          },
        ],
      };

      colDef.push(obj);
    });
    if (state.isEdit) {
      colDef.push({
        field: '',
        headerName: 'Actions',
        width: 100,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRendererFramework: (params: any) => {
          return (
            <>
              <TooltipIconButton
                onClick={() => {
                  params.api.stopEditing();
                  setTimeout(() => {
                    let fields = [...getValues('businessDescription.topCustomers'), {}];
                    setValue('businessDescription.topCustomers', fields);
                    params.api.setRowData(fields);
                  }, 100);
                }}
                icon={getIcon(ADD, theme.palette.primary.main)}
                title="Add"
              />
              {params.rowIndex > 0 && (
                <TooltipIconButton
                  onClick={(e: any) => {
                    let updatedFields = [...getValues('businessDescription.topCustomers')];
                    if (updatedFields.length > 0) {
                      updatedFields.splice(params.node.rowIndex, 1);
                      setValue('businessDescription.topCustomers', updatedFields);
                      params.api.setRowData(updatedFields);
                    }
                  }}
                  icon={getIcon(DELETE, theme.palette.error.main)}
                  title="Delete"
                />
              )}
            </>
          );
        },
      });
    }
    return colDef;
  }, [actuals]);

  const onCellValueChanged = useCallback((event) => {
    let updatedRowData: any = [];
    event.api.forEachNode((e: any) => {
      updatedRowData.push(e.data);
    });
    setValue('businessDescription.topCustomers', updatedRowData);
  }, []);

  return (
    <Grid item lg={12}>
      <Box sx={{ pl: 0.5, pr: 0.5 }}>
        <FormTitle formNumbering='2.3' title={t('loanProjection.businessBackgroud.businessDescription.topCustomers')}></FormTitle>
        <Box sx={{ mt: 1 }}>
          <DataTable
            columnDefs={defaultColDefs}
            autoSize={true}
            rowData={watchAllFields?.businessDescription?.topCustomers}
            pagination={false}
            domLayout={'autoHeight'}
            sizeToFit={true}
            style={{ height: '100%' }}
            onCellValueChanged={onCellValueChanged}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TopCustomers;
