import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ReviewCamButton from 'components/common/review-cam-btn/review-cam-btn';
import HeadingDenseCell from 'components/common/table/heading-dense-cell';
import DenseCellSm from 'components/common/table/styled-dense-cell';
import TableHeading from 'components/common/table-header';
import { roles, routes } from 'constants/strings';
import useLocalStore from 'hooks/user-local-store';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormsData } from 'services/loan-projection-services';
import { RootState } from 'state/reducers';
import { toggleLoader } from 'state/reducers/common-actions';
import { changeMenuTab } from 'state/reducers/tabReducer';
import { Row, SubRow, SubSubRow } from '../../../../../common/table/table-data-row/rows';
import { MENU_ITEMS } from '../../../projection-menu-schema';
import { formatNumber } from 'utils/common-utils';
import { MainCard } from 'components/layout/styles';
import { NumberDenseCell } from 'components/common/table/NumberDenseCell';

export default function FormIV() {
  const { state } = useLocation();
  const { tabs, selectedTab } = useSelector((state: RootState) => state.projections);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const projectionId: number = tabs[selectedTab].id;

  const { data, isLoading } = useQuery(
    'getFormIIIData',
    () => getFormsData(state.loanRequestId, projectionId, 'formIV'),
    {
      onSuccess: () => {
        dispatch(toggleLoader(false));
      },
    }
  );

  if (isLoading) {
    dispatch(toggleLoader(true));
    return null;
  }
  const numberOfYears: string[] = _.map(data?.form4, (e) => e.year);

  const dataArr = _.map(numberOfYears, (year, idx: number) => {
    if (data?.form4[idx].actuals) {
      return data?.form4[idx].actuals;
    }
    return data?.form4[idx]?.projection;
  });

  return (
    <MainCard>
      <Table size="small">
        <TableHeading title="Current Assets & Current Liabilities" />
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              width={'25%'}
              sx={{
                background: '#F8F8F8',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}>
              <Typography>
                <b>Assessment of working capital requirement</b>
              </Typography>
            </TableCell>
            {_.map(numberOfYears, (year: string) => (
              <TableCell
                key={year}
                align="center"
                sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', background: '#F8F8F8' }}>
                <Typography>
                  <b>{year}</b>
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>A. Current Assets</HeadingDenseCell>
          </TableRow>
          <Row
            heading
            title="Raw Materials"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.rawMaterial?.rawMaterialValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.rawMaterial?.domestic.domesticValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubSubRow
            title="Month's Consumption"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.rawMaterial?.domestic.monthlyConsumption)}
                </NumberDenseCell>
              );
            })}
          />

          <SubRow
            title="Imported"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.rawMaterial?.imported.importedValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubSubRow
            title="Month's Consumption"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.rawMaterial?.imported.monthlyConsumption)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Stores & Spares"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.storesAndSpare?.storesAndSpareValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubRow
            title="Domestic"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.storesAndSpare?.domestic.domesticValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubSubRow
            title="Month's Consumption"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.storesAndSpare?.domestic.monthlyConsumption)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Imported"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.storesAndSpare?.imported.importedValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubSubRow
            title="Month's Consumption"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.storesAndSpare?.imported.monthlyConsumption)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Stock-in-process"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.stockInProcess?.stockInProcessValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Month's cost of production"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.stockInProcess?.monthCostOfProduction)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Finished Goods"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.finishedGoods?.finishedGoodsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Month's cost of sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.finishedGoods?.monthCostOfSale)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Allowance for Inventory (-)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <HeadingDenseCell key={idx} align="right">
                  {formatNumber(dataArr[idx]?.currentAssets?.allowanceForInventory)}
                </HeadingDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Domestic receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.domesticReceivable?.domesticReceivableValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Month's domestic sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.domesticReceivable?.monthDomesticSale)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Export receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.exportReceivable?.exportReceivableValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Month's export sales"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.exportReceivable?.monthExportSale)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Net Impairment of doubtful debt (trade)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.impairementOfDoubtfulDebt)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Other current assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherCurrentAssetsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Cash & Cash Equivalents"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.cashAndCashEquivalents)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other Bank Balances"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherBankBalance)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Investments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.investments.investmentsValue)}
                </NumberDenseCell>
              );
            })}
          />

          <SubSubRow
            title="Investments in Government Securities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.investments.investmentInGovtSecurities
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Investments in Equity Instruments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.investments.investmentInEquity)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Investment in Mutual Funds"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.investments.investmentInMutualFund
                  )}
                </DenseCellSm>
              );
            })}
          />
          {/*<SubSubRow
            title="Adjustment in valuation of current investments"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.investments.adjustmentInValuation)}</NumberDenseCell>
              );
            })}
          />
*/}
          <SubRow
            title="Other Financial Assets"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherFinancialAssets)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other Operating Current Asset"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset
                      .otherOperatingCurrentAssetsValue
                  }
                </DenseCellSm>
              );
            })}
          />
          {/***here */}
          <SubSubRow
            title="Advances to Suppliers (Excluding on Capital a/c)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.advanceToSupplier
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="GST tax"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.gstTax
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Loans & Advances to subsidiaries"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset
                      .loansAndAdvancesToSubs
                  }
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Export Incentive receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.exportIncentives
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="VAT/Cenvat Receivables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.vat)}
                </NumberDenseCell>
              );
            })}
          />
          <SubSubRow
            title="Advance royalty"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.advanceRoyalty
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Deposit and Advance with Authorities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset
                      .depositAndAdvanceToAuthority
                  }
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Prepaid expenses"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.prepaidExpense
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubSubRow
            title="Others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentAssets?.otherCurrentAssets?.otherOperatingCurrentAsset.others
                  )}
                </DenseCellSm>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell>Total Current Assets-A</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell heading key={idx}>
                  {formatNumber(dataArr[idx]?.currentAssets['totalCurrentAssets-A'])}
                </NumberDenseCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell colSpan={numberOfYears?.length + 1}>&nbsp;</TableCell>
          </TableRow>
          {/** LIABILITIES */}
          <TableRow>
            <HeadingDenseCell colSpan={numberOfYears?.length + 1}>B. Current Liabilities</HeadingDenseCell>
          </TableRow>
          <Row
            heading
            title="Other Working Capital STD"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherWorkingCapitalStd?.otherWorkingCapitalSTDValue
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Short term borrowings from others"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherWorkingCapitalStd?.shortTermBorrowing)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Mobilization advance- current portion"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherWorkingCapitalStd?.mobilizationAdvance)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Trade Payable"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.tradePayable?.tradePayableValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Month's purchases"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.tradePayable?.monthPurchase)}
                </NumberDenseCell>
              );
            })}
          />

          <Row
            heading
            title="Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.provisions?.provisionsValue)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Provison for Employee benefits"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.provisions?.provisionForEmployeeBenefit)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Other Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.provisions?.otherProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <Row
            heading
            title="Installment of liabilities due within 1 Year"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.installmentOfLiabilitiesValue
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Preference Capital (redeemed after 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.preferenceCapital)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Debentures (maturity > 1 year)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.debentures)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Current portion of LTD"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.currentPortionOfLTD)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Borrowing from Subsidiaries & Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.borrowingFromSubs)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Intercorporate Deposits"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.interCorporateDeposit)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Commercial Paper"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.commercialPaper)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Current portion of lease obligations"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.installmentOfLiabilities?.currentPortionOfLeaseObligations
                  )}
                </DenseCellSm>
              );
            })}
          />
          <Row
            heading
            title="Other Financial Liabilities"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.otherFinancialLiabilitiesValue
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Advances from Affiliates & Associates"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.advancesFromAffiliates
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Tax Provisions"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.taxProvisions)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Dividends Payable"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.dividendsPayable)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Liability for Operating Expenses"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.liabilityForOperatingExpense
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Creditors for Capital Goods"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.creditorsForCapitalGoods
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Earnest money on security deposits"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.earnestMoney)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Payable to employees"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.payableToEmployees)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Accrued Interest"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.accruedInterest)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Accrued Interest on borrrowings"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <DenseCellSm key={idx} align="right">
                  {formatNumber(
                    dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.accruedInterestOnBorrowing
                  )}
                </DenseCellSm>
              );
            })}
          />
          <SubRow
            title="Others (Derviatives and others)"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.others)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Advance from customers"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.advanceFromCustomers)}
                </NumberDenseCell>
              );
            })}
          />
          <SubRow
            title="Statutory Dues Payables"
            data={_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities?.otherFinancialLiabilities?.statutoryDuesPayable)}
                </NumberDenseCell>
              );
            })}
          />
          <TableRow>
            <HeadingDenseCell>Total Current Liabilities excluding Bank Borrowings-B</HeadingDenseCell>
            {_.map(numberOfYears, (_, idx: number) => {
              return (
                <NumberDenseCell key={idx}>
                  {formatNumber(dataArr[idx]?.currentLiabilities['totalCurrentLiabilitiesExclBankBorrowings-B'])}
                </NumberDenseCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>

      <ReviewCamButton
        onClick={() => {
          navigate(routes.CAM_ROUTE, {
            state: { ...state, loanRequestId: state.loanRequestId },
          });
          const camId = MENU_ITEMS.find((req: any) => req?.name === 'CAM');
          camId && camId?.id && dispatch(changeMenuTab(camId?.id));
        }}
      />
    </MainCard>
  );
}
