import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import CreditAnalysis from '../components/dashboard/credit-analysis';
import CompanyMaster from '../components/dashboard/credit-analysis/company-master';
import LoanProposal from '../components/dashboard/credit-analysis/loan-proposal';
import PendingApproval from '../components/dashboard/status/pending-approval';
import Dashboard from '../components/dashboard/main-page';
import RiskUnit from '../components/dashboard/risk-unit';
import MemosLayout from '../components/dashboard/memos/MemosLayout';
import Memos from '../components/dashboard/memos';
import Login from '../components/login/Login';
import PendingDisbursment from '../components/dashboard/status/pending-disbursment';
import PendingCompliances from '../components/dashboard/status/pending-compliances';
import LoanRequests from '../components/dashboard/credit-analysis/loan-proposal/loan-requests';
import CreateNewLoanRequest from '../components/dashboard/credit-analysis/loan-proposal/loan-requests/new-loan-request';
import FinancialProjections from '../components/dashboard/credit-analysis/loan-proposal/FinancialProjections';
import MyDashboard from '../components/dashboard/my-dashboard';
import RiskRationale from '../components/dashboard/risk-unit/risk-rationale';
import ApprovalAuthority from '../components/dashboard/approval-authority';
import RiskRationaleDoc from '../components/dashboard/approval-authority/RiskRationaleDoc';
import AddCompany from '../components/dashboard/credit-analysis/company-master/register-company-form';
import ListedCompanies from '../components/dashboard/credit-analysis/company-master/company-list-table/listed-companies';
import Projection from '../components/dashboard/loan-projection-and-proposal/projection-components/financial-projection';
import BusinessBackground from '../components/dashboard/loan-projection-and-proposal/business-background';
import Annexure from '../components/dashboard/loan-projection-and-proposal/annexure/annexure';
import CAM from '../components/dashboard/loan-projection-and-proposal/cam/CAM';
import ReviewComment from '../components/dashboard/memos/ReviewComment';
import CreateMemo from '../components/dashboard/memos/create-memo';
import MemosRequest from '../components/dashboard/memos/MemosRequest';
import Compliance from '../components/dashboard/loan-projection-and-proposal/compliance';
import ProposalAndTermsCondition from '../components/dashboard/loan-projection-and-proposal/proposals-and-tc';
import ApprovalDetails from 'components/dashboard/approval-authority/approval-details'
import AuthorityDocuments from 'components/dashboard/approval-authority/AuthorityDocuments'
import ApprovalAuth from 'components/dashboard/approval-authority/ApprovalAuth';
import LoanApproval from 'components/dashboard/approval-authority/loan-approval';
import Visualization from 'components/dashboard/loan-projection-and-proposal/visualization';
import DocumentPreparation from 'components/dashboard/loan-projection-and-proposal/document-preparation';
import ResourceCenter from 'components/dashboard/resource-center';
import ArchiveList from 'components/dashboard/archive';
import SanctionLetter from 'components/dashboard/credit-admin/SanctionLetter';
import DocumentSubmission from 'components/dashboard/credit-admin/DocumentSubmission';
import CreditList from 'components/dashboard/credit-admin';
import DocumentList from 'components/dashboard/legal-admin/DocumentList';
import FinalDocumentList from 'components/dashboard/legal-admin/FinalDocumentList';
import LegalList from 'components/dashboard/legal-admin';
import Credit from 'components/dashboard/credit-admin/Credit';
import Legal from 'components/dashboard/legal-admin/Legal';
import PortfolioManagement from 'components/dashboard/portfolio-management';
import Portfolio from 'components/dashboard/portfolio-management/Portfolio';
import StatusTracker from 'components/dashboard/portfolio-management/StatusTracker';
import DrawdownForecast from 'components/dashboard/portfolio-management/DrawdownForecast';
import PortfolioReport from 'components/dashboard/portfolio-management/PortfolioReport';
import CallReport from 'components/dashboard/portfolio-management/CallReport';
import YearlyPlan from 'components/dashboard/portfolio-management/YearlyPlan';
import AccountPlan from 'components/dashboard/portfolio-management/AccountPlan';
import PowerBi from 'components/dashboard/portfolio-management/PowerBi';

const getToken = () => {
  const userValue = localStorage.getItem('user');
  let user;
  if (typeof userValue === 'string') {
    user = JSON.parse(userValue);
  }
  return user?.jwtToken;
};

const Router = () => {
  let location = useLocation();

  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/dashboard',
      element: getToken() ? <Dashboard /> : <Navigate to="/" state={{ from: location }} replace />,
      children: [
        {
          path: '',
          element: <MyDashboard />,
        },
        {
          path: 'loan-proposal',
          element: <FinancialProjections />,
          children: [
            { path: '', element: <Projection /> },
            { path: 'risk', element: <RiskRationale /> },
            { path: 'business-background', element: <BusinessBackground /> },
            { path: 'annexure', element: <Annexure /> },
            { path: 'compliance', element: <Compliance /> },
            { path: 'tc', element: <ProposalAndTermsCondition /> },
            { path: 'cam', element: <CAM /> },
            { path: 'visualisation', element: <Visualization /> },
            { path: 'review-comment', element: <ReviewComment /> },
            { path: 'document-preparation', element: <DocumentPreparation /> },
          ],
        },
        {
          path: 'credit-analysis',
          element: <CreditAnalysis />,
          children: [
            {
              path: '',
              element: <LoanProposal />,
              children: [
                { path: '', element: <LoanRequests /> },
                { path: 'new-loan-request', element: <CreateNewLoanRequest /> },
              ],
            },
          ],
        },
        {
          path: 'portfolio-management',
          element: <PortfolioManagement />,
          children: [
            {
              path: '',
              element: <Portfolio />,
            },
            { path: 'status-tracker', element: <StatusTracker /> },
            { path: 'drawdown-forecast', element: <DrawdownForecast /> },
            { path: 'portfolio-report', element: <PortfolioReport /> },
            { path: 'call-report', element: <CallReport /> },
            { path: 'yearly-plan', element: <YearlyPlan /> },
            { path: 'account-plan', element: <AccountPlan /> },
            { path: 'power-bi', element: <PowerBi /> },
          ],
        },
        {
          path: 'risk-unit',
          element: <RiskUnit />,
          children: [{ path: '', element: <RiskRationale /> }],
        },
        {
          path: 'approval-authority',
          element: <ApprovalAuth />,
          children: [
            {
              path: '',
              element: <ApprovalAuthority />,
            },
            {
              path: 'approval',
              element: <ApprovalDetails />,
              children: [
                { path: '', element: <CAM /> },
                { path: 'risk-rationale', element: <RiskRationaleDoc /> },
                { path: 'authority-visualisation', element: <Visualization /> },
                { path: 'authority-documents', element: <AuthorityDocuments /> },
              ],
            },
          ],
        },
        { path: 'pending-approval', element: <PendingApproval /> },
        { path: 'pending-disbursment', element: <PendingDisbursment /> },
        { path: 'pending-compliances', element: <PendingCompliances /> },
        {
          path: 'resource-center',
          element: <ResourceCenter />,
        },
        {
          path: 'company-master',
          element: <CompanyMaster />,
          children: [
            { path: '', element: <ListedCompanies /> },
            { path: 'add-company', element: <AddCompany /> },
            { path: 'update-company', element: <AddCompany /> },
            { path: 'view-company', element: <AddCompany /> },
          ],
        },
        {
          path: 'memos',
          element: <MemosLayout />,
          children: [
            { path: '', element: <Memos /> },
            { path: 'new-memos-request', element: <CreateMemo /> },
          ],
        },
        {
          path: 'memos-request',
          element: <MemosRequest />,
        },
        {
          path: 'credit-admin-list',
          element: <CreditList />,
          children: [],
        },
        {
          path: 'credit-admin',
          element: <Credit />,
          children: [
            {
              path: 'sanction-letter',
              element: <SanctionLetter />,
            },
            {
              path: 'document-submission',
              element: <DocumentSubmission />,
            },
          ],
        },
        {
          path: 'legal-admin-list',
          element: <LegalList />,
        },
        {
          path: 'legal-admin',
          element: <Legal />,
          children: [
            {
              path: 'list-of-documents',
              element: <DocumentList />,
            },
            {
              path: 'final-documents',
              element: <FinalDocumentList />,
            },
          ],
        },
        {
          path: 'archive',
          element: <ArchiveList />,
        },
      ],
    },
  ]);
};

export default Router;
