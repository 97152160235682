import { useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../../constants/strings';
import { RootState } from '../../../../state/store';
import LinearStepper from '../../../common/stepper';
import FinancialAssesment from './financial-assesment';
import GenerateRiskRating from './generate-risk';
import PeerAnalysis from './peer-analysis';
import { riskUnitApproval } from 'services/loan-projection-services';
import { toggleLoader } from 'state/reducers/common-actions';
import {
  generateRiskRating,
  getRiskRationale,
  getRiskRationaleData,
  saveRiskRational,
} from 'services/common-services';
import ActionBar from 'components/styled-components/ActionBar';
import DialogWithComment from 'components/common/modals/DialogWithComment';
import useLoanTable from 'hooks/useLoanTable';
import { filterGridEmptyData } from 'utils/common-utils';

const RiskRationale = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { getRequestStatusCode } = useLoanTable();
  const viewMode =
    state.viewOnly || state?.statusCode === getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') ? true : false;
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const [approveReturn, setApproveReturn] = useState<boolean | null>(null);
  const { menuList }: any = useSelector((state: RootState) => state.user?.userDetails);
  const { loanRequestId, status }: any = useSelector((state: RootState) => state.loanProposal);
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      id: null,
      loanRequestId: state.loanRequestId,
      financialAssessment: {
        currentProposal: '',
        riskMitigation: '',
        sanctionDetails: [], //data from proposal & TC sanction details section
        borrowerSummary: {
          proposalROE: '',
          borrower: '',
          externalRating: '',
          industrySubIndstry: '',
          ratedBy: '',
          industryOutlook: '',
          countryOfRisk: '',
          previousRating: '',
          empId: '',
          currentRating: '',
          teamLead: '',
          riskWeight: '',
          riskAnalyst: '',
          ratingDate: '',
          headOfRisk: '',
        },
      },
      ratings: [],
      peerAnalysis: [{}],
      commentByRiskTeam: '',
      finalRatingDetails: {
        finalRiskRating: null,
        finalScore: null,
      },
    },
  });

  const watchAllFields = watch();

  const { data: allData } = useQuery({
    queryKey: 'getRiskRationaleData',
    queryFn: () => getRiskRationaleData(state.loanRequestId),
    onSuccess: (data: any) => {
      if (data) {
        reset({
          ...data,
          peerAnalysis: data?.peerAnalysis && data?.peerAnalysis.length > 0 ? data?.peerAnalysis : [{}],
        });
      }
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  useQuery({
    queryKey: 'getRiskRationale',
    queryFn: () => getRiskRationale(state.loanRequestId),
    enabled: allData?.ratings?.length > 0 ? false : true,
    onSuccess: (data: any) => {
      if (data) {
        updateRatingData(data);
      }
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const updateRatingData = (ratingData: any) => {
    const dataArr = ratingData.map((rating: any) => {
      return { ...rating, sNo: rating.id };
    });
    setValue('ratings', dataArr);
  };

  const riskUnitApprovalMutation = useMutation((loanRequestDetail: any) => riskUnitApproval(loanRequestDetail), {
    onSuccess: (data, variables) => {
      if (data?.respCode === 400) {
        enqueueSnackbar(data?.respMsg, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          `Loan Request ID : ${loanRequestId} has been ${variables?.approvalStatus ? 'Approved' : 'Returned'}`,
          {
            variant: 'success',
          }
        );
        navigate(menuList?.includes('RISK_UNIT') ? routes.RISK_UNIT_ROUTE : routes.LOAN_OFFICER_ROUTE);
      }
    },
    onError: (data, variables) => {
      enqueueSnackbar(
        `Failed to ${variables?.approvalStatus ? 'approve' : 'return'} Loan Request ID : ${loanRequestId}`,
        {
          variant: 'error',
        }
      );
    },
    onSettled: (data) => {
      dispatch(toggleLoader(false));
    },
  });

  const saveMutation = useMutation((data: any) => saveRiskRational(state.loanRequestId, data), {
    onSuccess: async (data: any) => {
      enqueueSnackbar('Successfully Updated', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(toggleLoader(false));
      queryClient.invalidateQueries('getRiskRationaleData');
    },
  });

  const getRatingMutation = useMutation((data: any) => generateRiskRating(state.loanRequestId, data), {
    onSuccess: async (data: any) => {
      setValue('finalRatingDetails', data);
      enqueueSnackbar('Rating Generated Successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    },
    onSettled: () => {
      _onSaveExit(getValues());
    },
  });

  const handleClickOpen = (approveReturn: boolean | null, clickType: string) => {
    if (clickType === 'RECOMMEND') {
      if (
        watchAllFields?.finalRatingDetails &&
        watchAllFields?.finalRatingDetails?.finalRiskRating &&
        watchAllFields?.finalRatingDetails?.finalScore
      ) {
        if (approveReturn != null) {
          setApproveReturn(approveReturn);
        }
        setOpen(true);
      } else {
        enqueueSnackbar('Please generate Risk Score & Risk Rating', {
          variant: 'error',
        });
      }
    } else {
      if (approveReturn != null) {
        setApproveReturn(approveReturn);
      }
      setOpen(true);
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setStepByIndex = (step: number) => {
    setActiveStep(step);
  };

  const onClickHandler = (comment: any) => {
    if (loanRequestId && approveReturn != null && comment && state?.flowableTaskId) {
      riskUnitApprovalMutation.mutate({
        approvalStatus: approveReturn,
        loanId: loanRequestId,
        approvalComment: comment,
        approvalTaskId: state?.flowableTaskId,
      });
    }
  };

  const steps = [
    {
      label: 'Peer Analysis',
    },
    {
      label: 'Generate Rating',
    },
    {
      label: 'Rating Statement',
    },
  ];

  const formProps = {
    control,
    setValue,
    getValues,
    watchAllFields,
    viewMode,
  };

  // step handler
  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return <PeerAnalysis {...formProps} />;
      case 1:
        return <GenerateRiskRating {...formProps} />;
      case 2:
        return <FinancialAssesment {...formProps} />;
      default:
        throw new Error('Unknown step');
    }
  };

  const _onSaveExit = async (data: any) => {
    
    if (data?.financialAssessment?.borrowerSummary?.ratingDate === undefined) {
      data.financialAssessment.borrowerSummary.ratingDate = new Date().toJSON();
    }

    const filteredData = filterGridEmptyData(data, ['peerAnalysis']);

    saveMutation.mutate(filteredData);
    if (saveMutation?.isLoading) {
      dispatch(toggleLoader(true));
    }
  };

  const _generateRating = async () => {
    getRatingMutation.mutate(getValues('ratings'));
    if (getRatingMutation?.isLoading) {
      dispatch(toggleLoader(true));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(_onSaveExit)}>
        <Box sx={{ p: 1 }}>
          <LinearStepper
            steps={steps}
            activeStep={activeStep}
            stepperStyle={{ width: '50%' }}
            setStepByIndex={setStepByIndex}
          />
        </Box>
        <Box
          style={{
            height: 'calc(100vh - 220px)',
            overflowY: activeStep === 1 ? 'hidden' : 'scroll',
            marginTop: '3px',
            paddingBottom: '40px',
            width: '100%',
          }}>
          <Box>{handleSteps(activeStep)}</Box>
        </Box>
        {state?.statusCode !== getRequestStatusCode('PENDING_FOR_RISK_ASSIGNMENT') && (
          <ActionBar>
            <Button
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ marginRight: 2, p: 1 }}>
              Back
            </Button>
            {activeStep === 2 ? null : (
              <Button variant="contained" onClick={handleNext} sx={{ marginRight: 2, p: 1 }}>
                Continue
              </Button>
            )}
            {!state?.viewOnly && state?.statusCode === getRequestStatusCode('PENDING_FOR_RISK_APPROVAL') && (
              <Button
                variant="contained"
                onClick={() => handleClickOpen(false, 'REWORK')}
                sx={{ marginRight: 2, p: 1 }}>
                Rework
              </Button>
            )}
            {!state?.viewOnly && activeStep === 1 && (
              <Button variant="contained" sx={{ marginRight: 2, p: 1 }} onClick={_generateRating}>
                Generate Risk Rating
              </Button>
            )}
            {!state?.viewOnly && activeStep != 1 && (
              <Button variant="contained" sx={{ marginRight: 2, p: 1 }} type="submit">
                Save Draft
              </Button>
            )}
            {!state?.viewOnly && state?.statusCode === getRequestStatusCode('PENDING_FOR_RISK_APPROVAL') && (
              <Button
                variant="contained"
                onClick={() => handleClickOpen(true, 'RECOMMEND')}
                sx={{ marginRight: 2, p: 1 }}>
                Recommend
              </Button>
            )}
          </ActionBar>
        )}
      </form>
      <DialogWithComment
        open={open}
        setOpen={setOpen}
        title={'Confirmation'}
        description={`Do you want to ${approveReturn ? 'Approve' : 'Return'} for Loan Request ID: ${
          state?.loanRequestId
        } ?`}
        type={'info'}
        onClickHandler={onClickHandler}
      />
    </>
  );
};

export default RiskRationale;
